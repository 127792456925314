.possibility-wrap {
  width: 100vw;
  background-color: #F6F6F6;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 70vh;
}

.possibility-wrap main {
  margin: 100px auto 100px auto;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.possibility-wrap main .possibility-step1,
.possibility-wrap main .possibility-step2 {
  width: 620px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 4px 18px 0 #0000000a;
  padding: 24px 40px 32px 40px;
}

.possibility-wrap main .possibility-step1 {
  height: 212px;
}

.possibility-wrap main .step1-wrap h2 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 22px;
  line-height: 140%;
}

.possibility-wrap main .button-wrap {
  display: flex;
  justify-content: space-between;
}

.possibility-wrap main .possibility-step2 .button-wrap {
  margin-top: 0;
}
.possibility-wrap main .button-wrap .type-btn-wrap button {
  font-family: "PretendardSB", "sans-serif";
  font-size: 15px;
  width: 110px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  margin-right: 10px;
  color: #858585;
}

.possibility-wrap main .button-wrap .type-btn-wrap button.active {
  color: #319dff;
  border: 1px solid #319dff;
  background-color: #E6F0F9;
}

.possibility-wrap main .button-wrap .strength-btn-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap {
  display: flex;
  gap: 5px;
  position: relative;
}
.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap img {
  cursor: pointer;
}
.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap img:hover+article {
  display: block;
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap article::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap article{
  top: 25px;
  /* left: -10%; */
  right: -137px;
  /* 위치 조정 */
  width: max-content;
  position: absolute;
  background-color: black;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  font-size: 12px;
  color: white;
  padding: 10px 12px;
  display: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  z-index: 10;
  white-space: nowrap;
  border-radius: 4px;
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap article::before {
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap p {
  font-family: "PretendardSB", "sans-serif";
  font-size: 15px;
  line-height: 140%;
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap p span {
  color: #ff0000;
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap button {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  font-family: "PretendardSB", "sans-serif";
  font-size: 15px;
  line-height: 140%;
  border: 1px solid #E0E0E0;
  color: #858585;
}

.possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap button.active {
  border: 1px solid #319dff;
  color: #319dff;
  background-color: #E6F0F9;
}

.possibility-wrap main .input-wrap {
  position: relative;
}

.possibility-wrap main input {
  border: 1px solid #319dff;
  width: 100%;
  height: 46px;
  padding: 16px;
  font-size: 16px;
  line-height: 140%;
  border-radius: 4px;
  margin-top: 16px;
}

.possibility-wrap main .clear-btn {
  position: absolute;
  right: 10px;
  bottom: 7px;
}

.possibility-wrap main .clear-btn img {
  width: 24px;
  height: 24px;
  filter: invert(53%) sepia(91%) saturate(627%) hue-rotate(185deg);
}

.possibility-wrap main .input-wrap.product input {
  margin-top: 0;
}
.possibility-wrap main .input-wrap.product {
  display: flex;
  margin-top: 16px;
}

.possibility-wrap main .possibility-step1 button input::placeholder {
  color: #858585;
}
.possibility-wrap .step1-wrap .possibility-step2 .search-btn {
  background-color: #319dff;
    border-radius: 4px;
    width: 100px;
    height: 44px;
    color: white;
    font-family: "PretendardSB", "sans-serif";
    margin-left: 8px;
}

.possibility-wrap main .next-btn {
  background-color: #319dff;
  width: 100%;
  color: white;
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  height: 56px;
  border-radius: 8px;
  box-shadow: 0 4px 18px 0 #0000000a;
}

.possibility-wrap main .step1-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.possibility-wrap main .possibility-step2 .list,
.brandnaming-wrap main .product-wrap .list {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  flex-direction: column;
}

.possibility-wrap main .possibility-step2 .list .list-item ,
.brandnaming-wrap main .product-wrap .list .list-item{
  width: 100%;
  border: 1px solid #E0E0E0;
  padding: 0 16px;
  border-radius: 4px;
}

.possibility-wrap main .possibility-step2 .list-item.open,
.brandnaming-wrap main .product-wrap .list-item.open {
  background: #E6F0F9;
  border: 1px solid #319dff;
  transition: all 0.3s;
}

.possibility-wrap main .possibility-step2 .list-item.open strong,
.brandnaming-wrap main .product-wrap .list-item.open strong {
  color: #319dff;
  font-family: "PretendardSB", "sans-serif";
}

.possibility-wrap main .possibility-step2 .list-item.open span,
.brandnaming-wrap main .product-wrap .list-item.open span {
  color: #319dff;
  font-family: "PretendardSB", "sans-serif";
}

.possibility-wrap main .possibility-step2 .list .list-item .list-preview,
.brandnaming-wrap main .product-wrap .list .list-item .list-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 46px;
}

.possibility-wrap main .possibility-step2 .list .list-item strong,
.brandnaming-wrap main .product-wrap .list .list-item strong {
  font-size: 16px;
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
  white-space: nowrap;
}


.possibility-wrap main .possibility-step2 .list .list-item span,
.brandnaming-wrap main .product-wrap .list .list-item span {
  margin: 0 16px;
  font-size: 14px;
}

.possibility-wrap main .possibility-step2 .list .list-item img.rotated,
.brandnaming-wrap main .product-wrap .list .list-item img.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.possibility-wrap main .possibility-step2 .list .list-item .list-detail,
.brandnaming-wrap main .product-wrap .list .list-item .list-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 12px;
}

.possibility-wrap main .possibility-step2 .list .list-item .list-detail li,
.brandnaming-wrap main .product-wrap .list .list-item .list-detail li {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.possibility-wrap main .possibility-step2 .list .list-item .list-detail li img,
.brandnaming-wrap main .product-wrap .list .list-item .list-detail li img {
  width: 16px;
  height: 16px;
}

.possibility-wrap main .possibility-step2 .list .list-item .list-detail li p,
.brandnaming-wrap main .product-wrap .list .list-item .list-detail li p{
  font-size: 13px;
  line-height: 140%;
}

.possibility-wrap .possibility-tempResult,
.possibility-wrap .step2-wrap .possibility-edit {
  background-color: white;
  width: 628px;
  border-radius: 16px;
  padding: 32px 40px;
  box-shadow: 0 4px 18px 0 #0000000a;
}

.possibility-wrap .step2-wrap .possibility-edit {
  margin: 16px auto;
}

.possibility-wrap .possibility-tempResult h2,
.possibility-wrap .step2-wrap .possibility-edit h2,
.possibility-wrap .step3-wrap h2 {
  font-size: 18px;
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
  margin-bottom: 12px;
  color: #333333;
}

.possibility-wrap .possibility-tempResult .result {
  font-size: 22px;
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
  margin: 0 0 16px 8px;
}

.possibility-wrap .possibility-tempResult hr {
  margin-bottom: 16px;
  background-color: #E0E0E0;
  border: none;
  height: 1px;
}

.possibility-wrap .possibility-tempResult .title-wrap {
  display: flex;
  align-items: flex-end;
}

.possibility-wrap .possibility-tempResult .title-wrap span {
  margin-bottom: 16px;
  font-size: 14px;
  margin-left: 12px;
  color: #858585;
}
.possibility-wrap .step2-wrap .possibility-tempResult .item-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.possibility-wrap .step2-wrap .possibility-tempResult .item-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.possibility-wrap .step2-wrap .possibility-tempResult .item-wrap .item-title {
  font-size: 14px;
  color: #585858;
}

.possibility-wrap .step2-wrap .possibility-tempResult .item-wrap .item-content {
  font-size: 13px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  padding: 12px 8px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.possibility-wrap .step2-wrap .possibility-tempResult .item-wrap .item-content.first {
  width: 63px;
  text-align: center;
}

.possibility-wrap .step2-wrap .possibility-tempResult .item-wrap .item-content.second {
  width: 355px;
}

.possibility-wrap .caution{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.possibility-wrap .possibility-edit .caution {
  align-items: flex-start;
}

.possibility-wrap .possibility-edit .caution img {
  margin-top: 2px;
}
.possibility-wrap .step2-wrap .possibility-edit .caution p,
.possibility-wrap .caution p
 {
  font-size: 14px;
  color: #585858;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}

.possibility-wrap .step3-wrap .caution p {
  font-size: 16px;
  color: #585858;
}

.possibility-wrap .step3-wrap .caution p strong {
  font-size: 16px;
  color: #585858;
  font-family: "PretendardB", "sans-serif";
}

.possibility-wrap .step2-wrap .possibility-edit .caution p strong {
  font-size: 14px;
  color: #585858;
  font-family: "PretendardSB", "sans-serif";
}

.possibility-wrap .step3-wrap .chart-wrap .description p strong {
  font-family: "PretendardB", "sans-serif";
  color: #585858;
}


.possibility-wrap .step2-wrap .possibility-edit .edit-content {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.possibility-wrap .step2-wrap .possibility-edit .edit-content.second {
  margin-top: 8px;
}

.possibility-wrap .step2-wrap .possibility-edit .edit-content .edit-title {
  color: #585858;
  font-size: 16px;
  line-height: 140%;
  white-space: nowrap;
}

.possibility-wrap .step2-wrap .possibility-edit .edit-content input {
  width: 431px;
  border: 1px solid #e0e0e0;
  height: 46px;
  padding: 12px 16px;
  border-radius: 4px;
  font-family: "PretendardM", "sans-serif";
  margin-top: 0;
}

.possibility-wrap .step2-wrap .possibility-edit .edit-content input:disabled {
  color: #666; 
  border: 1px solid #e0e0e0;
}

.possibility-wrap .step2-wrap .next-btn:disabled {
  background-color: #b8b8b8;
}

.possibility-wrap .step2-wrap .possibility-edit .edit-content button {
  position: absolute;
  right: 10px;
}

.possibility-wrap .step2-wrap .possibility-edit .edit-content input.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  color: transparent;
  animation: skeleton-animation 3s ease-in-out infinite;
}

.possibility-wrap .step2-wrap .possibility-edit h3 {
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  margin: 37px auto 10px auto
}

@keyframes skeleton-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}



.possibility-wrap .step3-wrap {
  display: flex;
  gap: 16px;
}

.possibility-wrap .step3-wrap article {
  width: 620px;
  padding: 32px 40px;
  border-radius: 16px;
  box-shadow: 0 4px 18px 0 #0000000a;
  background-color: white;
}


.possibility-wrap .step3-wrap .possibility-tempResult {
  margin-bottom: 16px;
}



.possibility-wrap .step3-wrap .possibility-tempResult .select-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.possibility-wrap .step3-wrap .possibility-tempResult .select-wrap .item-title {
  font-size: 14px;
  line-height: 140%;
  color: #585858;
}

.possibility-wrap .step3-wrap .possibility-tempResult .select-wrap select {
  font-size: 14px;
  line-height: 140%;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.possibility-wrap .step3-wrap .possibility-tempResult .select-wrap .maincode {
  width: 483px;
  height: 40px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  padding: 10px 12px;
}

.possibility-wrap .step3-wrap .possibility-tempResult .select-wrap .product-item {
  width: 329px;
  height: 40px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  padding: 10px 12px;
}

.possibility-wrap .step3-wrap .chart-wrap .split-result {
  display: flex;
  align-items: center;
  gap: 12px;
}

.possibility-wrap .step3-wrap .chart-wrap .split-result .result-title {
  font-size: 22px;
  line-height: 140%;
  color: #333333;
}

.possibility-wrap .step3-wrap .chart-wrap .split-wrap {
  width: 100%;
  margin-top: 24px;
}

.possibility-wrap .step3-wrap .chart-wrap .split-result .result-content {
  font-size: 22px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
}

.possibility-wrap .step3-wrap .chart-wrap {
  margin-bottom: 16px;
}

.possibility-wrap .step3-wrap .chart-wrap .content-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.possibility-wrap .step3-wrap .chart-wrap .content-center .warn-wrap {
  display: flex;
  gap: 8px;
  align-items: center;
}

.possibility-wrap .step3-wrap .chart-wrap .content-center .warn-wrap .description {
  color: #585858;
  font-size: 16px;
  line-height: 140%;
  font-family: "PretendardR", "sans-serif";
}


.possibility-wrap .step3-wrap .chart-wrap .content-center hr {
  background-color: #E5E5E5;
  width: 100%;
  border: none;
  height: 1px;
}

.possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap .result-title {
  font-size: 22px;
  line-height: 140%;
  color: #333;
}

.possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap span {
  font-size: 25px;
  color: #dddddd;
  margin: 0 40px;
}

.possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap .result-content {
  font-size: 22px;
  line-height: 140%;
  margin-left: 12px;
  font-family: "PretendardSB", "sans-serif";
  color: #319dff;
}

.possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap .result-content.red {
  color: #E4423C;
}

.possibility-wrap .step3-wrap .right-section .tab-list {
  margin-top: 12px;
  display: flex;
  gap: 12px;
}

.possibility-wrap .step3-wrap .right-section .tab-list button {
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  width: 172px;
  height: 46px;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
}

.possibility-wrap .step3-wrap .right-section .tab-list button.active {
  border: 2px solid #319dff;
  background-color: #EFF4FE;
  color: #319dff;
}

.possibility-wrap .step3-wrap .right-section section {
  margin-top: 24px;
}

.possibility-wrap .step3-wrap .right-section section .cardList {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.possibility-wrap .step3-wrap .right-section section .cardList .card {
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4F4F4;
}

.possibility-wrap .step3-wrap .right-section section .cardList .card .img-wrap {
  width: 100%;
  background-color: white;
  height: 128px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.possibility-wrap .step3-wrap .right-section section .cardList .card img {
  width: 137px;
  height: 110px;
}

.possibility-wrap .step3-wrap .right-section section .cardList .card .num-wrap {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 34px;
}

.possibility-wrap .step3-wrap .right-section section .cardList .card .num-wrap span {
  font-family: "PretendardSB", "sans-serif";
  font-size: 12px;
  line-height: 140%;
  padding: 2px 5px;
  background-color: #319dff;
  color: white;
  border-radius: 4px;
}

.possibility-wrap .step3-wrap .right-section section .cardList .card .num-wrap p {
  font-size: 12px;
  line-height: 140%;
  font-family: "PretendardM", "sans-serif";
}

.possibility-wrap .step3-wrap .right-section section hr {
  margin: 24px 0;
  border: none;
  height: 1px;
  background-color: #e5e5e5e5;
}

.possibility-wrap .step3-wrap .right-section section .badgeList {
  display: flex;
  justify-content: center;
  align-items: center;
}

.possibility-wrap .step3-wrap .right-section section .badgeList .badge-title {
  font-size: 20px;
  line-height: 140%;
  color: #585858;
  margin-right: 8px;
}

.possibility-wrap .step3-wrap .right-section section .badgeList .badge {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  color: white;
  border-radius: 34px;
  padding: 4px 12px;
  background-color: #E4423C;
}

.possibility-wrap .step3-wrap .right-section section .badgeList .badge.blue {
  background-color: #319dff;
}

.possibility-wrap .step3-wrap .right-section section .badgeList strong {
  font-size: 20px;
  font-family: "PretendardSB", "sans-serif";
  color: #1150cb;
  line-height: 140%;
}

.possibility-wrap .step3-wrap .right-section section .badgeList span {
  font-size: 24px;
  color: #D2D2D2;
  margin: 0 23px;
  font-family: "PretendardR", "sans-serif";
}

.possibility-wrap .step3-wrap .right-section section h3 {
  font-size: 20px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  margin-bottom: 16px;
}

.possibility-wrap .step3-wrap .right-section section ul {
  margin: 0 20px;
}

.possibility-wrap .step3-wrap .right-section section li {
  list-style-type: disc;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
  color: #333333;
}

.possibility-wrap .step3-wrap .right-section section li strong {
  font-family: "PretendardB", "sans-serif";
  font-size: 20px;
  line-height: 140%;
  color: #333333;
}

.possibility-wrap .step3-wrap .right-section section .description {
  line-height: 140%;
  font-size: 20px;
}