.monitorName-wrap {width: 100%;}
.monitorName-wrap .monitor-all{
  width: 100%;
  height: 100vh;
}
.monitorName-wrap .monitor-contentBox01{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monitorName-wrap .monitor-contentBox01 .content-topBox{
  width: 100%;
} 
.monitorName-wrap .monitor-contentBox01 .content-topBox .content-top{
  width: 100%;  
  max-width: 1280px;
  height: auto;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox01 .content-topBox .content-top span{
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox01 .content-topBox .content-top h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox01 .content-topBox .content-top p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 1280px;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul li{
  width: 400px;
  padding: 40px 0px 40px 30px ;
  background: #EFEDFD;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul li img{
  display: block;
  width: 240px;
  height: 200px;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul li h3{
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  line-height: 140%;
  margin-bottom: 12px;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul li span{
  display: block;
  color: #666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  text-align: left;
  line-height: 140%; 
  margin-bottom: 40px;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-01{
  width: 240px;
  height: 200px;
  background-image: url('image/monitor01_01.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-02{
  width: 297px;
  height: 200px;
  background-image: url('image/monitor01_02.png');
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-03{
  width: 205px;
  height: 200px;
  background-image: url('image/monitor01_03.png');
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
}
/* 섹션2 */
.monitorName-wrap .monitor-contentBox02{
  width: 100%;
  height: 100vh;
  min-width: 1920px;
  background: rgba(116, 96, 212, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox{
  width: 100%;
  /* height: 100vh; */
  margin: 0px auto;
  box-sizing: border-box;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox .monitor-youtube{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 1080px;
  height: 550px;
  background: #383838;
  color: #fff;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox ul{
  display: flex;
  justify-content: center;
  gap: 14px;
  margin-bottom: 70px;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox ul li{
  /* min-width: 215px; */
  min-width: 100px;
  border-radius: 100px;
  text-align: center;
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  padding: 16px 24px;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox .selected-tab {
  background-color: #319DFF;
  color: #fff;
}
.monitorName-wrap .monitor-contentBox02 .content-oneBox .non-tab {
  background-color: white;
  color: #050F2F;
}
/* 섹션2-1 */
.monitorName-wrap .monitor-contentBox0201{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: #F6F6F6;
}
.monitorName-wrap .monitor-contentBox0201 .content-topBox{
  width: 100%;
} 
.monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top{
  width: 100%;  
  max-width: 1280px;
  height: auto;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top span{
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0px 45px;
  width: 1280px;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li{
  width: 305px;
  background: #fff;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li img{
  display: block;
  width: 240px;
  height: 200px;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li b{
  display: block;
  text-align: center;
  color: #7460D4;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
  margin-bottom: 16px;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li h3{
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
  margin-bottom: 20px;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li span{
  display: block;
  color: #666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  line-height: 140%; 
  margin-bottom: 40px;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li .monitor0201-01{
  height: 200px;
  background-image: url('image/monitor02_01.png');
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li .monitor0201-02{
  height: 200px;
  background-image: url('image/monitor02_02.png');
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
}
.monitorName-wrap .monitor-contentBox0201 .content-botBox ul li .monitor0201-03{
  height: 200px;
  background-image: url('image/monitor02_03.png');
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
}
/* 섹션3 */
.monitorName-wrap .monitor-contentBox03{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox{
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-youtube{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 550px;
  background: #383838;
  color: #fff;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-tab{
  display: flex;
  justify-content: center;
  gap: 14px;
  width: 12%;
  margin: 0px auto 0px;
  background: #ccc;
  padding: 8px;
  border-radius: 100px;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-tab li{
  min-width: 95px;
  border-radius: 100px;
  text-align: center;
  font-size: 18px;
  font-family: "PretendardRdasda", "sans-serif";
  padding: 16px 24px;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-tab .selected-tab {
  background-color: #319DFF;
  color: #fff;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-tab .non-tab {
  background-color: white;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-imgbox{
  width: 100%;
}
.monitor-contentBox03 .content-oneBox .monitor-imgbox img{
  display: block;
  width: 980px;
  height: 635px;
  margin: 0px auto;
}
/* 섹션4 */
.monitorName-wrap .monitor-contentBox04{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox{
  width: 100%;
  height: 100vh;
  height: auto;
  margin: 0px auto;
  box-sizing: border-box;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox span{
  display: block;
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox{
  display: flex;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
  width: 50%;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
  margin: 0px auto;
  min-height: 520px;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
  width: 50%;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li{
  display: flex;
  /* align-items: center; */
  width: 620px;
  height: 110px;
  margin-bottom: 20px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px 35px 24px 24px;
  border: 2px solid transparent; 
  transition: border 0.3s; 
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li:hover{
  border-color: #1CC4CB;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li img{
  display: inline-block;
  margin-right: 20px;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} 
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li h3{
  width: 100%;
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  line-height: 150%;
}
.monitorName-wrap .monitor-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content p{
  width: 100%;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  color: #666;
}
/* 섹션5 */
.monitorName-wrap .monitor-contentBox05{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox{
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;
  box-sizing: border-box;
}
.monitorName-wrap .monitor-contentBox05 h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox05  h2 span{
  color: #319DFF;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox{
  position: relative;
  margin: 0px auto;
  min-width: 1280px;
  height: 350px;
  background-image: url('image/subBg6_1.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content{ 
  width: 502px;
  height: 150px;
  position: absolute;
  right: 265px;
  bottom: 99px;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content p{
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content .guidebox-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  font-family: "PretendardR", "sans-serif";
  padding: 8px 16px;
  border: 1px solid #050F2F;
  cursor: pointer;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  min-height: 254px;
  background: #7460D4;
  border-radius: 8px;
  margin: 20px auto 0px;
  padding: 60px 180px 60px 120px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text{
  width: 70%;;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text h4{
  color: #fff;
  font-family: "PretendardB", "sans-serif";
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 40px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text span{
  color: #fff;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link{
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 245px; */
  width: 290px;
  height: 60px;
  background-color: #fff;
  color: #050F2F;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link .main-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  gap: 8px;
  font-family: "PretendardR", "sans-serif";
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link .main-nav img{
  transform: rotate(90deg);
}
/* 
풀페이지 스크롤
.monitor-allwrap .Section{
  max-width: 1280px;
  height: 100vh !important;
  border: 1px solid #000;
}
.monitor-section01{background-color: #009fff;}
.monitor-section05 {padding: 140px 320px !important;}
.monitor-section05 .final-title-wrap h6 ,
.monitor-section05 .final-title-wrap h5,
.monitor-section05 .final-title-wrap h5 span{
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}
.monitor-section05 .final-title-wrap {
  margin-bottom: 60px;

}
.monitor-section05 .final-title-wrap h6 {
  line-height: 46px;
  color: #050F2F;
  margin-bottom: 4px;
}
.monitor-section05 .final-title-wrap h5 {
  line-height: 70px;
}
.monitor-section05 .final-title-wrap h5 span {
  line-height: 70px;
}
.monitor-section05 .title-point {
  color: #319DFF;
}
.monitor-section05 .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  min-width: 1280px;
  height: 350px;
  background-image: url(http://localhost:3000/static/media/guideBg02.bac9e6a….svg);
  background-repeat: no-repeat;
  background-position: center;
}
.monitor-section05 .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.monitor-section05 .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  padding: 8px 16px;
  border: 1px solid #000;
  cursor: pointer;
}
.monitor-section05 .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  color: #333333;
}
.monitor-section05 .box-wrap .service-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: 350px;
  padding: 83px 216px 80px 104px;
  border-radius: 8px;
  background-color: #00274A;
}
.monitor-section05 .box-wrap .service-box .service-text, .service-link {
  width: 50%;
  color: #fff;
}
.monitor-section05 .box-wrap .service-box .service-text h4 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 40px;
}
.monitor-section05.box-wrap .service-box .service-text span {
  font-size: 20px;
  color: #DDDDDD;
}
.monitor-section05 .box-wrap .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: #319DFF;
}
.monitor-section05 .box-wrap .service-box .service-link a {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
} */