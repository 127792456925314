.content-customer-wrap .customer {
  width: 100%;
  min-width: 1280px;
  padding-top: 100px;
  margin: 0 auto;
}
.content-customer-wrap .customer > .title-wrap {
  position: relative;
}
.content-customer-wrap .customer > .title-wrap > .title {
  margin-bottom: 0;
}
.content-customer-wrap .customer > .title-wrap > .title > span {
  color: #15171a;
  font-size: 24px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  font-weight: 500;
  line-height: 36px;
}
.content-customer-wrap .customer > .title-wrap > .title > .circle {
  position: absolute;
  left: -22px;
  top: -14px;
  z-index: -1;
  width: 40px;
  height: 40px;
  background-color: #fbe395;
  border-radius: 50%;
}
.content-customer-wrap .customer > .title-wrap > .description {
  color: #858585;
  font-size: 14px;
  line-height: 22px;
  font-family: "NotoSansR", "맑은 고딕", "sans-serif";
  margin-top: 4px;
}
.content-customer-wrap .faq .faq-list > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  border-radius: 0px;
  margin-bottom: 0px;
  margin: -1px;
  padding: 32px 40px;
  overflow: hidden;
}
.content-customer-wrap .faq .faq-list > li:first-child {
  margin-top: 0px;
  border-top: 1px solid #050F2F;
}
.content-customer-wrap .faq .faq-list > li:last-child {
  margin-bottom: 56px;
  border-bottom: 1px solid #050F2F;
}
.content-customer-wrap .faq .faq-list > li > .arcodian {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: height 0.5s;
  cursor: pointer;
}
.content-customer-wrap .faq .faq-list > li > .arcodian > .img-wrap:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-customer-wrap .faq .faq-list > li > .arcodian > .img-wrap:first-child > span {
  font-size: 18px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  color: #15171a;
  line-height: 36px;
  margin-left: 12px;
  font-weight: 500;
}
.content-customer-wrap .faq .faq-list > li > .arcodian > .img-wrap:first-child > span.Q {
  color: #319DFF;
  font-size: 24px;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap {
  margin-top: 39px;
  height: 0;
  margin: 0;
  visibility: hidden;
  transition: 0.5s;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > p {
  height: 0;
  margin: 0;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > p > span {
  font-size: 18px;;
  font-family: "PretendardR", "sans-serif";
  margin-left: 12px;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > p > span.A {
  margin-left: 0;
  font-size: 24px;
  line-height: 36px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  color: #858585;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list {
  margin-top: 31px;
  padding-left: 76px;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list li {
  list-style: disc;
  margin-bottom: 24px;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list li > p {
  color: #ff8e00;
  margin-bottom: 8px;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  cursor: pointer;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list li > p > a {
  color: #319DFF;
  margin-bottom: 8px;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list li > span {
  font-size: 18px;
  /* font-family: "NotoSansR", "맑은 고딕", "sans-serif"; */
  font-family: "PretendardR", "sans-serif";
  line-height: 22px;
  padding-left: 10px;
}
.content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list li:last-child {
  margin-bottom: 0;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap {
  height: 0;
  margin: 0;
  visibility: hidden;
  transition: 0.5s;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > p {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 0;
  margin: 0;
  visibility: hidden;
  margin-bottom: 22.5px;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > p > span {
  margin-left: 16px;
  font-size: 16px;
  font-family: "NotoSansR", "맑은 고딕", "sans-serif";
  font-weight: 500;
  line-height: 23px;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > p > span.A {
  margin-left: 0;
  font-size: 24px;
  line-height: 36px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  color: #858585;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > p > span > span {
  font-size: 16px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  font-weight: 500;
  line-height: 23px;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description {
  padding-left: 40px;
  height: 0;
  margin: 0;
  visibility: hidden;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description > p {
  font-size: 14px;
  font-family: "NotoSansR", "맑은 고딕", "sans-serif";
  line-height: 22px;
  color: #15171a;
  margin-bottom: 16px;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description > p > span {
  font-size: 14px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 22px;
  font-weight: 500;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description > p:last-child {
  margin-bottom: 0;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description > p.high-light {
  color: #ff8e00;
  font-weight: 500;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 20px;
}
.content-customer-wrap .faq .faq-list > li:hover {
  /* background-color: #319dff80; */
  background-color: #fff;
}
.content-customer-wrap .faq .faq-list > li.active {
  background-color: #F6F6F6;
}
.content-customer-wrap .faq .faq-list > li.active > .find-wrap {
  height: max-content;
  visibility: initial;
  margin-top: 38.5px;
  transition: 0.5s;
}
.content-customer-wrap .faq .faq-list > li.active > .find-wrap > p {
  height: max-content;
  visibility: inherit;
}
.content-customer-wrap .faq .faq-list > li.active > .change-wrap {
  height: max-content;
  visibility: initial;
  margin-top: 38.5px;
  transition: 0.5s;
}
.content-customer-wrap .faq .faq-list > li.active > .change-wrap > p {
  height: max-content;
  visibility: inherit;
  transition: 0.5s;
}
.content-customer-wrap .faq .faq-list > li.active > .change-wrap > .change-description {
  height: max-content;
  visibility: inherit;
  transition: 0.5s;
}

/* faq */
.content-customer-wrap .customer-Title{
  margin: 0px auto;
  text-align: center;
}
.content-customer-wrap .customer-Title h2{
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 16px;
  line-height: 140%;
}
.content-customer-wrap .customer-Title .description{
  font-family: "PretendardR", "sans-serif";
  font-size: 20px;
  color: #666666;
  margin-bottom: 40px;
}
.content-customer-wrap .faq .service-box{
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 1280px;
    height: 276px;
    border-radius: 8px;
    background-color: #00274A;
    margin-bottom: 120px;
}
.content-customer-wrap .faq  .service-box .service-text, .service-link {
  width: 50%;
  color: #fff;
}
.content-customer-wrap .faq  .service-box .service-text h4 {
  font-size: 26px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 40px;
}
.content-customer-wrap .faq  .service-box .service-text span {
  color: #DDDDDD;
  font-size: 16px;
  line-height: 140%;
}
.content-customer-wrap .faq  .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: #319DFF;
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.content-customer-wrap .faq  .service-box .service-link a {
  display: flex;
  /* justify-content: space-between; */
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
  gap: 12px;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description > p{
  font-size: 18px;
  font-family: "PretendardR", "sans-serif";
  line-height: 22px;
  color: #15171a;
  margin-bottom: 16px;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description > p.high-light{
  color: #E72530;
  font-weight: 500;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > p > span{
    margin-left: 9px;
    font-size: 18px;
    font-family: "PretendardR", "sans-serif";
    font-weight: 500;
    line-height: 23px;
}
.content-customer-wrap .faq .faq-list > li > .change-wrap > p > span > span {
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  font-weight: 500;
  line-height: 23px;
}

.content-customer-wrap .faq .faq-list > li > .arcodian > .img-wrap:first-child > span{
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  color: #15171a;
  line-height: 36px;
  margin-left: 12px;
  font-weight: 500;
}

.content-customer-wrap .faq .faq-list > li > .arcodian > .img-wrap:first-child > span.Q-text{
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  color: #319DFF;
}
.content-customer-wrap .faq .faq-list > li > div > p > .A-text{
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  color: #858585;
  margin-left: 12px;
}