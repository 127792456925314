.selfProcedureName-wrap {
  width: 100%;
}

.selfProcedureName-wrap .selfProcedure-all {
  width: 100%;
  /* min-width: 1920px; */
  /* height: 100vh; */
}

.selfProcedureName-wrap .selfProcedure-contentBox01 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 90px; */
  /* margin-top: 147px; */
}

.selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox {
  width: 100%;
}

.selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top {
  width: 100%;
  /* max-width: 1280px; */
  /* height: 100vh; */
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top img {
  /* max-width: 1280px;
  display: block;
  margin: 0px auto; */
  /* margin-left: 105px;
  padding-bottom: 113px; */
  max-width: 100%;
}

.selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top span {
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top h2 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 43px;
  /* line-height: 140%; */
  color: #050F2F;
}

.selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top p {
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}

/* 섹션2 */
.selfProcedureName-wrap .selfProcedure-contentBox02 {
  width: 100%;
  /* height: 100vh; */
  /* padding-top: 213px; */
  /* padding-top: 120px; */
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin: 0px auto;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox h2 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 50px;
  line-height: 140%;
  color: #050F2F;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox span {
  display: block;
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left {
  /* width: 60%; */
  margin-right: 60px;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img {
  /* margin: 55px auto 0;
  min-height: 520px; */
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right {
  width: 50%;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
  display: flex;
  align-items: center;
  /* width: 620px; */
  /* height: 110px; */
  /* width: 100%; */
  width: 620px;
  height: 107px;
  margin-bottom: 15px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px 35px 24px 24px;
  border: 2px solid #F1F1F1;
  transition: border 0.3s;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:hover {
  border-color: #F8BD34;
  /* border: 2px solid #F8BD34; */
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img {
  /* display: inline-block;
  margin-right: 20px; */
  /* width: 100%; */
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
  width: 100%;
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  line-height: 150%;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
  width: 100%;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  color: #666;
  margin-top: 8px;
}

.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small {
  font-size: 11px;
  font-family: "PretendardR", "sans-serif";
  color: #bfbfbf;
}
.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(1) img{
  margin-right: 30px;
  margin-left: 7px;
}
.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img{
  margin-right: 30px;
  margin-left: 6px;
}
.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img{
  margin-right: 36px;
  margin-left: 13px;
}
.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img{
  margin-right: 33px;
  margin-left: 9px;
}
.selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(5) img{
  margin-right: 31px;
  margin-left: 18px;
}
/* 섹션3 */
.selfProcedureName-wrap .selfProcedure-contentBox03 {
  position: relative;
  width: 100%;
  background-color: #F6F6F6;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top {
  width: 100%;
  max-width: 1280px;
  height: auto;
  margin: 0px auto;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top span {
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top h2 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 110px;
  line-height: 140%;
  color: #050F2F;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top p {
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 60px;
  width: 1280px;
  margin: 0px auto;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li {
  width: 300px;
  height: auto;
  padding: 40px 0px 40px;
  background: #FFFFFF;
  border-radius: 4px;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li img {
  display: block;
  margin: 0px auto;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li b {
  display: block;
  text-align: center;
  color: #F8BD34;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
  margin-bottom: 16px;

}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li h3 {
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
  margin-bottom: 12px;
}

.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li span {
  display: block;
  color: #666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  line-height: 140%;
}

/* 
풀페이지 스크롤
.selfProcedure-allwrap .Section{
  max-width: 1280px;
  height: 100vh !important;
  border: 1px solid #000;
}
.selfProcedure-section01{background-color: #009fff;}
.selfProcedure-section05 {padding: 140px 320px !important;}
.selfProcedure-section05 .final-title-wrap h6 ,
.selfProcedure-section05 .final-title-wrap h5,
.selfProcedure-section05 .final-title-wrap h5 span{
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}
.selfProcedure-section05 .final-title-wrap {
  margin-bottom: 60px;

}
.selfProcedure-section05 .final-title-wrap h6 {
  line-height: 46px;
  color: #050F2F;
  margin-bottom: 4px;
}
.selfProcedure-section05 .final-title-wrap h5 {
  line-height: 70px;
}
.selfProcedure-section05 .final-title-wrap h5 span {
  line-height: 70px;
}
.selfProcedure-section05 .title-point {
  color: #319DFF;
}
.selfProcedure-section05 .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  min-width: 1280px;
  height: 350px;
  background-image: url(http://localhost:3000/static/media/guideBg02.bac9e6a….svg);
  background-repeat: no-repeat;
  background-position: center;
}
.selfProcedure-section05 .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.selfProcedure-section05 .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  padding: 8px 16px;
  border: 1px solid #000;
  cursor: pointer;
}
.selfProcedure-section05 .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  color: #333333;
}
.selfProcedure-section05 .box-wrap .service-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: 350px;
  padding: 83px 216px 80px 104px;
  border-radius: 8px;
  background-color: #00274A;
}
.selfProcedure-section05 .box-wrap .service-box .service-text, .service-link {
  width: 50%;
  color: #fff;
}
.selfProcedure-section05 .box-wrap .service-box .service-text h4 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 40px;
}
.selfProcedure-section05.box-wrap .service-box .service-text span {
  font-size: 20px;
  color: #DDDDDD;
}
.selfProcedure-section05 .box-wrap .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: #319DFF;
}
.selfProcedure-section05 .box-wrap .service-box .service-link a {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
} */

.self-application-wrap .content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  background-color: white;
  border-radius: 16px;
  padding: 48px 75px;
  box-shadow: none;
  width: 950px;
}

.self-application-wrap .content-box h3 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  margin-bottom: 16px;
}

.self-application-wrap .btn-wrap {
  width: 100%;
  margin: 48px auto;
}

.self-application-wrap .btn-wrap>button {
  margin-right: 16px;
}

.self-application-wrap .btn-wrap>button:last-child {
  margin-right: 0;
}


.self-application-wrap .self-application .base:first-child {
  z-index: 5;
}

.self-application-wrap .self-application .base:nth-child(2) {
  z-index: 4;
}

.self-application-wrap .self-application .base:nth-child(3) {
  z-index: 3;
}

.self-application-wrap .self-application .base:nth-child(4) {
  z-index: 2;
}

.self-application-wrap .self-application .base h3 {
  color: #cdcdcd;
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
  line-height: 24px;
  margin-right: 16px;
  transform: translateY(-3px);
}

.self-application-wrap .self-application .base span {
  color: #cdcdcd;
  font-size: 14px;
  line-height: 22px;
  transform: translateY(-3px);
}



.self-application-wrap .self-application .base.active h3,
.self-application-wrap .self-application .base.active span {
  color: #15171a;
}




.self-application-wrap .self-application .step-wrap {
  width: 100%;
  margin-bottom: 32px;
}

/**/
.self-application-wrap .self-application .applicant-info-wrap {
  width: 950px;
  padding: 48px 75px;
  background: #fff;
  border-radius: 16px;
  margin: 0 auto;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 19px;
  border-bottom: 1px solid #666666;
  margin-bottom: 40px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>h4 {
  font-size: 20px;
  line-height: 28px;
  font-family: "PretendardSB", "sans-serif";
  color: #000000;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>div label {
  cursor: pointer;
  display: flex;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>div label:first-child {
  margin-right: 24px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>div label input[type=radio] {
  display: none;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>div label input[type=radio]:checked+.radio {
  background-image: url("image/btn_check_box.svg");
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>div label .radio {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-image: url("image/btn_box.svg");
  vertical-align: middle;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-head>div label .text {
  font-size: 16px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
  color: #000;
}
.self-application-wrap .self-application .applicant-info-wrap .regi-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 420px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container.direct {
  align-items: flex-start;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container:first-child {
  margin-top: 0;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head {
  width: 420px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head>span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head>span>span {
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 18px;
  color: #319DFF;
  margin-left: 4px;
  cursor: pointer;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head>span img {
  cursor: pointer;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head.direct {
  width: 432px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head.direct>div>strong {
  font-family: "PretendardSB", "sans-serif";
}
.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head.direct>div {
  width: 420px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head.direct>div>span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head.direct>div>span>span {
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #319DFF;
  margin-left: 4px;
  cursor: pointer;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head.direct>div>span img {
  cursor: pointer;
  width: 15px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head>strong {
  font-family: "PretendardSB", "sans-serif";
}


.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap {
  width: 421px;
  margin-bottom: 16px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct {
  width: 420px;
  display: flex;
  flex-direction: row;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>input {
  width: 296px;
  margin-right: 8px;
}


.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>input:last-child {
  margin-right: 0;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>.share-input-wrap {
  width: 114px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>.share-input-wrap>span {
  color: #878787;
  font-size: 16px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>.share-input-wrap>input {
  width: 68px;
  height: 45px;
  border: 1px solid #d1d1d1;
  margin-right: 4px;
  border-radius: 4px;
  padding: 0;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>.share-input-wrap>input::placeholder {
  font-size: 16px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
  color: #878787;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap>input {
  width: 100%;
  padding: 11px 0 11px 21px;
  border: 1px solid #D1D1D1;
  font-size: 16px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
  border-radius: 4px;
}


.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap>input:last-child {
  width: 420px;
}


.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap:first-child>input {
  margin-bottom: 8px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .link-wrap {
  width: 100%;
}
.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .link-wrap.direct {
  width: 420px;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .link-wrap>button {
  font-size: 14px;
  line-height: 22px;
  color: #666;
  font-family: "PretendardR", "sans-serif";
  padding: 0;
  width: calc(50% - 1px);
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .link-wrap>span{
  content: "";
  display: inline-block;
  transform: translateY(2px);
  width: 1px;
  height: 15px;
  background-color: #050F2F;
  opacity: 0.3;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .line {
  width: 420px;
  height: 1px;
  margin-bottom: 40px;
  border: 1px dashed #d1d1d1;
}

.self-application-wrap .self-application .applicant-info-wrap .regi-content > .input-container:first-child .line {
  display: none;
}

.self-application-wrap .self-application .select-mark-container {
  width: 930px;
  background-color: #fff;
  padding: 32px 32px 39px 32px;
  margin: 0 auto;
  border-radius: 8px;
}

.self-application-wrap .self-application .select-mark-container>h4 {
  padding-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
  font-size: 20px;
  line-height: 28px;
  font-family: "PretendardM", "sans-serif";
  font-weight: 500;
  margin-bottom: 32px;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "PretendardM", "sans-serif";
  color: #15171a;
  margin-bottom: 31px;
  padding-left: 128px;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>.row>.column {
  width: 160px;
  height: 102px;
  padding: 16px 0 10px 0;
  text-align: center;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  background-color: #fff;
  margin-right: 40px;
  cursor: pointer;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>.row>.column.active {
  border: 2px solid #ffb353;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>.row>.column .check-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>.row>.column .check-wrap>h5 {
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
  font-weight: 500;
  line-height: 20px;
  color: #15171a;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>.row>.column .check-wrap>img {
  position: absolute;
  right: -42px;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>.row>.column>span {
  font-family: "PretendardR", "sans-serif";
  font-family: 400;
  font-size: 12px;
  line-height: 18px;
  color: #858585;
}

.self-application-wrap .self-application .select-mark-container .type-select-container>.row>.column:last-child {
  margin-right: 0;
}

.self-application-wrap .self-application .select-mark-container .mark-creacte-wrap>p {
  margin-top: 32px;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "PretendardM", "sans-serif";
  padding-left: 128px;
}

.self-application-wrap .self-application .select-mark-container .mark-creacte-wrap>.input-wrap {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.self-application-wrap .self-application .select-mark-container .mark-creacte-wrap>.input-wrap span {
  font-size: 16px;
  line-height: 24px;
  font-family: "PretendardM", "sans-serif";
}

.self-application-wrap .self-application .select-mark-container .mark-creacte-wrap>.input-wrap input {
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  padding: 9px 0 9px 16px;
  margin-left: 16px;
  margin-right: 8px;
  width: 310px;
  height: 40px;
}

.self-application-wrap .self-application .select-mark-container .mark-creacte-wrap>.input-wrap>img {
  cursor: pointer;
}

.self-application-wrap .self-application .select-mark-container .mark-creacte-wrap>.input-wrap>.remove {
  width: 30px;
  height: 30px;
}

.self-application-wrap .self-application .select-mark-container .image-change-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.self-application-wrap .self-application .select-mark-container .image-change-wrap>.image-change {
  width: 310px;
  height: 40px;
  background-color: #319DFF;
}

.self-application-wrap .self-application .select-mark-container .preview-title>p {
  margin-top: 24px;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "PretendardM", "sans-serif";
  padding-left: 128px;
}

.self-application-wrap .self-application .select-mark-container .preview-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.self-application-wrap .self-application .select-mark-container .preview-wrap>div {
  width: 309px;
  height: 102px;
  border: 1px solid #cdcdcd;
  font-size: 0;
}

.self-application-wrap .self-application>.self-classification {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.self-application-wrap .self-application>.self-classification>.content-box>h3 {
  font-weight: 500;
  line-height: 28px;
}

.self-application-wrap .self-application>.self-classification>.content-box hr {
  margin: 0;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #666666;
}

.self-classification>.content-box>.content>p {
  font-size: 16px;
  line-height: 24px;
  font-family: "PretendardSB", "sans-serif";
  margin-top: 40px;
}

.self-application-wrap .self-application>.select-product {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.self-application-wrap .self-application>.select-product>.content-box>h3 {
  font-weight: 500;
  line-height: 28px;
}

.self-application-wrap .self-application>.select-product>.content-box hr {
  margin: 0;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #666666;
}

.self-application-wrap .self-application>.issue-btn-container {
  margin-top: 40px;
  width: 930px;
  margin: 48px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.self-application-wrap .self-application>.issue-btn-container>button {
  width: 180px;
  height: 50px;
  color: #fff;
  border-radius: 8px;
  font-size: 20px;
  margin-right: 15px;
}

.self-application-wrap .self-application>.issue-btn-container>button.template {
  color: #319DFF;
  border: 2px solid #319DFF;

}

.self-application-wrap .self-application>.issue-btn-container>button.temporary-save {
  background-color: #D9D9D9;
  color: #241E20;
}

.self-application-wrap .self-application>.issue-btn-container>button.next {
  background-color: #319DFF;
  margin-right: 0;
}

.self-application-wrap .final-info-wrap {
  width: 950px;
  background: #ffffff;
  border-radius: 16px;
  margin: 0 auto;
  padding: 50px 75px;
}

.self-application-wrap .final-info-wrap>.head h4 {
  font-size: 20px;
  line-height: 28px;
  font-family: "PretendardSB", "sans-serif";
  padding-left: 8px;
}

.self-application-wrap .final-info-wrap>.head.mt-56>h4 {
  margin-top: 56px;
}

.self-application-wrap .final-info-wrap>.head>hr {
  width: 100%;
  border-top: 1px solid #666666;
  margin: 19px 0;
}

.self-application-wrap .final-info-wrap>.head>.info-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  justify-content: center;
}

.self-application-wrap .final-info-wrap>.head>.info-wrap p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  height: 35px;
  font-family: "PretendardR", "sans-serif";
  color: #050F2F;
  width: 130px;
  background-color: #E4F0F9;
  margin-right: 12px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
}

.self-application-wrap .final-info-wrap>.head>.info-wrap>div {
  line-height: 32px;
  width: 486px;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding-left: 20px;
}

.self-application-wrap .final-info-wrap>.head>.info-wrap>div .product-wrap {
  display: flex;
  gap: 10px;
}
.self-application-wrap .final-info-wrap>.head>.info-wrap>div .product-wrap .product-title {
  white-space: nowrap;
}

.self-application-wrap .final-info-wrap>.head>.info-wrap>div .product-wrap div {
  font-size: 14px;
}
.self-application-wrap .final-info-wrap>.head>.info-wrap>div>div {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  font-weight: 400;
}

.self-application-wrap .final-info-wrap>.head .img-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 56px;
  justify-content: center
}

.self-application-wrap .final-info-wrap>.head .img-wrap>div:first-child {
  width: 130px;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #E4F0F9;
  border-radius: 4px;
  border: 1px solid #D1D1D1;
}

.self-application-wrap .final-info-wrap>.head .img-wrap>div:last-child {
  margin-left: 12px;
  padding: 9px 10px;
  width: 486px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  height: 150px;
}

.self-application-wrap .final-info-wrap>.head .img-wrap>div>.img-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  height: 100px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #cdcdcd;
  border: 1px solid #d1d1d1;
}


.self-application-wrap .final-info-wrap>.head .img-wrap>div>.img-preview>img {
  min-width: 100px;
  height: 100px;
}

.self-application-wrap .final-info-wrap>.head .img-wrap>div>button {
  margin-top: 8px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0 12px;
  height: 25px;
  color: #050F2F;
  font-weight: 500;
  font-size: 12px;
}

.self-application-wrap .final-info-wrap .final-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
}

.self-application-wrap .final-info-wrap .final-btn-wrap>button {
  width: 420px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #666;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
}

.self-application-wrap .step5-btn-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 48px auto;
}

.self-application-wrap .step5-btn-wrap button {
  width: 180px;
  height: 50px;
  color: #fff;
  border-radius: 8px;
  margin-right: 15px;
  font-size: 20px;
}

.self-application-wrap .step5-btn-wrap button:first-child {
  color: #319DFF;
  border: 2px solid #319DFF;
}

.self-application-wrap .step5-btn-wrap button:last-child {
  background-color: #319DFF;
  margin-right: 0;
}

.self-application-wrap .step5-btn-wrap button.save-btn {
  background-color: #858585;
}

.self-application-wrap .modal-overlay.issue>.issue-modal {
  width: 652px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal>.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal>.head>p {
  font-size: 18px;
  font-family: "PretendardR", "sans-serif";
  font-weight: 500;
  line-height: 36px;
  color: #858585;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content {
  padding: 16px 0 32px 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content>p {
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content>p>a {
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
  color: #858585;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap>div>a {
  display: inline-block;
  height: 42px;
  border: 1px solid #319DFF;
  border-radius: 4px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap>div>a>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #319DFF;
  padding: 11px 10px 11px 20px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap>div>a>button>img {
  transform: translateY(1px);
  margin-left: 8px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap>div>a:first-child {
  margin-right: 16px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap>button {
  width: 86px;
  height: 42px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background-color: #cdcdcd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide {
  padding-top: 16px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap>div {
  margin-right: 25px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: "PretendardR", "sans-serif";
  color: #858585;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 154px;
  padding: 0 8px 0 16px;
  font-size: 14px;
  line-height: 20px;
  font-family: "PretendardM", "sans-serif";
  background-color: #319DFF;
  border-radius: 4px;
}

.self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap>button>img {
  margin-left: 7px;
}