@charset "UTF-8";

.sign-in-wrap,
.find-id,
.find-pw,
.change-pw,
.find-pw-auth,
.check-id,
.complete-change-pw,
.simple-find-pw,
.sign-in {
  justify-content: flex-start !important;
  background-color: #eaeef2;
}

.sign-in-wrap .title,
.find-id .title,
.find-pw .title,
.change-pw .title,
.find-pw-auth .title,
.check-id .title,
.complete-change-pw .title,
.simple-find-pw .title,
.sign-in .title {
  margin: 32px 0 132px;
}

.sign-in-wrap h2,
.find-id h2,
.find-pw h2,
.change-pw h2,
.find-pw-auth h2,
.check-id h2,
.complete-change-pw h2,
.simple-find-pw h2,
.sign-in h2 {
  font-family: "PretendardM", "sans-serif";
  font-size: 24px;
  text-align: center;
  line-height: 130%;
}

.sign-in-wrap>hr,
.find-id>hr,
.find-pw>hr,
.change-pw>hr,
.find-pw-auth>hr,
.check-id>hr,
.complete-change-pw>hr,
.simple-find-pw>hr,
.sign-in>hr {
  width: 600px;
  height: 2px;
  border: none;
  background-color: black;
  margin: 40px 0;
}

.header-all-wrap {
  position: relative;
  z-index: 999;
}

.header-all-wrap {
  position: relative;
  z-index: 999;
  transition: background-color 0.1s ease-in-out;
}

@media (min-width: 2500px){
  .header-all-wrap:hover {
    background-color: white;
  }
}



.sub-nav-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 999;
}

.container .sign-in {
  width: 100vw;
}

.sign-in-wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

.sign-in-wrap .login-img01 {
  width: 235px;
  height: 70px;
  margin: 0px auto 0px;
}

.sign-in-wrap .gray-span {
  text-align: center;
  color: #666666;
  font-size: 20px;
  margin-bottom: 60px;
}

.sign-in-wrap .login-formBox {
  width: 768px;
  background: #fff;
  padding: 70px 80px;
  border-radius: 8px;
}

.sign-in-wrap .login-formBox strong {
  width: 608px;
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  color: #050f2f;
  margin-bottom: 6px;
}

.sign-in-wrap .btn-wrap button.gradient-btn {
  background: #319dff;
  border-radius: 2px;
}

.sign-in-wrap .btn-wrap button.border-btn {
  border: 1px solid #050f2f;
  border-radius: 2px;
  color: #050f2f;
}

.sign-in-wrap .form-wrap input[type="text"],
.sign-in-wrap .form-wrap input[type="password"] {
  padding: 16px;
  /* width: 400px; */
  width: 608px;
  height: 48px;
  border: 1px solid #e1e1e1;
  /* border-radius: 4px; */
  margin-bottom: 20px;
}

.sign-in-wrap .form-wrap input[type="text"]:focus,
.sign-in-wrap .form-wrap input[type="password"]:focus{
  border: 1px solid #319dff;
}

.sign-in-wrap .form-wrap input[type="text"]::placeholder,
.sign-in-wrap .form-wrap input[type="password"]::placeholder {
  color: #cdcdcd;
}

.sign-in-wrap .form-wrap .check-wrap {
  /* width: 400px; */
  width: 608px;
  display: flex;
  /* justify-content: flex-start; */
  justify-content: space-between;
  margin-bottom: 40px;
}

.sign-in-wrap .form-wrap .check-wrap .common-checkBox {
  margin-right: 30px;
}

.sign-in-wrap .form-wrap .btn-wrap {
  margin-bottom: 20px;
}

.sign-in-wrap .form-wrap .btn-wrap>button {
  /* width: 400px; */
  width: 608px;
}

.sign-in-wrap .form-wrap .btn-wrap>button:first-child {
  margin-bottom: 20px;
}

.sign-in-wrap .form-wrap .find-wrap span {
  cursor: pointer;
  /* color: #858585; */
  color: #050f2f;
}

.sign-in-wrap .form-wrap .find-wrap span:first-child::after {
  /* content: "|"; */
  /* padding: 0 30px; */
  /* padding: 0 12px; */
  cursor: default;
  content: "";
  display: inline-block;
  width: 1px;
  height: 14px;
  background-color: #e1e1e1;
  margin: 0 12px;
  vertical-align: middle;
}

/*아이디 찾기*/

.find-id {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}

.find-id .findId-wrap {
  width: 768px;
  background-color: #fff;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  margin: 100px auto 120px auto;
}

.find-id .findId-wrap h2 {
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
  padding-bottom: 12px;
  text-align: left;
  border-bottom: 1px solid #050f2f;
  color: #050f2f;
  /* width: 608px; */
}

.find-id .findId-wrap img {
  width: 100px;
  height: 100px;
  margin: 40px auto;
}

.find-id .findId-wrap span {
  text-align: center;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  /* font-weight: bold; */
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e1e1e1;
}

.find-id .findId-wrap .btn-wrap {
  display: flex;
  gap: 20px;
}

.find-id .findId-wrap .find-id-prev {
  /* width: 294px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50% !important;
  height: 60px;
  padding: 16px 0 16px 0;
  border: 1px solid #050f2f;
  color: #050f2f;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}

.find-id .findId-wrap .blue-btn {
  width: 50% !important;
  height: 60px;
  border-radius: 2px;
  background-color: #319dff;
  color: #ffffff;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}

.check-id .findId-wrap .btn-wrap .blue-btn {
  width: 50%;
  height: 60px;
  border-radius: 2px;
  background-color: #319dff;
  color: #ffffff;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  margin-bottom: 0;
}

.check-id .findId-wrap .btn-wrap .border-btn {
  width: 50%;
  height: 60px;
  border: 1px solid #050f2f;
  border-radius: 2px;
  background-color: #ffffff;
  color: #050f2f;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}

/*아이디 체크*/
.check-id {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}

.check-id .findId-wrap {
  width: 768px;
  background-color: #fff;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  margin: 100px auto 120px auto;
}

.check-id .findId-wrap h2 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  color: #050f2f;
  padding-bottom: 12px;
  text-align: left;
  border-bottom: 1px solid #050f2f;
}

.check-id .findId-wrap img {
  width: 120px;
  height: 120px;
  margin: 40px auto;
}

.check-id .findId-wrap span {
  text-align: center;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  line-height: 24px;
}

.check-id .findId-wrap span.guide-text {
  color: #666666;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
}

.check-id .findId-wrap .id-result {
  color: #319dff;
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  margin-bottom: 14px;
}

.check-id .findId-wrap .btn-wrap {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-top: 1px solid #e1e1e1;
  padding-top: 40px;
}

.check-id .findId-wrap .blue-border-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 60px;
  padding: 15px;
  border: 1px solid #050f2f;
  margin-bottom: 0px;
  border-radius: 2px;
  background-color: #ffffff;
  color: #050f2f;
  font-family: "PretendardR", "sans-serif";
  font-size: 20px;
}

.check-id .findId-wrap .blue-btn {
  width: 50%;
  height: 60px;
  border-radius: 2px;
  background-color: #319dff;
  color: #ffffff;
  font-family: "PretendardR", "sans-serif";
  font-size: 20px;
  margin-bottom: 0px;
}

/*비밀번호 찾기*/
.find-pw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}

.find-pw .findPw-wrap {
  width: 768px;
  background-color: #fff;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  margin: 100px auto 120px auto;
}

.find-pw .findPw-wrap h2 {
  font-size: 18px;
  color: #050f2f;
  padding-bottom: 12px;
  text-align: left;
  border-bottom: 1px solid #050f2f;
  font-family: "PretendardSB", "sans-serif";
}

.find-pw .findPw-wrap img {
  width: 100px;
  height: 100px;
  margin: 40px auto;
}

.find-pw .findPw-wrap span {
  text-align: center;
  color: #050f2f;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
}

.find-pw .findPw-wrap .findPw-desc {
  margin: 20px auto 30px auto;
  font-size: 18px;
  font-family: "PretendardR", "sans-serif";
}

.find-pw .findPw-wrap strong {
  font-size: 18px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  margin-bottom: 6px;
  line-height: 25.2px;
}

.find-pw .findPw-wrap .btn-wrap {
  display: flex;
  gap: 10px;
  border-top: 1px solid #e1e1e1;
  padding-top: 40px;
}

.find-pw .findPw-wrap .common-text-input {
  width: 100%;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 0;
}

.find-pw .findPw-wrap .blue-border-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #050f2f !important;
  border-radius: 2px;
  color: #050f2f !important;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}

/* 새로운 비밀번호 */
.change-pw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}

.change-pw .changePw-wrap {
  width: 768px;
  background-color: #fff;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  margin: 100px auto 120px auto;
}

.change-pw .changePw-wrap .input-group {
  margin-top: 20px;
  margin-bottom: 20px;
}

.change-pw .changePw-wrap h2 {
  font-size: 18px;
  color: #050f2f;
  padding-bottom: 12px;
  text-align: left;
  border-bottom: 1px solid #050f2f;
  font-family: "PretendardSB", "sans-serif";
}

.change-pw .changePw-wrap span {
  text-align: center;
  color: #050f2f;
  font-size: 18px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
  margin: 10px auto 40px auto;
}

.change-pw .changePw-wrap h3 {
  font-size: 18px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  margin-bottom: 6px;
  line-height: 25.2px;
}

.change-pw .changePw-wrap .pw-confirm-title {
  margin-top: 20px;
}

.change-pw .changePw-wrap .btn-wrap {
  display: flex;
  gap: 20px;
  border-top: 1px solid #e1e1e1;
  padding-top: 40px;
}

.change-pw .changePw-wrap .common-text-input {
  width: 100%;
  margin: 0;
  font-family: "PretendardR", "sans-serif";
  font-size: 12px;
  color: #666666;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 0;
}

.change-pw .changePw-wrap .caution {
  margin-top: 8px;
  font-family: "PretendardR", "sans-serif";
  font-size: 12px;
  padding-left: 0;
}

.change-pw .changePw-wrap .caution.correct {
  color: #319dff;
}

.change-pw .changePw-wrap .caution:not(.correct) {
  color: #e72530;
}

.change-pw .changePw-wrap .blue-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 15px;
  background-color: #319dff;
  border: 1px solid #319dff;
  border-radius: 2px;
  color: #ffffff;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}

/* 비밀번호 변경완료 */
.complete-change-pw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}

.complete-change-pw .completePw-wrap {
  width: 768px;
  background-color: #fff;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  margin: 100px auto 120px auto;
}

.complete-change-pw .completePw-wrap h2 {
  font-size: 18px;
  color: #050f2f;
  padding-bottom: 12px;
  text-align: left;
  border-bottom: 1px solid #050f2f;
  font-family: "PretendardSB", "sans-serif";
}

.complete-change-pw .completePw-wrap img {
  width: 120px;
  height: 120px;
  margin: 20px auto 40px auto;
}

.complete-change-pw .completePw-wrap span {
  text-align: center;
  color: #666666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 24px;
}

.complete-change-pw .completePw-wrap h3 {
  font-size: 18px;
  color: #050f2f;
  text-align: center;
  font-family: "PretendardSB", "sans-serif";
  margin-bottom: 20px;
  line-height: 25.2px;
}

.complete-change-pw .completePw-wrap .btn-wrap {
  display: flex;
  gap: 20px;
  border-top: 1px solid #e1e1e1;
  padding-top: 40px;
}

.complete-change-pw .completePw-wrap .blue-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 15px;
  background-color: #319dff;
  border: 1px solid #319dff;
  border-radius: 2px;
  color: #ffffff;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}

/* 중복체크 */
.sign-up .input-id-wrap div .blue-border-btn {
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  width: 80px;
  height: 48px;
  border: 1px solid #319dff;
  color: #319dff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.find-pw .findPw-wrap .blue-btn {
  width: 100%;
  height: 60px;
  border-radius: 2px;
  margin-bottom: 0px;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  background-color: #319dff;
}

.find-id>img,
.find-pw-auth>img {
  width: 200px;
  margin-bottom: 30px;
}

.find-id>span,
.find-pw-auth>span {
  color: #858585;
  font-size: 18px;
  margin-bottom: 10px;
}

.find-id strong,
.find-pw-auth strong {
  text-align: center;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 30px;
}

.find-id .btn-wrap .blue-btn,
.find-pw-auth .btn-wrap .blue-btn {
  width: 400px;
}

.find-pw>span {
  color: #858585;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 20px;
}

.find-pw .btn-wrap {
  margin-top: 20px;
}

.find-pw .btn-wrap button {
  width: 400px;
}

/* .find-pw .btn-wrap button:first-child {
  margin-bottom: 20px;
} */

.failed-find-id>h2 {
  font-family: "PretendardM", "sans-serif";
  font-size: 24px;
}

.failed-find-id hr {
  height: 2px;
  width: 600px;
  border: none;
  background-color: #15171a;
  margin: 40px 0;
}

.failed-find-id>img {
  margin-bottom: 40px;
}

.failed-find-id strong {
  font-family: "PretendardM", "sans-serif";
  font-size: 18px;
  margin-bottom: 14px;
}

.failed-find-id>span {
  font-size: 18px;
  color: #858585;
  margin-bottom: 70px;
}

.failed-find-id .btn-wrap>button {
  width: 400px;
}

.failed-find-id .btn-wrap>button:first-child {
  margin-bottom: 20px;
}

/*비밀번호 찾기*/
.complete-change-pw {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  width: 100%;
}

.complete-change-pw .complete-change-wrap {
  width: 768px;
  height: 554px;
  background: #fff;
  padding: 60px 80px;
  border-radius: 8px;
}

.complete-change-pw .complete-change-wrap h2 {
  font-size: 18px;
  font-weight: bold;
  color: #050f2f;
  text-align: left;
}

.complete-change-pw .complete-change-wrap img {
  display: block;
  margin: 40px auto 30px;
}

.complete-change-pw .complete-change-wrap h3 {
  color: #050f2f;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.complete-change-pw .complete-change-wrap span {
  display: block;
  color: #666;
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}

.complete-change-pw .complete-change-wrap .btn-wrap button.blue-btn {
  width: 100%;
  background-color: #319dff;
}

.change-pw>span {
  color: #858585;
  font-size: 18px;
}

.change-pw .caution {
  width: 400px;
  color: #e60023;
  font-size: 12px;
  padding-left: 10px;
  margin-top: 10px;
}

.change-pw .caution.correct {
  color: #3c8fef;
}

.change-pw .common-text-input {
  margin-top: 20px;
}

.change-pw .btn-wrap {
  margin-top: 20px;
}

.change-pw .btn-wrap button {
  width: 400px;
}

.check-id>img {
  margin-bottom: 40px;
}

.check-id>span {
  font-size: 18px;
  line-height: 140%;
  color: #858585;
  text-align: center;
}

.check-id h3 {
  margin: 14px 0;
  /* font-size: 24px; */
  font-size: 18px;
  text-align: center;
  font-family: "PretendardM", "sans-serif";
  color: #3c8fef;
}

.check-id .btn-wrap {
  margin-top: 40px;
}

.check-id .btn-wrap>button {
  width: 400px;
}

.check-id .btn-wrap>button:first-child {
  margin-bottom: 20px;
}

.complete-change-pw>img {
  margin-bottom: 30px;
}

.complete-change-pw h3 {
  color: #3c8fef;
  font-family: "PretendardM", "sans-serif";
  font-size: 24px;
  margin-bottom: 14px;
}

.complete-change-pw>span {
  color: #858585;
  font-size: 18px;
}

.complete-change-pw .btn-wrap {
  margin-top: 40px;
}

.complete-change-pw .btn-wrap button {
  width: 400px;
}

.simple-find-pw .btn-wrap {
  margin-top: 20px;
}

.simple-find-pw .btn-wrap>button {
  width: 400px;
}

/*회원가입*/
.sing-wrap-all {
  padding: 100px 0 120px 0;
  width: 1920px;
  background: #eaeef2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sing-wrap-all .sign-upImg {
  margin-bottom: 20px;
}

.sing-wrap-all .sign-titleName {
  font-size: 20px;
  color: #666;
  margin-bottom: 60px;
  font-family: "PretendardR", "sans-serif";
}

.sing-wrap-all .sign-up-contwrap {
  width: 768px;
  background: #fff;
  padding: 50px 80px;
  border-radius: 8px;
}

/* 약관동의,정보입력,가입완료 라인선 */
.sign-up-contwrap .row {
  position: relative;
}

.sign-up-contwrap .row.steps::before {
  content: "";
  position: absolute;
  width: 202px;
  height: 1px;
  background: #e1e1e1;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.sign-up-contwrap .row .column {
  position: relative;
  z-index: 1;
}

.sign-up-contwrap .row .row.steps .column:last-child span {
  color: #666666;
}

.three-wrap .row {
  position: relative;
}

.three-wrap .row.steps::before {
  content: "";
  position: absolute;
  width: 202px;
  height: 1px;
  background: #e1e1e1;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.three-wrap .row .column {
  position: relative;
  z-index: 1;
}

/* 체크박스 */
.sign-up-contwrap .terms-wrap .checkBox {
  width: 16px;
  height: 16px;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}

.sign-up-contwrap .terms-wrap .checkBox.active {
  width: 16px;
  height: 16px;
  border: none;
  background-color: #319dff;
}

.sign-up-contwrap .terms-wrap .checkBox img {
  width: 14px;
  height: 14px;
}

.sign-up>h2 {
  font-family: "PretendardM", "sans-serif";
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 50px;
}

.input-wrap span p {
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
}

.common-text-input {
  height: 48px;
  width: 100%;
  padding: 14px;
}

/* 인풋 */
.common-text-input::placeholder {
  color: #878787;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}

.common-text-input::-webkit-input-placeholder {
  color: #878787;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}

.common-text-input::-moz-placeholder {
  color: #878787;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  opacity: 1;
}

.common-text-input:-moz-placeholder {
  color: #878787;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}

.common-text-input:-ms-input-placeholder {
  color: #878787;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}

.selectBox .selectVal {
  height: 48px;
}

.sign-up .row .step-arrow {
  margin: 18px 30px;
}

.sign-up .row>.column .circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 70px;
  border-radius: 100%;
}

.sign-up .row>.column .circle.active {
  border-color: transparent;
}

.sign-up .row>.column span {
  font-size: 14px;
  line-height: 140%;
  color: #666;
}

.sign-up .row>.column span.bolder {
  color: #004f97;
}

.sign-up h4 {
  width: 608px;
  color: #050f2f;
  border-bottom: 1px solid #050f2f;
  line-height: 25.2px;
  margin: 30px 0 0 0;
  padding-bottom: 12px;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
}

.sign-up.row hr {
  width: 552px;
  height: 1px;
  border: 0;
  background-color: #f0f0f0;
  margin: 0;
}

.sign-up.one h4 {
  margin-bottom: 24px;
}

.sign-up.one .gray-area {
  justify-content: flex-start;
  /* border-radius: 8px; */
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  margin-bottom: 20px;
}

.sign-up.one .gray-area button {
  text-decoration: underline;
  text-underline-position: under;
  font-size: 14px;
  color: #319dff;
}

.sign-up.one .gray-area span {
  font-size: 16px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
}

.sign-up.one .white-area-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 20px 40px;
  background: #f6f6f6;
}

.sign-up.one .white-area {
  width: 100%;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}

.sign-up.one .white-area .view-text {
  width: 26px;
  font-size: 14px;
  color: #666666;
  text-align: center;
  /* padding-bottom: 3px; */
  border-bottom: 1px solid #666666;
}

.sign-up.one .white-area label {
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
}

.sign-up.one .white-area .checkBox {
  margin-right: 16px;
}

.sign-up.one .white-area-wrap span {
  font-size: 16px;
  color: #050f2f;
  font-family: "PretendardR", "sans-serif";
}

.sign-up.one .white-area>img {
  position: absolute;
  right: 24px;
}

.sign-up.one .authorize {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sign-up.one .authorize button {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-family: "PretendardR", "sans-serif";
  width: 100%;
  height: 62px;
  background-color: #319dff;
}

.sign-up.one .authorize .naver-btn{
  background-color: #03C75A;
  align-items: center;
  display: flex;
  gap: 177px;
  padding-left: 44px;
}

.sign-in-wrap .form-wrap .btn-wrap hr {
  background-color: #050F2F;
  border: none;
  height: 1px;
  width: 100%;
  margin: 24px auto;
}

.sign-in-wrap .form-wrap .btn-wrap .naver-btn {
  background-color: #03C75A;
  border-radius: 2px;
  display: flex;
  align-items: center;
  color: white;
  gap: 177px;
  padding-left: 44px;
  width: 100%;
}



.sign-up.two .input-wrap {
  width: 100%;
}

.sign-up.two .input-wrap .row {
  position: relative;
  width: 100%;
  justify-content: flex-start;
  margin: 10px 0;
}

.sign-up .input-id-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.sign-up .input-id-wrap div input {
  margin-top: 12px;
  width: 520px;
  border-radius: 0;
  padding: 14px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #666666;
}

.sign-up .input-id-wrap div input,
.sign-up .input-pw-wrap input,
.sign-up .input-pw-wrap2 input {
  margin-top: 12px;
  width: 520px;
  border-radius: 0;
  padding: 14px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #666666;
}

/* 이메일 인풋 */

.input-email-wrapper .input-email-wrap input:nth-of-type(1) {
  width: 250px;
  border-radius: 0;
  padding: 14px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #878787;
}

.input-email-wrapper .input-email-wrap input:nth-of-type(2) {
  width: 160px;
  border-radius: 0;
  padding: 14px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #878787;
}

.input-email-wrapper input::placeholder {
  color: #878787;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}

.sign-up .input-name-wrap input,
.sign-up .input-phone-wrap input,
.sign-up .input-name-wrap input span {
  margin-top: 12px;
  border-radius: 0;
  width: 100%;
  padding: 14px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #878787;
}

.sign-up.two .input-email-wrap input {
  padding: 14px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #878787;
}

.sign-up.two .input-email-wrap input::placeholder {
  color: #878787;
}

.sign-up.two .input-email-wrap .selectBox .selectVal {
  padding: 14px;
  width: 159px;
  border-radius: 0;
}

.sign-up.two .input-email-wrap .selectBox .selectVal span {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  color: #878787;
}

.sign-up.two .input-email-wrap .selectBox .selectOptGroup {
  width: 159px;
  border: 1px solid #e1e1e1;
  background: #fff;
  margin-top: 4px;
}

.sign-up.two .input-email-wrap .selectBox .selectOptGroup .selectOpt {
  padding: 14px;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  color: #878787;
  text-align: left;
  cursor: pointer;
}

.input-pw-wrap,
.input-email-wrapper,
.input-phone-wrap,
.input-pw-wrap2,
.input-name-wrap {
  margin-top: 20px;
}

.sign-up .input-id-wrap div button {
  padding: 11px 11px;
  border: 1px solid #319dff;
  color: #319dff;
  margin-left: 8px;
  line-height: 19.6px;
  margin-top: 12px;
}

.sign-up .input-pw-wrap input,
.sign-up .input-phone-wrap input,
.sign-up .input-pw-wrap2 input,
.sign-up .input-name-wrap input {
  margin-top: 12px;
  border-radius: 0;
  width: 100%;
}

.input-email-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  margin-top: 12px;
}

.input-email-wrapper .input-email-wrap input:nth-of-type(1) {
  width: 250px;
  border-radius: 0;
}

.input-email-wrapper .input-email-wrap input:nth-of-type(2) {
  width: 160px;
  border-radius: 0;
}

.sign-up .input-phone-wrap input {
  color: #878787;
}

.sign-up .input-phone-wrap {
  margin-bottom: 60px;
}

.sign-up.two .input-wrap .input-pw-wrap>span,
.sign-up.two .input-wrap .input-id-wrap>span,
.sign-up.two .input-wrap .input-email-wrapper>span,
.sign-up.two .input-wrap .input-phone-wrap>span,
.sign-up.two .input-wrap .input-pw-wrap2>span,
.sign-up.two .input-wrap .input-name-wrap>span {
  position: relative;
}

.sign-up.two .input-wrap .input-pw-wrap>span p::before,
.sign-up.two .input-wrap .input-id-wrap>span p::before,
.sign-up.two .input-wrap .input-email-wrapper>span p::before,
.sign-up.two .input-wrap .input-phone-wrap>span p::before,
.sign-up.two .input-wrap .input-pw-wrap2>span p::before,
.sign-up.two .input-wrap .input-name-wrap>span p::before,
.sign-up.two .enter-info-titleBox p::before {
  content: " ";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #319dff;
  border-radius: 50%;
}

.sign-up.two .enter-info-titleBox p {
  position: relative;
}

.sign-up.two .enter-info-titleBox p::before {
  top: -4px;
  left: -8px;
}

.sign-up.two .input-wrap .input-id-wrap>span p::before {
  top: -4px;
  left: 44px;
}

.sign-up.two .input-wrap .input-pw-wrap>span p::before {
  top: -4px;
  left: 57px;
}

.sign-up.two .input-wrap .input-pw-wrap2>span p::before {
  top: -4px;
  left: 89px;
}

.sign-up.two .input-wrap .input-name-wrap>span p::before {
  top: -4px;
  left: 29px;
}

.sign-up.two .input-wrap .input-email-wrapper>span p::before {
  top: -4px;
  left: 43px;
}

.sign-up.two .input-wrap .input-phone-wrap>span p::before {
  top: -4px;
  left: 56px;
}

.sign-up.two .input-wrap .row>span p {
  position: relative;
}

.sign-up.two .input-wrap .input-email-wrap .common-text-input.disable {
  color: #858585;
}

.sign-up.two .input-wrap .input-email-wrap .selectBox .selectVal {
  padding: 12px 20px;
  width: 159px;
  border-radius: 0;
}

.sign-up.two .input-wrap .caution {
  margin-bottom: 10px;
  font-size: 12px;
  color: #e72530;
  margin-top: 8px;
  font-family: "PretendardR", "sans-serif";
}

.sign-up.two .input-wrap .caution.correct {
  color: #319dff;
}

.sign-up.two .btn-wrap {
  display: flex;
  gap: 20px;
}

.sign-up.two .btn-wrap button {
  width: 294px;
  height: 60px;
  border-radius: 0;
  font-size: 20px;
  margin-top: 40px;
}

.sign-up.two .btn-wrap .gray-btn {
  color: #050f2f;
  border: 1px solid #050f2f;
  background-color: white;
}

.sign-up.two .btn-wrap .blue-btn {
  background-color: #319dff;
}

/**/

.sign-up.three .three-wrap {
  background-color: white;
  width: 768px;
  border-radius: 8px;
  padding: 40px 0;
  text-align: center;
}

.sign-up.three .checkImg {
  margin-top: 40px;
  margin-bottom: 30px;
}

.sign-up.three h3 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 20px;
  color: #050f2f;
}

.sign-up.three span {
  font-size: 20px;
  color: #666;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}

.sign-up.three hr {
  width: 608px;
  height: 1px;
  border: 0;
  background-color: #f0f0f0;
  margin: 40px auto;
}

.sign-up.three .btn-wrap button {
  width: 608px;
  border-radius: 2px;
}

.sign-up.three .btn-wrap .blue-btn {
  background-color: #319dff;
}

/**/

.modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(21, 23, 26, 0.3);
}

.modal-bg .terms-modal {
  background-color: white;
  max-width: 640px;
  max-height: 800px;
  padding: 32px;
  border-radius: 8px;
}

.modal-bg .terms-modal>h1 {
  font-family: "PretendardB", "sans-serif";
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 24px;
}

.modal-bg .terms-modal .sign-up-desc {
  width: 576px;
  height: 600px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 16px;
  color: #cdcdcd;
  overflow-y: scroll;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 24px;
}

.modal-bg .terms-modal .sign-up-desc h1,
.modal-bg .terms-modal .sign-up-desc h3 {
  margin: 15px 0;
  font-family: "PretendardM", "sans-serif";
  font-size: 12px;
  color: #484848;
}

.modal-bg .terms-modal .sign-up-desc h2 {
  margin: 10px;
  font-family: "PretendardM", "sans-serif";
  font-size: 13px;
  color: #484848;
}

.modal-bg .terms-modal .sign-up-desc li {
  margin-left: 20px;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 20px;
}

.modal-bg .terms-modal .sign-up-desc p {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
}

.modal-bg .terms-modal .btn-wrap>button {
  /* padding: 10px; */
  width: 576px;
  height: 54px;
  font-size: 18px;
  line-height: 140%;
  border-radius: 0;
  font-family: "PretendardR", "sans-serif";
  background-color: #319dff;
  color: #ffffff;
}

.agree-wrap {
  margin-top: 25px;
}

.agree-wrap ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.agree-wrap ul li {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* 요소들이 줄바꿈되지 않도록 설정 */
  margin-right: 20px;
}

.agree-wrap ul li img {
  margin-right: 5px; /* 체크 이미지와 텍스트 사이 간격 */
}

.agree-wrap ul li p {
  margin-right: 5px; /* 텍스트와 버튼 사이 간격 */
  white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 */
}

.agree-wrap label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.agree-wrap label .checkBox {
  margin-right: 10px;
}



.payment-confirm .agree-wrap label .checkBox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}


.payment-confirm .agree-wrap label .checkBox img {
  width: 20px;
  height: 20px;
}

.payment-confirm .agree-wrap label span {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
}

.payment-confirm .agree-wrap ul li p {
  font-size: 14px;
  line-height: 140%;
  color: #666;  
  margin: 0 5px; /* 좌우 여백 조정 */
  white-space: nowrap; /* 텍스트가 줄바꿈되지 않게 */
}

.payment-confirm .agree-wrap ul li button {
  font-size: 14px;
  color: #666;
  text-decoration: underline;
  line-height: 140%;
  white-space: nowrap; /* 버튼 텍스트가 줄바꿈되지 않도록 */
  flex-shrink: 0; /* 버튼이 줄어들지 않도록 설정 */
}


.pick-home .swiper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 40px 0 160px;
}

.pick-home .swiper .swiper-slide {
  width: 1320px;
}

.pick-home .swiper .swiper-button-prev::after {
  content: " ";
  color: transparent;
  background-image: url("image/prevIcon.svg");
  background-position: center;
  position: absolute;
  left: calc(50vw - 645px);
  width: 32px;
  height: 50px;
}

.pick-home .swiper .swiper-button-next::after {
  content: " ";
  color: transparent;
  background-image: url("image/nextIcon.svg");
  background-position: center;
  position: absolute;
  right: calc(50vw - 645px);
  width: 32px;
  height: 50px;
}

.pick-home .swiper .progress-wrap {
  position: absolute;
  left: calc(50% - 540px);
  bottom: 58px;
}

.pick-home .swiper .progress-wrap span {
  color: #ffb353;
  font-family: "PretendardM", "sans-serif";
}

.pick-home .swiper .progress-wrap .progressBar {
  position: relative;
  width: 80px;
  height: 2px;
  background-color: white;
  margin: 0 8px;
}

.pick-home .swiper .progress-wrap .progressBar .progress {
  position: absolute;
  background-color: #ffb353;
  height: 2px;
  max-width: 100%;
  width: 0;
  transition: linear 1s;
}

.pick-home .swiper .progress-wrap .progressBar .progress.animate {
  width: 100%;
  transition: linear 8s;
}

.pick-home .swiper .slide-control-btn {
  position: absolute;
  left: calc(50% - 398px);
  bottom: 56px;
  z-index: 10;
  padding: 0;
}

.pick-home .card {
  background-color: gray;
  height: 580px;
  width: 1320px;
  border-radius: 8px;
  justify-content: space-between;
  padding: 0 120px;
}

.pick-home .title-point {
  color: #319dff;
}

.pick-home .title-point02 {
  color: #777777;
}

.pick-home .card .column {
  align-items: flex-start;
}

.pick-home .card .column h3 {
  font-family: "PretendardM", "sans-serif";
  font-size: 30px;
  line-height: 43px;
  color: #ff8e00;
  margin-bottom: 16px;
}

.pick-home .card .column h4 {
  font-size: 42px;
  line-height: 62px;
  color: #444444;
  margin-bottom: 16px;
}

.pick-home .card .column h4 strong {
  font-size: 48px;
  line-height: 70px;
  font-family: "PretendardM", "sans-serif";
}

.pick-home .card .column>span {
  font-size: 20px;
  line-height: 30px;
  color: #444444;
  margin-bottom: 40px;
}

.pick-home .card .btn-wrap button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  width: 240px;
  height: 62px;
}

.pick-home .card .btn-wrap button span {
  font-family: "PretendardM", "sans-serif";
  font-size: 20px;
  line-height: 32px;
}

.pick-home .card .btn-wrap button.common-btn span {
  color: white;
  margin: 0 20px 0 8px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
}

.pick-home .card .btn-wrap button.border-btn {
  border-color: #ffce33;
  transition: all 0.2s;
}

.pick-home .card .btn-wrap button.border-btn span {
  color: #ffce33;
  transition: all 0.2s;
  margin-right: 32px;
}

.pick-home .card .btn-wrap button.border-btn i {
  width: 10px;
  height: 20px;
  background-image: url("image/yellowRightArrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s;
}

.pick-home .card .btn-wrap button.border-btn:hover {
  background-color: rgba(255, 206, 51, 0.3);
  transition: all 0.2s;
}

.pick-home .card .btn-wrap button.border-btn:hover span {
  transition: all 0.2s;
  color: #ffb353;
}

.pick-home .card .btn-wrap button.border-btn:hover i {
  background-image: url("image/moreYellowRightArrow.svg");
  transition: all 0.2s;
}

.pick-home .card .btn-wrap button:first-child {
  margin-right: 24px;
}

.pick-home .card.a-card {
  background-color: #fff5d2;
}

.pick-home .card.a-card>img {
  width: 335px;
  height: 380px;
}

.pick-home .card.b-card {
  background-color: #ffecb1;
}

.pick-home .card.b-card>img {
  width: 356px;
  height: 334px;
}

/* 작업시작 */

/* 섹션1번 메인 */
/* .pick-home .mainSection01 {
  height: 100vh;
} */

.pick-home .mainSectionBG {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  background: linear-gradient(180deg, #c8e3ff 0%, rgba(243, 246, 249, 0) 100%);
}

.pick-home .brand-value-wrap {
  width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* padding: 60px 240px 160px; */
}

.pick-home .brand-value-wrap .brand-content-wrap {
  width: 1440px;
  /* margin: 90px auto 0px; */
  margin-bottom: 141px;
}

.pick-home .brand-value-wrap .brand-value-top {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.pick-home .brand-value-wrap .brand-value-top .brand-left-box {
  width: 48%;
}


.pick-home .brand-value-top .brand-left-box .partners-title {
  font-size: 56px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  margin-bottom: 16px;
  line-height: 78.4px;
  letter-spacing: -0.2px;
}

.pick-home .brand-value-top .brand-left-box .partners-title .partners-title_color {
  /* font-size: 48px; */
  font-size: 56px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  margin-bottom: 16px;
  line-height: 140%;
  color: #319dff;
}

.pick-home .brand-value-top .brand-left-box .title-point {
  color: #319dff;
  font-size: 56px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 14px;
}

.pick-home .brand-value-top .brand-left-box p {
  color: #666;
  /* font-size: 26px; */
  font-size: 30px;
  line-height: 150%;
  margin-bottom: 56px;
  word-break: keep-all;
}

.pick-home .brand-value-top .brand-left-box .brand-search {
  position: relative;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #319dff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #050f2f;
  padding: 18px 30px;
  font-size: 18px;
}

.pick-home .brand-value-top .brand-left-box .previewImgWrap {
  position: absolute;
  top: 0;
  left: 10px;
}

.pick-home .brand-value-top .brand-left-box .previewImgWrap button {
  background-color: #d1d1d1;
  border-radius: 50%;
  position: absolute;
  left: 31px;
  top: -7px;
}

.pick-home .brand-value-top .brand-left-box .brand-iconBox {
  position: relative;
  left: 86%;
  bottom: 46px;
  display: flex;
  gap: 12px;
}

.pick-home .brand-value-top .brand-left-box .brand-iconBox img {
  cursor: pointer;
}

/* 아래 박스 */

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom {
  display: flex;
  gap: 10px;
  width: 100%;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li {
  position: relative;
  width: 20%;
  min-height: 320px;
  padding: 36px 8px 0 12px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  box-shadow: 1px 4px 18px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li {
  position: relative;
  width: 20%;
  min-height: 320px;
  padding: 0px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  box-shadow: 1px 4px 18px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .main-nab {
  border: 1px solid #f00;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .main-nab li {
  position: relative;
  width: 20%;
  min-height: 320px;
  padding: 36px 30px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  box-shadow: 1px 4px 18px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  color: #858585;
  font-size: 18px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li:hover h3 {
  color: #fff;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li:hover p {
  color: #fff;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li:hover p:nth-child(2) {
  color: #fff;
}

/* new */
.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li:first-child:hover {
  background: #f8bd34;
}

/* .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li:first-child:hover .main_nav_btnbox p:first-child{
  border-right: 2px solid #fff;
} */
.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li:nth-child(2):hover {
  background: #316bff;
}

/* .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li:nth-child(2):hover .main_nav_btnbox p:first-child{
  border-right: 2px solid #fff;
} */
.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li:first-child:hover {
  background: #ffa37c;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li:hover .dis580_off {
  border-right: 2px solid #fff;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li:nth-child(2):hover {
  background: #1cc4cb;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li:last-child:hover {
  background: #7460d4;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3 {
  color: #050f2f;
  font-size: 32px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox {
  display: flex;
  align-items: center;
}


.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox p:first-child {
  padding-right: 20px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p:nth-child(2) {
  color: #050f2f;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
  padding-left: 20px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p:nth-child(3) {
  font-family: "PretendardB", "sans-serif";
  font-size: 16px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .brand-value-icon {
  position: absolute;
  bottom: 35px;
  right: 35px;
}

/* 메인 수정 */
.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left {
  display: flex;
  width: 49.2%;
  gap: 12px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li {
  /* width: 50%; */
  width: 348px;
  /* height: 320px; */
  height: 354px;
  min-height: auto;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-nav {
  display: block;
  width: 100%;
  height: 100%;
  padding: 42px 0 0 32px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-navbox {
  display: block;
  width: 100%;
  height: 100%;
  padding: 42px 0 0 32px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 50%;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li {
  display: flex;
  align-content: center;
  width: 100%;
  min-height: auto;
  /* padding-left: 44px; */
  /* padding: 0 42px; */
  padding-left: 32px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li h3 {
  font-size: 26px;
  margin-bottom: 0px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-nav {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-nav .newMain01-btnall {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-nav .newMain01-btn01 {
  gap: 20px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-nav .newMain01-btn02 p {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-nav .newMain01-btn02 .dis580_off {
  padding-right: 20px;
  border-right: 2px solid #cccccc;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding-right: 40px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btnall {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn01 {
  gap: 20px;
}

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 p {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 8px;
  letter-spacing: -0.5px;
  padding-right: 20px;
}

/* 팝업 시작 */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-body {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-body h2 {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

.modal-body p {
  font-size: 19px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body img {
  margin-right: 10px;
}

/* 팝업 끝 */

.pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 .dis580_off {
  padding-right: 20px;
  border-right: 2px solid #cccccc;
}

.pick-home .brand-value-top .brand-right-box {
  width: 720px;
  box-sizing: border-box;
  /* margin-top: 45px; */
  /* min-height: 455px; */
}

.pick-home .brand-value-top .brand-left-box .brand-iconBox .search-img-link {
  /* margin-top: 4px; */
}

.pick-home .brand-value-top .brand-right-box .brand-right-content {
  display: flex;
  gap: 15px;
  min-height: 375px;
}

.pick-home .brand-value-top .brand-right-box .brand-right-content li {
  width: 33.3%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  height: 375px;
}

.pick-home .brand-right-box .brand-right-content li .brand-right-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pick-home .brand-right-box .brand-right-content li .brand-right-head img {
  display: inline-block;
  position: absolute;
  right: 12px;
}

.pick-home .brand-right-box .brand-right-content li h3 {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  height: 53px;
  padding: 14px;
  background: #319dff;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  color: #fff;
  font-size: 18px;
  gap: 6px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: -0.002em;
}

.pick-home .brand-right-box .brand-right-content li h3.bg-free-consult {
  background-color: #006ACA;
}

.pick-home .brand-right-box .brand-right-content li h3.bg-trademark-registration {
  background-color: #003464;
}


.pick-home .brand-right-box .brand-right-content li h3 span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 18px;
  background: #ffa800;
  border-radius: 100px;
  color: #fff;
  font-size: 10px;
  padding: 1px 5px;
  font-family: "Pretendard", "sans-serif";
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-up {
  animation: slideUp 0.5s ease-out;
}



.pick-home .brand-right-box .brand-right-content li .content-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  border-bottom: 1px solid #eeeeee;
  border-radius: 3px;
  padding: 13px 12px;
  position: relative;
}

.pick-home .brand-right-box .brand-right-content li .content-box:last-child {
  margin-bottom: 0px;
}

.pick-home .brand-right-box .brand-right-content li .content-box .content-bg02 {
  background: #050f2f;
}

.pick-home .brand-right-box .brand-right-content li .content-box>span {
  display: flex;
  align-items: center;
  width: 46px;
  height: 19px;
  background: #aaaaaa;
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  font-family: "PretendardR", "sans-serif";
  font-weight: 400;
  padding: 2.5px 4px;
  letter-spacing: -0.002em;
}

.pick-home .brand-right-box .brand-right-content li .content-box>p:nth-child(2) {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  color: #666;
  margin-left: 10px;
}

.pick-home .brand-right-box .brand-right-content li .content-box>p:last-child {
  font-size: 11px;
  font-family: "PretendardR", "sans-serif";
  color: #bbbbbb;
  margin-left: 45px;
  position: absolute;
  right: 12px;
}

/* 섹션2번 price */
.price-info-wrap {
  width: 1920px;
  height: 100%;
}

.main-price-wrap {
  width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0 80px 0;
  /* padding-bottom:110px; */
}

.price-info-wrap .main-price-content {
  max-width: 1280px;
}

.price-info-wrap .title-point {
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  text-align: center;
}

.price-info-wrap .partners-title {
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 60px;
  line-height: 140%;
}

.price-info-wrap .check-shapeAll {
  content: "";
  width: 7px;
  height: 14px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  position: relative;
  left: 50%;
  transform: rotate(45deg);
}

.price-info-wrap .price-position {
  position: relative;
}

.price-info-wrap .price-position .infoB {
  position: absolute;
  top: 12px;
  left: 64%;
}

.price-info-wrap .price-position .infoSB {
  position: absolute;
  top: 13px;
  left: 58%;
}

.price-info-wrap .price-position .infoG {
  position: absolute;
  top: 13px;
  left: 58%;
}

.price-info-wrap .table-text-flex {
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  gap: 10px;
}

.price-info-wrap .table-text-flex span {
  font-size: 14px;
  color: #878787;
}

.price-info-wrap .table-text-flex .sale {
  color: red !important;
}

.price-info-wrap .table-text-flex s {
  font-size: 14px;
  color: #878787;
}

.sale-box{
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: red;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.price-info-wrap .table-text-last {
  color: #050f2f;
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
}

.price-info-wrap .check-shape01 {
  border-color: transparent #050e2f #050e2f transparent;
}

.price-info-wrap .check-shape02 {
  border-color: transparent #ff7900 #ff7900 transparent;
}

.price-info-wrap .check-shape03 {
  border-color: transparent #0ecdc1 #0ecdc1 transparent;
}

.price-info-wrap .check-shape04 {
  border-color: transparent #00bf7a #00bf7a transparent;
}

.price-info-wrap .check-shape {
  content: "";
  width: 6px;
  height: 12px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-color: transparent #319dff #319dff transparent;
  position: relative;
  left: 50%;
  transform: rotate(45deg);
}

.price-info-wrap .price-position .table-img {
  position: absolute;
  top: 8px;
  right: 13px;
}

.price-info-wrap .price-position .table-img02 {
  right: 10px;
}

.price-info-wrap .price-position .table-img03 {
  right: 20px;
}

.price-info-wrap table {
  width: 100%;
  margin: 0px auto;
  border-collapse: collapse;
}

.price-info-wrap table caption {
  color: #878787;
  font-size: 14px;
  text-align: right;
  margin-bottom: 10px;
}

.price-info-wrap table td.price-position .infoB + article {
  position: absolute;
  top: 90%; /* 위치 조정 필요할 수 있음 */
  left: 67%;
  transform: translateX(-50%);
  background-color: black;
  font-family: "PretendardR", "sans-serif";
  line-height: 130%;
  font-size: 12px;
  color: white;
  padding: 8px 10px;
  display: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  z-index: 5;
  white-space: nowrap;
}

.price-info-wrap table td.price-position .infoSB + article {
  position: absolute;
  top: 90%; /* 위치 조정 필요할 수 있음 */
  left: 61%;
  transform: translateX(-50%);
  background-color: black;
  font-family: "PretendardR", "sans-serif";
  line-height: 130%;
  font-size: 12px;
  color: white;
  padding: 8px 10px;
  display: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  z-index: 5;
  white-space: nowrap;
}


.price-info-wrap table td.price-position .infoG + article {
  position: absolute;
  top: 90%; /* 위치 조정 필요할 수 있음 */
  left: 61%;
  transform: translateX(-50%);
  background-color: black;
  font-family: "PretendardR", "sans-serif";
  line-height: 130%;
  font-size: 12px;
  color: white;
  padding: 8px 10px;
  display: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  z-index: 5;
  white-space: nowrap;
}


.price-info-wrap table td.price-position article::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.price-info-wrap table td.price-position .infoB:hover + article,
.price-info-wrap table td.price-position .infoSB:hover + article,
.price-info-wrap table td.price-position .infoG:hover + article {
  display: block;
}

.price-info-wrap table tr>td:first-child {
  /* position: relative; */
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  width: 340px;
}

.price-info-wrap table tr>td:first-child div {
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  display: flex;
  justify-content: space-between;
}

.price-info-wrap table tr>td:first-child article {
  position: absolute;
  top: 190%;
  left: 97%;
  transform: translateX(-50%);
  background-color: black;
  font-family: "PretendardR", "sans-serif";
  line-height: 130%;
  font-size: 12px;
  color: white;
  padding: 8px 10px;
  display: none;
  padding: 8px 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  z-index: 5;
  white-space: nowrap;
}

.price-info-wrap table tr>td:first-child article::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.price-info-wrap table tr>td:first-child img:hover+article {
  display: block;
}

.price-info-wrap table tr>td>p.table-text {
  font-size: 14px;
  color: #878787;
}

.price-info-wrap .price-info-title>th {
  padding: 32px 16px 24px;
  min-width: 260px;
}

.price-info-wrap .price-info-title>th>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 18px; */
}

.price-info-wrap .price-info-title>th>div:nth-child(2) {
  justify-content: space-between;
}

.price-info-wrap .price-info-title>th>div>.priceSelcet {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px;
}

.price-info-wrap .price-info-title>th>div>span {
  display: inline-block;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: 13px;
}

.price-info-wrap .price-info-title>th>div>span:first-child {
  background-color: #828282;
  margin-right: 5px;
}

.price-info-wrap .price-info-title>th>div>span:nth-child(2) {
  background-color: #319dff;
}

.price-info-wrap .price-info-title>th h3 {
  color: #050f2f;
  font-size: 26px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  height: 24px;
  line-height: 24px;
}

.pick-home .price-info-wrap .price-info-title>th:nth-child(3) h3 {
  color: #ff7a00;
}

.pick-home .price-info-wrap .price-info-title>th:nth-child(4) h3 {
  color: #07cdc1;
}

.pick-home .price-info-wrap .price-info-title>th:nth-child(5) h3 {
  color: #00bf7b;
}

.price-info-wrap .price-info-title>th>p:not(.price-font) {
  padding: 18px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #e1e1e1;
  line-height: 140%;
  color: #858585;
  text-align: left;
  font-size: 14px;
}

.price-info-wrap .price-font {
  /* font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  color: #555555;
  text-align: left;
  letter-spacing: -2px; */
  font-size: 28px;
  font-family: Roboto;
  font-weight: 700;
  color: #050f2f;
  text-align: left;
  letter-spacing: -2px;
}

.price-info-wrap .price-day {
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  color: #555555;
}

.price-info-wrap th,
td {
  border: 1px solid #e1e1e1;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}

.dropdown-content .table-wrap .number-td {
  padding: 0;
}

.price-info-wrap th {
  background-color: #fff;
}

.price-info-wrap th:first-child,
td:first-child {
  width: 20%;
}

.main-price-wrap th:not(:first-child),
.main-price-wrap td:not(:first-child) {
  height: 40px;
}

.price-info-wrap td .priceBtn {
  position: absolute;
  top: 6px;
  right: 5px;
}

.price-info-wrap .moreBtn-wrap td>p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 32px;
  border: 1px solid #878787;
  border-radius: 2px;
  color: #878787;
  font-size: 14px;
  margin: 0px auto;
}

.price-info-wrap .moreBtn-wrap td>p>a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 32px;
  border-radius: 2px;
  color: #878787;
  font-size: 14px;
  margin: 0px auto;
}

.price-info-wrap .moreBtn-wrap td>p>a::after {
  position: relative;
  left: 7px;
  top: 0px;
  content: "";
  width: 7px;
  height: 7px;
  border-top: 2px solid #858585;
  border-right: 2px solid #858585;
  transform: rotate(45deg);
}

.price-info-wrap .moreBtn-wrap td>p a:hover::after {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.price-info-wrap .moreBtn-wrap td>p:hover {
  background-color: #050f2f;
  color: #fff;
  border: none;
}

.price-info-wrap .moreBtn-wrap td>p>a:hover {
  background-color: #050f2f;
  color: #fff;
  border: none;
}

.none-height {
  height: 15px;
}

/* 메인섹션3번 서비스 */
.pick-home .service-type-wrap {
  width: 2500px;
  height: 100%;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 110px 0 110px 0;
  background-image: url("image/main_section_bg01_01.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* @media (min-width: 1920px) {
  .pick-home .service-type-wrap {
    width: 2500px;
    background-image: url("image/main_section_bg01_01.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
} */

.pick-home .service-type-wrap p {
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  text-align: center;
}

.pick-home .service-type-wrap .partners-title {
  font-size: 42px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 60px;
  line-height: 140%;
}

.pick-home .service-type-wrap ul {
  display: flex;
  flex-wrap: wrap;
  width: 1280px;
  margin: 0px auto;
}

.pick-home .service-type-wrap ul .me-32 {
  margin-right: 32px;
}

.pick-home .service-type-wrap ul li {
  width: 400px;
  height: 386px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pick-home .service-type-wrap li h3 {
  color: #050f2f;
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  line-height: 150%;
  text-align: center;
}

.pick-home .service-type-wrap li img {
  display: block;
  font-size: 16px;
  text-align: center;
  margin: 0px auto 50px;
  width: 100px;
  height: 100px;
}

.pick-home .service-type-wrap li span {
  display: block;
  font-size: 16px;
  margin: 0px auto;
  text-align: center;
  font-size: 16px;
  color: #666;
  line-height: 150%;
  word-break: keep-all;
}

/* 메인섹션4번 상표등록 */
.pick-home .trademark-wrap {
  width: 100%;
  /* margin-top: 140px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.pick-home .trademark-wrap .trademark-contentall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  width: 1440px;
  gap: 75px;
}

.pick-home .trademark-wrap p {
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
}

.pick-home .trademark-wrap .partners-title {
  font-size: 42px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  margin: 12px 0 64px;
  /* margin-bottom: 64px; */
  line-height: 140%;
}

.pick-home .trademark-wrap .partners-title .title-point {
  color: #319dff;
  font-size: 42px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 14px;
}

.pick-home .trademark-wrap .trademark-left,
.trademark-right {
  width: 30%;
}

.pick-home .trademark-wrap .trademark-left ul {
  display: flex;
  width: 100%;
  gap: 100px;
}

.pick-home .trademark-wrap .trademark-left ul li span {
  display: inline-block;
  font-size: 30px;
  font-family: "PretendardSB", "sans-serif";
  margin-bottom: 10px;
  line-height: 140%;
}

.pick-home .trademark-wrap .trademark-left ul li p {
  font-size: 72px;
  line-height: 140%;
  letter-spacing: -1px;
  min-width: 271px;
}

.pick-home .trademark-wrap .trademark-left ul li p small {
  position: relative;
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  color: #050f2f;
  top: -25px;
}

.pick-home .trademark-wrap .numtype_p {
  padding-top: 10px;
  border-top: 2px solid #ededed;
}

.pick-home .trademark-wrap .trademark-right {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  height: 640px;
  overflow: hidden;
  justify-content: right;
}

.slider-container {
  display: flex;
}

.trademark-grid {
  display: grid;
  grid-template-columns: repeat(3, 140px);
  /* 3열로 설정 */
  grid-template-rows: repeat(4, 140px);
  /* 4행으로 설정 */
  gap: 30px;
  /* 그리드 항목 사이의 간격을 변경하려면 이 값을 조정하세요 */
  scroll-behavior: smooth;
  position: relative;
}

.trademark-box {
  position: relative;
  width: 140px;
  height: 140px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

/* 첫 번째 줄 (1, 2, 3번째 아이템) */
.trademark-box:nth-child(1),
.trademark-box:nth-child(3),
.trademark-box:nth-child(4),
.trademark-box:nth-child(6),
.trademark-box:nth-child(7),
.trademark-box:nth-child(9),
.trademark-box:nth-child(10),
.trademark-box:nth-child(12) {
  transform: translateY(-20px);
}

/* 세 번째 줄 (7, 8, 9번째 아이템) */
.trademark-box:nth-child(2),
.trademark-box:nth-child(5),
.trademark-box:nth-child(8),
.trademark-box:nth-child(11) {
  transform: translateY(20px);
}

.trademark-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 새로운 상표이미지 레이아웃 */
.progress-content-wrap02 .marquee02 .marquee_imgbox01 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 204px;
  height: 205px;
  border: 1px solid #ccc;
  border-radius: 12px;
  /* padding: 8px; */
}

/* 섹션5번 추천정보 */
.pick-home .recommend-wrap {
  width: 2500px;
  /* height: 100vh; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #319dff;
  padding: 100px 0 100px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("image/main_section_bg01_03.svg");
}

/* @media (min-width: 2500px) {
  .pick-home .recommend-wrap {
    width: 2500px;
    background-image: url("image/main_section_bg01_03.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
} */
.pick-home .recommend-wrap h2 {
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  color: #fff;
  margin-bottom: 95px;
}

.pick-home .recommend-wrap h3 {
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  color: #050f2f;
  margin: 10px 0;
}

.pick-home .recommend-wrap p {
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  /* margin-bottom: 10px; */
  text-align: center;
  line-height: 39.2px;
}

.pick-home .recommend-wrap .partners-title {
  font-size: 52px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  color: #050f2f;
  margin: 12px 0 56px;
  line-height: 140%;
  letter-spacing: -0.002em;
}

.pick-home .recommend-wrap hr {
  height: 1px;
  border: 0;
  background-color: #e1e1e1;
}

.pick-home .recommend-wrap ul {
  display: flex;
  justify-content: space-between;
  /* width: 1280px; */
  width: 1460px;
  margin: 0px auto;
}

.pick-home .recommend-wrap ul li {
  /* width: calc(25% - 20px); */
  /* height: 470px; */
  width: calc(25% - 17px);
  height: 425px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  text-align: center;
  padding: 24px;
}

.pick-home .recommend-wrap ul li .recommend-textBox01 {
  /* min-height: 130px; */
  min-height: 115px;
  margin-top: 12px;
}

.pick-home .recommend-wrap ul li p {
  position: relative;
  color: #666666;
  font-size: 16px;
  text-align: left;
  padding: 0 0px 0px 30px;
  line-height: 200%;
  word-break: keep-all;
  font-family: "PretendardM", "sans-serif";
}

.pick-home .recommend-wrap ul>li>.recommend-textBox01>p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 42%;
  left: 5%;
}

.pick-home .recommend-wrap .recommend-textBox02 {
  /* width: 285px; */
  width: 305px;
  /* min-height: 165px; */
  background-color: #f5f6f9;
  border-radius: 8px;
  margin: 0px auto;
  padding: 12px 0;
}

.pick-home .recommend-wrap .recommend-textBox02 h5 {
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
  margin-bottom: 16px;
  color: #888888;
}

.pick-home .recommend-wrap .recommend-textBox02 img {
  position: relative;
  top: 3px;
  right: 3px;
}

.pick-home .recommend-wrap .recommend-textBox02 p {
  color: #050f2f;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 8px;
  word-break: keep-all;
}

.pick-home .recommend-wrap .recommend-textBox02 p::after {
  content: "";
  width: 5px;
  height: 10px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-color: transparent #319dff #319dff transparent;
  position: absolute;
  /* top: 18%;
  left: 7%; */
  top: 34%;
  left: 6%;
  transform: translate(-50%, -50%) rotate(45deg);
}

/* 섹션6번 타사비교 */
.pick-home .application-card-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-image: url("image/main_section_bg01_02.svg");
  margin-bottom: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* @media (min-width: 2500px) {
  .pick-home .application-card-wrap {
    width: 2500px;
    background-image: url("image/main_section_bg01_02.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
} */

.pick-home .application-card-wrap .service-top-title {
  width: 100%;
  margin-top: 160px;
}

.pick-home .application-home-title {
  width: 100%;
  color: #050f2f;
  font-family: "PretendardB", "sans-serif";
  font-size: 48px;
  line-height: 150%;
  text-align: center;
  margin: 12px 0px;
}

.pick-home .application-card-wrap p {
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
}

.pick-home .application-card-wrap .title-sub-text {
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
  word-break: keep-all;
}

.pick-home .application-card-wrap .service-box-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 64px;
  align-items: center;
}

.pick-home .application-card-wrap .last-pp {
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  color: #333333;
}

.pick-home .application-card-wrap .last-pp span {
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  color: #3c8fef;
}

.pick-home .application-card-wrap .service-box-wrap .service-left-box h3 {
  width: 100%;
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  margin-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 12px;
}

.pick-home .application-card-wrap .service-box-wrap .service-left-box,
.service-right-box {
  border-radius: 16px;
  /* padding: 32px 40px 30px; */
}

/* .pick-home .application-card-wrap .service-box-wrap .service-left-box{
  width: 336px;
  height: 297px;
  background-color: #E8E8E8;
} 
.pick-home .application-card-wrap .service-left-box h3{
  color: #656565;
}
.pick-home .application-card-wrap .service-left-box .service-left-img{
  margin-bottom: 12px;
}
.pick-home .application-card-wrap .service-left-box .left-ptag{
  display: flex;
  align-items: center;
  justify-content: center;
}
.pick-home .application-card-wrap .service-left-box .left-ptag span{
  font-size: 14px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  border-right: 1px solid #E3E3E3;
}
.pick-home .application-card-wrap .service-left-box .left-ptag span:last-child{
border:none;
}
.pick-home .application-card-wrap .service-left-ul{
  display: flex;
  flex-wrap: wrap;
  width: 336px;
  /* height: 297px;
}
.pick-home .application-card-wrap .service-left-ul02{
  display: flex;
  align-items: center;
  gap: 80px;
}
.pick-home .application-card-wrap .service-left-ul02 li{
  padding-left: 20px;
  padding-top: 12px;
  font-size: 16px;
  color: #585858;
  font-family: "PretendardR", "sans-serif";
}

.pick-home .application-card-wrap .service-left-ul  li{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 113px;
  height: 130px;
  border-radius: 16px;
  background-color: #fff;
}
.pick-home .application-card-wrap .service-left-ul li p{
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #585858;
  font-family: "PretendardR", "sans-serif";
}
.pick-home .application-card-wrap .service-box-wrap .service-right-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family: "PretendardB", "sans-serif";
  width: 460px;
  height: 513px;
  border: 1px solid #3C8FEF;
  background-color: #E4F0F9;
}
.pick-home .application-card-wrap .service-right-ul01{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px 8px;
  width: 312px;
  margin-bottom: 12px;
}
.pick-home .application-card-wrap .service-right-ul02{
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
}
.pick-home .application-card-wrap .service-right-ul01 li{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:120px;
  height: 130px;
  border-radius: 16px;
  background-color: #fff;
  padding:12px 10px;
}
.pick-home .application-card-wrap .service-right-ul01 li p{
  width: 100%;
  font-size: 20px;
  text-align: center;
}
.pick-home .application-card-wrap .service-right-ul01 li span{
  display: block;
  width:200px;
  background: #e6f0f9;
  border-radius: 20px;
  color: #3C8FEF;
  font-size: 14px;
  padding: 6px 9px;
  margin: 0px auto;
  text-align: center;
}

.pick-home .application-card-wrap .service-right-ul01 li:last-child p{
  color: #333;
}
.pick-home .application-card-wrap .service-right-ul02 li{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 130px;
  border-radius: 16px;
  background-color: #fff;
  padding: 12px 10px;
}
.pick-home .application-card-wrap .service-right-box h3 {
  font-size: 30px;
  color: #333333;
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #cbdeed;
  margin-bottom: 24px;
}
.pick-home .application-card-wrap .service-right-box h3 span{
  color: #3C8FEF;
  font-size: 30px;
}
.pick-home .application-card-wrap .service-right-box li p{
  width: 100%;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
}
*/
.pick-home .application-card-wrap .column {
  justify-content: flex-start;
  padding: 40px 16px 16px 24px;
  border-radius: 8px;
  width: 298px;
  height: 354px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  margin-right: 18px;
}

.pick-home .application-card-wrap .column:last-child {
  margin-right: 0;
}

.pick-home .application-card-wrap .column h5 {
  width: 100%;
  font-family: "PretendardM", "sans-serif";
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
}

.pick-home .application-card-wrap .column p {
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  color: #858585;
}

.pick-home .application-card-wrap .column img {
  width: 150px;
  height: 150px;
  margin-top: 40px;
  align-self: flex-end;
}

.pick-home .yellow-area {
  margin: 160px 0;
  width: 100vw;
  height: 250px;
  background-color: #fffbee;
}

.pick-home .yellow-area span {
  font-size: 28px;
  line-height: 43px;
  letter-spacing: -1.5%;
  color: #858585;
}

.pick-home .yellow-area strong {
  font-size: 34px;
  line-height: 50px;
  font-family: "PretendardM", "sans-serif";
  margin: 4px 0;
}

/* 섹션7번 브랜드 뉴스 */
.pick-home .main-news-wrap {
  width: 1920px;
  /* height: 100vh; */
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px 0 100px 0;
}

.pick-home .main-news-wrap p {
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  text-align: center;
  line-height: 39.2px;
}

.pick-home .main-news-wrap .partners-title {
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 80px;
  color: #050f2f;
  letter-spacing: -0.002em;
  line-height: 72px;
}

.pick-home .main-news-wrap .partners-title .title-point {
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
}

.pick-home .main-news-wrap ul {
  display: flex;
  gap: 20px;
  width: 1280px;
}

.pick-home .main-news-wrap ul li {
  width: 25%;
  border-radius: 8px;
  outline: 1px solid #e8e8e8;
}

.pick-home .main-news-wrap ul li .newsbox {
  padding: 24px 32px 32px 32px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  text-align: left;
}

.pick-home .main-news-wrap ul li:hover .newsbox {
  background: #050f2f;
}

.pick-home .main-news-wrap ul li:hover h3 {
  color: #fff;
}

.pick-home .main-news-wrap ul li .newimgall {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 50%;
  border-radius: 8px 8px 0 0;
}

.pick-home .main-news-wrap ul li:hover {
  box-shadow: 0px 4px 18px rgba(5, 15, 47, 0.5);
  outline: 0px;
}

.pick-home .main-news-wrap ul li .newimg0101 {
  background-image: url("image/news01_01.svg");
}

.pick-home .main-news-wrap ul li .newimg0201 {
  background-image: url("image/news02_01.svg");
}

.pick-home .main-news-wrap ul li .newimg0301 {
  background-image: url("image/news03_01.svg");
}

.pick-home .main-news-wrap ul li .newimg0401 {
  background-image: url("image/news04_01.svg");
}

.pick-home .main-news-wrap li:hover .newimg0101,
.pick-home .main-news-wrap li:hover .newimg0201,
.pick-home .main-news-wrap li:hover .newimg0301,
.pick-home .main-news-wrap li:hover .newimg0401 {
  filter: brightness(0.7);
}

.pick-home .main-news-wrap ul li span {
  display: inline-block;
  border-radius: 50px;
  color: #01d854;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 4px;
  line-height: 140%;
}

.pick-home .main-news-wrap ul li h3 {
  width: 100%;
  /* height: 40px; */
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  color: #050f2f;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  line-height: 140%;
}

.pick-home .main-news-wrap ul li p {
  width: 265px;
  height: 67px;
  color: #666;
  font-size: 16px;
  text-align: left;
  line-height: 140%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* 섹션8번 파트너사 */
.pick-home .progress-content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 120px 0 120px 0;
}

.pick-home .progress-content-wrap p {
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
}

.pick-home .progress-content-wrap .partners-title {
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin: 12px 0 80px;
  line-height: 140%;
  letter-spacing: -0.8px;
}

.pick-home .progress-content-wrap .partners-title .title-point {
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
}

/* 마지막섹션 */
.pick-home .final-title-wrap {
  /* margin-bottom: 200px; */
  margin-bottom: 0px;
}

.pick-home .final-title-wrap,
.pick-home .final-title-wrap h6,
.pick-home .final-title-wrap h5,
.pick-home .final-title-wrap h5 span {
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}

.pick-home .final-title-wrap h6 {
  line-height: 46px;
  color: #050f2f;
  margin-bottom: 4px;
}

.pick-home .sub-final-wrap .main-final-content01 .main-final-top01 {
  background: linear-gradient(360deg, #c8e3ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
}


.floating-btn {
  position: fixed;
  right: 5vw;
  top: 90vh;
}
/* @media (min-width: 2500px) {
  .pick-home .sub-final-wrap .main-final-content01 .main-final-top01 {
    width: 2500px;
    background: linear-gradient(360deg, #C8E3FF 0%, rgba(255, 255, 255, 0) 100%);
  }
} */
.sub-final-wrap .final-title-wrap .main-final-top01 h5 span {
  font-size: 52px;
}

.sub-final-wrap .final-title-wrap .main-final-top01 .main-final-move {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 297px;
  height: 74px;
  border-radius: 100px;
  margin: 0px auto;
  background: #319dff;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 8px 18px rgba(49, 157, 255, 0.18);
  border-radius: 90px;
  gap: 18px;
  font-family: "PretendardSB", "sans-serif";
}

.pick-home .final-title-wrap h5 {
  margin-bottom: 86px;
  text-align: center;
  line-height: 150%;
}

.pick-home .final-title-wrap h5 span {
  line-height: 70px;
}

.pick-home .final-title-wrap .main-final-move {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 297px;
  height: 74px;
  gap: 12px;

  border-radius: 100px;
  margin: 0px auto;
  background: #319dff;
  font-size: 24px;
  color: #fff;
  margin-bottom: 60px;
}

.pick-home .final-title-wrap .box-wrap {
  width: 1920px;
}

.pick-home .box-wrap>.row>.column {
  padding: 48px 50px;
  width: 510px;
  height: 250px;
  border-radius: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.pick-home .box-wrap>.row>.column * {
  color: white;
}

.pick-home .box-wrap>.row>.column h4 {
  font-family: "PretendardM", "sans-serif";
  font-size: 26px;
  line-height: 24px;
  margin-bottom: 29px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.pick-home .box-wrap>.row>.column>span {
  line-height: 24px;
  margin-bottom: 16px;
}

.pick-home .box-wrap>.row>.column .direct-btn {
  cursor: pointer;
}

.pick-home .box-wrap>.row>.column .direct-btn span {
  font-family: "PretendardM", "sans-serif";
  line-height: 32px;
  margin-right: 12px;
}

.pick-home .box-wrap>.row>.column .direct-btn img {
  width: 8px;
  height: 12px;
}

.pick-home .box-wrap>.row>.column.self-box {
  background-color: rgba(255, 194, 0, 0.75);
  margin-right: 20px;
}

.pick-home .box-wrap>.row>.column.agencies-box {
  background-color: rgba(255, 142, 0, 0.75);
}

.pick-home .box-wrap>.row>.column>img {
  position: absolute;
  right: 50px;
  bottom: 26px;
  z-index: 1;
  width: 80px;
  height: 124px;
}

.pick-home .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  width: 1280px;
  height: 350px;
  background-image: url("image/guideBg02.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}

.pick-home .box-wrap .guide-box .guide-content {
  width: 502px;
  height: 150px;
  position: absolute;
  right: 265px;
  bottom: 99px;
}

.pick-home .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}

.pick-home .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 46px;
  padding: 12px 24px;
  border: 1px solid #050f2f;
  border-radius: 2px;
  cursor: pointer;
}

.pick-home .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "PretendardM", "sans-serif";
  line-height: 32px;
  color: #333333;
}

.pick-home .box-wrap .service-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  /* justify-content: space-between; */
  /* max-width: 1280px; */
  /* height: 330px; */
  height: 280px;
  padding: 83px 216px 80px 104px;
  border-radius: 8px;
  background-color: #00274a;
}

.pick-home .box-wrap .service-box .service-text,
.service-link {
  width: 50%;
  color: #fff;
}

.pick-home .box-wrap .service-box .service-text h4 {
  font-size: 34px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 40px;
}

.pick-home .box-wrap .service-box .service-text span {
  font-size: 20px;
  color: #dddddd;
}

.pick-home .box-wrap .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: #319dff;
  border-radius: 2px;
}

.pick-home .box-wrap .service-box .service-link a {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
}

.application-step {
  padding: 32px 0;
}

.application-step>h1 {
  width: 100%;
  font-size: 40px;
  line-height: 57.92px;
  font-family: "PretendardM", "sans-serif";
  margin-bottom: 30px;
}

.application-step .select-step {
  justify-content: flex-start;
  border-bottom: 1px solid #cdcdcd;
  position: relative;
  width: 100%;
}

.application-step .select-step>span {
  transform: translateY(2px);
  padding: 0 16px;
  cursor: pointer;
  color: #858585;
  font-size: 18px;
  line-height: 36px;
  margin-right: 8px;
}

.application-step .select-step>span:last-child {
  margin-right: 0;
}

.application-step .select-step>span.active {
  font-family: "PretendardM", "sans-serif";
  color: #ffb353;
  border-bottom: 3px solid #ffb353;
}

.application-step .step-description {
  width: 100%;
}

.application-step .step-description .service-description {
  margin-top: 48px;
  width: 100%;
  height: 176px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
}

.application-step .step-description .service-description h1 {
  font-size: 40px;
  font-family: "PretendardM", "sans-serif";
  line-height: 58px;
}

.application-step .step-description .service-description span {
  margin-top: 10px;
  text-align: center;
  color: #858585;
  font-size: 14px;
  line-height: 22px;
}

.application-step .step-description>img {
  margin-top: 40px;
}

.cost {
  justify-content: flex-start !important;
}

.cost>.title {
  margin: 32px 0 52px;
}

.cost .select-wrap>.row {
  cursor: pointer;
  padding: 24px 37px;
  border-radius: 8px;
  background-color: #f0f0f0;
  margin-right: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}

.cost .select-wrap>.row span {
  margin-right: 24px;
  color: #cdcdcd;
  font-size: 18px;
}

.cost .select-wrap>.row.active {
  border: 3px solid #ffc200;
  background-color: white;
}

.cost .select-wrap>.row.active span {
  color: #15171a;
}

.cost .select-wrap>.row:last-child {
  margin-right: 0;
}

.cost .cost-wrap {
  margin-top: 70px;
}

.cost .cost-wrap .cost-card {
  margin-right: 20px;
  width: 345px;
  height: 430px;
  border-radius: 16px;
  padding: 0 24px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}

.cost .cost-wrap .cost-card h4 {
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 24px;
}

.cost .cost-wrap .cost-card .mark-type-wrap {
  margin-bottom: 28px;
}

.cost .cost-wrap .cost-card .mark-type-wrap span {
  cursor: pointer;
  font-size: 12px;
  line-height: 140%;
  color: #cdcdcd;
}

.cost .cost-wrap .cost-card .mark-type-wrap span.active {
  color: #15171a;
}

.cost .cost-wrap .cost-card .mark-type-wrap span::after {
  content: "|";
  color: #d9d9d9;
  margin: 0 8px;
}

.cost .cost-wrap .cost-card .mark-type-wrap span:last-child::after {
  content: "";
}

.cost .cost-wrap .cost-card hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #d9d9d9;
  margin: 0;
}

.cost .cost-wrap .cost-card .cost-description {
  height: 238px;
  width: 100%;
  justify-content: flex-start;
}

.cost .cost-wrap .cost-card .cost-description .price {
  margin-top: 23px;
  font-family: "PretendardM", "sans-serif";
  font-size: 24px;
  line-height: 130%;
}

.cost .cost-wrap .cost-card .cost-description>span {
  font-size: 12px;
  line-height: 140%;
  color: #858585;
  margin: 4px 0 24px;
}

.cost .cost-wrap .cost-card .cost-description .benefits {
  align-items: flex-start;
}

.cost .cost-wrap .cost-card .cost-description .benefits .row img {
  margin-right: 16px;
}

.cost .cost-wrap .cost-card .cost-description .benefits .row span {
  font-size: 12px;
}

.cost .cost-wrap .cost-card .cost-description .benefits .row:last-child {
  margin-bottom: 0;
}

.cost .cost-wrap .cost-card>span {
  text-align: center;
  margin-top: 24px;
  width: 100%;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #ffc200;
  color: white;
  font-size: 18px;
  line-height: 140%;
}

.cost .cost-wrap .cost-card:last-child {
  margin-right: 0;
}

.cost .cost-detail-wrap.apply {
  height: 600px;
}

.cost .cost-detail-wrap .tabs {
  margin-top: 50px;
}

.cost .cost-detail-wrap .tabs span {
  cursor: pointer;
  line-height: 140%;
  font-size: 14px;
  padding: 15px 24px;
  border-radius: 99px;
  user-select: none;
}

.cost .cost-detail-wrap .tabs span.active {
  background-color: #ffc200;
  color: white;
}

.cost .cost-detail-wrap>hr {
  margin: 50px 0;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #d9d9d9;
}

.cost .cost-detail-wrap .cost-guide {
  margin-top: 25px;
}

.cost .cost-detail-wrap .cost-guide h5 {
  margin-bottom: 8px;
  line-height: 140%;
  font-size: 16px;
  color: #e60023;
  position: relative;
}

.cost .cost-detail-wrap .cost-guide h5::before {
  content: "*";
}

.cost .cost-detail-wrap .cost-guide h5:last-child {
  margin-bottom: 0;
}

.cost .cost-detail-wrap .description-header {
  margin-bottom: 50px;
}

.cost .cost-detail-wrap .description-header h3 {
  font-family: "PretendardM", "sans-serif";
  font-size: 18px;
}

.cost .cost-detail-wrap table {
  width: 900px;
}

.cost .cost-detail-wrap table thead {
  background-color: #fff9e3;
}

.cost .cost-detail-wrap table tbody tr {
  border-bottom: 1px solid #d9d9d9;
}

.cost .cost-detail-wrap table tbody td {
  padding: 25px 0;
  line-height: 140%;
}

.cost .cost-detail-wrap table td,
.cost .cost-detail-wrap table th {
  text-align: center;
  padding: 15px 0;
  font-size: 14px;
  vertical-align: middle;
}

.cost .cost-detail-wrap table td.left,
.cost .cost-detail-wrap table th.left {
  text-align: left;
}

.cost .cost-detail-wrap table td .row,
.cost .cost-detail-wrap table th .row {
  width: 80%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cost .cost-detail-wrap table td .row span,
.cost .cost-detail-wrap table th .row span {
  font-size: 14px;
  line-height: 140%;
}

.cost .cost-detail-wrap table td .row:last-child,
.cost .cost-detail-wrap table th .row:last-child {
  margin-bottom: 0;
}

.cost .cost-detail-wrap table td .column span,
.cost .cost-detail-wrap table th .column span {
  margin-bottom: 20px;
  font-size: 14px;
}

.cost .cost-detail-wrap table td .column span:last-child,
.cost .cost-detail-wrap table th .column span:last-child {
  margin-bottom: 0;
}

/* 뉴 가격표 */
.main-price-info-wrap {
  width: 1280px;
  padding: 120px 0 120px;
}

.main-price-info-wrap .sub-title-point {
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 12px;
  text-align: center;
  line-height: 140%;
  color: #319dff;
}

.main-price-info-wrap .partners-title {
  font-size: 42px;
  font-family: "PretendardB", "sans-serif";
  color: #050f2f;
  text-align: center;
  margin-bottom: 12px;
  letter-spacing: -0.8px;
}

.main-price-info-wrap .title_p {
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  color: #666666;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: -0.2px;
}

.P_radio01:checked {
  background-color: #0ecdc1;
  border: 3px solid white;
}

.pricetable-wrap .title_h2 {
  color: #050f2f;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
}

.pricetable-wrap .title_p {
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  margin: 20px 0 50px;
  line-height: 140%;
}

.subprice_table .price-info-title>th {
  padding: 32px 16px 24px;
}

.subprice_table .price-info-title th:nth-child(2) h3 {
  color: #050e2f;
}

.subprice_table .price-info-title th:nth-child(3) h3 {
  color: #ff7900;
}

.subprice_table .price-info-title th:nth-child(4) h3 {
  color: #0ecdc1;
}

.subprice_table .price-info-title h3:last-child {
  color: #00bf7a;
}

.price-info-title>th>p:not(.price-font) {
  padding: 16px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #e1e1e1;
  line-height: 140%;
  color: #858585;
}

.price-info-title>th>.price-info-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}

.price-font {
  border-top: none;
  padding-top: 0;
  color: #555555;
}

.main-price-info-wrap .price-day {
  color: #686868;
}

.price-radio-wrap {
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 20px;
}

.price-radio-wrap div {
  display: flex;
  gap: 10px;
}

.price-radio-wrap input[type="radio"] {
  margin: 0px;
}

input[type="radio"]:checked+.P_radio01 {
  background-color: #07cdc1;
  border-color: #e2e2e2;
}

input[type="radio"]:checked+.P_radio02 {
  background-color: #07cdc1;
  border-color: #e2e2e2;
}

.price-radio-wrap label {
  color: #878787;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}

/* 라디오버튼 커스텀 */
.price-radio-wrap .P_radio01 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.price-radio-wrap .P_radio01:checked {
  background-color: #0ecdc1;
  border: 3px solid white;
  box-shadow: 0 0 0 1.6px #e1e1e1;
}

.price-radio-wrap .P_radio02 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.price-radio-wrap .P_radio02:checked {
  background-color: #00bf7a;
  border: 3px solid white;
  box-shadow: 0 0 0 1.6px #e1e1e1;
}

.main-price-info-wrap .none-height {
  height: 15px;
}

.subprice_table tr>td:first-child {
  padding: 12px 10px;
}

.pick-home .sub_wrap_all .subsection .price-radio-wrap .P_radio01:checked {
  background-color: #0ecdc1;
  border: 3px solid white;
  box-shadow: 0 0 0 1.6px #e1e1e1;
}

.agencies .title {
  margin-bottom: 32px;
}

.agencies .base:first-child {
  z-index: 5;
}

.agencies .base:nth-child(2) {
  z-index: 4;
}

.agencies .base:nth-child(3) {
  z-index: 3;
}

.agencies .base:nth-child(4) {
  z-index: 2;
}

.agencies .base h3 {
  color: #cdcdcd;
  font-family: "PretendardM", "sans-serif";
  margin: 0 16px;
  font-size: 14px;
}

.agencies .base span {
  color: #cdcdcd;
  font-size: 12px;
}

.agencies .base.active h3 {
  font-size: 16px;
  color: #15171a;
  margin-right: 8px;
}

.agencies .base.active span {
  color: #15171a;
  font-size: 14px;
}

.agencies .content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 950px;
  background-color: white;
  border-radius: 16px;
  padding: 48px 75px;
}

.agencies .content-box h3 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  line-height: 140%;
  width: 100%;
  margin-bottom: 19px;
}

.agencies hr {
  margin: 0;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #666;
}

.agencies .secondLine {
  margin-bottom: 40px;
}



.agencies.input-applicant .content-box .topBar {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: flex-end;
}

.agencies.input-applicant .content-box .topBar h3 {
  margin-bottom: 0;
}

.agencies.input-applicant .content-box .topBar .common-checkBox {
  margin-right: 20px;
}

.agencies.input-applicant .content-box .topBar .common-checkBox div {
  width: 20px;
  height: 20px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  border-radius: 2px;
}

.agencies.input-applicant .content-box .topBar .common-checkBox div.active {
  background-color: #319dff;
  border-color: #319dff;
}

.agencies.input-applicant .content-box .topBar .common-checkBox span {
  color: #000;
  font-size: 16px;
  white-space: nowrap;
}

.agencies.input-applicant .content-box .topBar .common-checkBox:last-child {
  margin-right: 0;
}

.agencies.input-applicant .content-box .dashed {
  width: 624px;
  background-color: transparent;
  border: 1px dashed #666;
  margin: 32px auto;
}

.agencies.input-applicant .content-box .dashed.foreign {
  width: 624px;
  margin-bottom: 0;
}

.agencies.input-applicant .content-box .agree-wrap {
  align-items: flex-start;
  margin-bottom: 18px;
}

.agencies.input-applicant .content-box .agree-wrap .agree-title {
  font-size: 16px;
  font-family: "PretendardM", "sans-serif";
  margin-right: 48px;
}

.agencies.input-applicant .content-box .agree-wrap>.column {
  width: 374px;
  align-items: flex-start;
}

.agencies.input-applicant .content-box .agree-wrap>.column .row {
  margin-bottom: 20px;
}

.agencies.input-applicant .content-box .agree-wrap > .column .sub-agree {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.agencies.input-applicant .content-box .agree-wrap>.column .row:last-child {
  margin-bottom: 0;
}

.agencies.input-applicant .content-box .agree-wrap>.column .row .agree-context {
  justify-content: flex-start;
  line-height: 140%;
}

.agree-wrap .column .row {
  align-items: flex-start;
}

.agencies.input-applicant .content-box .agree-wrap>.column .row .agree-context.bold {
  font-family: "PretendardM", "sans-serif";
}

.agencies.input-applicant .content-box .agree-wrap>.column .row .agree-context img {
  margin-left: 4px;
  cursor: pointer;
  margin-top: 2px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .common-checkBox {
  margin-right: 12px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .common-checkBox .checkBox {
  border: 1px solid #cdcdcd;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .common-checkBox .checkBox .active {
  border: 1px solid #319dff !important;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .common-checkBox:last-child {
  margin-right: 0;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .common-checkBox span {
  font-size: 14px;
  color: #050f2f;
}

.agencies.input-applicant .content-box .agree-wrap>.column .row.agree-all .checkBox {
  border-color: #d1d1d1;
}

.agencies.input-applicant .guide {
  position: absolute;
  z-index: 5;
  text-align: center;
  background-color: #000;
  font-size: 12px;
  color: white;
  padding: 8px 13px;
  line-height: 140%;
  width: 380px;
  transform: translate(-50%, 50px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.agencies.input-applicant .guide.privacy {
  width: 480px;
  left: 50%;
  transform: translate(-20%, -120px);
  display: flex;
  align-items: flex-start;
  text-align: start;
}

.agencies.input-applicant .guide.privacy span {
  color: white;
  font-size: 12px;
}

.agencies.input-applicant .form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;
}

.agencies.input-applicant .form>.row {
  position: relative;
  margin-bottom: 16px;
}

.agencies.input-applicant .form>.row .email-input-wrap {
  display: flex;
  align-items: center;
}

.agencies.input-applicant .form>.row:last-child {
  margin-bottom: 0;
}

.agencies.input-applicant .form>.row .essential,
.agencies.input-applicant .form>.row .optional {
  margin-right: 17px;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
}

.agencies.input-applicant .form>.row .essential img,
.agencies.input-applicant .form>.row .optional img {
  margin-left: 6px;
  cursor: pointer;
  width: 16px;
}

.agencies.input-applicant .form>.row .essential::before {
  content: "*";
  color: #e12653;
}

.agencies.input-applicant .form>.row input[type="text"],
.agencies.input-applicant .form>.row input[type="password"] {
  padding: 16px;
  line-height: 140%;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  background-color: white;
}

.agencies.input-applicant .form>.row input {
  height: 45px;
  color: #000;
  font-size: 16px;
  margin-left: 0;
}

.agencies.input-applicant .form>.row input::placeholder {
  color: #878787;
}

.agencies.input-applicant .form>.row input[type="text"].common-input,
.agencies.input-applicant .form>.row input[type="password"].common-input {
  width: 451px;
}

.agencies.input-applicant .form>.row input[type="text"].id-input,
.agencies.input-applicant .form>.row input[type="password"].id-input {
  width: 210px;
}

.agencies.input-applicant .form>.row input[type="text"].email-input,
.agencies.input-applicant .form>.row input[type="password"].email-input {
  width: 140px;
  margin: 0;
}

.agencies.input-applicant .form>.row input[type="text"].phone-input,
.agencies.input-applicant .form>.row input[type="password"].phone-input {
  width: 130px;
  margin: 0;
}

.agencies.input-applicant .form>.row input[type="text"].search-input,
.agencies.input-applicant .form>.row input[type="password"].search-input {
  width: 351px;
}

.agencies.input-applicant .form>.row input[type="text"]::placeholder,
.agencies.input-applicant .form>.row input[type="password"]::placeholder {
  color: #878787;
}

.agencies.input-applicant .form>.row .hyphen {
  margin: 0 12px;
}

.agencies.input-applicant .form>.row .at {
  margin: 0 9px;
}

.agencies.input-applicant .form>.row .search-btn {
  width: 90px;
  background-color: #319dff;
  border-radius: 4px;
  color: white;
  margin-left: 10px;
  height: 45px;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
}

.agencies.input-applicant .form>.row .selectBox {
  width: 130px;
  margin-left: 9px;
}

.agencies.input-applicant .form>.row .selectBox .selectVal {
  padding: 11.5px 15px;
  height: 45px;
  color: #000;
  width: 130px;
}

.agencies.input-applicant .form>.row .selectBox .selectVal img {
  width: 13px;
}

.agencies.input-applicant .form>.row .selectBox .selectOptGroup {
  width: 130px;
}

.agencies.input-applicant .form>.row .file-input {
  border-radius: 4px;
}

.agencies.input-applicant .form>.row .file-input .select-file-button {
  cursor: pointer;
  border: 1px solid #319dff;
  padding: 11.5px 15px;
  height: 45px;
  margin-left: 10px;
  width: 90px;
  border-radius: 4px;
  color: #319dff;
  line-height: 140%;
}

.agencies.input-applicant .form>.row .check-wrap {
  width: 400px;
  justify-content: flex-start;
  padding-left: 22px;
  margin-left: -24px;
}

.agencies.input-applicant .form>.row .check-wrap .common-checkBox {
  margin-right: 10px;
}

.agencies.input-applicant .form>.row .check-wrap .common-checkBox .checkBox {
  border-color: #d9d9d9;
  margin-right: 8px;
}

.agencies.input-applicant .form>.row .check-wrap .common-checkBox .checkBox.active {
  background-color: #15171a;
  border-color: #15171a;
}

.agencies.input-applicant .form>.row .check-wrap .common-checkBox span {
  color: #15171a;
}

.agencies.input-applicant .form>.row .copy-title {
  margin-right: 10px;
  font-size: 16px;
  color: #050f2f;
}

.agencies.input-applicant .form>.row .copy-btn {
  width: 90px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #319dff;
  font-size: 16px;
  color: #319dff;
  padding: 14px 16px;
  cursor: pointer;
  margin-left: 10px;
}

.agencies.input-applicant .form>.row .file-name-input {
  padding: 16px;
  height: 45px;
  width: 350px;
  border: 1px solid #d1d1d1;
  color: #878787;
  border-radius: 4px;
}

.agencies.input-applicant .form>.row.check-foreign-certification {
  margin-top: 16px;
}

.agencies.payment-confirm .content-box {
  padding: 48px 75px;
}

.agencies.payment-confirm .content-box .payment-step {
  width: 100%;
  margin: 40px 0 32px;
}

.agencies.payment-confirm .content-box .payment-step div {
  width: 50%;
  text-align: center;
  padding: 13px 0;
  background-color: #f5faff;
  font-family: "PretendardR", "sans-serif";
  color: #666;
  font-size: 16px;
  border: 1px solid #d1d1d1;
  position: relative;
}

.agencies.payment-confirm .content-box .payment-step div.active {
  border: 1px solid #319dff;
  color: #319dff;
  background-color: #fff;
  font-family: "PretendardB", "sans-serif";
}

/* 기본 화살표 스타일 */
.agencies.payment-confirm .content-box .payment-step .step {
  position: relative;
}

.agencies.payment-confirm .content-box .payment-step .step.arrow-right:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 20px solid #d1d1d1;
  z-index: 1;
}

.agencies.payment-confirm .content-box .payment-step .step.arrow-right:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -19px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 20.5px solid transparent;
  border-bottom: 20.5px solid transparent;
  border-left: 19px solid #fff;
  z-index: 2;
}

.agencies.payment-confirm .content-box .payment-step .step.arrow-left:not(:first-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-right: 20px solid #d1d1d1;
  z-index: 1;
}

.agencies.payment-confirm .content-box .payment-step .step.arrow-left:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -19px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 20.5px solid transparent;
  border-bottom: 20.5px solid transparent;
  border-right: 19px solid #fff;
  z-index: 2;
}

/* 활성화된 상태의 화살표 */
.agencies.payment-confirm .content-box .payment-step .step.arrow-right.active:not(:last-child)::after {
  border-left-color: #319dff;
}

.agencies.payment-confirm .content-box .payment-step .step.arrow-left.active:not(:first-child)::after {
  border-right-color: #319dff;
}

/*----------------------------------------------*/

.agencies.payment-confirm .content-box>h4 {
  width: 100%;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 140%;
  margin-top: 32px;
}

.agencies.payment-confirm .content-box .order-info {
  width: 100%;
  align-items: flex-start;
}

.agencies.payment-confirm .content-box .order-info h3 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
}

.agencies.payment-confirm .content-box .order-info .order-info-wrap {
  display: flex;
  width: 100%;
}

.agencies.payment-confirm .content-box .order-info .second .box-head {
  border-top: none;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
}

.agencies.payment-confirm .content-box .order-info .second .box-content {
  border-top: none;
  padding: 0;
}

.agencies.payment-confirm .content-box .order-info hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #d9d9d9;
  margin: 30px 0;
}

.agencies.payment-confirm .content-box .order-info .order-content:first-child {
  border-top: none !important;
}

.agencies.payment-confirm .content-box .order-info .order-content {
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #d1d1d1;
  align-items: flex-start;
}

.agencies.payment-confirm .content-box .order-info .order-content .order-list {
  justify-content: flex-start;
}

.agencies.payment-confirm .content-box .order-info .order-content .order-list span {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
}

.agencies.payment-confirm .content-box .order-info .order-content .order-list .order-list-detail h5 {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  margin-top: 4px;
}

.agencies.payment-confirm .content-box .order-info .order-content>.column {
  width: 430px;
  margin-right: 60px;
  align-items: flex-start;
}

.agencies.payment-confirm .content-box .order-info .order-content>.column h5 {
  margin-bottom: 16px;
}

.agencies.payment-confirm .content-box .order-info .order-content>.column .common-checkBox .checkBox {
  width: 16px;
  height: 16px;
  border-color: #d9d9d9;
  margin-right: 8px;
}

.agencies.payment-confirm .content-box .order-info .order-content>.column .common-checkBox .checkBox img {
  width: 8px;
}

.agencies.payment-confirm .content-box .order-info .order-content>.column .common-checkBox .checkBox.active {
  background-color: #ffc200;
}

.agencies.payment-confirm .content-box .order-info .order-content>.column .common-checkBox span {
  color: #15171a;
  font-size: 12px;
  line-height: 140%;
}

.agencies.payment-confirm .content-box .order-info .order-content>.column .common-checkBox:last-child {
  margin-left: 32px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type {
  display: flex;
  flex-direction: column;
  position: relative;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .small-box {
  display: flex;
  align-items: center;
  width: 240px;
  border: 1px solid #d1d1d1;
  padding: 8px;
  justify-content: center;
  border-radius: 4px;
  gap: 4px;
  margin-bottom: 8px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .small-box h5 {
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide-icon {
  cursor: pointer;
  position: absolute;
  right: 16px;
  width: 20px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide {
  position: absolute;
  transform: translate(96px, -105px);
  padding: 16px;
  align-items: flex-start;
  background-color: #000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  width: 300px;
  font-size: 14px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 15px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide span {
  line-height: 140%;
  color: white;
  font-size: 14px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide span.red {
  color: #ff0000;
  margin: 12px 0;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide>.row {
  width: 268px;
  justify-content: space-between;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide>.row .column span {
  color: #319dff;
  text-align: left;
  white-space: nowrap;
  margin-bottom: 4px;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide>.row .column.date-title span {
  width: 135px;
  text-align: left;
}

.agencies.payment-confirm .content-box .order-info .order-content .application-type .guide>.row .column.date {
  align-items: flex-end;
}

.agencies.payment-confirm .content-box .view-amount-list {
  width: 100%;
  margin-top: 16px;
  justify-content: flex-start;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group {
  width: 188px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  margin-right: 16px;
  padding: 20px 16px;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .card-title-box {
  display: flex;
  align-items: center;
  gap: 4px;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .application-type {
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .card-itemList {
  font-size: 12px;
  font-family: "PretendardR", "sans-serif";
  margin-top: 12px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  text-align: center;
  position: relative;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .dashed {
  width: 100%;
  border: 1px dashed #cdcdcd;
  margin: 16px 0;
  background-color: transparent;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .row {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .final {
  margin-bottom: 0;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .row span {
  font-size: 12px;
  line-height: 140%;
  font-family: "PretendardR", "sans-serif";
  color: #666;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .row span.price {
  color: #666;
  width: 66px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .row.final-check-price {
  margin: 0;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .row.final-check-price span {
  font-size: 14px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .row.final-check-price span:last-child {
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  width: 127px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group .row.total-price {
  margin-bottom: 0;
}

.agencies.payment-confirm .content-box .view-amount-list .amount-group:last-child {
  margin-right: 0;
}

.agencies.payment-confirm .content-box .final-check {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.agencies.payment-confirm .content-box .box-head {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  background-color: #f5faff;
  width: 100px;
  color: #050f2f;
  border: 1px solid #d1d1d1;
  white-space: nowrap;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agencies.payment-confirm .content-box .box-content .mark-img {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  padding: 10px;
}

.agencies.payment-confirm .content-box .box-content {
  font-size: 14px;
  line-height: 140%;
  padding: 15.5px 16px;
  border: 1px solid #d1d1d1;
  width: calc(100% - 100px);
  border-left: none;
}

.agencies.payment-confirm .content-box .final-check .box-content {
  font-family: "PretendardSB", "sans-serif";
  color: #ff0000;
}

.agencies.payment-confirm .content-box .payment-select {
  width: 100%;
  align-items: flex-start;
  margin-top: 32px;
}

.agencies.payment-confirm .content-box .payment-select .tax-bill-wrap {
  display: flex;
  width: 100%;
}

.agencies.payment-confirm .content-box .payment-select .tax-bill-wrap .box-head {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
}

.agencies.payment-confirm .content-box .payment-select .tax-bill-wrap .box-content {
  border-top: none;
}

.agencies.payment-confirm .content-box .payment-select .select-method-title {
  font-family: "PretendardSB", "sans-serif";
  color: #050f2f;
  font-size: 16px;
}

.agencies.payment-confirm .content-box .payment-select .select-area {
  margin-top: 16px;
  width: 100%;
}

.agencies.payment-confirm .content-box .payment-select .select-area .box-content {
  display: flex;
}

.agencies.payment-confirm .content-box .payment-select .radio-option {
  margin-right: 16px;
  font-family: "PretendardR", "sans-serif";
  display: flex;
  align-items: center;
  gap: 8px;
}

.agencies.payment-confirm .content-box .payment-select .radio-option .radio-btn {
  margin: 0;
  cursor: pointer;
}

.agencies.payment-confirm .content-box .payment-select .radio-option .radio-label {
  font-size: 14px;
}

.agencies.payment-confirm .content-box .payment-select .bank-name {
  display: flex;
  width: 100%;
  height: 72px;
}

.agencies.payment-confirm .content-box .payment-select .bank-name .box-head {
  padding: 26px 0;
  border-top: none;
}

.agencies.payment-confirm .content-box .payment-select .bank-name .box-content {
  display: flex;
  gap: 16px;
  border-top: none;
}

.agencies.payment-confirm .content-box .payment-select .bank-namebox {
  width: 180px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  font-size: 14px;
}

.agencies.payment-confirm .content-box .payment-select .bank-manual {
  font-size: 14px;
  line-height: 140%;
}

.agencies.payment-confirm .content-box .payment-select .radio-area {
  display: flex;
  align-items: center;
}

.agencies.payment-confirm .content-box .payment-select .radio-area input {
  margin: 0;
  cursor: pointer;
}

.agencies.payment-confirm .content-box .payment-select .red {
  line-height: 140%;
  font-size: 14px;
}

.agencies.payment-confirm .content-box .payment-select .red::before {
  content: "*";
  color: #e60023;
}

.agencies.payment-confirm .content-box .payment-select .red.private {
  margin-left: 0;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap {
  margin: 20px auto;
  align-items: flex-start;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap .row {
  margin-bottom: 12px;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap .row:last-child {
  margin-bottom: 0;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap .row strong {
  width: 85px;
  font-size: 14px;
  text-align: right;
  font-family: "PretendardM", "sans-serif";
  margin-right: 8px;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap .row input[type="text"] {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 14px 16px;
  width: 266px;
  height: 40px;
  margin-left: 0;
  font-size: 14px;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap .row input[type="text"] ::placeholder {
  color: #878787;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap .row input[type="text"].number-input {
  width: 76px;
  text-align: center;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap .row .hyphen {
  margin: 0 6px;
}

.agencies.payment-confirm .content-box .payment-select .input-wrap.private .row strong {
  width: auto;
}

.agencies.payment-confirm .content-box .payment-check {
  width: 100%;
}

.agencies.payment-confirm .content-box .payment-check hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #d1d1d1;
  margin: 16px 0;
}

.agencies .payment-confirm .content-box .payment-check h4 {
  width: 100%;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 140%;
  margin-top: 40px;
}

.agencies.payment-confirm .content-box .payment-check .bank-info,
.agencies.payment-confirm .content-box .payment-check .purchase-info {
  width: 100%;
  display: flex;
}

.agencies.payment-confirm .content-box .payment-check .purchase-content {
  width: 100%;
  display: flex;
}

.agencies.payment-confirm .content-box .payment-check .purchase-info.last {
  border-top: none;
  background-color: #f6f6f6;
  border-left: 1px solid #d1d1d1;
  width: 797px;
}

.agencies.payment-confirm .content-box .payment-check .purchase-content .box-content {
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agencies.payment-confirm .content-box .payment-check .purchase-content .box-content:first-child {
  border-left: 1px solid #d1d1d1;
}

.agencies.payment-confirm .content-box .payment-check .first {
  margin-top: 16px;
}

.agencies.payment-confirm .content-box .payment-check .box-head {
  border-right: none;
  vertical-align: middle;
}



.agencies.payment-confirm .content-box .payment-check .box-head:last-child {
  border-right: 1px solid #d1d1d1;
}

.agencies.payment-confirm .content-box .payment-check .second .box-content {
  border: 1px solid #d1d1d1;
  text-align: center;
  border-top: none;
  border-right: none;
}

.agencies.payment-confirm .content-box .payment-check .second .box-content:nth-child(2) {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.agencies.payment-confirm .content-box .payment-check .second .box-content:last-child {
  border-right: 1px solid #d1d1d1;
}

.agencies.payment-confirm .content-box .payment-check h4 {
  text-align: left;
  width: 100%;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  margin-top: 40px;
}

.agencies.payment-confirm .content-box .payment-check h4.purchase {
  margin: 20px auto 16px auto;
  line-height: 140%;
}

.agencies.payment-confirm .content-box .payment-check .caution {
  margin-top: 16px;
  width: 100%;
  font-size: 14px;
}

.agencies.payment-confirm .content-box .payment-check .caution p {
  font-size: 14px;
  line-height: 22.4px;
  color: #050F2F;
}

.agencies.payment-confirm .content-box .payment-check .caution span {
  margin-right: 2px;
  color: #FF0000;
}

.agencies.payment-confirm .content-box .payment-check .guide-context {
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-family: "PretendardR", "sans-serif";
  line-height: 130%;
  margin-bottom: 24px;
}

.agencies.payment-confirm .content-box .payment-check .guide-context span {
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
  color: #319dff;
}

.agencies.payment-confirm .content-box .payment-check .guide-comment {
  width: 100%;
  text-align: left;
  line-height: 24px;
  color: #666666;
}

.agencies.payment-confirm .content-box .payment-check .guide-comment span {
  font-family: "PretendardSB", "sans-serif";
  color: #666666;
}

.agencies.payment-confirm .content-box .payment-check .price {
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 16px;
}

.agencies.payment-confirm .content-box .payment-check .total span {
  font-family: "PretendardSB", "sans-serif";
}

.agencies.payment-confirm .content-box .payment-check .price :first-child {
  color: #050f2f;
}

.agencies.payment-confirm .content-box .payment-check .price.pay-method {
  margin-top: 16px;
}

.agencies .modal-bg {
  z-index: 10;
}

.agencies .modal-bg .warrant {
  position: relative;
  justify-content: flex-start;
  padding: 40px 40px 24px 40px;
  width: 640px;
  height: 700px;
}

.agencies .modal-bg .scroll-wrap {
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.08);
}

.agencies .modal-bg .warrant hr {
  width: 100%;
  border: 0;
  border-top: 1px solid #cdcdcd;
  margin: 24px auto;
}

.agencies .modal-bg .warrant .warrant-title {
  font-family: "PretendardB", "sans-serif";
  font-size: 28px;
  line-height: 140%;
}

.agencies .modal-bg .warrant .exit-img {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.agencies .modal-bg .warrant .sub-title {
  width: 100%;
  font-family: "PretendardB", "sans-serif";
  font-size: 18px;
  line-height: 140%;
}

.agencies .modal-bg .warrant .info {
  width: 100%;
  justify-content: space-between;
  height: 20px;
  font-size: 14px;
  margin: 12px 0 24px 0;
}

.agencies .modal-bg .warrant .second-title {
  margin-bottom: 1px;
}

.agencies .modal-bg .warrant .info-wrap {
  width: 100%;
}

.agencies .modal-bg .warrant .info-one {
  justify-content: flex-start;
  margin: 11px auto 24px auto;
  width: 100%;
}

.agencies .modal-bg .warrant .info-detail {
  justify-content: flex-start;
  width: 230px;
  border: none;
}

.agencies .modal-bg .warrant .info-content {
  border: none;
  white-space: nowrap;
}

.agencies .modal-bg .warrant .info-detail p,
.agencies .modal-bg .warrant .info-detail span {
  font-size: 14px;
  margin-bottom: 0;
}

.agencies .modal-bg .warrant .info-detail .info-title {
  justify-content: space-between;
}

.agencies .modal-bg .warrant .info-detail .info-title p {
  margin-right: 5px;
}

.agencies .modal-bg .warrant .info-detail .colon {
  margin: 0 10px 0 0;
}

.agencies .modal-bg .warrant .event-mark,
.agencies .modal-bg .warrant .event-mark p {
  width: 100%;
  font-size: 14px;
  margin-top: 12px;
}

.agencies .modal-bg .warrant .event-mark p {
  margin-top: 8px;
}

.agencies .modal-bg .warrant .warrant-matters-title {
  margin: 24px 0 12px;
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  width: 100%;
}

.agencies .modal-bg .warrant .warrant-matters {
  align-items: flex-start;
  margin-bottom: 40px;
}

.agencies .modal-bg .warrant .warrant-matters p {
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 8px;
}

.agencies .modal-bg .warrant .warrant-matters p:last-child {
  margin-bottom: 0;
}

.agencies .modal-bg .warrant .warrant-info span {
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 140%;
}

.agencies .modal-bg .warrant .warrant-info span.date {
  margin-bottom: 10px;
}

.agencies .modal-bg .agree-btn {
  background-color: #319dff;
  width: 560px;
  padding: 13px 0;
  border-radius: 2px;
  color: white;
  margin: 24px auto 0 auto;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
}

.agencies .modal-bg .search-country {
  width: 420px;
  padding: 24px 32px;
}

.agencies .modal-bg .search-country h3 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  color: #050f2f;
  margin-bottom: 0;
}

.agencies .modal-bg .search-country hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #cdcdcd;
  margin: 16px 0 24px;
}

.agencies .modal-bg .search-country .searchCountryBtn {
  width: 80px;
  border: 1px solid #319dff;
  border-radius: 4px;
  color: #319dff;
  height: 40px;
  font-family: "PretendardM", "sans-serif";
  font-size: 14px;
  margin-left: 8px;
}

.agencies .modal-bg .search-country>.row .common-text-input {
  width: 268px;
  padding: 11.5px 16px;
  border-color: #cdcdcd;
  font-size: 14px;
  height: 40px;
}

.agencies .modal-bg .search-country>.row .common-text-input::placeholder {
  color: #878787;
}

.agencies .modal-bg .search-country .none-list {
  margin: 115px 0 83px;
}

.agencies .modal-bg .search-country .none-list span {
  margin-top: 8px;
  color: #cdcdcd;
}

.agencies .modal-bg .search-country .country-list {
  width: 100%;
  margin-top: 24px;
}

.agencies .modal-bg .search-country .country-list .result {
  width: 100%;
  font-size: 14px;
  line-height: 22px;
}

.agencies .modal-bg .search-country .country-list ul {
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  height: 197px;
  border: 1px solid #d9d9d9;
  overflow: auto;
}

.agencies .modal-bg .search-country .country-list ul li {
  width: 100%;
  cursor: pointer;
  display: flex;
}

.agencies .modal-bg .search-country .country-list ul li span {
  font-family: "PretendardR", "sans-serif";
  color: #050f2f;
  font-size: 14px;
}

.agencies .modal-bg .search-country .country-list ul li.active {
  background-color: #319dff;
}

.agencies .modal-bg .search-country .country-list ul li.active span {
  font-family: "PretendardSB", "sans-serif";
  color: #fff;
}

.agencies .modal-bg .search-country .country-list ul li.top .country-code-wrap {
  width: 125px;
  text-align: center;
  height: 32px;
  padding: 9.5px 0;
}

.agencies .modal-bg .search-country .country-list .country-code-wrap {
  width: 125px;
  text-align: center;
  height: 32px;
  padding: 9.5px 0;
}

.agencies .modal-bg .search-country .country-list ul li.top .country-wrap {
  width: 231px;
  text-align: center;
  height: 32px;
  padding: 9.5px 0;
}

.agencies .modal-bg .search-country .country-list .country-wrap {
  width: 231px;
  text-align: center;
  height: 32px;
  padding: 9.5px 0;
}

.agencies .modal-bg .search-country .country-list ul li.top {
  cursor: default;
  background-color: #f5faff;
}

.agencies .modal-bg .search-country .country-list ul li.top :first-child {
  margin-right: 0;
  font-family: "PretendardSB", "sans-serif";
}

.agencies .modal-bg .search-country .country-list ul li:last-child {
  margin-bottom: 0;
}

.agencies .modal-bg .search-country .btn-wrap {
  margin: 24px auto 0 auto;
}

.agencies .modal-bg .search-country .btn-wrap button {
  width: 100px;
  height: 40px;
  padding: 10px 37px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  border-radius: 0;
}

.agencies .modal-bg .search-country .btn-wrap button:first-child {
  margin-right: 8px;
}

.agencies .modal-bg .search-country .btn-wrap button:last-child {
  background-color: #858585;
  color: white;
}

.agencies .btn-wrap {
  margin: 48px auto;
}

.agencies .btn-wrap>button {
  margin-right: 16px;
}

.agencies .btn-wrap>button:last-child {
  margin-right: 0;
}

/* 메인 검색시 나오는 페이지 */
.SelfDiagnosis01-wrap .SelfDiagnosis-content {
  padding-top: 80px;
  padding-bottom: 165px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 56px;
  flex-direction: row;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left {
  width: 900px;
  background: #F5FAFF;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content {
  padding: 40px 48px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul .first-li01 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul .first-li02 {
  width: 159px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #319dff;
  border-radius: 8px;
  gap: 12px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul .first-li02 p {
  color: #fff;
  font-family: "PretendardSB", "sans-serif";
  font-size: 24px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul li .title-p {
  font-size: 28px;
  color: #000;
  font-family: "PretendardSB", "sans-serif";
  line-height: 42px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul li .title-p span {
  font-size: 28px;
  color: #319dff;
  font-family: "PretendardB", "sans-serif";
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul li .title-p .txt-black {
  color: #000;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .txt-underline {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .txt-underline {
  font-family: "PretendardSB", "sans-serif" !important;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .dangerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .danger_text {
  font-size: 22px;
  font-family: "PretendardSB", "sans-serif" !important;
  line-height: 30.8px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul {
  width: 782px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px;
  gap: 8px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  cursor: pointer;
}


.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul li:hover {
  border: 2px solid #319dff;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul .second-ul-mb {
  margin-bottom: 8px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 782px;
  height: 184px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  padding: 40px 42.5px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul img {
  width: 50px;
  height: 65px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul p {
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  line-height: 33.6px;
  color: #050f2f;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .mark_subtitle {
  font-family: "PretendardR", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  white-space: nowrap;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .mark_subtitle_mt {
  margin-top: 10px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul .i_check {
  margin-right: 16px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul .i_sec {
  margin-left: 8px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li img {
  width: 80px;
  height: 80px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content {
  width: 100%;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content span {
  color: #319dff;
  font-size: 26px;
  font-family: "PretendardB", "sans-serif";
  display: block;
  margin-bottom: 4px;
  line-height: 36.4px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content h2 {
  width: 100%;
  font-size: 34px;
  font-family: "PretendardB", "sans-serif";
  color: #050f2f;
  margin-bottom: 40px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}


.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li p {
  font-size: 20px;
  color: #585858;
  line-height: 30px;
  font-family: "PretendardR", "sans-serif";
}


.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li p strong {
  font-size: 20px;
  color: #585858;
  line-height: 30px;
  font-family: "PretendardSB", "sans-serif";
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot {
  width: 100%;
  margin-top: 26px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 64px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li:first-child {
  background: #319dff;
  width: 420px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li:first-child a {
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  padding: 16.5px 0;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li a {
  font-size: 22px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  display: block;
  text-align: center;
  width: 100%;
  padding: 16.5px 0;
  white-space: nowrap;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box {
  border-radius: 8px;
  height: 308px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  border: 1px solid #d1d1d1;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box span {
  font-family: "PretendardB", "sans-serif";
  font-size: 24px;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box .title-p {
  font-size: 24px;
  font-family: "PretendardSB", "sans-serif";
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box span {
  color: #319dff;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box .btn_more {
  font-family: "PretendardSB", "sans-serif";
  padding: 12px 20px;
  border-radius: 8px;
  border: 2px solid #d9d9d9;
  display: flex;
  align-items: center;
  color: #666;
}

.SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box .btn_more .chevronright {
  margin-left: 9px;
  width: 10px;
  height: 12px;
}


/* 새로운 마이페이지 */
.new-mypage-wrap {
  width: 100%;
  background: #eaeef2;
  padding: 110px 0;
}

.new-mypage-wrap .new-mypage-box {
  display: flex;
  gap: 40px;
  width: 1280px;
  height: 100%;
  margin: 0px auto;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab {
  width: 250px;
  border-radius: 8px;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .user-info {
  display: flex;
  width: 100%;
  background: #319dff;
  flex-wrap: wrap;
  align-items: center;
  padding: 50px;
  border-radius: 8px 8px 0 0;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .user-info h2 {
  width: 100%;
  color: #fff;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "PretendardR", "sans-serif";
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .user-info span {
  font-size: 14px;
  color: #fff;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .tab-list {
  width: 100%;
  padding: 30px 0px;
  background: #fff;
  border-radius: 0 0 8px 8px;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .tab-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 28px;
  border-bottom: 1px solid #ccc;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .tab-list li :active {
  color: #319dff;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .tab-list li:last-child {
  border-bottom: none;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .tab-list li p {
  font-size: 18px;
  color: #050f2f;
}

.new-mypage-wrap .new-mypage-box .new-mypage-tab .tab-list li img {
  width: 20px;
  height: 30px;
  transform: rotate(90deg);
}

.new-mypage-wrap .new-mypage-box .new-mypage-write-wrap {
  background: #fff;
  width: 990px;
  border-radius: 8px;
  padding: 60px 40px;
}

.new-mypage-write-wrap .mypage-write-all h2 {
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  color: #050f2f;
  border-bottom: 1px solid #050f2f;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.new-mypage-write-wrap .mypage-write-all .topbox {
  display: flex;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.new-mypage-write-wrap .mypage-write-all .head-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 55px;
  background: #f2f8fd;
}

.new-mypage-write-wrap .mypage-write-all .head-title p {
  color: #050f2f;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
}

.new-mypage-write-wrap .mypage-write-all .topbox .infobox {
  width: 319px;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
}

.new-mypage-write-wrap .mypage-write-all .infobox .inputbox {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  border: 1px solid #e1e1e1;
}

.new-mypage-write-wrap .mypage-write-all .botbox {
  display: flex;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: -1px;
}

.new-mypage-write-wrap .mypage-write-all .botbox .infobox {
  width: 319px;
  padding: 10px 12px;
}

.new-mypage-write-wrap .mypage-write-all .infobox .inputbox {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
}

.new-mypage-write-wrap .mypage-write-all .infobox .telbox {
  width: 66%;
  height: 100%;
  background: #fff;
}

.new-mypage-write-wrap .mypage-write-all .infobox button {
  border: 1px solid #319dff;
  background: #fff;
  color: #319dff;
  padding: 9px 16px;
}

.new-mypage-write-wrap .mypage-write-all .infobox .emailbox {
  width: 100%;
  height: 100%;
  background: #fff;
}

.new-mypage-write-wrap .mypage-write01 {
  margin-bottom: 60px;
}

.new-mypage-write-wrap .mypage-write02 {
  margin-bottom: 60px;
}

.new-mypage-write-wrap .mypage-write03 {
  margin-bottom: 60px;
}

.new-mypage-write-wrap .mypage-write-all .twobox {
  display: flex;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.new-mypage-write-wrap .mypage-write-all .twobox .infobox {
  width: 319px;
  padding: 10px 12px;
}

.new-mypage-write-wrap .mypage-write-all .twobox .infobox .inputbox {
  width: 100%;
  height: 100%;
  background: #fff;
}

.new-mypage-write-wrap .mypage-write-all .threebox {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.new-mypage-write-wrap .mypage-write-all .threebox .infobox {
  width: 80%;
  padding: 10px 12px;
  display: flex;
  gap: 10px;
}

.new-mypage-write-wrap .mypage-write-all .threebox .infobox .telbox {
  width: 300px;
}

.new-mypage-write-wrap .member-quit {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.new-mypage-write-wrap .member-quit button {
  color: #666666;
}

.new-mypage-write-wrap .info-save-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.new-mypage-write-wrap .info-save-btn button {
  width: 138px;
  height: 45px;
  background: #319dff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 2px;
}

.mypage02tab .table-wrap .table-head {
  display: flex;
  flex-direction: row;
}

.mypage02tab table {
  width: 100%;
  border-collapse: collapse;
}

.mypage02tab th {
  height: 55px;
  background: #f2f8fd;
}

.mypage02tab th p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #050f2f;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
}

.mypage02tab th,
td {
  border: 1px solid #ddd;
  text-align: center;
}

.mypage-write03 .tab02_03 {
  display: flex;
  flex-wrap: wrap;
}

.mypage-write03 ul {
  width: 50%;
}

.mypage-write03 .tab02_03 ul li p {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f8fd;
  color: #050f2f;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  height: 55px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-right: none;
}

.mypage-write03 .tab02_03 ul:first-child li p {
  border-right: 1px solid #e1e1e1;
}

.mypage-write03 .tab02_03 ul li span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #878787;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  height: 55px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-right: none;
  margin: -1px;
}

.new-mypage-write-wrap .mypage-write-all .infobox .num01 {
  width: 50px;
  height: 35px;
}

.new-mypage-write-wrap .mypage-write-all .infobox .num02 {
  width: 50px;
  height: 35px;
}

.new-mypage-write-wrap .mypage-write-all .infobox .num03 {
  width: 100px;
  height: 35px;
}

.new-mypage-write-wrap .mypage-write-all .infobox .num04 {
  width: 50px;
  height: 35px;
}

.new-mypage-write-wrap .mypage-write-all .threebox .infobox .inputbox {
  background: #fff;
  font-size: 14px;
}

.new-mypage-write-wrap .mypage-write02 .threebox .infobox button {
  width: 80px;
  height: 35px;
}

.mypage-write04 ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mypage-write04 ul li {
  display: flex;
  width: calc(50% - 20px);
  height: 72px;
  align-items: center;
  justify-content: center;
  border: 1px solid #319dff;
  border-radius: 2px;
  gap: 27px;
}

.mypage-write04 ul li p {
  font-size: 16px;
  color: #050f2f;
}

.mypage-write04 ul li p span {
  font-size: 16px;
  color: #319dff;
}

.mypage03tab .table-wrap .table-head {
  display: flex;
  flex-direction: row;
}

.mypage03tab table {
  width: 100%;
  border-collapse: collapse;
}

.mypage03tab th {
  height: 55px;
  background: #f2f8fd;
}

.mypage03tab th p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #050f2f;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
}

.mypage03tab th,
td {
  border: 1px solid #ddd;
  text-align: center;
}

.mypage04tab th p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #050f2f;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
}

.mypage04tab table {
  width: 100%;
}

.mypage04tab th {
  border: 1px solid #ddd;
  text-align: center;
}

.mypage04tab th {
  height: 55px;
  background: #f2f8fd;
}

.mypage04tab td {
  font-size: 14px;
}

.mypage04tab table .state_01 {
  color: #878787;
  font-size: 14px;
}

.mypage04tab table .state_02 {
  color: #319dff;
  font-size: 14px;
}

.mypage04tab button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #319dff;
  border-radius: 2px;
  color: #fff;
  width: 150px;
  height: 46px;
  margin: 60px auto 0;
}

.new-mypage-wrap .tab-list li.active p {
  color: blue;
}


.brand-value-top {
  height: 485px;
}

.brand-value-top.default {
  padding-top: 90px;
}

.brand-value-top.hovered-1 {
  padding-top: 45px;
}

.brand-value-top.hovered-2 {
  padding-top: 23px;
}

.brand-value-top.hovered-3 {
  padding-top: 45px;
}

.brand-value-top.hovered-4 {
  padding-top: 45px;
}

.brand-value-top.hovered-5 {
  padding-top: 41px;
}


.brand-left-box.default {
  margin-top: 40px;
}

.brand-left-box.hovered-1 {
  margin-top: 85px;
}

.brand-left-box.hovered-2 {
  margin-top: 83px;
}

.brand-left-box.hovered-3 {
  margin-top: 85px;
}

.brand-left-box.hovered-4 {
  margin-top: 85px;
}

.brand-left-box.hovered-5 {
  margin-top: 89px;
}

.sign-up .enter-info-title {
  border-bottom: none;
  padding-bottom: 7px;
}

.enter-info-titleBox {
  display: flex;
}

.enter-info-titleBox p {
  white-space: nowrap;
  margin-top: 33px;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  color: #878787;
}

.enter-hr {
  width: 100%;
  height: 1px;
  background-color: #050f2f;
  border: none;
  margin-bottom: 40px;
}

.end-hr {
  background-color: #f0f0f0;
}

@media (min-width: 360px) and (max-width:768px) {
  .brand-content-wrap.hovered-1 {
    padding-top: 32px;
  }
  .brand-content-wrap.hovered-1 .brand-left-box {
    padding-top: 0px;
  }

  .brand-value-top.hovered-1 {
    height: 100%;
  }

  .sub_wrap_all .newselfSection02 {
    padding: 0 0 0 0 !important;
    margin: 0 !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box strong {
    font-size: 12px !important;
    font-weight: 700;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box h3 {
    font-size: 22px !important;
    font-weight: 700;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box p {
    font-size: 16px !important;
    font-weight: 400;
  }
}


.modal-overlay .modal-body.main {
  height: 422px;
  padding: 40px 24px 32px 24px;
  border-radius: 16px;
  width: 340px;
}

.modal-overlay .modal-body.main h2 {
  font-family: "PretendardB", "sans-serif";
  font-size: 28px;
  color: #319dff;
  margin: 24px auto 16px auto;
  line-height: 39.2px;
}

.modal-overlay .modal-body.main .sub-title {
  font-size: 16px;
  font-family: "PretendardM", "sans-serif";
  color: #585858;
  margin-bottom: 24px;
}

.modal-overlay .modal-body.main .desc {
  color: #585858;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 19.6px;
}

.modal-overlay .modal-body.main .desc.last {
  margin: 8px auto 24px auto
}

.modal-overlay .modal-body.main .desc.last span {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  color: #585858;
}

.modal-overlay .modal-body.main .mail-desc {
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 4px;
}

.modal-overlay .modal-body.main button {
  background-color: #319dff;
  color: white;
  width: 100%;
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  height: 48px;
  margin-top: 20px;
}

.modal-overlay .modal-body.main .copy-message {
  font-size: 12px;
  color: #319dff;
}

.modal-bg .mypage-agent-popup,
.modal-bg .mypage-additional-popup {
  width: 800px;
  height: 600px;
  border-radius: 16px;
  padding: 0;
}

.modal-bg .mypage-agent-popup .head {
  width: 100%;
  padding: 32px 32px 16px 32px;
}

.modal-bg .mypage-agent-popup .head h4 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 16px;
}

.modal-bg .mypage-agent-popup .applicant-info {
  padding: 0 32px 0 32px;
  height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.08);
}

.modal-bg .mypage-agent-popup .applicant-info table {
  width: 100%;
  border: 1px solid #d1d1d1;
  table-layout: fixed;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info.first {
  margin: 24px auto;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info hr {
  background: #d1d1d1;
  height: 1px;
  border: 0;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info h5 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 16px;
  margin-top: 8px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count {
  border-bottom: 1px solid #D1D1D1;
  padding: 13px 0 13px 16px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count h6 {
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .type-product {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 10px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .type-product .product-title {
  font-size: 14px;
  color: #666;
  font-family: "PretendardR", "sans-serif";
  margin-top: 8px;
  margin-left: 16px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .type-product .product-number {
  font-size: 14px;
  color: #666;
  font-family: "PretendardM", "sans-serif";
  margin-top: 2px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .type-product .product-list {
  width: 576px;
  line-height: 140%;
  color: #666;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}


.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .type-product .product-list strong {
  line-height: 140%;
  color: #666;
  font-family: "PretendardM", "sans-serif";
  font-size: 14px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .type-product .product-list p {
  line-height: 140%;
  color: #666;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .price {
  display: flex;
  margin: 6px 16px 0 16px;
  justify-content: space-between;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .type-desc .price p {
  color: #666;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .total-count {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 0 16px;
  border-top: 1px dotted #d1d1d1;
  margin-top: 12px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .final-total-count {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 0 16px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .final-total-coun p {
  font-size: 16px;
  font-family: "PretendardM", "sans-serif";
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .type-count .total-count p,
.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc p {
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}

.modal-bg .mypage-agent-popup .applicant-info table thead th {
  background-color: #F5FAFF;
  height: 48px;
  vertical-align: middle;
  border: 1px solid #d1d1d1;
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info {
  padding: 12px 16px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc {
  display: flex;
  justify-content: space-between;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc.date {
  margin-top: 12px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc.status {
  margin-bottom: 0 !important;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc.status .status-content {
  color: #666;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc.status .status-content.text-red {
  color: #ff0000;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc.status .status-content.text-blue {
  color: #319dff;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc.status .status-content.text-gray {
  color: #666;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info .payment-info .desc p {
  margin-bottom: 12px;
}

.modal-bg .mypage-agent-popup .applicant-info table tbody td {
  height: 48px;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  padding: 0 10px;
  line-height: 140%;
}

.modal-bg .mypage-agent-popup .applicant-info table tbody td .agentImage {
  height: 40px;
  object-fit: cover;
  vertical-align: middle;
}

.modal-bg .mypage-agent-popup .applicant-info table tbody .type-agent {
  white-space: nowrap;
}

.modal-bg .mypage-agent-popup .applicant-info table tbody td span {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
}

.modal-bg .mypage-agent-popup .foot {
  width: 100%;
  height: 104px;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info.second {
  margin-bottom: 0;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info.second,
.modal-bg .mypage-agent-popup .applicant-info .count-info.third {
  border-bottom: 1px solid #d1d1d1;
}

.modal-bg .mypage-agent-popup .applicant-info .count-info.third .desc.date {
  margin-bottom: 0px;
}

.modal-bg .mypage-agent-popup .foot button {
  background-color: #319dff;
  margin: 24px 32px;
  width: calc(100% - 64px);
  height: 48px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
}

.modal-bg .mypage-agent-popup .applicant-info .count-info.second {
  margin-top: 0;
}

.modal-bg .mypage-agent-popup .applicant-info .total {
  display: flex;
  font-family: "PretendardM", "sans-serif";
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
  padding: 16px;
}

.modal-bg .mypage-additional-popup .cancelBtn-wrap button {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  padding: 0 16px;
  color: #666;
  height: 38px;
  border: 1px solid #666;
  background-color: #F6F6F6;
}

.modal-bg .mypage-agent-popup .applicant-info button {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  color: #666;
  width: 110px;
  height: 38px;
  border: 1px solid #666;
  background-color: #F6F6F6;
}

.modal-bg .mypage-additional-popup .cancelBtn-wrap {
  text-align: end;
  margin-top: 16px;
}

.modal-bg .mypage-agent-popup .applicant-info .btn-wrap {
  text-align: end;
  margin-top: 16px;
  margin-bottom: 24px;
}

.modal-bg .mypage-agent-popup .applicant-info .btn-wrap button {
  border-radius: 0;
}


.modal-overlay .modal.toss {
  width: 500px;
}

.modal-overlay .toss-btn {
  width: 100%;
  background-color: #319dff;
  color: white;
  font-family: "PretendardSB", "sans-serif";
  height: 50px;
  border-radius: 4px;
  font-size: 18px;
}

.payment-success {
  height: calc(100vh - 300px);
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.payment-success h1{
  font-size: 40px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 50px;

}

.payment-success img {
  width: 150px;
  margin-bottom: 20px;
}

.payment-success p {
  font-size: 20px;
  margin-bottom: 20px;
}

.payment-success button {
  background-color: #319dff;
  color: white;
  border-radius: 4px;
  width: 200px;
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  height: 50px;
}