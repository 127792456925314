.SelfProcedureAll-wrap01{
  width: 100%;
  background: #F6F6F6;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap{
  width: 1320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  padding: 30px 0 50px 0;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfTop01img{
  width: 750px;
  margin: 0px auto 40px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-contentBox{
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background: #fff;
  border-radius: 16px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox{
  width: 950px;
  border-radius: 16px;
  padding: 43px 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox h2{
  font-size: 20px;
  font-weight: bold;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #666666;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .label-box{
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap label{
  display: flex;
  align-items: center;
}

.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap{
  width: 420px;
  margin: 0px auto;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto 20px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox h2{
  font-size: 16px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox button{
  color: #319DFF;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox button img{
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01{
  margin: 0px auto;
}
.con-botbox01 .common-text-input{
  margin-bottom: 10px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .selfLink{
  display: flex;
  justify-content: center;
  margin: 10px auto 0;
  display: flex;
}
.selfAll-botBox .selfLink a{
  color: #666666;
}
.selfAll-botBox .selfLink a:first-child::after {
  content: "|";
  /* padding: 0 30px; */
  padding: 0 12px;
  cursor: default;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfLinkGroup{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 50px auto 0;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfLinkGroup a{
  width: 180px;
  height: 50px;
  padding: 12px 27px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfLinkGroup a:first-child{
  border: 2px solid #319DFF;
  color: #319DFF;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfLinkGroup a:nth-child(2){
  background: #d9d9d9;
  color: #241E20;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfLinkGroup a:last-child{
  background: #319DFF;
  color: #ffff;
}

/*2번*/
.SelfProcedureAll-wrap02{
  width: 100%;
  background: #F6F6F6;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap{
  width: 1320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  padding: 30px 0 50px 0;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfTop01img{
  width: 750px;
  margin: 0px auto 40px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #666666;
}

.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-contentBox{
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background: #fff;
  border-radius: 16px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox{
  width: 950px;
  border-radius: 16px;
  padding: 43px 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox h2{
  font-size: 20px;
  font-weight: bold;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box{
  width: 100%;
  margin: 40px auto 40px;
  padding-bottom: 35px;
  border-bottom: 1px solid #666666;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box h3{
  width: 420px;
  font-size: 16px;
  font-weight: bold;
  margin: 0px auto 20px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box ul{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box ul li{
  width: 140px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  cursor: pointer;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box ul li.active {
  border: 1px solid #319dff;
  background: rgba(49, 157, 255, 0.05);
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box ul li p{
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 13px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box ul li p.active{
  color: #319dff;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .middle-box ul li span{
  display: block;
  width: 100%;
  line-height: 130%;
  color: #666666;
  font-size: 14px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #666666;
}

.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap{
  width: 420px;
  margin: 0px auto;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto 20px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox h2{
  font-size: 16px;
}

.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01{
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0px auto 10px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01 strong{
  min-width: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #222;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .con-botbox02 h2{
  font-size: 16px;
  margin: 40px auto 20px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .con-botbox02 .preview-box{
  width: 350px;
  height: 150px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  margin: 0px auto;
}
.SelfProcedureAll-wrap02 .con-botbox01 .common-text-input{
  width: 320px;
  height: 45px;
  margin-bottom: 0px;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01 img{
  width: 15px;
  height: 15px;
}

.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfLinkGroup{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 50px auto 0;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfLinkGroup a{
  width: 180px;
  height: 50px;
  padding: 12px 27px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfLinkGroup a:first-child{
  border: 2px solid #319DFF;
  color: #319DFF;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfLinkGroup a:nth-child(2){
  background: #d9d9d9;
  color: #241E20;
}
.SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfLinkGroup a:last-child{
  background: #319DFF;
  color: #ffff;
}

/*3번*/
.SelfProcedureAll-wrap03{
  width: 100%;
  background: #F6F6F6;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap{
  width: 1320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  padding: 30px 0 50px 0;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfTop01img{
  width: 750px;
  margin: 0px auto 40px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #666666;
}

.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-contentBox{
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background: #fff;
  border-radius: 16px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox{
  width: 950px;
  border-radius: 16px;
  padding: 43px 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox h2{
  font-size: 20px;
  font-weight: bold;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box{
  width: 100%;
  margin: 40px auto 40px;
  padding-bottom: 35px;
  border-bottom: 1px solid #666666;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box h3{
  width: 420px;
  font-size: 16px;
  margin: 0px auto 20px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box ul{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box ul li{
  width: 140px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box ul li.active {
  border: 1px solid #319dff;
  background: rgba(49, 157, 255, 0.05);
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box ul li p.active{
  color: #319dff;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box ul li p{
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 13px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .middle-box ul li span{
  display: block;
  width: 100%;
  line-height: 130%;
  color: #666666;
  font-size: 14px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #666666;
}

.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap{
  width: 630px;
  margin: 0px auto;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto 20px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox h2{
  font-size: 16px;
}

.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01{
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0px auto 10px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01 ul{
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: left;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01 ul li{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 30px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 8px 0 8px 0;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox01 ul li.active {
  border: 1px solid #319dff;
  background: rgba(49, 157, 255, 0.05);
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox01 ul li p.active {
  color: #319dff;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01 ul li p{
  font-size: 13px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01 img{
  width: 15px;
  height: 15px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 h2{
  font-size: 16px;
  margin: 40px auto 20px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul li{
  display: flex;
  gap: 16px;
  align-items: center;
  padding-left: 16px;
  width: 310px;
  height: 63px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul li p{
  font-size: 12px;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul li span{
  font-size: 12px;
  color: #666666;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul li.active {
  border: 1px solid #319dff;
  background: rgba(49, 157, 255, 0.05);
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul li.active p {
  color: #319dff;
}

.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfLinkGroup{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 50px auto 0;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfLinkGroup a{
  width: 180px;
  height: 50px;
  padding: 12px 27px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfLinkGroup a:first-child{
  border: 2px solid #319DFF;
  color: #319DFF;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfLinkGroup a:nth-child(2){
  background: #d9d9d9;
  color: #241E20;
}
.SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfLinkGroup a:last-child{
  background: #319DFF;
  color: #ffff;
}

/*4번*/
.SelfProcedureAll-wrap04{
  width: 100%;
  background: #F6F6F6;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap{
  width: 1320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  padding: 30px 0 50px 0;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfTop01img{
  width: 750px;
  margin: 0px auto 40px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #666666;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-contentBox{
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background: #fff;
  border-radius: 16px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox{
  width: 950px;
  border-radius: 16px;
  padding: 43px 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox h2{
  font-size: 20px;
  font-weight: bold;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #666666;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap{
  width: 625px;
  margin: 0px auto;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .dropdown{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap #product-select{
  width: 120px;
  height: 35px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .directTxt{
  display: flex;
  position: relative;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .directTxt input{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 260px;
  height: 35px;
  color: #878787;
  font-size: 14px;
  border: 1px solid #d1d1d1; 
  border-radius: 4px;
  padding: 8px 16px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .directTxt img{
  position: absolute;
  top: 11px;
  right: 25px;
  width: 12px;
  height: 12px;
}

.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .scrollBox01{
  width: 625px;
  height: 185px;
  border: 1px solid #d1d1d1;
  border-radius: 4px; 
  overflow-y: scroll;
  padding: 16px 20px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .scrollBox01 ul{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .scrollBox01 ul li{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d1d1;
  border-radius: 4px; 
  /* padding: 5px 4px; */
  padding: 7px 10px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .movingBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .movingBtn button{
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  font-size: 14px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02 h2{
  font-size: 16px;
  margin: 20px auto 20px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02 textarea{
  width: 625px;
  height: 185px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  resize: none;
  outline: none;
  padding: 15px;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 50px auto 0;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a{
  width: 180px;
  height: 50px;
  padding: 12px 27px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a:first-child{
  border: 2px solid #319DFF;
  color: #319DFF;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a:nth-child(2){
  background: #d9d9d9;
  color: #241E20;
}
.SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a:last-child{
  background: #319DFF;
  color: #ffff;
}
/* 5번 */
.SelfProcedureAll-wrap05{
  width: 100%;
  background: #F6F6F6;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap{
  width: 1320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  padding: 30px 0 50px 0;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfTop01img{
  width: 750px;
  margin: 0px auto 40px;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #666666;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-contentBox{
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background: #fff;
  border-radius: 16px;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox{
  width: 950px;
  border-radius: 16px;
  padding: 10px 75px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox h2{
  font-size: 20px;
  font-weight: bold;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
  margin: 40px 0 40px;
  /* margin-bottom: 40px; */
  border-bottom: 1px solid #666666;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox .flex-box{
  display: flex;
  gap:10px;
  width: 650px;
  margin: 0px auto 5px;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox .flex-box > p:first-child{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  font-size: 14px;
  color: #050f2f;
  background: #E4F0F9;
  border: 1px solid #e4f0f9;
  border-radius: 4px;
  padding: 8px 0 8px 0 ;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox input[type="text"]{
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 8px 0 8px 20px;
  width: 100%;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox .selfbrand-img{
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 8px 0 8px 20px;
  width: 100%;
} 
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox .selfbrand-img div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px solid #D1D1D1;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox .selfbrand-img > div > p{
  color: #d9d9d9;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox .selfbrand-img > button{
  width: 100px;
  height: 25px;
  color: #050f2f;
  font-size: 12px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 4px ;
  margin-top: 8px;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .filedownBtn01{
  width: 420px;
  height: 35px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  color: #666;
  font-size: 14px;
  text-align: center;
  margin: 100px auto 0;
}




.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfLinkGroup{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 50px auto 0;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfLinkGroup a{
  width: 180px;
  height: 50px;
  padding: 12px 27px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfLinkGroup a:first-child{
  border: 2px solid #319DFF;
  color: #319DFF;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfLinkGroup a:nth-child(2){
  background: #d9d9d9;
  color: #241E20;
}
.SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfLinkGroup a:last-child{
  background: #319DFF;
  color: #ffff;
}

/*acting 4번 폼*/
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .label-box{
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .label-box span{
  font-size: 16px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap label{
  display: flex;
  align-items: center;
}
.ActingProcedureAll-wrap04{
  width: 100%;
  background: #F6F6F6;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap{
  width: 1320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  padding: 30px 0 50px 0;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfTop01img{
  width: 750px;
  margin: 0px auto 40px;
}
.SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #666666;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-contentBox{
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background: #fff;
  border-radius: 16px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox{
  width: 950px;
  border-radius: 16px;
  padding: 43px 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox h2{
  font-size: 20px;
  font-weight: bold;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 0px;
  margin-bottom: 40px;
  border-bottom: 1px solid #666666;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter{
  width: 590px;
  margin: 0px auto;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter label{
  width: 15%;
  font-size: 15px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter > div{
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .inputTextAll{
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  height: 45px;
  padding: 12px 0 12px 16px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter span{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .name-ko-wrap .inputTextAll,
.name-en-wrap .inputTextAll{
  width: 450px;;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .socialNum-wrap .inputTextAll{
  width: 210px;;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .email-wrap .inputTextAll{
  width: 135px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .email-wrap .inputSelect{
  width: 130px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  text-align: center;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .mobile-wrap .inputTextAll{
  width: 130px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .postalCode-wrap .inputTextAll{
  width: 350px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .postalCode-wrap > button{
  width: 90px;
  height: 45px;
  background: #319dff;
  color: #fff;
  border-radius: 4px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .formInfoEnter .address-wrap .inputTextAll{
  width: 450px;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02{
  display: flex;
  justify-content: space-around;
  width: 624px;
  border-top: 1px dashed #666666;
  padding-top: 40px;
  margin: 40px auto 0;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02 .txt{
  font-weight: bold;
  padding-top: 3px;
}

.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .chkBox-wrap .chkcontBox{
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 50px auto 0;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a{
  width: 180px;
  height: 50px;
  padding: 12px 27px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a:first-child{
  border: 2px solid #319DFF;
  color: #319DFF;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a:nth-child(2){
  background: #d9d9d9;
  color: #241E20;
}
.ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfLinkGroup a:last-child{
  background: #319DFF;
  color: #ffff;
}