.refund-all{
  width: 100%;
}
.refund-all .refund .refund-bg{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.refund-all .refund .refund-bg .refund-wrap{
  width: 1100px;
  background: #fff;
  padding-bottom: 104px;
  margin: 0px auto;
  border-radius: 8px;
}
.refund-all .refund .refund-bg .refund-wrap .content-list{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #d1d1d1;
  border-bottom: none;
}
.refund-all .refund .refund-bg .refund-wrap .content-list li{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}
.refund-all .refund .refund-bg .refund-wrap .content-list li h3{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  background: #004F97;
  color: #fff;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
  border-bottom: 1px solid #fff;
} 
.refund-all .refund .refund-bg .refund-wrap .content-list li:nth-child(2) h3{
  background: #2A7FCB;
}
.refund-all .refund .refund-bg .refund-wrap .content-list li:nth-child(3) h3{
  background: #5689B7;
}
.refund-all .refund .refund-bg .refund-wrap .content-list li:last-child h3{
  background: #7C8C9C;
}
.refund-all .refund .refund-bg .refund-wrap .content-list li .textBox{
  width: 70%;
  padding: 20px 35px;
  border-bottom: 1px solid #E1E1E1;
}
.refund-all .refund .refund-bg .refund-wrap .content-list li .textBox p{
  position: relative;
  color: #050F2F;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
  margin-bottom: 6px;
}

.refund-all .refund .refund-bg .refund-wrap .content-list li .textBox p:last-child {
  margin-bottom: 0;
}
/* .refund-all .refund .refund-bg .refund-wrap .content-list li .textBox p::before {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #050F2F;
  position: absolute;
  top: 6px;
  left: -15px;
} */
.refund-all .refund .refund-bg .refund-wrap .warningText{
  background: #F6F6F6;
  color: #ff0000;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  margin-top: 40px;
  padding: 24px;
  line-height: 140%;
}