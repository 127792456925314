.brand-allwrap1{width: 1920px !important;}
.brandName-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.brandName-wrap .brand-all{
  width: 100%;
  height: 100%;
  /* height: 100vh; */
}

.brandName-wrap .brand-contentBox01{
  display: flex;
  width: 100%;
}
.brandName-wrap .brand-contentBox01 .content-leftBox, 
.content-rightBox{
  position: relative;
  width: 50%;
  background-color: #fff;
}
.brandName-wrap .brand-contentBox01 .content-leftBox .content-left{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: auto;
  margin: 0px auto;
}
.brandName-wrap .brand-contentBox01 .content-leftBox .content-left span{
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: left;
}
.brandName-wrap .brand-contentBox01 .content-leftBox .content-left h2{
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.brandName-wrap .brand-contentBox01 .content-leftBox .content-left p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: left;
}
.brandName-wrap .brand-contentBox01 .content-rightBox{
  width: 50%;
  background: rgba(255, 163, 124, 0.2);
}
.brandName-wrap .brand-contentBox01 .content-rightBox ul{
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(0%, -50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  /* width: 720px; */
  width: 67%;
}
.brandName-wrap .brand-contentBox01 .content-rightBox ul li{
  display: flex;
  align-items: center;
  width: 100%;
  width: 439px;
  min-height: 100px;
  background: #fff;
  border-radius: 4px;;
  padding: 22px 18px;
  box-sizing: border-box;
}
.brandName-wrap .brand-contentBox01 .content-rightBox ul li:nth-child(even) {
  margin-left: auto;
}
.brandName-wrap .brand-contentBox01 .content-rightBox ul li img{  
  display: block;
  margin-right: 10px;
}
.brandName-wrap .brand-contentBox01 .content-rightBox ul li p{
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  color: #000;
}
.brandName-wrap .brand-contentBox01 .content-rightBox ul li:last-child{
  position: absolute;
  top: 6%;
  left: 21%;
  z-index: -1;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #FFA37C;
}
/* 섹션2 */
.brandName-wrap .brand-contentBox02{
  width: 100%;
  max-width: 1048px;
  margin: 0px auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.brandName-wrap .brand-contentBox02 .content-oneBox{
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
}
.brandName-wrap .brand-contentBox02 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.brandName-wrap .brand-contentBox02 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.brandName-wrap .brand-contentBox02 .content-oneBox img{
  width: 100%;
}
/* 섹션3 */
.brandName-wrap {width: 100%;}
.brandName-wrap .brand-all{
  width: 100%;
  height: 100vh;
}
.brandName-wrap .brand-contentBox03{
  display: flex;
  width: 100%;
}
.brandName-wrap .brand-contentBox03 .content-leftBox, 
.content-rightBox{
  position: relative;
}
.brandName-wrap .brand-contentBox03 .content-leftBox{
  background: #EAEEF2;
  width: 1080px;
}
.brandName-wrap .brand-contentBox03 .content-leftBox .content-left{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 620px;
  height: auto;
  margin: 0px auto;
}
.brandName-wrap .brand-contentBox03 .content-leftBox .content-left .brand-hashtag{
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}
.brandName-wrap .brand-contentBox03 .content-leftBox .content-left .brand-hashtag .one-hashtag{
  width: 45px;
  height: 45px;
  padding: 8px 16px;
}
.brandName-wrap .brand-contentBox03 .content-leftBox .content-left span{
  display: block;
  color: #319DFF;
  font-size: 18px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
  border: 1px solid #319DFF;
  border-radius: 50px;
  padding: 10px 20px;
  background: #fff;
}
.brandName-wrap .brand-contentBox03 .content-leftBox .content-left span:hover{
  background: #319DFF;
  color: #fff;
}
.brandName-wrap .brand-contentBox03 .content-leftBox .content-left h2{
  color: #050F2F;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  margin-bottom: 20px;
  line-height: 140%;
}
.brandName-wrap .brand-contentBox03 .content-leftBox .content-left p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: left;
}
.brandName-wrap .brand-contentBox03 .content-rightBox{
  width: 840px;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-right{
  /* width: 640px; */
  width: 705px;
  position: absolute;
  top: 50%;
  left: -15%;
  transform: translate(0px, -50%);
} 
.brandName-wrap .brand-contentBox03 .content-rightBox .content-topBox{
  display: flex;
  align-items: center;
  width: 100%;
  height: 44.44px;
  display: flex;
  background: #6C757E;
  padding-left: 18px;
  border-radius: 4px 4px 0 0 ;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-topBox p{
  width: 10.67px;
  height: 10.67px;
  left: 977.78px;
  top: 359.89px;
  border-radius: 50%;
  margin-right: 10px;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-topBox p:first-child{
  background: #FF6565;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-topBox p:nth-child(2){
  background: #FFD058;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-topBox p:last-child{
  background: #3CD97A;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox{
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
  border: 1px solid #E1E1ED;
  border-radius: 0 0 4px 4px;
  background: #fff;
  padding: 60px 45px;

}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox h3{
  font-family: "PretendardB", "sans-serif";
  font-size: 14px;
  margin-bottom: 20px;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox h3 span{
  color: #319DFF;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul{
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  width: 100%;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul li{
  width: calc(33.3% - 17px);
  /* padding: 19px; */
  padding: 27px;
  border: 1px solid #e1e1ed;
  border-radius: 4px;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul li h4{
  text-align: center;
  font-family: "PretendardB", "sans-serif";
  line-height: 20px;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul li span{
  display: block;
  text-align: center;
  font-size: 13px;
}
.brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul li p{
  color: #878787;
  font-size: 12px;
  text-align: center;
  margin-top: 9px;
  line-height: 20px;
}
/* 섹션4 */
.brandName-wrap .brand-contentBox04{
  width: 100%;
  max-width: 1100px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}
.brandName-wrap .brand-contentBox04 .content-oneBox{
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
}
.brandName-wrap .brand-contentBox04 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.brandName-wrap .brand-contentBox04 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.brandName-wrap .brand-contentBox04 .content-oneBox img{
  width: 100%;
}
/* 섹션5 */
.brandName-wrap .brand-contentBox05{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.brandName-wrap .brand-contentBox05 .content-oneBox{
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;
  box-sizing: border-box;
}
.brandName-wrap .brand-contentBox05 h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.brandName-wrap .brand-contentBox05  h2 span{
  color: #319DFF;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
}
.brandName-wrap .brand-contentBox05 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.brandName-wrap .brand-contentBox05 .content-oneBox{
  position: relative;
  margin: 0px auto;
  width: 1280px;
  height: 350px;
  background-image: url('image/subBg6_1.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.brandName-wrap .brand-contentBox05 .content-oneBox .guide-content{ 
  width: 502px;
  height: 150px;
  position: absolute;
  right: 265px;
  bottom: 99px;
}
.brandName-wrap .brand-contentBox05 .content-oneBox .guide-content p{
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.brandName-wrap .brand-contentBox05 .content-oneBox .guide-content .guidebox-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  font-family: "PretendardR", "sans-serif";
  padding: 8px 16px;
  border: 1px solid #050F2F;
  cursor: pointer;
}
.brandName-wrap .brand-contentBox05 .guidebox-bot{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  min-height: 254px;
  background: #FFA37C;
  border-radius: 8px;
  margin: 20px auto 0px;
  padding: 60px 180px 60px 120px;
}
.brandName-wrap .brand-contentBox05 .guidebox-bot .service-text{
  width: 70%;;
}
.brandName-wrap .brand-contentBox05 .guidebox-bot .service-text h4{
  color: #fff;
  font-family: "PretendardB", "sans-serif";
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 40px;
}
.brandName-wrap .brand-contentBox05 .guidebox-bot .service-text span{
  color: #fff;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}
.brandName-wrap .brand-contentBox05 .guidebox-bot .service-link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 245px;
  height: 60px;
  background-color: #fff;
  color: #050F2F;
}
.brandName-wrap .brand-contentBox05 .guidebox-bot .service-link .main-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}
.brandName-wrap .brand-contentBox05 .guidebox-bot .service-link .main-nav img{
  transform: rotate(90deg);
}
/* .brand-section01 {
  height: 100vh; 
} */
/* 
풀페이지 스크롤
.brand-allwrap .Section{
  max-width: 1280px;
  height: 100vh !important;
  border: 1px solid #000;
}
.brand-section01{background-color: #009fff;}
.brand-section05 {padding: 140px 320px !important;}
.brand-section05 .final-title-wrap h6 ,
.brand-section05 .final-title-wrap h5,
.brand-section05 .final-title-wrap h5 span{
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}
.brand-section05 .final-title-wrap {
  margin-bottom: 60px;

}
.brand-section05 .final-title-wrap h6 {
  line-height: 46px;
  color: #050F2F;
  margin-bottom: 4px;
}
.brand-section05 .final-title-wrap h5 {
  line-height: 70px;
}
.brand-section05 .final-title-wrap h5 span {
  line-height: 70px;
}
.brand-section05 .title-point {
  color: #319DFF;
}
.brand-section05 .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  min-width: 1280px;
  height: 350px;
  background-image: url(http://localhost:3000/static/media/guideBg02.bac9e6a….svg);
  background-repeat: no-repeat;
  background-position: center;
}
.brand-section05 .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.brand-section05 .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  padding: 8px 16px;
  border: 1px solid #000;
  cursor: pointer;
}
.brand-section05 .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  color: #333333;
}
.brand-section05 .box-wrap .service-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: 350px;
  padding: 83px 216px 80px 104px;
  border-radius: 8px;
  background-color: #00274A;
}
.brand-section05 .box-wrap .service-box .service-text, .service-link {
  width: 50%;
  color: #fff;
}
.brand-section05 .box-wrap .service-box .service-text h4 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 40px;
}
.brand-section05.box-wrap .service-box .service-text span {
  font-size: 20px;
  color: #DDDDDD;
}
.brand-section05 .box-wrap .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: #319DFF;
}
.brand-section05 .box-wrap .service-box .service-link a {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
} */