.br768{display: block;}
.br768_02{display: none;}
.br580{display: none;}
.br1024_02{display: none;}
.table-scroll {
  width: 100%;
  overflow-x: auto; 
}
@media (max-width: 1680px) {
  /* 포텐셜 섹션5 */
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
    gap: 30px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    padding: 24px 2px 24px 6px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 42%;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 95%;
  }
  /* 셀프 트레이드 */
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 95%;
  }
  /* 셀프 상표등록 */
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 100%;
    margin-right: 0;
  }
  
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    min-height: 400px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    padding: 24px 2px 24px 6px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: 500px;
  }
   /* 상표등록대행 */
  /* .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 45%;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 37%;
  } */
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    min-height: 490px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    padding: 24px 2px 24px 6px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top img {
    width: 98vw;
  }
}
@media (max-width: 1440px) {
  /* 브랜드네이밍1번섹션 */
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left{
    left: 65%;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul{
    width: 66%;
    left: 20px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top img {
    width: 60%;
  }
  /* 브랜드네이밍3번섹션 */
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left{
    left: 60%;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox{
    width: 100%;
  }

  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul{
    width: 100%;
  }
  /* 포텐셜 섹션1 */
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left{
    left: 67%;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox{
    width: 56%;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li p{
    word-break: keep-all;
    text-align: center;
  }
  /* 포텐셜 섹션 5 */

  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 85%;
  }  
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    margin: 40px 0px 0px 25px;
    min-height: 440px;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 35%;
  }
  /* 에이전시 4 */
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 85%;
  }  
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    margin: 75px 0px 0px 45px;
    min-height: auto;
    height: 400px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 35%;
  }

  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 71%;
  }  
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 35%;
  }
  /* 에이전시 4 */
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    margin: 75px 0px 0px 273px;
    min-height: auto;
    height: 400px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 50%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 50%;
  }
  /* 대행 */
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 98%;
  }  
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 50%;
    margin-right: 33px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    margin: 40px 0px 0px 25px;
    min-height: 440px;
  }
  /* .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 35%;
  } */
  /* 에이전시 4 */
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 98%;
  }  
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    margin: 75px 0px 0px 45px;
    min-height: auto;
    height: 400px;
  }
  /* .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 35%;
  } */

    /* 상세등록 */
    .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li{
      /* width: calc(33.2% - 20px); */
      width: 420px;
    }
  
    .sub_wrap_all .subsection .subarticle{
      width: 100%;
    }
    .selfProcedureName-wrap .selfProcedure-contentBox01 {
      height: 90vh;
  }


  

/* 1440끝 */



}
@media (max-width: 1360px) {
  .brandName-wrap .brand-contentBox01 .content-rightBox ul {
    width: 62%;
  }
}
@media (max-width: 1280px) {
  /* 브랜드네이밍1번섹션 */
  .brandName-wrap .brand-contentBox01 .content-rightBox ul li{
    padding: 22px 5px;
  }
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left{
    left: 60%;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul {
    width: 70%;
  }
  /* 브랜드섹션3 */
  .brandName-wrap .brand-contentBox03 .content-leftBox{
    width: 100%;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left{
    width: 100%;
    left: 68%;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left h2{
    font-size: 30px;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul li p{
    word-break: keep-all;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul li{
    width: calc(50% - 30px);
    padding: 9px;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-right{
    width: 110%;
    left: -24%;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox{
    padding: 25px 45px 25px 25px;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul{
    justify-content: center;
  }
  /* 브랜드섹션5 */
  .brandName-wrap .brand-contentBox04 .content-oneBox h2{
    font-size: 40px;
  }
  /* 브랜드 마지막 */
  .brandName-wrap .brand-contentBox05 .content-oneBox{
    width: 100%;
    min-width: auto;
  }
  .brandName-wrap .brand-contentBox05 .content-oneBox .guide-content{
    right: 35px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot{
    padding: 60px 65px 60px 40px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-text h4{
    word-break: keep-all;
  }
  /* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */

  /* 포텐셜 섹션1 */
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left{
    left: 81%;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li h3{
    text-align: center;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li h3 p{
    text-align: center;
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left h2{
    font-size: 32px;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li{
    max-width: 230px;
    padding: 34px 10px 29px 10px;
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left b,p{
    font-size: 28px;
  }
  /* 포텐셜 섹션2 */
  .potentialName-wrap .potential-contentBox02 .content-oneBox{
    max-width: 100%;
    padding: 0 10px;
  }
  /* 포텐셜 섹션5 */
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 30%;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 35%;
    min-height: auto;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 28%;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
    word-break: keep-all;
    text-align: left;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
    width: 50px;
    height: 50px;
  }
    /* 포텐셜 섹션5 */
    .potentialName-wrap .potential-contentBox04{
      height: 100%;
    }
    .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox{
      display: flex;
      flex-wrap: wrap;
    }
    .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
      width: 100%;
      margin-bottom: 20px;
    }
    .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
      width: 60%;
    }
    .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
      height: auto;
    }
    .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
      width: calc(45% - 50px);
    }
    /* 포텐셜 마지막 섹션 */
    .potentialName-wrap .potential-contentBox05 .content-oneBox{
      width: 100%;
      min-width: auto;
    }
    .potentialName-wrap .potential-contentBox05 .content-oneBox .guide-content{
      /* right: 35px; */
      right: 190px;
    }
    .potentialName-wrap .potential-contentBox05 .guidebox-bot{
      padding: 60px 65px 60px 40px;
      justify-content: space-around;
    }
    .potentialName-wrap .potential-contentBox05 .guidebox-bot .service-text{
      width: 47%;
    }
    .potentialName-wrap .potential-contentBox05 .guidebox-bot .service-link{
      width: 240px;
    }






    /* 셀프상표출원  */
    .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li{
      width: 325px;
      padding: 0 10px;
    }
    .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li span{
      word-break: keep-all;
    }
    /* 셀프3번 */
    .selfTradeName-wrap .selfTrade-contentBox03-01 .content-oneBox img{
      width: 50%;
    }
    /* 셀프 5번  */
    .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .selfTradeName-wrap .selfTrade-contentBox04{
      /* height: 100%; */
    }
    .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
      width: 100%;
    }
    .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
      margin: 0px auto;
      min-height: auto;
      height: 350px;
    }
    .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
      width: 60%;
    }
    .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
      width: calc(45% - 50px);
      padding: 16px 10px 16px 10px;
      margin-bottom: 0px;
    }

    /* 마크에이전시 */
      /* 1번섹션 */
      .agencyName-wrap .agency-contentBox01 .content-botBox ul li{
        width: 325px;
        padding: 0 10px;
      }
      .agencyName-wrap .agency-contentBox01 .content-botBox ul li span{
        word-break: keep-all;
        text-align: center;
      }
      
     /* 마크3번 */
      .agencyName-wrap .agency-contentBox03-01 .content-oneBox img{
        width: 50%;
      }
      
      /* 에이전시 */
      /* 셀프 5번  */
      .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .agencyName-wrap .agency-contentBox04{
      height: 100%;
    }
    .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
      width: 100%;
    }
    .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
      margin: 0px auto;
      min-height: auto;
      height: 350px;
    }
    .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
      width: 60%;
    }
    .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
      width: calc(45% - 50px);
      padding: 16px 10px 16px 10px;
      margin-bottom: 0px;
    }
    
    /* 위조상품/모니터링*/
    .monitorName-wrap .monitor-contentBox01 .content-topBox .content-botBox{
      width: 100%;
    }
    .monitorName-wrap .monitor-contentBox01 .content-botBox ul{
      width: 100%;
    }
    .monitorName-wrap .monitor-contentBox01 .content-botBox ul li{
      width: 300px;
      padding: 20px 6px 20px 6px;
    }
    .monitorName-wrap .monitor-contentBox01 .content-botBox ul li h3{
      text-align: center;
    }
    .monitorName-wrap .monitor-contentBox01 .content-botBox ul li span{
      text-align: center;
      word-break: keep-all;
    }
    /* 위조,모니터링 2번 */
    .monitorName-wrap .monitor-contentBox02 .content-oneBox p{
      margin-bottom: 20px;
    }
    .monitorName-wrap .monitor-contentBox02 .content-oneBox ul{
      margin-bottom: 40px;
    }
    .monitorName-wrap .monitor-contentBox02 .content-oneBox .monitor-youtube{
      width: 100%;
    }

    /* 셀프상표등록절차  */
    .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top{
      padding: 20px 10px;
    }
    .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top h2{
      font-size: 40px;
    }
    .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top img{
      display: block;
      /* width: 100%; */
      width: 46%;
      margin: 0px auto;
      max-width: none;
    }


      /* 셀프상표등록 */
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox{
    display: flex;
    /* flex-wrap: wrap; */
    width: 50%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02{
    height: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    margin: 0px auto;
    min-height: auto;
    height: 350px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 60%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul{
    width: 90%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: calc(45% - 50px);
    padding: 16px 10px 16px 10px;
    margin-bottom: 0px;
  }

  /* 상표등록대행 */
  .actingProcedureName-wrap .actingProcedure-contentBox01 {
    padding: 20px 10px;
    height: 90vh;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    height: 70px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top{
    padding: 20px 10px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top h2{
    font-size: 36px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top img{
    display: block;
    /* width: 100%; */
    width: 50%;
    margin: 0px auto;
    max-width: none;
  }
  .actingProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }


  .selfProcedureName-wrap .selfProcedure-contentBox02{
    height: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    /* margin: 0px auto; */
    min-height: auto;
    height: 295px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul{
    width: 89%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 100%;
    padding: 16px 10px 16px 10px;
    margin-bottom: 0px;
  }

  /* 대행 */
  .actingProcedureName-wrap .actingProcedure-contentBox02{
    height: 100%;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
    width: 100%;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    margin: 0px auto;
    min-height: auto;
    height: 295px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 100%;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul{
    width: 89%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    font-size:12px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: 100%;
    padding: 16px 10px 16px 10px;
    margin-bottom: 0px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    font-size:12px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox h2{
    font-size: 36px;
  }
  /* .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox{
    height: 100%;
  } */
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
    display: flex;
    justify-content: center;
    width: 50%;
  }

  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2{
    font-size: 30px;
  }

  /* 마지막 섹션 */
  .selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot{
    justify-content: center;
  }
  .agencyName-wrap .agency-contentBox05 .guidebox-bot{
    justify-content: center;
  }
  .monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text{
    width: 66%;
  }
  .monitorName-wrap .monitor-contentBox05 .guidebox-bot{
    justify-content: center;
  }

  /* 셀프상표등록 */
  .SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap{
    width: 100%;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap{
    width: 100%;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap{
    width: 100%;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap{
    width: 100%;
  }

  /* 상표등록대행 */
  .SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap{
    width: 100%;
  }

  /* cost01 */
  /* cost02 */
  .Cost-Wrap .costDetail{
    padding: 20px 0;
  }
  .Cost-Wrap .cost-detail-wrap01 .description-header01 h3{
    justify-content: center;
  }

  /* faq */
  .content-customer-wrap .customer{
    width: 100%;
    min-width: auto;
    padding: 20px 10px;
  }

  .refund-all .refund .refund-bg .refund-wrap{
    width: 100%;
    padding: 50px 16px;
  }
  /* 1:1문의 */
  .content-customer-wrap .inquiry .service-box{
    justify-content: space-around;
  }
  .content-customer-wrap .inquiry .inquiry-form-wrap{
    width: 100%;
  }
  .content-customer-wrap .inquiry .inquiry-form-bg{
    background: #fff;
  }
  .content-customer-wrap .inquiry .service-box .service-text, .service-link{
    width: 75%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    height: 70px;
  }
    /* 1280끝 */
}

@media (max-width: 1024px) {
  .brand-allwrap1{width: 100% !important;}
  .br1024{display: none;}
  .br1024_02{display: block;}
  .brandName-wrap{height: 100%;}
  .brandName-wrap .brand-contentBox01{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgba(255, 163, 124, 0.2);
  }
  .brandName-wrap .brand-contentBox01 .content-leftBox{
    width: 100%;
    background: none;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox{
    width: 100%;
    background: none;
  }
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left{
    position: unset;
    transform: none;
    width: 100%;
    height: 100%;
    margin: 0px auto;
  }
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left span{
    text-align: center;
  }
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left h2{
    text-align: center;
    font-size: 40px;
  }
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left p{
    text-align: center;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul{
    display: flex;
    flex-wrap: wrap;
    position: unset;
    transform: none;
    width: 100%;
    height: auto;
    padding: 8px 8px;
    margin: 0px auto;
    gap: 8px;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul li{
    max-width: auto;
    min-height:auto
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul li:last-child{
    display: none;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul li{
    display: block;
    width: calc(50% - 10px);
    margin: 0px auto;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul li img{
    margin: 0px auto;
  }
  .brandName-wrap .brand-contentBox01 .content-rightBox ul li p{
    word-break: keep-all;
    text-align: center;
    margin-top:10px
  }
  /* 브랜드 섹션2 */
  .brandName-wrap .brand-contentBox02 .content-oneBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    height: 100%;
  }
  .brandName-wrap .brand-contentBox02 .content-oneBox p{
    margin-bottom: 20px;
  }
  /* 브랜드 섹션3 */
  .brandName-wrap .brand-contentBox03{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox{
    width: 100%;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left{
    position: unset;
    transform:none;
    padding-top: 40px;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox{
    position: unset;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox{
    position: unset;
    padding: 0px 8px;
    background: #EAEEF2;
    padding-bottom: 40px;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-right{
    position: unset;
    transform: none;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left h2{
    text-align: center;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left p{
    text-align: center;
    margin-bottom: 20px;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left .brand-hashtag{
    justify-content: center;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox{
    padding: 0px;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-right{
    width: 100%;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox h3{
    padding: 8px 0 0 8px;
  }
  .brandName-wrap .brand-contentBox03 .content-rightBox .content-midBox ul{
    padding-bottom: 20px;
  }
  /* 브랜드섹션5 */
  .brandName-wrap .brand-contentBox04 .content-oneBox h2{
    font-size: 30px;
  }
  .brandName-wrap .brand-contentBox05 .content-oneBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eaeef2;
    background-image: none;
}
.brandName-wrap .brand-contentBox05 .content-oneBox .guide-content{
  position: unset;
  width: 100%;
  justify-content: space-between;

}
  .brandName-wrap .brand-contentBox05{
    width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 40px 0;
      padding: 0 10px;
      border-radius: 8px;
  }
  .brandName-wrap .brand-contentBox05 .content-oneBox .guide-content p{
    font-size: 18px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot {
    padding: 0 20px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-link{
    width: 200px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-link .main-nav{
    font-size: 16px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-text span{
    word-break: keep-all;
  }
  /* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
  
  /* 포텐셜 섹션1 */
  .potentialName-wrap .potential-contentBox01{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px 0 20px;
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left{
    width: 100%;
    position: unset;
    transform:none
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox, .content-rightBox{
    width: 100%;
    position: unset;
  }

  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left span{
    text-align: center;
    margin-bottom: 10px;
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left h2{
    text-align: center;
    margin-bottom: 10px;
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left b{
    text-align: center;
    font-size: 16px;
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left p{
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    word-break: keep-all;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox{
    width: 100%;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul{
    display: flex;
    flex-wrap: nowrap;
    justify-content:center;
    position: unset;
    transform: none;
    width: 100%;
    gap: 10px;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li{
    /* width: 100%; */
    width: calc(25% - 20px);
    max-width: auto;
    min-height: auto;
    max-height: auto;
    background: #BBEEF0;
    border-radius: 4px;
    padding: 0px;
    box-sizing: border-box;
    padding: 18px 0 0px;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li h3, p{
    font-size: 20px;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li:nth-child(2){
    margin-top: 0px;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li:last-child {
    margin-top: 0px;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox .potentialAll {
    width: 100%;
    /* padding-bottom: 100%; */
    padding-bottom: 75%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    background-size: 50%;
  }
  /* 포텐셜 섹션2  */
  .potentialName-wrap .potential-contentBox02{
    height: 100%;
    padding: 20px 0 ;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    min-height: 370px;
  }
  .potentialName-wrap .potential-contentBox03 .content-oneBox{
    min-width: 100%;
  }
  .potentialName-wrap .potential-contentBox03 .content-oneBox h2{
    font-size: 26px;
    word-break: keep-all;
  }
  .potentialName-wrap .potential-contentBox03 .content-oneBox p{
    font-size: 16px;
  }
  .potentialName-wrap .potential-contentBox03 .content-oneBox p{
    margin-bottom: 30px;
    word-break: keep-all;
  }
  .potentialName-wrap .potential-contentBox03 .content-oneBox .potential-tab li{
    font-size: 16px;
    min-width: auto;
    padding: 12px 14px;
  }
  .potential-contentBox03 .content-oneBox .potential-imgbox img{
    max-width: 100%;
    height: auto;
  }
  /* 포텐셜 섹션3 */
  .potentialName-wrap .potential-contentBox03{
    height: 100%;
    padding: 20px 0;
  }
  /* 포텐셜 섹션 5 */
  .potentialName-wrap .potential-contentBox04 .content-oneBox{
    height: 100%;
    padding: 20px 0;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox h2{
    font-size: 28px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox span{
    font-size: 18px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox span{
    margin-bottom: 20px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
    /* min-height: 480px; */
    /* width: 100%; */
    min-height: auto;
    height: 400px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 100%;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width:  calc(50% - 50px );
    min-height: 150px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    display: block;
    padding: 0px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
    display: block; 
    margin: 0px auto 10px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
    text-align: center;
    margin-bottom: 5px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
    text-align: center;
  }

  .potentialName-wrap .potential-contentBox05 .content-oneBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eaeef2;
    background-image: none;
}
.potentialName-wrap .potential-contentBox05 .content-oneBox .guide-content{
  position: unset;
  width: 100%;
  justify-content: space-between;

}
.potentialName-wrap .potential-contentBox05{
    width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 40px 0;
      padding: 0 10px;
      border-radius: 8px;
  }

  /* 셀프상표출원 */
  .selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top h2{
    font-size: 40px;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top p{
    font-size: 18px;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top{
    max-width: 100%;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul{
    width: 100%;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li{
    width: calc(33.3% - 20px)
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li img{
    width: 100px;
    height: 100px;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li{
    height: 275px;
    padding: 10px 10px;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li span{
    height: 90px;
    font-size: 15px;
  }
  /* 셀프2번섹션 */
  .selfTradeName-wrap .selfTrade-contentBox02{
    min-width: auto;
    height: 100%;
    padding: 20px 10px ;
  }
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox{
    min-width: 100%;
  }
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox h2{
    font-size: 26px;
    word-break: keep-all;
  }
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox p{
    font-size: 16px;
  }
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox p{
    margin-bottom: 30px;
    word-break: keep-all;
  }
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox .potential-tab li{
    font-size: 16px;
    min-width: auto;
    padding: 12px 14px;
  }
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox .potential-imgbox img{
    max-width: 100%;
    height: auto;
  }
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox .selfTrade-youtube{
    max-width: 100%;
  }
  /* 셀프트레이드마크3번 */
  .selfTradeName-wrap .selfTrade-contentBox03{
    height: 100%;
    padding: 20px 10px;
  }
  .selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox img{
    width: 100%;
    margin: 0px 0px 0px 70px;
  }
  .selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox h2{
    font-size: 30px;
  }
  .selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox p{
    font-size: 16px;
    /* height: 400px; */
  }
  .selfTradeName-wrap .selfTrade-contentBox03-01 .content-oneBox h2{
    font-size: 30px;
    margin-bottom: 40px;
  }
  .selfTradeName-wrap .selfTrade-contentBox03-01 .content-oneBox img{
    width: 95%;
  }
  
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 100%;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: calc(50% - 16px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content{
    width: 100%;
    text-align: center;
  }


  /* 셀프 트레이드 마지막 */
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
    word-break: keep-all;
    font-size: 14px;
  }

  /* 에이전시*/
  .agencyName-wrap .agency-contentBox01 .content-topBox .content-top h2{
    font-size: 40px;
  }
  .agencyName-wrap .agency-contentBox01 .content-topBox .content-top p{
    font-size: 18px;
  }
  .agencyName-wrap .agency-contentBox01 .content-topBox .content-top{
    max-width: 100%;
  }
  .agencyName-wrap .agency-contentBox01 .content-botBox ul{
    width: 100%;
  }
  .agencyName-wrap .agency-contentBox01 .content-botBox ul li{
    width: calc(33.3% - 20px)
  }
  .agencyName-wrap .agency-contentBox01 .content-botBox ul li img{
    width: 100px;
    height: 100px;
  }
  .agencyName-wrap .agency-contentBox01 .content-botBox ul li{
    /* height: 275px; */
    height: 250px;
    padding: 10px 10px;
  }
  .agencyName-wrap .agency-contentBox01 .content-botBox ul li span{
    height: 90px;
    font-size: 15px;
  }
  .agencyName-wrap .agency-contentBox01 .content-topBox .content-top h2{
    font-size: 30px;
  }
  /* 에이전시2 */
  .agencyName-wrap .agency-contentBox02{
    min-width: auto;
    height: 100%;
    padding: 20px 10px ;
  }
  
  .agencyName-wrap .agency-contentBox02 .content-oneBox{
    min-width: 100%;
  }
  .agencyName-wrap .agency-contentBox02 .content-oneBox h2{
    font-size: 26px;
    word-break: keep-all;
  }
  .agencyName-wrap .agency-contentBox02 .content-oneBox p{
    font-size: 16px;
  }
  .agencyName-wrap .agency-contentBox02 .content-oneBox p{
    margin-bottom: 30px;
    word-break: keep-all;
  }
  .agencyName-wrap .agency-contentBox02 .content-oneBox .agency-youtube {
    height: 400px;
  }
  .agencyName-wrap .agency-contentBox02 .content-oneBox .potential-tab li{
    font-size: 16px;
    min-width: auto;
    padding: 12px 14px;
  }
  .agencyName-wrap .agency-contentBox02 .content-oneBox .potential-imgbox img{
    max-width: 100%;
    height: auto;
  }
  .agencyName-wrap .agency-contentBox02 .content-oneBox .selfTrade-youtube{
    max-width: 100%;
  }
  /* 에이전시3 */
  .agencyName-wrap .agency-contentBox03{
    height: 100%;
    padding: 20px 10px;
    min-height: auto;
  }
  .agencyName-wrap .agency-contentBox03 .content-oneBox img{
    width: 100%;
    /* margin: 0px 0px 0px 70px; */
  }
  .agencyName-wrap .agency-contentBox03 .content-oneBox h2{
    font-size: 30px;
  }
  .agencyName-wrap .agency-contentBox03 .content-oneBox p{
    font-size: 16px;
    margin-bottom: 40px;
    word-break: keep-all;
    /* height: 400px; */
  }
  .agencyName-wrap .agency-contentBox03-01 .content-oneBox h2{
    font-size: 30px;
    margin-bottom: 40px;
  }
  .agencyName-wrap .agency-contentBox03-01 .content-oneBox img{
    width: 95%;
  }
  /* 에이전시4 */
  .agencyName-wrap .agency-contentBox03-01{
    min-height: auto;
    height: 100%;
    padding: 20px 0;
  }
  .agencyName-wrap .agency-contentBox03-01 .content-oneBox img{
    width: 95%;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 100%;
  }

  .agencyName-wrap .agency-contentBox04  .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width: calc(50% - 16px);
    min-height: 156px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
  }
  .agencyName-wrap .agency-contentBox04  .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content{
    width: 100%;
    text-align: center;
  }

  /* 셀프 트레이드 마지막 */
  .agencyName-wrap .agency-contentBox04  .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .agencyName-wrap .agency-contentBox04  .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
    word-break: keep-all;
    font-size: 14px;
  }

      /* 위조상품/모니터링 */
  .monitorName-wrap .monitor-contentBox01{
    height: 100%;
    padding: 20px 0; 
  }
  .monitorName-wrap .monitor-contentBox01 .content-topBox .content-top h2{
    font-size: 30px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-topBox .content-top p{
    font-size: 18px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li{
    width: calc(33.3% - 20px);
    height: auto;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li span{
    min-height: 45px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-01{
    width: 100%;
    height: 150px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-02{
    width: 100%;
    height: 150px;
    background-size: contain;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-03{
    width: 100%;
    height: 150px;
    background-size: contain;
  }

  /* 위조 2번 섹션 */
  .monitorName-wrap .monitor-contentBox02{
    min-width: 100%;
    height: 100%;
    padding: 20px 10px;
  }
  .monitorName-wrap .monitor-contentBox02 .content-oneBox h2{
    font-size: 30px;
  }
  .monitorName-wrap .monitor-contentBox02 .content-oneBox p{
    font-size: 18px;
  }
  .monitorName-wrap .monitor-contentBox0201{
    height: 100%;
  }

  /* 위조3 */
  .monitorName-wrap .monitor-contentBox0201 .content-topBox{
    padding: 20px 10px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top h2{
    font-size: 30px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top p{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul{
    width: 100%;
    gap: 0px 20px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li{
    width: calc(33.3% - 20px);
    padding: 0px 10px 10px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li .monitor0201-01{
    height: 150px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li .monitor0201-02 {
    height: 150px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li .monitor0201-03 {
    height: 150px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li span{
    margin-bottom: 0px;
    word-break: keep-all;
  }
  .monitorName-wrap .monitor-contentBox03{
    height: 100%;
    padding: 20px 10px;
  }
  .monitorName-wrap .monitor-contentBox03 .content-oneBox h2{
    font-size: 30px;
    word-break: keep-all;
  }
  .monitorName-wrap .monitor-contentBox03 .content-oneBox p{
    font-size: 18px;
    margin-bottom: 20px;
    word-break: keep-all;
  }
  .monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-tab{
    /* width: 30%; */
    width: 225px;
  }
  .monitorName-wrap .monitor-contentBox03 .content-oneBox .monitor-tab li{
    font-size: 16px;
    padding: 10px 0px;
  }
  .monitor-contentBox03 .content-oneBox .monitor-imgbox img{
    width: 100%;
    height: auto;
  }
  .monitorName-wrap .monitor-contentBox03 .content-oneBox h2{
    font-size: 24px;
  }
  .monitorName-wrap .monitor-contentBox03 .content-oneBox p{
    font-size: 16px;
  }

  /* 셀프상표등록절차 */
  .selfProcedureName-wrap .selfProcedure-contentBox01{
    height: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top h2{
    font-size: 36px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox h2{
    font-size: 36px;
    word-break: keep-all;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top img{
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox{
    height: 100%;
  }
  
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox{
    height: 100%;
    padding: 20px 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox h2{
    font-size: 28px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox span{
    font-size: 18px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox span{
    margin-bottom: 20px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
    display: flex;
    flex-direction: column;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-left img{
    /* min-height: 480px; */
    /* width: 100%; */
    min-height: auto;
    /* height: 370px; */
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right{
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    width:  calc(50% - 50px );
    min-height: auto;
    display: block;
    padding: 10px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small{
    text-align: center;
    display: block;
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
    display: block; 
    margin: 0px auto 10px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
    text-align: center;
    margin-bottom: 5px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
    text-align: center;
    word-break: keep-all;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: 100%;
    min-height: 150px;
    display: block;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul {
  width: 100%;
  gap: 0px 20px;
}
.selfProcedureName-wrap .selfProcedure-contentBox03  .content-botBox ul li {
  width: calc(33.3% - 20px);
  padding: 10px 10px 10px;
}
.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li img {
  height: 85px;
}
.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li span {
  margin-bottom: 0px;
  word-break: keep-all;
}
.selfProcedureName-wrap .selfProcedure-all{
  padding: 20px 0;
  height: 100%;
}
.selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox{
  height: 100%;
}
.selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top h2{
  margin-bottom: 20px;
  font-size: 28px;
  word-break: keep-all;
}
.selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li b{
  font-size: 18px;
  margin-bottom: 0px;
}
.actingProcedureName-wrap .actingProcedure-contentBox01{
  height: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top img{
  width: 85%;
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top h2{
  font-size: 36px;
  margin-bottom: 43px;
}


.actingProcedureName-wrap .actingProcedure-contentBox02 {
  padding: 20px 0;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox h2{
  font-size: 36px;
  word-break: keep-all;
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top img{
  width: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox{
  height: 100%;
}

.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox{
  height: 100%;
  padding: 20px 0;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox h2{
  font-size: 28px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox span{
  font-size: 18px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox span{
  margin-bottom: 20px;
  
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
  flex-direction: column;
  gap: 30px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-left img{
  /* min-height: 480px; */
  /* width: 100%; */
  min-height: auto;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right{
  width: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
  width: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
  width:  calc(50% - 50px );
  min-height: auto;
  display: block;
  padding: 10px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small{
  text-align: center;
  display: block;
  width: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
  display: block; 
  margin: 0px auto 10px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
  text-align: center;
  margin-bottom: 5px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02  .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
  text-align: center;
  word-break: keep-all;
}

.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
  width: 100%;
  min-height: 150px;
  display: block;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actingProcedureName-wrap .actingProcedure-contentBox03{
  height: 100%;
  padding: 20px 10px;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul {
  width: 100%;
  gap: 0px 20px;
}
.actingProcedureName-wrap .actingProcedure-contentBox03  .content-botBox ul li {
  width: calc(33.3% - 20px);
  padding: 10px 10px 10px;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li img {
  height: 85px;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li span {
  margin-bottom: 0px;
  word-break: keep-all;
}
.selfProcedureName-wrap .selfProcedure-all{
  padding: 20px 0;
  height: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox{
  height: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2{
  margin-bottom: 20px;
  font-size: 28px;
  word-break: keep-all;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li b{
  font-size: 18px;
  margin-bottom: 0px;
}

/* 서브 마지막 섹션 */
.potentialName-wrap .potential-contentBox05 .content-oneBox .guide-content p{
  font-size: 18px;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot {
  padding: 0 10px;
  justify-content: space-between;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-link{
  width: 200px;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-link .main-nav{
  font-size: 16px;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-text span{
  word-break: keep-all;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-text{
  width: 70%;
}

/*  */
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox  .guide-content p{
  font-size: 18px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot {
  padding: 0 10px;
  justify-content: space-between;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-link{
  width: 200px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-link .main-nav{
  font-size: 16px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text span{
  word-break: keep-all;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text{
  width: 70%;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eaeef2;
  background-image: none;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content{
position: unset;
width: 100%;
justify-content: space-between;

}
.selfTradeName-wrap .selfTrade-contentBox05{
  width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0;
    padding: 0 10px;
    border-radius: 8px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox{
  min-width: auto;
}
/*  */
.agencyName-wrap .agency-contentBox05 .content-oneBox  .guide-content p{
  font-size: 18px;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot {
  padding: 0 10px;
  justify-content: space-between;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-link{
  width: 200px;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-link .main-nav{
  font-size: 16px;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-text span{
  word-break: keep-all;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-text{
  width: 70%;
}
.agencyName-wrap .agency-contentBox05 .content-oneBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eaeef2;
  background-image: none;
}
.agencyName-wrap .agency-contentBox05 .content-oneBox .guide-content{
position: unset;
width: 100%;
justify-content: space-between;

}
.agencyName-wrap .agency-contentBox05{
  width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0;
    padding: 0 10px;
    border-radius: 8px;
}
.agencyName-wrap .agency-contentBox05 .content-oneBox{
  min-width: auto;
}
/*  */
.monitorName-wrap .monitor-contentBox05 .content-oneBox  .guide-content p{
  font-size: 18px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot {
  padding: 0 10px;
  justify-content: space-between;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link{
  width: 200px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link .main-nav{
  font-size: 16px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text span{
  word-break: keep-all;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text{
  width: 70%;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eaeef2;
  background-image: none;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content{
position: unset;
width: 100%;
justify-content: space-between;

}
.monitorName-wrap .monitor-contentBox05{
  width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0;
    padding: 0 10px;
    border-radius: 8px;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox{
  min-width: auto;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link .main-nav{
  word-break: keep-all;
  text-align: center;
}

  /* 셀프상표등록 */
  .SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-contentBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfAll-botBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-contentBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
    display: block;
    text-align: center;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
    display: block;
    text-align: center;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
    justify-content: center;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-contentBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
    justify-content: center;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-contentBox{
    width: 100%;
  }
  

  /* 상표등록대행 */
  .SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-contentBox{
    width: 100%;
  }
  .SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfAll-botBox{
    width: 100%;
  }
  .ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox{
    padding: 43px 22px;
  }

  /* 요금안내 */
  .Cost-Wrap{width: 100%;}
  .cost-detail-wrap01 .table-scroll {
    width: 100%; 
    overflow-x: auto;
  }
  .table-scroll {
    overflow-x: scroll;
  }
  .Cost-Wrap .cost-detail-wrap01{
    padding: 35px 0;
  }
  .Cost-Wrap .cost-detail-wrap01 table{
    min-width: 880px;
  }
  .Cost-Wrap .cost-detail-wrap01 .cost-guide{
    min-width: 880px;
  }
  .Cost-Wrap .costHead h2{
    font-size: 30px;
  }
  .Cost-Wrap .cost-detail-wrap01 .tabs li{
    font-size: 16px;
    pad: 12px 7px;
  }
  .Cost-Wrap .cost-detail-wrap01 .tabs{
    gap: 15px;
  }
  .content-customer-wrap .faq .faq-list > li{
    padding: 32px 10px;
  }
  .content-customer-wrap .faq .faq-list > li > .arcodian > .img-wrap:first-child > span{
    font-size: 18px;
    word-break: keep-all;
  }
  .content-customer-wrap .faq .faq-list > li > .find-wrap > p > span{
    word-break: keep-all;
    font-size: 16px;
  }
  .content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list li > span{
    font-size: 16px;
    word-break: keep-all;
  }
  .content-customer-wrap .faq .faq-list > li > .change-wrap > p > span{
    font-size: 16px;
    word-break: keep-all;
  }
  .content-customer-wrap .faq .faq-list > li > .change-wrap > .change-description > p{
    font-size: 16px;
    word-break: keep-all;
  }

  .refund-all .refund .refund-bg .refund-wrap .content-list li h3{
    font-size: 16px;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li .textBox{
    padding: 20px 20px;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li .textBox p{
    word-break: keep-all;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li .textBox p::before{
    left: -11px;
  }
  .refund-all .refund .refund-bg .refund-wrap .warningText{
    text-align: center;
  }
  .content-customer-wrap .inquiry .service-box{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin-bottom: 20px;
  }
  .content-customer-wrap .inquiry .service-box .service-text{
    width: 100%;
  }
  .content-customer-wrap .inquiry .service-box .service-text h4{
    text-align: center;
  }
  .content-customer-wrap .inquiry .service-box .service-text span{
    display: block;
    text-align: center;
    word-break: keep-all;
  }
  .content-customer-wrap .inquiry .service-box .service-text h4{
    margin-bottom: 20px;
  }
  .content-customer-wrap .inquiry .inquiry-form-wrap{
    padding: 0px;
  }
  /* 1024 끝 */
}

@media (max-width: 768px) {
  .br768{display: none;}
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left h2{
    font-size: 30px;
  }
  .brandName-wrap .brand-contentBox01 .content-leftBox .content-left p{
    font-size: 16px;
    word-break: keep-all;
  }
  /* 브랜드섹션1 */
  .brandName-wrap .brand-contentBox01{
    height: 100%;
    padding: 40px 0;
  }
  /* 브랜드섹션2 */
  .brandName-wrap .brand-contentBox02{
    height: 100%;
  }
  .brandName-wrap .brand-contentBox02{
    margin: 40px auto;
  }
  .brandName-wrap .brand-contentBox02 .content-oneBox h2{
    font-size: 30px;
  }
  .brandName-wrap .brand-contentBox02 .content-oneBox p{
    font-size: 16px;
    word-break: keep-all;
  }
  /* 브랜드섹션3 */
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left h2{
    font-size: 26px;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left p{
    font-size: 20px;
  }
  .brandName-wrap .brand-contentBox03 .content-leftBox .content-left span{
    font-size: 14px;
    align-items: center;
  }
  .brandName-wrap .brand-contentBox04{
    padding: 40px 0 40px;
    height: 100%;
  }
  /* 브랜드섹션5 */
  .brandName-wrap .brand-contentBox04 .content-oneBox h2{
    font-size: 26px;
  }
  .brandName-wrap .brand-contentBox04 .content-oneBox p{
    font-size: 16px;
    margin-bottom: 40px;
    word-break: keep-all;
  }


  .brandName-wrap .brand-contentBox05 .content-oneBox .guide-content p{
    text-align: center;
    margin: 0px auto 0;
    width: 100%;
    word-break: keep-all;
    font-size: 16px;
  }
  .brandName-wrap .brand-contentBox05 .content-oneBox .guide-content .guidebox-top{
    margin: 20px auto 0;
    height: 60px;
  }

  .brandName-wrap .brand-contentBox05 .content-oneBox .guide-content{
    height: auto;
  }
  .brandName-wrap .brand-contentBox05 h2{
    font-size: 30px;
  }
  .brandName-wrap .brand-contentBox05 h2 span{
    font-size: 30px;
  }
  .brandName-wrap .brand-contentBox05 .content-oneBox .guide-content{
    padding: 0px;
  }
  .brandName-wrap .brand-contentBox05 h2{
    font-size: 26px;
  }
  .brandName-wrap .brand-contentBox05 h2 span{
    font-size: 26px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot{
    display: block;
    padding: 40px 8px 40px 8px
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-text h4{
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-text{
    width: 100%;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-text span{
    display: block;
    text-align: center;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-link{
    margin: 0px auto;
  }
  .guide-content h4{
    margin-bottom: 10px;
  }
  .brandName-wrap .brand-contentBox05 .content-oneBox .guide-content p{
    line-height: 24px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-text h4{
    margin-bottom: 10px;
  }
  .brandName-wrap .brand-contentBox05 .guidebox-bot .service-link{
    margin: 24px auto;
  }
  /* ㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
  /* 포텐셜 페이지  */
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul{
    display: flex;
    flex-wrap: wrap;
  }
  .potentialName-wrap .potential-contentBox01 .content-rightBox ul li{
    /* width: calc(50% - 20px); */
    width: calc(50% - 25px);
    max-width: 100%;
    padding: 18px 8px 0px;
  }
  .potentialName-wrap .potential-contentBox01 .content-leftBox .content-left h2{
    font-size: 30px;
  }
  /* 포텐셜섹션2 */
  .potentialName-wrap .potential-contentBox02 .content-oneBox h2{
    font-size: 30px;
  }
  .potentialName-wrap .potential-contentBox02 .content-oneBox p{
    font-size: 16px;
    margin-bottom: 20px;
    word-break: keep-all;
  }
  /* 포텐셜섹션5 */
  .potentialName-wrap .potential-contentBox04 .content-oneBox h2{
    font-size: 26px;
    word-break: keep-all;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox span {
    font-size: 16px;
    margin-bottom: 20px;
    word-break: keep-all;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 100%;
    height: 350px;
  }
  .potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    /* min-height: 185px; */
    /* display: flex; */
    min-height: 144px;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(50% - 10px);
  }
  
  /* 셀프상표출원 */  
  .br768_01{display: none;}
  .br768_02{display: block;}
  .selfTradeName-wrap .selfTrade-all{
    /* height: 100%; */
    padding: 20px 0 20px;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top h2{
    font-size: 30px;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top p{
    font-size: 18px;
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li {
    width: calc(50% - 20px);
  }
  .selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li{
    height: 240px;
  }
  /* 셀프상표 */
  .selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox{
    padding: 0px 10px;
  }
  /* 셀프 3번 */
  .selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox p{
    margin-bottom: 20px;
  }
  .selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox img{
    margin: 0px 0px 0px 40px;
  }
  /* 셀프 4번 */
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox h2{
    font-size: 30px;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox span{
    font-size: 16px;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
    font-size: 16px;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    min-height: 140px;
  }
  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px;
  }

  /* 에이전시 */
  .agencyName-wrap .agency-contentBox01{
    height: 100%;
    padding: 20px 0 20px;
  }
  .agencyName-wrap .agency-contentBox01 .content-botBox ul li{
    width: calc(50% - 20px);
    height: 220px;
  }
  .agencyName-wrap .agency-contentBox01 .content-topBox .content-top p{
    word-break: keep-all;
    font-size: 16px;
  }
  .agencyName-wrap .agency-contentBox01.content-oneBox p{
    margin-bottom: 20px;
  }
  .agencyName-wrap .agency-contentBox01 .content-oneBox img{
    margin: 0px 0px 0px 40px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    min-height: 140px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px;
  } 
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content {
    width: 100%;
    text-align: center;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    word-break: keep-all;
    font-size: 14px;
  }
  /* 에이전시 4번 */
  .agencyName-wrap .agency-contentBox04 .content-oneBox h2{
    font-size: 30px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox span{
    font-size: 16px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
    font-size: 16px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    min-height: 155px;
    width: calc(50% - 16px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin-bottom: 0px;
  }
  .agencyName-wrap .agency-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px;
  }

  /* 위조 모니터링 */
  .monitorName-wrap .monitor-contentBox01 .content-topBox .content-top h2{
    font-size: 26px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-topBox .content-top p{
    font-size: 16px;
    margin-bottom: 20px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul{
    gap: 8px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li h3 {
    text-align: center;
    font-size: 18px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li span{
    min-height: 68px;
    margin-bottom:10px
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-01{
    width: 100%;
    height: 120px;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-02{
    width: 100%;
    height: 120px;
    background-size: contain;
  }
  .monitorName-wrap .monitor-contentBox01 .content-botBox ul li .monitor01-03{
    width: 100%;
    height: 120px;
    background-size: contain;
  }
  .monitorName-wrap .monitor-contentBox02 .content-oneBox .monitor-youtube{
    height: 400px;
  }
  .monitorName-wrap .monitor-contentBox02 .content-oneBox ul li{
    padding: 10px 6px;
    font-size: 16px;
  }
  .monitorName-wrap .monitor-contentBox02 .content-oneBox h2{
    font-size: 26px;
    word-break: keep-all;
  }
  .monitorName-wrap .monitor-contentBox02 .content-oneBox p{
    font-size: 16px;
    word-break: keep-all;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top h2{
    font-size: 26px;
    word-break: keep-all;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-topBox .content-top p {
    font-size: 16px;
    margin-bottom: 20px;
    word-break: keep-all;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li{
    width: 100%;
    padding: 10px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li .monitor0201-01{
    height: 100px;
    background-size: 100px 100px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li b{
    margin-bottom: 0px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li h3{
    font-size: 20px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul li h3{
    margin-bottom: 8px;
  }
  .monitorName-wrap .monitor-contentBox0201 .content-botBox ul{
    gap: 8px;
  }
  /* 셀프상표등록 */
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top h2{
    font-size: 30px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox h2{
    font-size: 30px;
  }
  


  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px;
  } 
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content {
    width: 100%;
    text-align: center;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    word-break: keep-all;
    font-size: 14px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    min-height: 155px;
    width: calc(50% - 16px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin-bottom: 0px;
  }

  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    height: 350px;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top h2{
    font-size: 30px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top img{
    width: 100%;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox h2{
    font-size: 30px;
    margin-bottom: 0px;
  }

  /*  */
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox h2{
    font-size: 30px;
  }
  
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 10px;
  } 
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content {
    width: 100%;
    text-align: center;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    word-break: keep-all;
    font-size: 14px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li{
    min-height: 155px;
    width: calc(50% - 16px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin-bottom: 0px;
  }

  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    height: 350px;
  }

/*서브 마지막 */
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content{
  justify-content: center;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content p{
  text-align: center;
  margin: 0px auto 0;
  width: 100%;
  word-break: keep-all;
  font-size: 16px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content .guidebox-top{
  margin: 20px auto 0;
  height: 60px;
}

.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content{
  height: auto;
}
.selfTradeName-wrap .selfTrade-contentBox05 h2{
  font-size: 30px;
}
.selfTradeName-wrap .selfTrade-contentBox05 h2 span{
  font-size: 30px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content{
  padding: 0px;
}
.selfTradeName-wrap .selfTrade-contentBox05 h2{
  font-size: 26px;
}
.selfTradeName-wrap .selfTrade-contentBox05 h2 span{
  font-size: 26px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot{
  display: block;
  padding: 40px 8px 40px 8px
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text h4{
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text{
  width: 100%;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text span{
  display: block;
  text-align: center;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-link{
  margin: 0px auto;
}
.guide-content h4{
  margin-bottom: 10px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content p{
  line-height: 24px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text h4{
  margin-bottom: 10px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-link{
  margin: 24px auto;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text h4{
  font-size: 19px;
}
/*  */
.agencyName-wrap .agency-contentBox05 .content-oneBox .guide-content{
  justify-content: center;
}
.agencyName-wrap .agency-contentBox05 .content-oneBox .guide-content p{
  text-align: center;
  margin: 0px auto 0;
  width: 100%;
  word-break: keep-all;
  font-size: 16px;
}
.agencyName-wrap .agency-contentBox05 .content-oneBox .guide-content .guidebox-top{
  margin: 20px auto 0;
  height: 60px;
}

.agencyName-wrap .agency-contentBox05 .content-oneBox .guide-content{
  height: auto;
}
.agencyName-wrap .agency-contentBox05 h2{
  font-size: 30px;
}
.agencyName-wrap .agency-contentBox05 h2 span{
  font-size: 30px;
}
.agencyName-wrap .agency-contentBox05 .content-oneBox .guide-content{
  padding: 0px;
}
.agencyName-wrap .agency-contentBox05 h2{
  font-size: 26px;
}
.agencyName-wrap .agency-contentBox05 h2 span{
  font-size: 26px;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot{
  display: block;
  padding: 40px 8px 40px 8px
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-text h4{
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-text{
  width: 100%;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-text span{
  display: block;
  text-align: center;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-link{
  margin: 0px auto;
}
.guide-content h4{
  margin-bottom: 10px;
}
.agencyName-wrap .agency-contentBox05 .content-oneBox .guide-content p{
  line-height: 24px;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-text h4{
  margin-bottom: 10px;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-link{
  margin: 24px auto;
}
.agencyName-wrap .agency-contentBox05 .guidebox-bot .service-text h4{
  font-size: 19px;
}
.monitorName-wrap .monitor-contentBox05 h2{
  font-size: 28px;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content{
  justify-content: center;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content p{
  text-align: center;
  margin: 0px auto 0;
  width: 100%;
  word-break: keep-all;
  font-size: 16px;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content .guidebox-top{
  margin: 20px auto 0;
  height: 60px;
}

.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content{
  height: auto;
}
.monitorName-wrap .monitor-contentBox05 h2{
  font-size: 30px;
}
.monitorName-wrap .monitor-contentBox05 h2 span{
  font-size: 30px;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content{
  padding: 0px;
}
.monitorName-wrap .monitor-contentBox05 h2{
  font-size: 26px;
}
.monitorName-wrap .monitor-contentBox05 h2 span{
  font-size: 26px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot{
  display: block;
  padding: 40px 8px 40px 8px
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text h4{
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text{
  width: 100%;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text span{
  display: block;
  text-align: center;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link{
  margin: 0px auto;
}
.guide-content h4{
  margin-bottom: 10px;
}
.monitorName-wrap .monitor-contentBox05 .content-oneBox .guide-content p{
  line-height: 24px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text h4{
  margin-bottom: 10px;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-link{
  margin: 24px auto;
}
.monitorName-wrap .monitor-contentBox05 .guidebox-bot .service-text h4{
  font-size: 19px;
}

  /* 셀프상표등록 */
  .SelfProcedureAll-wrap01 .SelfProcedureAll-contentwrap .selfTop01img{
    width: 100%;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfTop01img{
    width: 100%;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap{
    width: 100%;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox{
    justify-content: center;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox01 ul{
    justify-content: center;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 h2{
    text-align: center;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul{
    text-align: center;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul li span{
    display: block;
    text-align: left;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .con-botbox02 ul{
    justify-content: center;
  }
  .SelfProcedureAll-wrap03 .SelfProcedureAll-contentwrap .selfLinkGroup{
    width: 100%;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfTop01img{
    width: 100%;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap{
    width: 100%;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .scrollBox01{
    width: 100%;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02 textarea{
    width: 100%;
  }
  .SelfProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02 h2{
    text-align: center;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfTop01img{
    width: 100%;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap{
    width: 100%;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botboxwrap .scrollBox01{
    width: 100%;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .con-botbox02 textarea{
    width: 100%;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .con-botbox02 h2{
    text-align: center;
  }
  .SelfProcedureAll-wrap05 .SelfProcedureAll-contentwrap .selfAll-botBox .con-botbox .flex-box{
    width: 100%;
  }
  /* 상표등록대행 */
  .SelfProcedureAll-wrap02 .SelfProcedureAll-contentwrap .selfTop01img{
    width: 100%;
  }
  .ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02{
    display: block;
  }
  .ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .con-botbox02 .txt{
    margin-bottom: 20px;
  }
  .ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox .con-topbox{
    display: flex;
    flex-wrap: wrap;
  }
  .ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .selfAll-botBox h2{
    width: 100%;
  }
  .ActingProcedureAll-wrap04 .SelfProcedureAll-contentwrap .label-box{
    width: 100%;
    justify-content: space-around;
  }
  .content-customer-wrap .faq .service-box{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content-customer-wrap .faq .service-box .service-text h4{
    width: 100%;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .content-customer-wrap .faq .service-box .service-text, .service-link{
    width: 100%;
  }
  .content-customer-wrap .faq .service-box .service-text span{
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .content-customer-wrap .customer-Title h2{
    font-size: 30px;
  }
  .content-customer-wrap .customer-Title .description{
    font-size: 16px;
    margin-bottom: 20px;
  }
  /* 로그인 */
  .container .sign-in{
    width: 100% !important;
  }
  .sign-in-wrap{width: 100%;}
  .sign-in-wrap .login-formBox{width: 100%;}
  .sign-in-wrap .login-img01{
    width: 205px;
    height: 55px;
  }
  .sign-in-wrap .login-formBox strong{width: 100%;}
  .sign-in-wrap .form-wrap .check-wrap{width: 100%;}
  .sign-in-wrap .form-wrap .btn-wrap{width: 100%;}
  .sign-in-wrap .form-wrap .btn-wrap > button{width: 100%;}
  .brandName-wrap .brand-contentBox05 .content-oneBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0px;
    flex-direction: row;
    padding: 0px 20px;
    width: 100%;
    height: 280px;
    border-radius: 8px;
  }
  
  .content-customer-wrap .faq .faq-list > li > .find-wrap > .find-list{
    padding-left: 45px;
  }
  .refund-all .refund .refund-bg{
    background: none;
    padding: 0px;
  }
  .refund-all .refund .refund-bg .refund-wrap{
    padding: 0px;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li h3{
    width: 100%;
    height: 50px;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li .textBox{
    width: 100%;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li{
    border: 1px solid #ccc;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list{
    border: none;
    gap: 8px;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li .textBox{
    border: none;
  }
  .refund-all .refund .refund-bg .refund-wrap .content-list li .textBox p{
    margin-bottom: 8px;
  }



}


@media (min-width: 360px) and (max-width:768px){
  .actingProcedureName-wrap .actingProcedure-contentBox01 {
    padding: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li h3 {
    font-size: 16px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li b {
    font-size: 12px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li span {
    font-size: 14px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li {
    width: 100%;
    border: 1px solid #F1F1F1;
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li img {
    margin: 5px auto;
  }

  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li h3 {
    font-size: 16px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li b {
    font-size: 12px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li span {
    font-size: 14px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li {
    width: 100%;
    border: 1px solid #F1F1F1;
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li img {
    margin: 5px auto;
  }
  .Cost-Wrap .costHead h2 {
    font-size: 22px;
    font-weight: 700;
  }
  .Cost-Wrap .costDetail {
    background: transparent;
  }
  .Cost-Wrap .costDetail {
    padding: 56px 0 0 0;
  }
  .Cost-Wrap .cost-detail-wrap01 .cost-guide {
    background: transparent;
  }
  .brand-allwrap1 {
    width: 360px !important;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 {
    background-color: transparent;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul {
    flex-direction: column;
    gap: 20px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li {
    width: 100%;
    border: 1px solid #F1F1F1;
    box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li img {
    margin: 5px auto;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top h2 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox h2 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    flex-direction: column;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: 360px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: none;
  }
  .selfProcedureName-wrap {
    padding-top : 35px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top {
    padding: 0;
  }
  .selfProcedureName-wrap .selfProcedure-all {
    padding: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox {
    padding-bottom: 96px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(1) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(5) img {
    margin-right: 0;
    margin-left: 0;
  }



  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 {
    background-color: transparent;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul {
    flex-direction: column;
    gap: 20px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li {
    width: 100%;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top h2 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox h2 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    flex-direction: column;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: 360px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: none;
  }
  .actingProcedureName-wrap {
    padding-top : 35px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top {
    padding: 0;
  }
  .actingProcedureName-wrap .selfProcedure-all {
    padding: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox {
    padding-bottom: 96px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(1) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(5) img {
    margin-right: 0;
    margin-left: 0;
  }
  .brand-content-wrap.hovered-2 {
    margin-top: 0;
  }
  .brand-content-wrap.hovered-2 .brand-left-box {
    padding-top: 32px;
  }
  .brand-content-wrap.hovered-3 {
    padding-top: 32px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li:first-child:hover h3,
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li:nth-child(2):hover h3{
    font-size: 16px;
  }
  .brand-content-wrap.hovered-3 .brand-left-box {
    padding-top: 0;
  }
  .pick-home .brand-value-top .brand-left-box .partners-title .partners-title_color {
    font-size: 30px;
  }
  .brand-content-wrap.hovered-4 .brand-left-box {
    padding-top: 0;
  }
  .brand-content-wrap.hovered-4 {
    margin-top: 0;
    padding-top: 32px;
  }
  .brand-content-wrap.hovered-5 {
    padding-top: 32px;
  }
  .brand-content-wrap.hovered-5 .brand-left-box {
    padding-top: 0px;
  }
  .brand-value-top.hovered-5 {
    padding-bottom: 0;
  }
  .Cost-Wrap .cost-detail-wrap01 .tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    text-align: center;
    justify-items: center;
  }
  .Cost-Wrap .cost-detail-wrap01 .tabs li {
    width: 80%;
  }


  
}

@media (min-width: 320px) and (max-width:359px){
  .brand-allwrap1 {
    width: 360px !important;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 {
    background-color: transparent;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul {
    flex-direction: column;
    gap: 20px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-botBox ul li {
    width: 100%;
    border: 2px solid #F1F1F1;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    flex-direction: column;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: 360px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: none;
  }
  .selfProcedureName-wrap {
    padding-top : 96px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox01 .content-topBox .content-top {
    padding: 0;
  }
  .selfProcedureName-wrap .selfProcedure-all {
    padding: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox03 .content-topBox {
    padding-bottom: 96px;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox {
    width: 100%;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(1) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img {
    margin-right: 0;
    margin-left: 0;
  }
  .selfProcedureName-wrap .selfProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(5) img {
    margin-right: 0;
    margin-left: 0;
  }



  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 {
    background-color: transparent;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul {
    flex-direction: column;
    gap: 20px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li {
    width: 100%;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    flex-direction: column;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: 360px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: none;
  }
  .actingProcedureName-wrap {
    padding-top : 96px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top {
    padding: 0;
  }
  actingProcedureName-wrap .selfProcedure-all {
    padding: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox {
    padding-bottom: 96px;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(1) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img {
    margin-right: 0;
    margin-left: 0;
  }
  .actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(5) img {
    margin-right: 0;
    margin-left: 0;
  }
}