.potentialName-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.potentialName-wrap .potential-all{
  width: 100%;
  height: 100vh;
}
.potentialName-wrap .potential-contentBox01{
  display: flex;
  width: 100%;
}
.potentialName-wrap .potential-contentBox01 .content-leftBox, 
.content-rightBox{
  position: relative;
  width: 50%;
}
.potentialName-wrap .potential-contentBox01 .content-leftBox .content-left{
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 540px;
  height: auto;
  margin: 0px auto;
}
.potentialName-wrap .potential-contentBox01 .content-leftBox .content-left span{
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: left;
}
.potentialName-wrap .potential-contentBox01 .content-leftBox .content-left h2{
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.potentialName-wrap .potential-contentBox01 .content-leftBox .content-left b{
  display: block;
  color: #050F2F;
  font-size: 36px;
  font-family: "PretendardR", "sans-serif";
  text-align: left;
  margin-bottom: 20px;
  line-height: 140%;
}
.potentialName-wrap .potential-contentBox01 .content-leftBox .content-left p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: left;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox{
  width: 50%;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox ul{
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(0%, -50%);
  width: 720px;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 40px;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox ul li{
  width: 100%;
  max-width: 300px;
  min-height: 350px;
  max-height: 350px;
  background: #BBEEF0;
  border-radius: 4px;;
  padding: 34px 26px 29px 34px;  
  box-sizing: border-box;
  
}
.potentialName-wrap .potential-contentBox01 .content-rightBox ul li h3{
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 12px;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox ul li p{
  color: #666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox ul li:nth-child(2){
  margin-top: 40px;
  background: #CBF3EA;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox ul li:nth-child(3){
  background: #CBF3EA;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox ul li:last-child{
  margin-top: 40px;
}

.potentialName-wrap .potential-contentBox01 .content-rightBox .potentialAll{
  width: 100%;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}
.potentialName-wrap .potential-contentBox01 .content-rightBox .potential01{
  background-image: url('image/Potential01-01.png');
}
.potentialName-wrap .potential-contentBox01 .content-rightBox .potential02{
  background-image: url('image/Potential01-02.png');
}
.potentialName-wrap .potential-contentBox01 .content-rightBox .potential03{
  background-image: url('image/Potential01-03.png');
}
.potentialName-wrap .potential-contentBox01 .content-rightBox .potential04{
  background-image: url('image/Potential01-04.png');
}



/* 섹션2 */
.potentialName-wrap .potential-contentBox02{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.potentialName-wrap .potential-contentBox02 .content-oneBox{
  width: 100%;
  max-width: 1048px;
  margin: 0px auto;
  box-sizing: border-box;
}
.potentialName-wrap .potential-contentBox02 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.potentialName-wrap .potential-contentBox02 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.potentialName-wrap .potential-contentBox02 .content-oneBox .potential-youtube{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 550px;
  background: #383838;
  color: #fff;
}
/* 섹션3 */

.potentialName-wrap .potential-contentBox03{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(28, 196, 203, 0.1);
}
.potentialName-wrap .potential-contentBox03 .content-oneBox{
  width: 100%;
  min-width: 1920px;
  height: auto;
  margin: 0px auto;
  box-sizing: border-box;
  /* padding-top: 30px; */
}
.potentialName-wrap .potential-contentBox03 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.potentialName-wrap .potential-contentBox03 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.potentialName-wrap .potential-contentBox03 .content-oneBox .potential-youtube{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 550px;
  background: #383838;
  color: #fff;
}
.potentialName-wrap .potential-contentBox03 .content-oneBox .potential-tab{
  display: flex;
  justify-content: center;
  gap: 14px;
  /* margin-bottom: 70px; */
  margin-bottom: 20px;
}
.potentialName-wrap .potential-contentBox03 .content-oneBox .potential-tab li{
  min-width: 215px;
  border-radius: 100px;
  text-align: center;
  font-size: 18px;
  font-family: "PretendardRdasda", "sans-serif";
  padding: 16px 24px;
}
.potentialName-wrap .potential-contentBox03 .content-oneBox .potential-tab .selected-tab {
  background-color: #319DFF;
  color: #fff;
}
.potentialName-wrap .potential-contentBox03 .content-oneBox .potential-tab .non-tab {
  background-color: white;
  color: #050F2F;
}
.potentialName-wrap .potential-contentBox03 .content-oneBox .potential-imgbox{
  width: 100%;
}
.potential-contentBox03 .content-oneBox .potential-imgbox img{
  display: block;
  max-width: 100%;
  margin: 0px auto;
  max-width: 880px;
  height: 560px;
}
/* 섹션4 */
.potentialName-wrap .potential-contentBox04{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox{
  width: 100%;
  /* padding-top: 80px; */
  margin: 0px auto;
  box-sizing: border-box;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox span{
  display: block;
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox{
  width: 100%;
  display: flex;
  justify-content: center;
  
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
  width: 50%;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
  margin: 0px auto;
  min-height: 520px;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
  /* width: 50%; */
  width: 34%;
}

.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li{
  display: flex;
  /* width: 645px; */
  width: 100%;
  height: 110px;
  margin-bottom: 20px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px 35px 24px 24px;
  border: 2px solid transparent; 
  transition: border 0.3s; 
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li:hover{
  border-color: #1CC4CB;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li img{
  display: inline-block;
  margin-right: 20px;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} 
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li h3{
  width: 100%;
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  line-height: 150%;
}
.potentialName-wrap .potential-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content p{
  width: 100%;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  color: #666;
}
/* 섹션5 */
.potentialName-wrap .potential-contentBox05{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.potentialName-wrap .potential-contentBox05 .content-oneBox{
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;
  box-sizing: border-box;
}
.potentialName-wrap .potential-contentBox05 h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.potentialName-wrap .potential-contentBox05  h2 span{
  color: #319DFF;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
}
.potentialName-wrap .potential-contentBox05 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.potentialName-wrap .potential-contentBox05 .content-oneBox{
  position: relative;
  margin: 0px auto;
  /* min-width: 1280px; */
  width: 1280px;
  height: 350px;
  background-image: url('image/subBg6_1.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.potentialName-wrap .potential-contentBox05 .content-oneBox .guide-content{ 
  width: 502px;
  height: 150px;
  position: absolute;
  right: 265px;
  bottom: 99px;
}
.potentialName-wrap .potential-contentBox05 .content-oneBox .guide-content p{
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.potentialName-wrap .potential-contentBox05 .content-oneBox .guide-content .guidebox-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  font-family: "PretendardR", "sans-serif";
  padding: 8px 16px;
  border: 1px solid #050F2F;
  cursor: pointer;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  min-height: 254px;
  background: #1CC4CB;
  border-radius: 8px;
  margin: 20px auto 0px;
  padding: 60px 180px 60px 120px;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-text{
  width: 70%;;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-text h4{
  color: #fff;
  font-family: "PretendardB", "sans-serif";
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 40px;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-text span{
  color: #fff;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 265px;
  height: 60px;
  background-color: #fff;
  color: #050F2F;
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-link .main-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}
.potentialName-wrap .potential-contentBox05 .guidebox-bot .service-link .main-nav img{
  transform: rotate(90deg);
}
/* 
풀페이지 스크롤
.potential-allwrap .Section{
  max-width: 1280px;
  height: 100vh !important;
  border: 1px solid #000;
}
.potential-section01{background-color: #009fff;}
.potential-section05 {padding: 140px 320px !important;}
.potential-section05 .final-title-wrap h6 ,
.potential-section05 .final-title-wrap h5,
.potential-section05 .final-title-wrap h5 span{
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}
.potential-section05 .final-title-wrap {
  margin-bottom: 60px;

}
.potential-section05 .final-title-wrap h6 {
  line-height: 46px;
  color: #050F2F;
  margin-bottom: 4px;
}
.potential-section05 .final-title-wrap h5 {
  line-height: 70px;
}
.potential-section05 .final-title-wrap h5 span {
  line-height: 70px;
}
.potential-section05 .title-point {
  color: #319DFF;
}
.potential-section05 .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  min-width: 1280px;
  height: 350px;
  background-image: url(http://localhost:3000/static/media/guideBg02.bac9e6a….svg);
  background-repeat: no-repeat;
  background-position: center;
}
.potential-section05 .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.potential-section05 .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  padding: 8px 16px;
  border: 1px solid #000;
  cursor: pointer;
}
.potential-section05 .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  color: #333333;
}
.potential-section05 .box-wrap .service-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: 350px;
  padding: 83px 216px 80px 104px;
  border-radius: 8px;
  background-color: #00274A;
}
.potential-section05 .box-wrap .service-box .service-text, .service-link {
  width: 50%;
  color: #fff;
}
.potential-section05 .box-wrap .service-box .service-text h4 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 40px;
}
.potential-section05.box-wrap .service-box .service-text span {
  font-size: 20px;
  color: #DDDDDD;
}
.potential-section05 .box-wrap .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: #319DFF;
}
.potential-section05 .box-wrap .service-box .service-link a {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
} */