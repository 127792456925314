.price-info-wrap .costWrap {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}
.Cost-Wrap .costHead{
  text-align: center;
}
.Cost-Wrap .costHead h2{
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
}

.Cost-Wrap .costHead span{
  display: inline-block;
  color: #666;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  margin-bottom: 20px;
}
/* .Cost-Wrap .price-info-wrap .costWrap .price-info-title th h3{
  font-size: 18px;
  } */
.Cost-Wrap .costDetail{
  padding: 100px 320px;
  background: #EAEEF2;
}
.Cost-Wrap .cost-detail-wrap01{
  background: #fff;
  padding: 35px 88px;
  width: 1300px;
}
.Cost-Wrap .cost-detail-wrap01 .column{
  border: none;
  padding: 20px 40px 20px 40px;
  border-bottom: 1px solid #e1e1e1;
}
.Cost-Wrap .cost-detail-wrap01 .tabs{
  max-width: 680px;
  background: #EAEEF2;
  border-radius: 99px;
  gap: 6px;
  padding: 6px;
  margin: 0px auto 42px;
}
.Cost-Wrap .cost-detail-wrap01 .tabs li.active {
  background-color: #319DFF;
  color: #fff;
}
.Cost-Wrap .cost-detail-wrap01 .tabs li {
  border-radius: 99px;
  cursor: pointer;
  font-size: 18px;
  /*line-height: 140%;*/
  /*padding: 15px 20px;*/
  padding: 12px 15px;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
}
.Cost-Wrap .cost-detail-wrap01 .description-header01 h3{
  display: flex;
  align-items: center;
  gap: 6px;
  color: #003464;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 23px;
}
.Cost-Wrap .cost-detail-wrap01 table{
  width: 100%;
}
.Cost-Wrap .cost-detail-wrap01 table thead{
  background: #7C8C9C;
}
.Cost-Wrap .cost-detail-wrap01 table thead th{
  color: #fff;
  border-right: 1px solid #fff;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  height: 60px;
  min-height: 60px;
  vertical-align: middle;
}
.Cost-Wrap .cost-detail-wrap01 td {
  /*text-align: right;*/
  padding: 20px 40px 20px 40px;
}
.text-end{
  text-align: end;
}
.Cost-Wrap .sale{
  color: red !important;
}
.Cost-Wrap .cost-detail-wrap01 tr td:last-child {
  text-align: right;
  margin-right:50px;
}
.Cost-Wrap .cost-detail-wrap01  .Cost-reset{
  background-color: #fff !important;
  font-family: "PretendardR", "sans-serif" !important;
  /*text-align: right !important;*/
  /* padding: 20px 40px 20px 40px !important; */
}
.Cost-Wrap .cost-detail-wrap01  .Cost-reset td{
  font-family: "PretendardR", "sans-serif" !important;
}/*
.Cost-Wrap .cost-detail-wrap01 table tbody tr:nth-child(even) {
  background-color: #F2F8FD; 
}*/
.Cost-Wrap .cost-detail-wrap01 .application-table tbody tr:nth-child(odd){
  background-color: #F2F8FD; 
}
.Cost-Wrap .cost-detail-wrap01 table.opinion-notice-table tbody tr td:first-child{
  width: 367px;
}
.Cost-Wrap .cost-detail-wrap01 table.Inquire-table tbody tr td:first-child{
  width: 550px;
}
.Cost-Wrap .cost-detail-wrap01 table tbody tr td:first-child{
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  color: #050F2F;
  vertical-align: middle;
  text-align: center;
  padding: 0px;
}
.Cost-Wrap .cost-detail-wrap01 table.apply-table tbody tr td:first-child{
  width:220px;
}
.Cost-Wrap .cost-detail-wrap01 table.apply-table tbody tr td:nth-child(2){
  width:330px;
}

.Cost-Wrap .cost-detail-wrap01 table td .row{
  width: 100%;
  justify-content: space-between;
  line-height: 36px;
}
.Cost-Wrap .cost-detail-wrap01 table td .row span{
  color: #050F2F;
}
.Cost-Wrap .cost-detail-wrap01 .totalSpan span{
  display: flex;
  flex-direction: column;
  line-height: 36px;
}
.Cost-Wrap .cost-detail-wrap01 .cost-guide{
  background: #F6F6F6;
  padding: 30px 24px;
  margin-top: 37px;
}
.Cost-Wrap .cost-detail-wrap01 .cost-guide h5{
  /*margin-bottom: 10px;*/
  margin-bottom:20px;
  color: #e72530;
  position: relative;
  padding-left: 15px;
}
.Cost-Wrap .cost-detail-wrap01 .cost-guide h5:last-child{
  margin-bottom: 0;
}
.Cost-Wrap .cost-detail-wrap01 .cost-guide h5 > span.text-under {
  text-decoration: underline;
  color: #e60023;
  line-height: 250%;
}
.Cost-Wrap .cost-detail-wrap01 .cost-guide h5::after {
  content: "※";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

/* 커스텀 */
.Cost-Wrap .costHead h2{
  font-size: 42px;
  font-family: "PretendardB", "sans-serif";
  color: #050F2F;
  text-align: center;
  margin-bottom: 12px;
  letter-spacing: -0.8px;
}
.Cost-Wrap .costHead span{
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  margin: 20px 0 50px;
  line-height: 140%;
}

.Cost-Wrap .P_radio01:checked{
    background-color: #0ECDC1;
    border: 3px solid white;
}


.Cost-Wrap .pricetable-wrap .title_h2{
  color: #050f2f;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
}
.Cost-Wrap .pricetable-wrap .title_p{
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  margin: 20px 0 50px;
  line-height: 140%;
}
.Cost-Wrap .pricetable-wrap .subprice_table .price-info-title >th{
  padding: 32px 16px 24px;
}
.price-info-wrap .price-info-title > th h3{
  color: #050F2F;
  font-size: 24px;
  font-family: "PretendardB", "sans-serif";
  text-align: left;
  height:24px;
  line-height:24px;
}
.Cost-Wrap .price-info-wrap .price-info-title > th:nth-child(3) h3{
  color: #FF7A00;
}
.Cost-Wrap .price-info-wrap .price-info-title > th:nth-child(4) h3{
  color: #07CDC1;
}
.Cost-Wrap .price-info-wrap .price-info-title > th:nth-child(5) h3{
  color: #00BF7B;
}
.Cost-Wrap .price-info-title > th >p:not(.price-font){
  padding: 16px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #E1E1E1;
  line-height: 140%;
  color: #858585;
}
.Cost-Wrap  .price-info-title > th > .price-info-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.Cost-Wrap .price-font{
  border-top: none;
  padding-top: 0;
  color: #555555;
}
.Cost-Wrapp .price-day{
  color: #686868;
}
.Cost-Wrap .price-radio-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Cost-Wrap .price-radio-wrap div{
  display: flex;
  gap: 10px;
}
.Cost-Wrap .price-radio-wrap input[type="radio"]{
  margin: 0px;
}
input[type="radio"]:checked + .P_radio01 {
  background-color: #07CDC1;
  border-color: #E2E2E2;
}
input[type="radio"]:checked + .P_radio02  {
  background-color: #07CDC1;
  border-color: #E2E2E2;
}
.Cost-Wrap .price-radio-wrap label{
  color: #878787;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}
/* 라디오버튼 커스텀 */
.Cost-Wrap .price-radio-wrap .P_radio01{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc; 
  border-radius: 50%;
  outline: none; 
  cursor: pointer;
}
.Cost-Wrap .price-radio-wrap .P_radio01:checked {
  background-color: #0ECDC1; 
  border: 3px solid white; 
  box-shadow: 0 0 0 1.6px #E1E1E1; 
}
.Cost-Wrap .price-radio-wrap .P_radio02{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc; 
  border-radius: 50%;
  outline: none; 
  cursor: pointer;
}
.Cost-Wrap .price-radio-wrap .P_radio02:checked {
  background-color: #00BF7A; 
  border: 3px solid white; 
  box-shadow: 0 0 0 1.6px #E1E1E1; 
}
.Cost-Wrap .none-height{
  height: 15px;
}
.Cost-Wrap .subprice_table tr > td:first-child{
  padding: 12px 10px;
}

.Cost-Wrap .pricetable-wrap .subprice_table .price-info-title >th{
  padding: 32px 16px 24px;
}
.Cost-Wrap .pricetable-wrap .subprice_table .price-info-title th:nth-child(2) h3{
  color: #050E2F;
}
.Cost-Wrap .pricetable-wrap .subprice_table .price-info-title th:nth-child(3) h3{
  color: #FF7900;
}
.Cost-Wrap .pricetable-wrap .subprice_table .price-info-title th:nth-child(4) h3{
  color: #0ECDC1;
}
.Cost-Wrap .pricetable-wrap .subprice_table .price-info-title h3:last-child{
  color: #00BF7A;
}

.pick-home .sub_wrap_all .subsection .price-radio-wrap .P_radio01:checked {
  background-color: #0ECDC1;
  border: 3px solid white;
  box-shadow: 0 0 0 1.6px #E1E1E1;
}
