.inquiry_bg {
  width: 100%;
  background-color: #EAEEF2;
}

.inquiry_bg .inquiry_container {
  background-color: white;
  width: 1280px;
  margin: 100px auto;
  border-radius: 8px;
  text-align: center;
  padding: 60px 140px;
}

.inquiry_bg .inquiry_container .inquiry_title {
  font-family: "PretendardB", "sans-serif";
  font-size: 48px;
  line-height: 67.2px;
}

.inquiry_bg .inquiry_container .inquiry_subtitle {
  font-family: "PretendardR", "sans-serif";
  font-size: 18px;
  line-height: 21.6px;
  margin-top: 16px;
}

.inquiry_bg .inquiry_container .inquiry_form {
  border-top: 2px solid #666666;
  border-bottom: 2px solid #666666;
  margin-top: 56px;
}

.inquiry_bg .inquiry_container .section_border_bt {
  display: flex;
  border-bottom: 1px solid #d1d1d1;
  align-items: center;
}

.inquiry_bg .inquiry_container .inquiry_label_border {
  background-color: #F5FAFF;
  height: 60px;
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inquiry_bg .inquiry_container .inquiry_label_border label {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";

}

.inquiry_bg .inquiry_container .radio_group,
.inquiry-form-wrap section .conditionsform_section .radio_group{
  display: flex;
}

.inquiry_bg .inquiry_container .radio_group .radio_label,
.inquiry-form-wrap section .conditionsform_section .radio_group .radio_label {
  margin-right: 39px;
  padding: 0 17px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #666666;
  display: flex;
  align-items: center;
}

.inquiry_bg .inquiry_container .radio_group .second {
  margin-right: 15px;
}

.inquiry_bg .inquiry_container .radio_group .third {
  margin-right: 63px;
}

.inquiry_bg .inquiry_container .radio_group .radio_label input {
  margin-right: 8px;
}

.inquiry_bg .inquiry_container .section_border_bt div input {
  border: 1px solid #d1d1d1;
  height: 35px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  padding: 9px 16px;
}

.inquiry_bg .inquiry_container .section_border_bt div input ::placeholder {
  color: #878787;
}

.inquiry_bg .inquiry_container .section_border_bt .input-wrap {
  display: flex;
  align-items: center;
  padding: 12.5px 16px;
  gap: 10px;
  width: 863px;
}

.inquiry_bg .inquiry_container .section_border_bt .input-wrap p {
  color: #666666;
  font-size: 14px;
  white-space: nowrap;
}

.inquiry_bg .inquiry_container .section_border_bt .input-wrap .text_input-title {
  width: 750px;
}

.inquiry_bg .inquiry_container .section_border_bt .input-box {
  padding: 12.5px 16px;
}

.inquiry_bg .inquiry_container .none-id .section-wrap {
  display: flex;
}

.inquiry_bg .inquiry_container .none-id .section-wrap .section_border_bt .input-wrap {
  width: 362.5px;
}

.inquiry_bg .inquiry_container .none-id .section-wrap .section_border_bt .input-wrap input {
  width: 330.5px;
}

.inquiry_bg .inquiry_container .none-id .section-wrap .section_border_bt .phone_input_group .phone_input {
  width: 92px;
}


.inquiry_bg .inquiry_container .none-id .section-wrap .section_border_bt .phone_input_group {
  padding: 0 16px;
}

.inquiry_bg .inquiry_container .none-id .section-wrap .section_border_bt .phone_input_group .choosephone {
  border: 1px solid #d1d1d1;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 92.17px;
  height: 35px;
  text-align: center;
}

.inquiry_bg .inquiry_container .none-id .section-wrap .section_border_bt .phone_input_group span {
  margin: auto 10px;
}

.inquiry_bg .inquiry_container .section_border_bt .button-list {
  padding: 0 16px;
  display: flex;
}

.inquiry_bg .inquiry_container .section_border_bt .button-list label {
  display: inline-block;
  border: 1px solid #d1d1d1;
  padding: 9px 20px;
  margin-right: 10px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.inquiry_bg .inquiry_container .section_border_bt .button-list input {
  display: none;
}

.inquiry_bg .inquiry_container .section_border_bt .button-list input:checked+label {
  background-color: #F5FAFF;
  color: #319dff;
  border-color: #007bff;
}

.inquiry_bg .inquiry_container .section_border_bt .trademark input {
  width: 831px;
}

.inquiry_bg .inquiry_container .contactform_section .inquiry_contactlabel_border {
  background-color: #F5FAFF;
  height: 305px;
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inquiry_bg .inquiry_container .contactform_section .inquiry_contactlabel_border label {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
}

.inquiry_bg .inquiry_container .contactform_section .detail textarea {
  width: 831px;
  height: 250px;
  border: 1px solid #d1d1d1;
  padding: 16px 20px;
  resize: none;
  outline-color: #d1d1d1;
  font-size: 14px;
}
.inquiry_bg .inquiry_container .contactform_section .detail textarea::placeholder {
  color: #878787;
}
.inquiry_bg .inquiry_container .contactform_section .detail {
  display: flex;
  flex-direction: column;
  align-items: flex-end
}

.inquiry_bg .inquiry_container .contactform_section .detail p {
  font-size: 14px;
  color: #666;
}

.inquiry_bg .inquiry_container .inquiry_form .file_input,
.content-customer-wrap .inquiry .inquiry-form-wrap section .file_input {
  display: none;
}

.inquiry_bg .inquiry_container .inquiry_form .file-wrap,
.content-customer-wrap .inquiry .inquiry-form-wrap section .file-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 863px;
}

.inquiry_bg .inquiry_container .inquiry_form .file-wrap p,
.content-customer-wrap .inquiry .inquiry-form-wrap section .file-wrap p {
  font-size: 14px;
  color: #666;
}

.inquiry_bg .inquiry_container .inquiry_form .custom_file_label,
.content-customer-wrap .inquiry .inquiry-form-wrap section .custom_file_label{
  border: 1px solid #050f2f;
  font-size: 14px;
  width: 89px;
  height: 35px;
  line-height: 16.8px;
  padding: 9px 20px;
  margin-left: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.inquiry_bg .inquiry_container .inquiry_form .file_name_display {
  text-decoration: underline;
  margin-left: 10px;
  font-family: "PretendardM", "sans-serif";
  font-size: 14px;
}

.inquiry_bg .inquiry_container .conditionsform_section,
.content-customer-wrap .inquiry .inquiry-form-wrap section .conditionsform_section{
  display: flex;
  justify-content: space-between;
  padding: 26px 20px;
  border-bottom: 2px solid #666;
}

.inquiry_bg .inquiry_container .conditionsform_section .condition,
.content-customer-wrap .inquiry .inquiry-form-wrap section .conditionsform_section .condition {
  text-decoration: underline;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
}

.inquiry_bg .inquiry_container .conditionsform_section .conditionagree,
.content-customer-wrap .inquiry .inquiry-form-wrap section .conditionsform_section .conditionagree{
  display: flex;
  align-items: center;
  margin-left: 56px;
  gap: 8px;
}

.inquiry_bg .inquiry_container .conditionsform_section .conditionagree .conditionagree_txt,
.content-customer-wrap .inquiry .inquiry-form-wrap section .conditionsform_section .conditionagree .conditionagree_txt{
  color: #666;
}

.inquiry_bg .inquiry_container .email_red_txt {
  color: #E72530;
  text-align: start;
  margin-top: 16px;
}


.inquiry_bg .inquiry_container .submit-btn {
  background-color: #319dff;
  color: white;
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  width: 180px;
  height: 56px;
  border-radius: 8px;
  margin-top: 56px;
}