.guide-content h4{display: none;}
/* 데스크탑 헤더 스타일 */
.mobile-header {
  display: none;
}
.br580_01{display: none;}
/* 모바일 메뉴 스타일링 */
.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: -100%;
  width: 270px;
  height: 100%;
  background-color: #fff;
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 32px;
}
.value-left-mo{
  display: none !important;
}


.mobile-header .hamburger,
.mobile-header .hamburger img {
  width: 48px;
  height: 48px;
  padding: 0;
}


.mo_userlogin_wrap{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  border-top: 1px solid #d1d1d1;
}
.mo_userlogin_wrap .signall{
  border: 1px solid #d1d1d1;
  text-align: center;
  width: 100%;
  height: 56px;
}
.mo_userlogin_wrap .signall a,
.mo_userlogin_wrap .signall button
{
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
}
.mo_userlogin_wrap .signin-box{
  display: block;
  padding: 17px 0;
} 
.mo_userlogin_wrap .signup-box{
  display: block;
  padding: 17px 0;
}

.pc_d-none{
  display: none;
}
.br1440_on{display: none;}

.footer .foot_innerbox .mo_copylight{
  display: none;
}
.pick-home .mo_only{
  display: none;
}
.pick-home .recommend-wrap ul li .recommend-textBox01 span {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
}
.mo-only {
  display: none;
}
.pc-only {
  display: block;
}
@media (max-width: 1440px) {
  .br1440_on{display: block;}
  .container .header{
    width: 1725px;
  }

  .container .content-wrap{
    width: 100%;
  }

  /* 섹션1 */
  .pick-home .brand-value-wrap{
    width: 100%;
    padding: 0px 16px;
  }
  .pick-home .mainSection01{
    width: 100%;
    /* height: 100%; */
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li h3{
    font-size:22px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3{
    font-size:27px;
    margin-bottom: 20px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p{
    font-size:15px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap{
    width: 100%;
  }

  .pick-home .brand-value-top .brand-left-box .partners-title{
    font-size: 40px;
  }
  .pick-home .brand-value-top .brand-left-box .title-point{
    font-size: 40px;
  }
  .pick-home .brand-value-top .brand-left-box p{
    font-size: 20px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-search{
    font-size: 16px;
    width: 90%;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox{
    /* left: 390px; */
    /* left: 525px; */
    left: 75%;
    gap: 5px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom{

  }
  .pick-home .recommend-wrap h3{
    font-size: 20px;
  }
  .pick-home .recommend-wrap ul{
    width: 1280px;
  }
  .pick-home .recommend-wrap ul li{
    padding: 20px 10px;
  }
  .pick-home .recommend-wrap .recommend-textBox02{
    width: 100%;
  }
  .pick-home .recommend-wrap .recommend-textBox02 p{
    font-size: 14px;
  }
  .pick-home .recommend-wrap ul li p{
    font-size: 15px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li {
    /* width: 300px; */
    width: 325px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .brand-value-icon{
    width: 100px;
    height: 100px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left{
    gap: 8px;
  }
  .pick-home .brand-value-top .brand-right-box{
    width: 50%;
  }
  .pick-home .brand-value-top .brand-right-box .brand-right-content{
    gap: 8px;
  }
  .pick-home .brand-right-box .brand-right-content li .content-box{
    padding: 10px 3px;
  }
  .pick-home .brand-right-box .brand-right-content li .content-box > p:last-child{
    margin-left: 36px;
  }
  .pick-home .trademark-wrap .trademark-contentall{
    width: 100%;
    gap: 0;
    padding: 0 16px;
  }
  .pick-home .trademark-wrap .trademark-right{
    width: 50%;
  }
  .pick-home .trademark-wrap .trademark-left ul {
    gap: 50px;
  }
  .sub-final-wrap .final-title-wrap .box-wrap{
    width: 1920px;
  }
  .price-info-wrap .price-info-title>th{
    min-width: 100%;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p {
    width: 100%;
    padding: 0px 0px 0px;
    font-size: 14px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p span{
    width: 100%;
    padding: 0px 0px 0px;
    font-size: 14px;
    margin-left: 30px;
  }
  

  .pick-home .brand-value-wrap .brand-value-top .brand-left-box {
    padding-left: 20px;
  }
  .pick-home .brand-value-top .brand-right-box {
    width: 58%;
  }

}

@media (max-width: 1280px) {
  /* 섹션1 */


  /* 섹션2 */

  .price-info-wrap, .main-price-wrap {
    width: 100%;
    padding: 20px;
  }
  
  .price-info-wrap .main-price-content {
    max-width: 100%;
  }

  .price-info-wrap .partners-title {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .price-info-wrap table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .price-info-wrap th, .price-info-wrap td {
    white-space: nowrap;
    font-size: 14px;
    padding: 8px;
  }

  .price-info-wrap .price-info-title > th > div {
    width: 100%;
    /* display: block; */
    text-align: center;
  }

  .price-info-wrap .price-info-title > th > div > span {
    display: block;
    margin-bottom: 8px;
  }

  .price-info-wrap .price-info-title > th > h3 {
    /* font-size: 16px;
    text-align: center; */
  }

  .price-info-wrap .price-info-title > th > p:not(.price-font) {
    /* font-size: 12px;
    text-align: center; */
  }

  .price-info-wrap .price-font {
    font-size: 24px;
    text-align: center;
    padding-top: 8px;
  }
  .price-info-wrap table tr > td:first-child img{
    display: none;
  }
  .price-info-wrap .moreBtn-wrap td> p {
    width: 100px;
    height: 28px;
    font-size: 12px;
  }
  /* 메인섹션2끝 */

  .price-info-wrap .price-info-title > th > div > .priceSelcet{display: none;}
  .price-info-wrap .price-info-title > th h3{
    /* text-align: center; */
  }
  .br1280{display: none;}
  /* 섹션3 */
  .pick-home .service-type-wrap{
    width: 100%;
  }
  .pick-home .service-type-wrap ul{
    width: 100%;
    justify-content:center;
  }
  .pick-home .service-type-wrap ul li{
    width: calc(30.3% - 13px);
    height: 340px;
  }
  /* 섹션4 */
  .pick-home .trademark-wrap{
    max-width: 100%;
  }
  .pick-home .trademark-wrap .trademark-right{
    width: 56%;
  }
  .pick-home .trademark-wrap .trademark-left ul li p{
    font-size: 48px;
    min-width: 189px;
  }
  /* 메인섹션5 */
  .pick-home .recommend-wrap{
    width: 100%;
    height: 100%;
    padding: 100px 16px;
  }
  .pick-home .recommend-wrap h3{
    font-size: 20px;
  }
  .pick-home .recommend-wrap ul{
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  .pick-home .recommend-wrap ul li{
    padding: 20px 10px;
    width: calc(50% - 80px);
  }
  .pick-home .recommend-wrap ul li .imgsize{
    width: 80px;
    height: 80px;
  }
  .pick-home .recommend-wrap .recommend-textBox02{
    width: 95%;
  }
  .pick-home .recommend-wrap ul li p{
    text-align: center;
    padding: 0px 10px 4px;
  }
  .pick-home .recommend-wrap ul > li > .recommend-textBox01 > p::after{
    display: none;
    top: 19%;
    left: 1%;
  }
  .pick-home .recommend-wrap .recommend-textBox02 p::after{
    display: none;
  }
  
  /* 메인 마크픽 뉴스 */
  .pick-home .main-news-wrap{
    width: 100%;
  }
  .pick-home .main-news-wrap .main-news-content{
    width: 100%;
  }
  .pick-home .main-news-wrap .main-news-content ul{
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pick-home .main-news-wrap .main-news-content > ul > li{
    width: calc(25% - 20px);
    display: flex;
    flex-direction: column;
  }
  .pick-home .main-news-wrap ul li .newsbox{
    padding: 20px 10px 12px;
  }
  .pick-home .main-news-wrap ul li h3{
    font-size: 18px;
    /* max-height: 31px; */
  }
  /* 메인 마지막 */
  .pick-home .final-title-wrap{
    width: 100%;
  }
  .pick-home .final-title-wrap .main-final-content{
    width: 100%;
  }
  .pick-home .final-title-wrap .main-final-content .box-wrap{
    width: 100%;
    padding: 0px 10px;
  }
  .pick-home .box-wrap .guide-box{
    width: 100%;
  }
  .pick-home .box-wrap .service-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0px;
  }
  .pick-home .box-wrap .guide-box .guide-content{
    position: absolute;
    right: 50px;
  }

  .main-recommend-content{
    width: 100%;
  }
  .trademark-grid{
    gap: 10px;
  }


  .pick-home .brand-value-top .brand-right-box {
    display: none;
  }
  .pick-home .brand-value-wrap .brand-value-top {
    display: flex;
    justify-content: center;
  }
  .pick-home .brand-value-top .brand-left-box .partners-title {
    text-align: center;
  }
  .pick-home .brand-value-top .brand-left-box p {
    text-align: center;
  }
  .brand-value-top.hovered-1 {
    margin-bottom: 0;
  }
  .brand-value-top.hovered-2 {
    margin-bottom: 27px;
  }
  .brand-value-top.hovered-4 {
    margin-bottom: 23px;
  }
  .brand-content-wrap.hovered-5 .brand-left-box {
    padding-top: 85px;
  }
}

@media (max-width: 1240px) {
  
  .header-all-wrap{
    width: 100%;
    background-color: transparent;
  }
  .container .header{
    width: 100%;
    padding: 0px
  }
  .container .header > .header-wrap{
    padding: 0px 24px;
  }
  .container .header .header-wrap .logo{
    width: 100px;
  }
  .container .header .menu > li{
    margin-right: 30px;
  }
  .container .header .menu li a{
    font-size: 18px;
  }
  .container .header .userMenu>li:last-child{
    margin-left: 20px;
  }
  .container .header .userMenu .snsBox{
    margin-right: 22px;
  }
  .container .header .sub-nav > li::after{
    margin: 0px 18px;
  }
  /* 섹션1 */
  
  .pick-home .brand-value-wrap .brand-value-top{
    display: block;
    margin-bottom: 20px;
  }
  .pick-home .brand-right-box .brand-right-content li .content-box > p:last-child{
    margin-left: 10px;
  }
  .pick-home .brand-value-top .brand-right-box .brand-right-content{
    display: flex;
    gap: 4px;
    justify-content:center;
  }
  .pick-home .brand-value-top .brand-left-box .partners-title{
    text-align: center;
    margin-bottom: 16px;
  }
  .pick-home .brand-value-wrap .brand-value-top .brand-left-box{
    width: 100%;
    text-align: center;
    margin-top: 0px;
    min-height: 250px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-search{
    width: 50%;
    font-size: 16px;
    padding: 18px 6px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox{
    left: 66%;
  }
  .pick-home .brand-value-top .brand-left-box p{
    margin-bottom: 20px;
  }
  .pick-home .brand-value-top .brand-right-box .brand-right-content li{
    width: 23%;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3{
    font-size: 16px;
    margin-bottom: 20px;
    /* text-align: center; */
    word-break: keep-all;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li{
    width: 18%;
    min-height: 180px;
    padding: 16px 8px 0 12px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .brand-value-icon{
    width: 50px;
    height: 50px;
    bottom: 14px;
    right: 7px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom{
    justify-content: center;
  }
  .pick-home .brand-value-top .brand-right-box{
    width: 100%;
    min-height: 380px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li{
    height: 315px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-navbox{
    padding: 20px 0 0 20px;
  }



  /* 메인 섹션4 */
  .trademark-box{
    width: 125px;
    height: 125px;
  }
  .pick-home .trademark-wrap .partners-title{
    font-size: 40px;
  }
  .pick-home .trademark-wrap .partners-title .title-point{
    font-size: 40px;
  }
  .pick-home .trademark-wrap .trademark-left ul li p{
    font-size: 40px;
    min-width: 180px;
  }
  .pick-home .trademark-wrap .trademark-left ul li p small{
    font-size: 40px;
  }
  /* .trademark-grid{
    grid-template-columns: repeat(4, 120px);
    grid-template-rows: repeat(3, 130px);
    gap: 8px;
  } */
  .pick-home .recommend-wrap ul{
    gap: 10px;
  }
  .main-recommend-content{
    width: 100%;
  }
  
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3{
    font-size: 27px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li:hover h3{
    font-size: 24px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-nav{
    padding: 16px 0 0 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li{
    padding: 0px 8px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li h3{
    padding: 0px 8px;
    font-size: 18px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-nav .newMain01-btn02 p{
    font-size: 14px;
  }
    .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox p:first-child {
    padding-right: 10px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 p{
    padding-right: 10px;
  }
  .container .header > .header-wrap{
    gap: 0px;
  }
  .container .header > .header-wrap > .menu{
    gap: 10px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p span {
    margin-left: 0;
  }

}
@media (max-width: 1180px) {
  /* 섹션3 */
  .pick-home .service-type-wrap{
    width: 100%;
  }
  .pick-home .service-type-wrap ul{
    width: 100%;
    gap: 18px;
  }
  .pick-home .service-type-wrap ul li {
      width: 400px;
      height: 320px;
      margin-bottom: 0;
  }
  .pick-home .service-type-wrap ul .me-32{
    margin: 0;
  }
  
  .pick-home .service-type-wrap li h3{
    font-size: 20px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 p {
    width: 100%;
    padding: 0px 0px 0px;
    font-size: 16px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p {
    width: 100%;
    padding: 0px 0px 0px;
    font-size: 14px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p span {
    width: 100%;
    padding: 0px 0px 0px;
    font-size: 14px;
  }
  .pick-home .recommend-wrap ul > li > .recommend-textBox01 > p::after {
    left: 1%;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 {
    /* min-height: 156px; */
    min-height: 110px;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  /* 메인섹션4 */
  /* .price-info-wrap table {
    display: none;
  } */
  .price-info-wrap .check-shapeAll {
    left: 45%;
}
  /* 섹션4 모바일 */
  .mo_plans_btn{
    padding: 8px 10px 8px 14px;
    gap: 8px;
    border-radius: 2px 0px 0px 0px;
    color: #fff;
    display: flex;
    align-items: center;
    background: #050F2F;
  }
  .img_w_100{
    width: 100%;
  }
  /* 메인섹션5 */
  .pick-home .recommend-wrap .recommend-textBox02 {
    /* min-height: 140px; */
    min-height: auto;
    padding: 12px 0 6px ;
  }
  .pick-home .recommend-wrap ul li{
    height: 400px;
  }
  /* 푸터 */
  .footer * {
    font-size: 14px;
  }
  .footer{
    padding: 32px 16px;
  }
  
  .footer .foot-leftbox .leftbox-mid > ul > li{
    font-size: 12px;
  }
  .foot-rightbox .foot_right_ul > li > p {
    font-size: 11px;
  }
  .footer .foot-leftbox .leftbox-top > .rule_ul li:last-child a {
    font-size: 12px;
    font-family: "PretendardB", "sans-serif";
  }
  .footer .foot-leftbox .leftbox-top > .rule_ul li:first-child a{
    font-size: 12px;
  }
  .foot-rightbox .foot_right_ul > li > span{
    font-size: 11px;
  }
  .footer .foot-leftbox .leftbox-fot > p{
    font-size: 11px;
  }
  .footer .bottom div p:nth-child(1), .footer .bottom div p:nth-child(2), .footer .bottom div p:nth-child(3){
    margin: 20px 14px 20px 0;
  }
  
}

@media (max-width: 1080px) {
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left{
    width: 47.2%;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right{
    width: 52%;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox p:first-child {
    padding-right: 10px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li h3 {
    font-size: 18px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p {
    font-size: 14px;
  }
  .price-info-wrap table tr>td:first-child{
    font-size: 10px;
  }
  .price-info-wrap .price-info-title > th h3{
    font-size: 20px;
  }
  .price-info-wrap .price-info-title>th>p:not(.price-font){
    font-size: 11px;
  }
  .price-info-wrap .price-day{
    font-size: 10px;
  }
  .price-info-wrap .price-font{
    font-size: 16px;
    padding-top: 0;
  }
  .price-info-wrap .table-text-last{
    font-size: 16px;
  }
  .price-info-wrap .check-shapeAll{
    left: 30%;
  }
  .price-info-wrap .price-position .table-img{
    top: 5px;
    right: 15px;
  }
  .price-info-wrap .price-position .table-img02{
    top: 5px;
  }
  .price-info-wrap .price-position .table-img03{
    top: 5px;
  }
  .main-price-info-wrap .price-radio-wrap label{
    font-size: 12px;
  }
  .main-price-info-wrap .price-radio-wrap div {
    gap: 5px;
    align-items: center;
  }
  .price-info-wrap .price-position .infoB{
    top: 9px;
    left: 70%;
  }
}



@media (max-width: 1024px) {
  .container .header > .header-wrap{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: flex;
  }
  .mobile-menu {
    display: block;
  }
  .hamburger{
    display: flex;
  }
  .mobile-menu-header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 40px 0px 40px 0;
  }
  .mobile-menu-header img{
    width: 50px;
    height: 50px;
  }
  .mobile-menu-header .close-button{
    font-size: 20px;
  }

  /* 섹션1 */

  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-nav .newMain01-btn01{
    gap: 30px !important;
  }
  .pick-home .brand-value-wrap .brand-content-wrap{
    margin-bottom: 0;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox {
    left: 66%;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-navbox{
    padding: 10px 0 0 10px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p {
    font-size: 14px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li {
    padding: 0px 15px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn01 {
    gap: 0px;
  }


   .pick-home .brand-value-top .brand-right-box .brand-right-content li{
    width: 30%;
  }
 
  /* 섹션2 */
  .price-info-wrap .partners-title{font-size: 30px;}

  /* 섹션3 */
  .pick-home .service-type-wrap{
    padding: 48px 0 48px 0;
  }
  .pick-home .service-type-wrap .partners-title{
    font-size: 40px;
    margin-bottom: 30px;
  }
    .pick-home .service-type-wrap ul {
    flex-direction: column;
  }
  .pick-home .service-type-wrap ul li{
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: start;
    padding: 16px 32px;
    margin-bottom: 0;
  }
    .pick-home .service-type-wrap ul {
    flex-direction: column;
  }
  .pick-home .service-type-wrap li img{
    width: 50px;
    height: 50px;
    margin: 0 32px 0 0;
  } 
  .pick-home .service-type-wrap li h3{
    text-align: start;
  }
  /* 메인섹션4 */
  .swiper-horizontal {width: 100%;}
  .pick-home .trademark-wrap .partners-title{
    margin-bottom: 26px;
    font-size: 40px;
  }
  .pick-home .trademark-wrap .trademark-left, .trademark-right{width: 100%; margin-left: 0px;}
  .pick-home .trademark-wrap p{text-align: center;}
  .pick-home .trademark-wrap .partners-title{text-align: center;}
  .pick-home .trademark-wrap .trademark-left ul{justify-content: center;}
  .pick-home .trademark-wrap .trademark-contentall{
    /* flex-direction: column; */
  }
  .pick-home .trademark-wrap .trademark-right{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 400px;
  }
  .progress-content-wrap02 .marquee02 .marquee_imgbox01 {
    width: 64px;
    height: 64px;
    padding: 8px;
    border: 0.3px solid #F1F1F1;
    border-radius: 2.4px;
  }
  .progress-content-wrap02 .marquee02 .marquee_imgbox01 img{
    width: 100%;
  }
  .trademark-grid{
    gap: 10px;
  }
  .trademark-grid{justify-content: center;}
  .pick-home .trademark-wrap{
    height: 100%;
    padding: 48px 16px 46px;
  }
  /* 메인섹션5 */
  .pick-home .recommend-wrap{
    padding: 48px 16px;
  }
  .pick-home .recommend-wrap h2{
    font-size: 30px;
  }
  .pick-home .recommend-wrap .partners-title{
    font-size: 30px;
  }
  .pick-home .recommend-wrap ul li{

  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 {
    min-height: 110px;
  }
  .pick-home .application-card-wrap .service-left-ul li {
    width: calc(50% - 5px);
  }
  .pick-home .application-card-wrap .service-top-title{
    margin-top: 48px;
  }
  .pick-home .application-card-wrap{
    margin-bottom: 50px;
  }
  /* 메인섹션6 */
  .main-price-info-wrap .pricetable-wrap .title_p {
    font-size: 20px;
  }
  /*마크픽 뉴스*/
  .pick-home .main-news-wrap{
    height: 100%;
    margin: 48px 0 46px 0;
  }
  .pick-home .main-news-wrap ul li h3 {
    font-size: 16px;
  }
  .pick-home .main-news-wrap .main-news-content > ul > li{
    width: calc(50% - 125px);
  }
  .pick-home .main-news-wrap .partners-title{
    font-size: 30px;
    margin-bottom: 50px;
  }
  .pick-home .main-news-wrap .partners-title .title-point{
    font-size: 40px;
  }
  .pick-home .application-home-title{
    font-size: 30px;
  }
  .pick-home .service-type-wrap .partners-title{
    font-size: 30px;
  }
  .pick-home .application-card-wrap .title-sub-text {
    font-size: 20px;
  }
  /* 파트너사 */
  .pick-home .progress-content-wrap{
    padding: 48px 0;
  }
  .pick-home .progress-content-wrap .partners-title{
    font-size: 30px;
  }
  .pick-home .progress-content-wrap .partners-title .title-point{
    font-size: 40px;
  }
  .pick-home .progress-content-wrap .progress-content img{
    width: 200px;
  }
  .marquee > .marquee__groupP{
    width: 70% !important;
  }

  /* 메인 마지막 */
  
  .pick-home .final-title-wrap h5{
    font-size: 30px;
    margin-bottom: 40px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 h5 span{
    font-size: 40px;
  }
  .pick-home .box-wrap .guide-box{
    background-color: #eaeef2;
    background-image: none;
  }
  .pick-home .brand-value-top .brand-right-box {
    display: none;
  }

  .guide-content{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px;
  } 
  .guide-content h4{
    display: block;
    width: 100%;
    /* font-size: 36px; */
    font-size: 30px;
    font-family: "PretendardB", "sans-serif";
    color: #222;
    line-height: 140%;
    margin-bottom: 10px;
  }
  .pick-home .box-wrap .service-box .service-text{
    width: 100%;
    text-align: center;
  }
  .pick-home .box-wrap .guide-box .guide-content{
    position: unset;
    width: 100%;
    justify-content: space-between;
  }
  .sub-final-wrap .main-final-content {
    margin-top: 0;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 .main-final-move{
    width: 260px;
    height: 60px;
    font-size: 20px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top{
    padding: 48px 0;
  }
  .sub-final-wrap .box-wrap .service-box {
    height: 288px;
  }
  /* 푸터 */
  .footer{padding: 16px 8px 20px;}
  .footer .foot_innerbox{
    gap: 0;
    justify-content: space-around;
  }
  .footer .bottom-flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer .bottom div p:nth-child(1), .footer .bottom div p:nth-child(2), .footer .bottom div p:nth-child(3){
    width: 58%;
  }
  .footer .bottom .bottom-flex  .foot-two{
    width: 30%;
  }
  .footer .bottom .bottom-flex  .foot-two > p{
    width: 100%;
  }
  .footer .foot-leftbox .leftbox-mid > ul > li:not(:first-child):not(:nth-child(4)):not(:nth-child(2)) {
    padding-left: 0px;
  }

  .footer .foot-leftbox .leftbox-mid > ul > li:nth-child(2) {
      padding-left: 16px;
  }
  .foot-rightbox .foot_right_ul > li > p {
    font-size: 12px;
  }
  .foot-rightbox .foot_right_ul > li > span {
    font-size: 12px;
}
}
@media (max-width: 950px) {
  /* .pick-home .application-card-wrap .service-box-wrap .service-left-box{
    width: 520px;
  }  */
  .pick-home .application-card-wrap .service-box-wrap{
    flex-direction: column;
  }
  .pick-home .brand-value-top .brand-left-box .brand-search {
    width: 80%;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox {
    left: 78%;
  }
}

@media (min-width: 769px) and (max-width:1023px){
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom{
    flex-direction: column;
    justify-content: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left {
    width: 100%;
    justify-content: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li{
    width: 45%;
    height: 160px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
    width: 100%;
    justify-content: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li{
    width: 90%;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox{
    padding: 20px 40px 20px 0;
  }
  .footer * {
    font-size: 12px;
  }
  .footer{
    height: auto;
  }
  .footer .foot_innerbox{
    flex-direction: column;
    align-items: flex-start;
  }
  .footer .foot-leftbox {
    width: 100%;
  }
  .footer .foot-leftbox .leftbox-mid > ul{
    margin-bottom: 0;
  }
  .foot-rightbox{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 0;
  }
  .foot-rightbox .foot_right_ul {
    display: flex;
  }
  .foot-rightbox .foot_right_ul > li{
    justify-content: flex-start;
  }
  .foot-rightbox .foot_right_ul > li > p {
    margin-right: 3px;
  }
  .foot-rightbox .foot-three{
    margin-bottom: 0;
    width: 20%;
    height: 70%;
  }
  .footer .foot_innerbox .pc_copylight{
    display: none;
  }
  .footer .foot_innerbox .mo_copylight{
    display: flex;
    border-top: 1px solid #323223;
    padding: 16px 0 4px;
    width: 100%;
  }
}
@media (min-width: 360px) and (max-width:768px){
  .img_w_100{
    width: 100%;
  } 
  .header-all-wrap {
    /* width: 360px; */
    width: 100%;
    padding: 0 20px;
  }
  .container .header > .header-wrap {
    padding: 0;
  }
  .brand-content-wrap.default {
    padding-top: 32px;
  }
  .container {
    display: flex;
    justify-content: center; /* 중앙 정렬 */
    align-items: center; /* 수직 정렬 (선택 사항) */
  }

  .content-wrap .pick-home {
    width: 100%; /* 기본적으로 화면 너비에 맞춤 */
    max-width: 360px; /* 최대 너비 360px */
    box-sizing: border-box; /* 패딩 포함 너비 계산 */
  }
  .brand-right-box{
    display: none;
  }
  .pick-home .brand-value-wrap {
    padding: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li {
    width: 174px;
    height: 207px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left {
    width: 100%;
    justify-content: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
    width: 100%;
    justify-content: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li{
    height: 72px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox {
    padding: 20px 40px 20px 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    width: 360px;
    flex-direction: column;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left {
    gap: 12px;
  }
  .pick-home .brand-value-top .brand-left-box .title-point {
    font-size: 36px;
  }
  .pick-home .brand-value-top .brand-left-box .mobile-partners-title{
    font-size: 36px;
    font-family: "PretendardB", "sans-serif";
  }
  .pick-home .brand-value-top .brand-left-box .partners-title {
    font-size: 30px;
    line-height: 36px;
  }
  .pc_d-none{
    display: flex;
    flex-direction: column;
  }
  .mo_d-none{
    display: none;
  }
  .partners-title {
    text-align: center;
    line-height: 1.5;
  }
  .partners-title .black-text {
    font-family: "PretendardB", "sans-serif";
    color: #050F2F;
    font-size: 36px;
  }
  .partners-title .sec-black-text {
    font-family: "PretendardB", "sans-serif";
    color: #050F2F;
    font-size: 30px;
  }
  .pick-home .brand-value-top .brand-left-box p{
    font-size: 16px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-search {
    width: 360px;
    height: 48px;
    font-size: 12px;
    font-weight: 400;
    padding: 17px 16px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox img{
    width: 19.5px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox {
    left: 0;
    position: static;
    display: block;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox button {
    padding: 3px;
    position: relative;
    left: 150px;
    top: -36px;
    margin-right: 8px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox .search-img-link{
    margin-top: 0;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox .search-img-link img{
    width: 18px;
  }
  .pick-home .brand-value-wrap .brand-value-top .brand-left-box{
    min-height: 0;
    padding-left: 0;
  }
  .pick-home .brand-value-wrap .brand-value-top{
    margin-bottom: 0;
  }
  .brand-value-top.default {
    padding-bottom: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3 {
    font-size: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li h3 {
    font-size: 14px;
    padding: 0 0 0 12px;
    letter-spacing: -0.5px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .brand-value-icon {
    width: 64px;
    height: 64px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .brand-value-icon {
    width: 40px;
    height: 40px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p {
    font-size: 12px;
    gap: 8px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox a > p > img{
    width: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 img{
    width: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li {
    padding: 0px 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox {
    padding:0;
    gap: 0;
  } 
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btnall {
    gap: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 p{
    gap: 4px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 > a > p{
    padding-right: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 > span > p{
    padding-right: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox .pc_only {
    display: none;
  }
  .pick-home .trademark-wrap .trademark-left .pc_only {
    display: none;
  }
  .pick-home .trademark-wrap .trademark-right .mo_only {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    gap: 50px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .mo_only {
    display: flex;
    justify-content: center;
    margin-top: 27px;
    width: 100%;
    /* border-top: 1px solid #CCCCCCCC; */
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .mo_only > p {
    padding-top: 10px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox{
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .brand-value-icon {
    position: relative;
    top: 17px;
    left: 73px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p > img {
    width: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li {
    padding: 24px 16px 0 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-navbox {
    padding: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox p:first-child{
    padding-right: 0;
    padding-top: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3 {
    margin-bottom: 16px;
  }
  .pick-home .trademark-wrap .trademark-contentall{
    flex-direction: column;
  }
  .progress-content02{
    height: 196px;
    width: 208px;
  }
  .marquee02 {
    height: 100%;
    gap: 7px;
  }
  .marquee__group02 {
    gap: 7px;
  }
  .pick-home .trademark-wrap p{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .trademark-wrap .partners-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    display: flex;
    justify-content: center;
  }
  .pick-home .trademark-wrap .partners-title .title-point {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    margin-left: 6px;
  }
  .pick-home .title-point02 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
  }
  .pick-home .trademark-wrap .numtype_p {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    letter-spacing: -1px;
    min-width:94px ;
  }
  .pick-home .trademark-wrap .numtype_p > small{
    top: -9px;
    position: relative;
    margin-left: 6px;
  }
  .pick-home .trademark-wrap .trademark-right {
    width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 196px;
  }
  .pick-home .trademark-wrap .partners-title {
    margin-bottom: 48px;
  }
  .pick-home .service-type-wrap p{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .service-type-wrap .partners-title{
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .pick-home .service-type-wrap li h3{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .pick-home .service-type-wrap li img{
    width: 40px;
    height: 40px;
  }
  .main-price-info-wrap .sub-title-point{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .price-info-wrap .partners-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
  }
.mo_subprice_table {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 768px;
  }
.mo_subprice_table .touch{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;
  }
.mo_subprice_table .touch p{
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    color: #858585;
  }
.mo_subprice_table .touch_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.mo_subprice_table .touch_btn .mo_plans_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;
  }
  .main-price-info-wrap .pricetable-wrap .title_p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    margin: 0;
  }
  .price-info-wrap .partners-title {
    margin-bottom: 12px;
  }
  .price-info-wrap, .main-price-wrap {
    padding: 35px 0;
  }
  .pick-home .application-card-wrap .service-box-wrap {
    gap: 16px;
  }
  .pick-home .application-card-wrap p {
    font-size: 11px;
    font-weight: 700;
    line-height: 15.4px;
  }
  .pick-home .application-home-title{
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .pick-home .application-card-wrap .title-sub-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    margin-bottom: 40px;
  }
  .pick-home .application-card-wrap .title-sub-text .mo_only {
    display: block;
  }
  .pick-home .recommend-wrap {
    padding: 48px 0px;
  }
  .pick-home .recommend-wrap p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .recommend-wrap .partners-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .pick-home .recommend-wrap .partners-title {
    margin: 12px 0 40px;
  }
  .pick-home .recommend-wrap ul li:nth-child(1) {
    width: 360px;
    height: 298px;
  }
  .pick-home .recommend-wrap ul li {
    width: 360px;
    height: 330px;
  }
  .pick-home .recommend-wrap ul {
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: start;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 .need_p_mb {
    margin-bottom: 10px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 span{
    color: #666666;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    margin-left: 30px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 {
    min-height: 0;
    margin-top: 12px;
  }
  .pick-home .recommend-wrap .recommend-textBox02 h5 {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 12px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: start;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p span {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    margin-left: 34px;
  }
  .pick-home .recommend-wrap .recommend-textBox02 {
    padding: 8px 0;
    width: 94%;
    margin-top: 16px;
  }
  .pick-home .recommend-wrap ul>li>.recommend-textBox01>p::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #666666;
    position: absolute;
    top: 42%;
    left: 5%;
  }
  .pick-home .recommend-wrap .recommend-textBox02 p {
    color: #050F2F;
    font-size: 15px;
    line-height: 150%;
    margin-bottom: 8px;
    word-break: keep-all;
  }
  .pick-home .recommend-wrap .recommend-textBox02 p::after {
    display: flex;
    content: "";
    width: 5px;
    height: 10px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: transparent #319DFF #319DFF transparent;
    position: absolute;
    top: 34%;
    left: 6%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .pick-home .recommend-wrap h3 {
    font-size: 18px;
  }
  .pick-home .recommend-wrap ul li {
    padding: 24px;
  }
  .pick-home .recommend-wrap ul li .mo_flexible{
    display: flex;
    align-items: center;
  }
  .pick-home .recommend-wrap ul li .imgsize {
    width: 48px;
    height: 48px;
  }
  .pick-home .recommend-wrap ul li .mo_flexible h3{
    margin-left: 16px;
  }
  .pick-home .recommend-wrap .recommend-textBox02 img {
    top: 6px;
  }
  .pick-home .main-news-wrap p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    margin-bottom: 12px;
  }
  .pick-home .main-news-wrap .partners-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    margin-bottom: 40px;
  }
  .pick-home .main-news-wrap ul {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .pick-home .main-news-wrap .main-news-content > ul > li {
    width: 360px;
    height: 77px;
    flex-direction: row;
  }
  .pick-home .main-news-wrap ul li .newimgall {
    padding: 0;
    width: 120px;
    height: 77px;
    border-radius: 8px 0 0 8px;
  }
  .pick-home .main-news-wrap ul li span {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .main-news-wrap ul li h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    -webkit-line-clamp: 1;
  }
  .pc_only {
    display: none;
  }
  .pick-home .main-news-wrap ul li .newsbox {
    padding: 16px 16px 20px;
    width: 240px;
  }
  .pick-home .progress-content-wrap p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .progress-content-wrap .partners-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    margin: 12px 0 40px;
  }
  .marquee__group {
    gap: 16px;
    margin-right: 0;
    width: 100% !important;
    /* flex-shrink: 1; */
  }
  .marquee {
    gap: 16px;
    width: 768px;
  }
  .pick-home .progress-content-wrap .progress-content img {
    width: 180px;
  }
  .progress-content {
    gap: 16px;
  }
  .pick-home .final-title-wrap h5 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    margin-bottom: 24px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 h5 span {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 .main-final-move {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    width: 195px;
    height: 48px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 .main-final-move img {
    width: 16px;
    height: 16px;
  }
  .sub-final-wrap .box-wrap .service-box .service-text h4 {
    font-size: 22px !important;
    font-weight: 700;
    line-height: 30.8px;
    margin-bottom: 12px !important;
  }
  .sub-final-wrap .box-wrap .service-box .service-text span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
  }
  .sub-final-wrap .box-wrap .service-box {
    height: 228px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pick-home .box-wrap .service-box .service-text{
    margin-bottom: 24px;
  }
  .sub-final-wrap .box-wrap .service-box .service-link {
    width: 153px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .sub-final-wrap .box-wrap .service-box .service-link a {
    font-family: "Pretendard", "sans-serif";
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    align-items: center;
  }
  .footer {
    height: 100%;
  }
  .foot-rightbox {
    width: 100%;
    border-bottom: 1px solid #323223;
    margin-bottom: 12px;
  }
  .foot-rightbox .pc_only {
    display: none;
  }
  .footer .foot_innerbox .mo_copylight {
    display: block;
    width: 100%;
  }
  .footer .foot-leftbox .pc_copylight {
    display: none;
  }
  /* .foot-rightbox .foot_right_ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    padding: 0;
    margin: 0;
  } */
  .foot-rightbox .foot_right_ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "customer email"
      "fax officer";
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(1) {
    grid-area: customer;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(2) {
    grid-area: fax;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(3) {
    grid-area: email;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(4) {
    grid-area: officer;
  }
  .foot-rightbox .foot_right_ul > li {
    margin-bottom: 6px;
  }
  .foot-rightbox .foot_right_ul > li:nth-child(3),
  .foot-rightbox .foot_right_ul > li:nth-child(4) {
    margin-bottom: 12px;
  }
  .foot-rightbox .foot_right_ul > li > p {
    font-size: 11px;
    font-weight: 700;
    line-height: 15.4px;
  }
  .foot-rightbox .foot_right_ul > li > span {
    font-size: 11px;
    font-weight: 400;
    line-height: 15.4px;
  }
  .footer .foot_innerbox .mo_copylight p {
    font-size: 11px;
  }
  .sub-final-wrap .box-wrap .service-box .service-link a img {
    width: 16px;
    height: 16px;
    margin-left: 12px;
  }
  .footer .foot_innerbox {
    flex-direction: column;
    width: 360px;
  }
  .footer .foot-leftbox {
    width: 100%;
  }
  .footer .foot-leftbox .leftbox-top {
    padding-bottom: 12px;
  }
  .footer .foot-leftbox .leftbox-mid > ul {
    margin-bottom: 12px;
  }
  .footer .foot-leftbox .leftbox-mid > ul > li {
    font-size: 11px;
  }
  .footer .foot-leftbox .leftbox-mid > ul > li:nth-child(2) {
    border-right:0;
  }
  .footer .foot-leftbox .leftbox-mid > ul > li:nth-child(4) {
    border-right:0;
  }
  .Cost-Wrap .price-info-wrap .price-info-title > th h3 {
    font-size: 17px;
  }
  .price-info-wrap .check-shapeAll {
    display: none;
  }
  .price-info-wrap .price-position .table-img {
    top: 0;
  }
  .price-info-wrap .price-position .table-img02 {
    top: 0px;
  }
  .price-info-wrap .price-info-title>th {
    padding: 5px 5px 5px;
  }


 /* cost 반응형 */
  .Cost-Wrap .price-radio-wrap div {
    display: flex;
    gap: 0px;
    align-items: center;
  }
  
  .Cost-Wrap .price-info-title > th > p:not(.price-font) {
    font-size: 11px;
    padding: 12px 0;
  }

  .Cost-Wrap .price-info-title > th > .price-info-box {
    margin-top: 12px;
  }

  .Cost-Wrap .price-font {
    font-size: 16px;
  }

  .Cost-Wrap .price-day {
    font-size: 14px;
  }

  .Cost-Wrap .pricetable-wrap .subprice_table .price-info-title > th {
    padding: 24px 12px 18px;
  }

  .Cost-Wrap .subprice_table tr > td:first-child {
    padding: 8px 0px;
    font-size: 11px;
  }

  .Cost-Wrap .price-radio-wrap label {
    font-size: 12px;
  }

  .Cost-Wrap .table-text {
    font-size: 14px;
  }

  .Cost-Wrap .table-text-last {
    font-size: 14px;
  }
  .Cost-Wrap .costHead h2 {
    font-size: 24px;
  }
  .Cost-Wrap .costHead span {
    font-size: 16px;
    margin: 0 0 40px 0; 
  }
  .price-info-wrap .costWrap {
    height: 100%;
  } 
  .Cost-Wrap .costHead h2 {
    font-size: 24px;
  }
  .Cost-Wrap .cost-detail-wrap01 .row {
    flex-direction: column;
  }
  .Cost-Wrap .cost-detail-wrap01 .tabs {
    background: transparent;
  }
  .Cost-Wrap .cost-detail-wrap01 .description-header01 h3 {
    font-size: 13px;
  }
  .Cost-Wrap .cost-detail-wrap01 table thead th {
    height: 40px;
  }
  .Cost-Wrap .cost-detail-wrap01 .cost-guide h5 {
    font-size: 13px;
  }
  .Cost-Wrap .cost-detail-wrap01 .cost-guide h5 > span.text-under {
    font-size: 15px;
  }
  .Cost-Wrap .cost-detail-wrap01 td {
    /* padding: 0; */
  }
  .text-end {
    text-align: center;
  }
  .Cost-Wrap .cost-detail-wrap01 table tbody tr td:first-child {
    font-size: 13px;
  } 
  .Cost-Wrap .cost-detail-wrap01 table td .row span{
    font-size: 13px;
  }
  .Cost-Wrap .cost-detail-wrap01 td {
    font-size: 13px;
  }
  .Cost-Wrap .cost-detail-wrap01 .totalSpan span {
    font-size: 13px;
    text-align: center;
  }
  .Cost-Wrap .cost-detail-wrap01 table thead th {
    font-size: 16px;
  }
  .price-info-wrap table caption {
    font-size: 13px;
  }


  .pick-home .hovered-3 .brand-left-box .partners-title {
    margin-bottom: 10px;
  }
  .pick-home .hovered-5 .brand-left-box .partners-title {
    margin-bottom: 10px;
  }
}

@media (min-width: 320px) and (max-width:359px){
  .header-all-wrap {
    width: 360px;
  }
  .container .header > .header-wrap {
    padding: 0;
  }
  .brand-content-wrap.default {
    padding-top: 32px;
  }
  .container {
    display: flex;
    justify-content: center; /* 중앙 정렬 */
    align-items: center; /* 수직 정렬 (선택 사항) */
  }

  .content-wrap .pick-home {
    width: 100%; /* 기본적으로 화면 너비에 맞춤 */
    max-width: 360px; /* 최대 너비 360px */
    box-sizing: border-box; /* 패딩 포함 너비 계산 */
  }
  .brand-right-box{
    display: none;
  }
  .pick-home .brand-value-wrap {
    padding: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li {
    width: 174px;
    height: 207px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left {
    width: 100%;
    justify-content: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
    width: 100%;
    justify-content: center;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li{
    height: 72px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox {
    padding: 20px 40px 20px 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    width: 360px;
    flex-direction: column;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left {
    gap: 12px;
  }
  .pick-home .brand-value-top .brand-left-box .title-point {
    font-size: 36px;
  }
  .pick-home .brand-value-top .brand-left-box .mobile-partners-title{
    font-size: 36px;
    font-family: "PretendardB", "sans-serif";
  }
  .pick-home .brand-value-top .brand-left-box .partners-title {
    font-size: 30px;
    line-height: 36px;
  }
  .pc_d-none{
    display: flex;
    flex-direction: column;
  }
  .mo_d-none{
    display: none;
  }
  .partners-title {
    text-align: center;
    line-height: 1.5;
  }
  .partners-title .black-text {
    font-family: "PretendardB", "sans-serif";
    color: #050F2F;
    font-size: 36px;
  }
  .partners-title .sec-black-text {
    font-family: "PretendardB", "sans-serif";
    color: #050F2F;
    font-size: 30px;
  }
  .pick-home .brand-value-top .brand-left-box p{
    font-size: 16px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-search {
    width: 360px;
    height: 48px;
    font-size: 12px;
    font-weight: 400;
    padding: 17px 16px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox img{
    width: 19.5px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox {
    left: 0;
    position: static;
    display: block;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox button {
    padding: 3px;
    position: relative;
    left: 150px;
    top: -36px;
    margin-right: 8px;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox .search-img-link{
    margin-top: 0;
  }
  .pick-home .brand-value-top .brand-left-box .brand-iconBox .search-img-link img{
    width: 18px;
  }
  .pick-home .brand-value-wrap .brand-value-top .brand-left-box{
    min-height: 0;
  }
  .pick-home .brand-value-wrap .brand-value-top{
    margin-bottom: 0;
  }
  .brand-value-top.default {
    padding-bottom: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3 {
    font-size: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li h3 {
    font-size: 14px;
    padding: 0 0 0 12px;
    letter-spacing: -0.5px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .brand-value-icon {
    width: 64px;
    height: 64px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .brand-value-icon {
    width: 40px;
    height: 40px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p {
    font-size: 12px;
    gap: 8px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox a > p > img{
    width: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 img{
    width: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li {
    padding: 0px 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox {
    padding:0;
    gap: 0;
  } 
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btnall {
    gap: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 p{
    gap: 4px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 > a > p{
    padding-right: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 > span > p{
    padding-right: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox .pc_only {
    display: none;
  }
  .pick-home .trademark-wrap .trademark-left .pc_only {
    display: none;
  }
  .pick-home .trademark-wrap .trademark-right .mo_only {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    gap: 50px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .mo_only {
    display: flex;
    justify-content: center;
    margin-top: 27px;
    width: 100%;
    /* border-top: 1px solid #CCCCCCCC; */
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .mo_only > p {
    padding-top: 10px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox{
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .brand-value-icon {
    position: relative;
    top: 17px;
    left: 73px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li p > img {
    width: 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li {
    padding: 24px 16px 0 16px;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-left li .main-navbox {
    padding: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li .main_nav_btnbox p:first-child{
    padding-right: 0;
    padding-top: 0;
  }
  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom li h3 {
    margin-bottom: 16px;
  }
  .pick-home .trademark-wrap .trademark-contentall{
    flex-direction: column;
  }
  .progress-content02{
    height: 196px;
    width: 208px;
  }
  .marquee02 {
    height: 100%;
    gap: 7px;
  }
  .marquee__group02 {
    gap: 7px;
  }
  .pick-home .trademark-wrap p{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .trademark-wrap .partners-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    display: flex;
    justify-content: center;
  }
  .pick-home .trademark-wrap .partners-title .title-point {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    margin-left: 6px;
  }
  .pick-home .title-point02 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
  }
  .pick-home .trademark-wrap .numtype_p {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    letter-spacing: -1px;
  }
  .pick-home .trademark-wrap .numtype_p > small{
    top: -9px;
    position: relative;
    margin-left: 6px;
  }
  .pick-home .trademark-wrap .trademark-right {
    width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 196px;
  }
  .pick-home .trademark-wrap .partners-title {
    margin-bottom: 48px;
  }
  .pick-home .service-type-wrap p{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .service-type-wrap .partners-title{
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .pick-home .service-type-wrap li h3{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .pick-home .service-type-wrap li img{
    width: 40px;
    height: 40px;
  }
  .main-price-info-wrap .sub-title-point{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .price-info-wrap .partners-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
  }
  .main-price-info-wrap .mo_subprice_table {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .main-price-info-wrap .mo_subprice_table .touch{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;
  }
  .main-price-info-wrap .mo_subprice_table .touch p{
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    color: #858585;
  }
  .main-price-info-wrap .mo_subprice_table .touch_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-price-info-wrap .mo_subprice_table .touch_btn .mo_plans_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;
  }
  .main-price-info-wrap .pricetable-wrap .title_p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    margin: 0;
  }
  .price-info-wrap .partners-title {
    margin-bottom: 12px;
  }
  .price-info-wrap, .main-price-wrap {
    padding: 48px 0;
  }
  .pick-home .application-card-wrap .service-box-wrap {
    gap: 16px;
  }
  .pick-home .application-card-wrap p {
    font-size: 11px;
    font-weight: 700;
    line-height: 15.4px;
  }
  .pick-home .application-home-title{
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .pick-home .application-card-wrap .title-sub-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    margin-bottom: 40px;
  }
  .pick-home .application-card-wrap .title-sub-text .mo_only {
    display: block;
  }
  .pick-home .recommend-wrap {
    padding: 48px 0px;
  }
  .pick-home .recommend-wrap p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .recommend-wrap .partners-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .pick-home .recommend-wrap .partners-title {
    margin: 12px 0 40px;
  }
  .pick-home .recommend-wrap ul li:nth-child(1) {
    width: 360px;
    height: 298px;
  }
  .pick-home .recommend-wrap ul li {
    width: 360px;
    height: 330px;
  }
  .pick-home .recommend-wrap ul {
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: start;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 .need_p_mb {
    margin-bottom: 10px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 span{
    color: #666666;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    margin-left: 30px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox01 {
    min-height: 0;
    margin-top: 12px;
  }
  .pick-home .recommend-wrap .recommend-textBox02 h5 {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 12px;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: start;
  }
  .pick-home .recommend-wrap ul li .recommend-textBox02 p span {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    margin-left: 34px;
  }
  .pick-home .recommend-wrap .recommend-textBox02 {
    padding: 8px 0;
    width: 94%;
    margin-top: 16px;
  }
  .pick-home .recommend-wrap ul>li>.recommend-textBox01>p::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #666666;
    position: absolute;
    top: 42%;
    left: 5%;
  }
  .pick-home .recommend-wrap .recommend-textBox02 p {
    color: #050F2F;
    font-size: 15px;
    line-height: 150%;
    margin-bottom: 8px;
    word-break: keep-all;
  }
  .pick-home .recommend-wrap .recommend-textBox02 p::after {
    display: flex;
    content: "";
    width: 5px;
    height: 10px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: transparent #319DFF #319DFF transparent;
    position: absolute;
    top: 34%;
    left: 6%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .pick-home .recommend-wrap h3 {
    font-size: 18px;
  }
  .pick-home .recommend-wrap ul li {
    padding: 24px;
  }
  .pick-home .recommend-wrap ul li .mo_flexible{
    display: flex;
    align-items: center;
  }
  .pick-home .recommend-wrap ul li .imgsize {
    width: 48px;
    height: 48px;
  }
  .pick-home .recommend-wrap ul li .mo_flexible h3{
    margin-left: 16px;
  }
  .pick-home .recommend-wrap .recommend-textBox02 img {
    top: 6px;
  }
  .pick-home .main-news-wrap p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    margin-bottom: 12px;
  }
  .pick-home .main-news-wrap .partners-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    margin-bottom: 40px;
  }
  .pick-home .main-news-wrap ul {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .pick-home .main-news-wrap .main-news-content > ul > li {
    width: 360px;
    height: 77px;
    flex-direction: row;
  }
  .pick-home .main-news-wrap ul li .newimgall {
    padding: 0;
    width: 120px;
    height: 77px;
    border-radius: 8px 0 0 8px;
  }
  .pick-home .main-news-wrap ul li span {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .main-news-wrap ul li h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    -webkit-line-clamp: 1;
  }
  
  .pc_only {
    display: none;
  }
  .pick-home .main-news-wrap ul li .newsbox {
    padding: 16px 16px 20px;
    width: 240px;
  }
  .pick-home .progress-content-wrap p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
  }
  .pick-home .progress-content-wrap .partners-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    margin: 12px 0 40px;
  }
  .marquee__group {
    gap: 16px;
    margin-right: 0;
    width: 100% !important;
    /* flex-shrink: 1; */
  }
  .marquee {
    gap: 16px;
    width: 768px;
  }
  .pick-home .progress-content-wrap .progress-content img {
    width: 180px;
  }
  .progress-content {
    gap: 16px;
  }
  .pick-home .final-title-wrap h5 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    margin-bottom: 24px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 h5 span {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 .main-final-move {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    width: 195px;
    height: 48px;
  }
  .sub-final-wrap .final-title-wrap .main-final-top01 .main-final-move img {
    width: 16px;
    height: 16px;
  }
  .sub-final-wrap .box-wrap .service-box .service-text h4 {
    font-size: 22px !important;
    font-weight: 700;
    line-height: 30.8px;
    margin-bottom: 12px !important;
  }
  .sub-final-wrap .box-wrap .service-box .service-text span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
  }
  .sub-final-wrap .box-wrap .service-box {
    height: 228px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pick-home .box-wrap .service-box .service-text{
    margin-bottom: 24px;
  }
  .sub-final-wrap .box-wrap .service-box .service-link {
    width: 153px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .sub-final-wrap .box-wrap .service-box .service-link a {
    font-family: "Pretendard", "sans-serif";
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    align-items: center;
  }
  .footer {
    height: 100%;
  }
  .foot-rightbox {
    width: 100%;
    border-bottom: 1px solid #323223;
    margin-bottom: 12px;
  }
  .foot-rightbox .pc_only {
    display: none;
  }
  .footer .foot_innerbox .mo_copylight {
    display: block;
    width: 100%;
  }
  .footer .foot-leftbox .pc_copylight {
    display: none;
  }
  /* .foot-rightbox .foot_right_ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    padding: 0;
    margin: 0;
  } */
  .foot-rightbox .foot_right_ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "customer email"
      "fax officer";
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(1) {
    grid-area: customer;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(2) {
    grid-area: fax;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(3) {
    grid-area: email;
  }
  
  .foot-rightbox .foot_right_ul > li:nth-child(4) {
    grid-area: officer;
  }
  .foot-rightbox .foot_right_ul > li {
    margin-bottom: 6px;
  }
  .foot-rightbox .foot_right_ul > li:nth-child(3),
  .foot-rightbox .foot_right_ul > li:nth-child(4) {
    margin-bottom: 12px;
  }
  .foot-rightbox .foot_right_ul > li > p {
    font-size: 11px;
    font-weight: 700;
    line-height: 15.4px;
  }
  .foot-rightbox .foot_right_ul > li > span {
    font-size: 11px;
    font-weight: 400;
    line-height: 15.4px;
  }
  .footer .foot_innerbox .mo_copylight p {
    font-size: 11px;
  }
  .sub-final-wrap .box-wrap .service-box .service-link a img {
    width: 16px;
    height: 16px;
    margin-left: 12px;
  }
  .footer .foot_innerbox {
    flex-direction: column;
    width: 360px;
  }
  .footer .foot-leftbox {
    width: 100%;
  }
  .footer .foot-leftbox .leftbox-top {
    padding-bottom: 12px;
  }
  .footer .foot-leftbox .leftbox-mid > ul {
    margin-bottom: 12px;
  }
  .footer .foot-leftbox .leftbox-mid > ul > li {
    font-size: 11px;
  }
   /* cost 반응형 */
   .Cost-Wrap .price-radio-wrap div {
    display: flex;
    gap: 0px;
    align-items: center;
  }
  
  .Cost-Wrap .price-info-title > th > p:not(.price-font) {
    font-size: 11px;
    padding: 12px 0;
  }

  .Cost-Wrap .price-info-title > th > .price-info-box {
    margin-top: 12px;
  }

  .Cost-Wrap .price-font {
    font-size: 16px;
  }

  .Cost-Wrap .price-day {
    font-size: 14px;
  }

  .Cost-Wrap .pricetable-wrap .subprice_table .price-info-title > th {
    padding: 24px 12px 18px;
  }

  .Cost-Wrap .subprice_table tr > td:first-child {
    padding: 8px 0px;
    font-size: 11px;
  }

  .Cost-Wrap .price-radio-wrap label {
    font-size: 12px;
  }

  .Cost-Wrap .table-text {
    font-size: 14px;
  }

  .Cost-Wrap .table-text-last {
    font-size: 14px;
  }
}
