.brandnaming-wrap {
  width: 100vw;
  background-color: #F6F6F6;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 70vh;
}

.brandnaming-wrap main {
  margin: 100px auto 100px auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
}


.brandnaming-wrap main .brandInput-wrap {
  width: 820px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.brandnaming-wrap main .brandInput-wrap div {
  background-color: white;
}

.brandnaming-wrap main .brandInput-wrap .keyword-wrap {
  width: 100%;
  padding: 24px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

.brandnaming-wrap main .brandInput-wrap h2 {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
}

.brandnaming-wrap main .brandInput-wrap .keyword-wrap input {
  width: 100%;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  height: 40px;
  font-size: 14px;
  margin-top: 14px;
  padding: 11.5px 16px;
}

.brandnaming-wrap main .brandInput-wrap .keyword-wrap input::placeholder {
  color: #BBBBBB;
}

.brandnaming-wrap main .brandInput-wrap .choice-wrap {
  width: 100%;
  padding: 24px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: flex;
  gap: 32px;
}


.brandnaming-wrap main .brandInput-wrap .choice-wrap .ai-wrap {
  width: 320px;
}

.brandnaming-wrap main .brandInput-wrap .choice-wrap .title-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.brandnaming-wrap main .brandInput-wrap .choice-wrap .ai-wrap .title-wrap img {
  width: 14px;
  height: 14px;
}

.brandnaming-wrap main .brandInput-wrap .button-list,
.button-wrap .type-btn-wrap {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.brandnaming-wrap main .brandInput-wrap .button-list button,
.button-wrap .type-btn-wrap button  {
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  font-size: 12px;
  height: 32px;
}

.brandnaming-wrap main .brandInput-wrap .choice-wrap .ai-wrap .button-list button {
  width: 156px;
}

.brandnaming-wrap main .brandInput-wrap .choice-wrap .strength-wrap .button-list button {
  width: 123.67px;
}

.brandnaming-wrap main .brandInput-wrap .button-list .active,
.button-wrap .type-btn-wrap button .active {
  border: 1px solid #319dff !important;
  font-family: "PretendardSB", "sans-serif";
  color: #319dff;
  background-color: #F2F8FD;
}

.brandnaming-wrap main .product-wrap .list .list-item strong {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
}

.brandnaming-wrap main .brandInput-wrap .choice-wrap .strength-wrap {
  width: 387px;
}

.brandnaming-wrap main .brandInput-wrap .choice-wrap .divider {
  width: 1px;
  height: 63px;
  background: #e4e4e4;
}

.brandnaming-wrap main .brandInput-wrap .concept-wrap {
  padding: 24px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

.brandnaming-wrap main .brandInput-wrap .concept-wrap .button-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 16px;
}

.brandnaming-wrap main .brandInput-wrap .concept-wrap .button-list button {
  border: 1px solid #e4e4e4;
  padding: 0 12px;
  font-size: 12px;
  height: 32px;
  border-radius: 4px;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap {
  padding: 24px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

.product-wrap .warn-text {
  display: flex;
  gap: 4px;
  margin-top: 6px;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .warn-text img {
  width: 16px;
}
 .product-wrap .warn-text p {
  color: #585858;
  font-size: 11px;
  line-height: 140%;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .button-list button,
.button-wrap .type-btn-wrap button {
  padding: 0 16px;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .select-list,
.possibility-step2 .select-list
 {
  display: flex;
  gap: 20px;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item,
.possibility-step2 .select-list .select-item {
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 5px;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item strong,
.possibility-step2 .select-list .select-item strong {
  font-size: 12px;
  font-family: "PretendardSB", "sans-serif";
  color: #585858;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item p,
.possibility-step2 .select-list .select-item p {
  font-size: 12px;
  max-width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #585858;
}

.possibility-step2 .select-list .select-item p {
  max-width: 180px;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item img,
.possibility-step2 .select-list .select-item img {
  width: 20px;
  cursor: pointer;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .input-wrap {
  display: flex;
  margin-top: 16px;
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .input-wrap input {
  padding: 0 16px;
  width: 662px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  font-family: "PretendardSB", "sans-serif";
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .input-wrap input::placeholder {
  font-size: 14px;
  color: #BBBBBB;
  font-family: "PretendardR", "sans-serif";
}

.brandnaming-wrap main .brandInput-wrap .product-wrap .input-wrap button{
  background-color: #319dff;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  color: white;
  font-family: "PretendardSB", "sans-serif";
  margin-left: 8px;
}

.brandnaming-wrap main .product-wrap .list .list-item .list-preview {
  background-color: transparent;
}

.brandnaming-wrap main .product-wrap .list .list-item .list-preview div {
  background-color: transparent;
}

.brandnaming-wrap main .brandInput-wrap .next-btn {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  color: white;
  background-color: #319dff;
  font-family: "PretendardSB", "sans-serif";
  margin-top: 8px;
}

.brandnaming-wrap main .brandResult-wrap .keyword-wrap {
  background-color: white;
  width: 820px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  padding: 24px;
}

.brandnaming-wrap main .brandResult-wrap .keyword-wrap h2 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px
}

.brandnaming-wrap main .brandResult-wrap .keyword-wrap h3 {
  margin-top: 16px;
  font-family: "PretendardB", "sans-serif";
  font-size: 22px;
}

.brandnaming-wrap main .brandResult-wrap .keyword-wrap .concepts-wrap {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  flex-wrap: wrap;
}

.brandnaming-wrap main .brandResult-wrap .keyword-wrap .concepts-wrap article {
  padding: 9px 12px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  font-size: 12px;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap {
  display: flex;
  margin: 8px auto 40px auto;
  flex-wrap: wrap;
  width: 820px;
  gap: 8px;
}


.brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version,
.brandnaming-wrap main .brandResult-wrap .result-wrap .common-version {
  width: 268px;
  height: 222px;
  border-radius: 4px;
  padding: 24px;
  border: 1px solid #EAEAEA;
  background-color: #e9baba;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version .title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #EEEEEE;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version .title-wrap h4 {
  font-family: "PretendardB", "sans-serif";
  font-size: 22px;
}


.brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version .title-wrap img {
  width: 24px;
  cursor: pointer;
}


.brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version .description {
  margin: 16px 0;
  height: 63px;
  text-overflow: ellipsis;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}


.brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version button,
.brandnaming-wrap main .brandResult-wrap .result-wrap .common-version button {
  border-top: 1px solid #EEEEEE;
  padding-top: 16px;
  width: 100%;
  text-decoration: underline;
  color: #585858;
}

.brandnaming-wrap main .brandResult-wrap .go-first {
  width: 100%;
  border-radius: 4px;
  height: 56px;
  border: 1px solid #319dff;
  background-color: white;
  color: #319dff;
  font-family: "PretendardSB", "sans-serif";
}

.brandnaming-wrap main .brandResult-wrap .result-wrap article:hover {
  border: 2px solid #319dff;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap article:hover button {
  color: #319dff;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap article:hover h4 {
  color: #319dff;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap .common-version {
  position: relative;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap .common-version img {
  position: absolute;
  top: 25px;
  right: 24px;
}

.brandnaming-wrap main .brandResult-wrap .result-wrap .common-version h4 {
  height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PretendardB", "sans-serif";
  font-size: 22px;
}

.brandnaming-wrap main .brandResult-wrap .button-list {
  display: flex;
  gap: 8px;
}

.brandnaming-wrap main .brandResult-wrap .button-list button {
  width: 406px;
  height: 56px;
  border-radius: 4px;
  font-family: "PretendardSB", "sans-serif";
  border: 1px solid #319dff;
}

.brandnaming-wrap main .brandResult-wrap .button-list button:first-child {
  background-color: white;
  color: #319dff;
}


.brandnaming-wrap main .brandResult-wrap .button-list button:last-child {
  background-color: #319dff;
  color: white;
}


/* 모달 스타일 시작 */
.brandnaming-wrap .modal-bg .brand-name {
  width: 760px;
  padding: 24px;
  gap: 20px;
  overflow: auto;
  overflow-x: hidden;
}

.brandnaming-wrap .modal-bg .brand-name .modal-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.brandnaming-wrap .modal-bg .brand-name .modal-title h4 {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
}

.brandnaming-wrap .modal-bg .brand-name .modal-title img {
  width: 24px;
}

.brandnaming-wrap .modal-bg .brand-name .keyword-wrap {
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #FFFDF9;
  padding: 16px 24px;
}

.brandnaming-wrap .modal-bg .brand-name .keyword-wrap h3 {
  font-family: "PretendardB", "sans-serif";
  font-size: 22px;
}

.brandnaming-wrap .modal-bg .brand-name .keyword-wrap div {
  display: flex;
  justify-content: space-between;
}

.brandnaming-wrap .modal-bg .brand-name .keyword-wrap hr {
  background-color: #eee;
  border: none;
  height: 1px;
  margin: 12px 0;
}

.brandnaming-wrap .modal-bg .brand-name .keyword-wrap p {
  font-size: 14px;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .keyword-wrap img {
  width: 24px;
}

.brandnaming-wrap .modal-bg .brand-name .tab-list {
  height: 40px;
  width: 100%;
  display: flex;
  gap: 8px;
}

.brandnaming-wrap .modal-bg .brand-name .tab-list button {
  width: 172px;
  height: 40px;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
}

.brandnaming-wrap .modal-bg .brand-name .tab-list button.active {
  background-color: #F2F8FD;
  border: 1px solid #319dff;
  color: #319dff;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap {
  width: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 8px;
  height: auto;
  align-items: flex-start;
  flex-direction: row;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section {
  width: 352px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 16px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .first-warn-wrap {
  display: flex;
  gap: 4px;
  align-items: center;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .first-warn-wrap img {
  width: 16px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .first-warn-wrap p {
  color: #585858;
  line-height: 140%;
  font-size: 11px;
  white-space: nowrap;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap hr {
  border: none;
  background-color: #E5E5E5;
  height: 1px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .first-warn-wrap strong {
  color: #585858;
  font-family: "PretendardSB", "sans-serif";
  font-size: 11px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .choose-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .choose-wrap div {
  display: flex;
  align-items: center;
  gap: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .choose-wrap h4,
.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section h4 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .choose-wrap span {
  font-size: 12px;
  line-height: 140%;
  color: #858585;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .choose-wrap p {
  color: #585858;
  font-size: 12px;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .choose-wrap select {
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  font-size: 10px;
  width: 57px;
  height: 18px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .second-warn-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .second-warn-wrap img {
  width: 24px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .second-warn-wrap p {
  font-size: 12px;
  color: #585858;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .second-warn-wrap strong {
  font-size: 13px;
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
  color: #585858;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin: 12px 0;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap p {
  font-size: 16px;
  line-height: 140%
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap .result {
  font-size: 16px;
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
  color: #1150CB;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap .divider {
  width: 2px;
  height: 25px;
  background-color: #DDDDDD;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap .result.red {
  color: #E4423C;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section {
  width: 352px;
  height: auto;
  border: 1px solid #eaeaea;
  padding: 20px 16px;
  border-radius: 4px;

}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .tab-list {
  margin: 10px 0;
  height: 30px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .tab-list button {
  height: 30px;
  width: 100px;
  font-size: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .tab-list button.active {
  background-color: #F2F8FD;
  border: 1px solid #319dff;
  color: #319dff;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section ul {
  list-style-type: disc;
  margin-left: 25px;
}


.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section ul li {
  font-size: 12px;
  color: #333333;
  line-height: 140%;
}


.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 156px;
  height: 156px;
  flex-direction: column;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item.min {
  border-radius: 5px;
  width: 100px;
  height: 120px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item .img-box {
  height: 125px;
  background-color: white;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item.min .img-box {
  height: 80px;
  background-color: white;
  border-radius: 4px 4px 0 0;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item img {
  width: 109px;
  height: 109px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item.min img {
  width: 70px;
  height: 70px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item .badge-list {
  display: flex;
  height: 31px;
  background-color: #f4f4f4;
  border-radius: 0 0 8px 8px;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item.min .badge-list-small {
  height: 40px;
  border-radius: 0 0 4px 4px;
  padding: 0 6px;
  background-color: #f4f4f4
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item .badge-list span {
  background-color: #319dff;
  font-size: 11px;
  padding: 2px 5px;
  font-family: "PretendardM", "sans-serif";
  border-radius: 2px;
  height: 16px;
  color: white;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item.min .badge-list-small span {
  background-color: #319dff;
  font-size: 11px;
  padding: 1.3px 5px;
  font-family: "PretendardM", "sans-serif";
  border-radius: 2px;
  height: 16px;
  color: white;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item .badge-list p {
  font-family: "PretendardM", "sans-serif";
  font-size: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item.min .badge-list-small p {
  font-size: 11px;
  margin-top: 5px;

}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap {
  display: flex;
  margin-top: 10px;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap .divider {
  background-color: #D2D2D2;
  width: 1px;
  height: 16px;
  margin: 0 8px;
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap p {
  font-size: 12px;
  color: #585858;
}


.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap .length {
  color: #1150CB;
  font-family: "PretendardSB", "sans-serif";
}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap span {
  color: white;
  font-size: 11px;
  font-family: "PretendardSB", "sans-serif";
  border-radius: 34px;
  padding: 2px 8px;
  background-color: #E4423C;

}

.brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap span.blue {
  background-color: #3C8FEF;

}

.brandnaming-wrap .modal-bg .brand-name .button-list {
  width: 100%;
  height: 40px;
  display: flex;
  gap: 8px;
}

.brandnaming-wrap .modal-bg .brand-name .button-list button {
  width: 352px;
  height: 40px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  border-radius: 4px;
  border: 1px solid #319dff;
}

.brandnaming-wrap .modal-bg .brand-name .button-list button:first-child {
  background-color: white;
  color: #319dff;
}

.brandnaming-wrap .modal-bg .brand-name .button-list button:last-child {
  background-color: #319dff;
  color: white;
}


.brandnaming-wrap .modal-bg .brand-name .social-wrap,
.brandnaming-wrap .modal-bg .brand-name .company-wrap,
.brandnaming-wrap .modal-bg .brand-name .product-wrap {
  width: 711px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .input-wrap,
.brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .input-wrap {
  display: flex;
  gap: 8px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .input-wrap input,
.brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap input,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .input-wrap input {
  border: 1px solid #e4e4e4;
  height: 40px;
  border-radius: 4px;
  padding: 0 16px;
  font-size: 14px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap select {
  border: 1px solid #e4e4e4;
  width: 130px;
  height: 40px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  padding: 0 16px;
  border-radius: 4px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .input-wrap input,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .input-wrap input {
  width: 647px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap input {
  width: 509px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  width: 351px;
  height: 140px;
  padding: 24px 16px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .title-wrap h5 {
  font-family: "PretendardB", "sans-serif";
  font-size: 14px;
  width: 270px;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .title-wrap span {
  border-radius: 15px;
  font-family: "PretendardSB", "sans-serif";
  font-size: 12px;
  line-height: 150%;
  padding: 5px 10px;
  white-space: nowrap;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .title-wrap .status-green {
  color: #3CA147;
  border: 1px solid #3CA147
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .title-wrap .status-blue {
  color: #1150CB;
  border: 1px solid #1150CB
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .title-wrap .status-red {
  color: #E4423C;
  border: 1px solid #E4423C;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article hr {
  border: none;
  background-color: #EEEEEE;
  height: 1px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .location-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .location-wrap img {
  width: 16px;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article .location-wrap p {
  font-family: "PretendardSB", "sans-serif";
  font-size: 12px;
  line-height: 150%;
}

.brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article p {
  font-family: "PretendardR", "sans-serif";
  font-size: 12px;
  line-height: 150%;
  color: #585858;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .input-wrap button,
.brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap button,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .input-wrap button {
  background-color: #319dff;
  color: white;
  border-radius: 4px;
  height: 40px;
  width: 57px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  white-space: nowrap;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .warn-wrap,
.brandnaming-wrap .modal-bg .brand-name .company-wrap .warn-wrap,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .warn-wrap {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 6px auto 20px 12px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .warn-wrap img,
.brandnaming-wrap .modal-bg .brand-name .company-wrap .warn-wrap img,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .warn-wrap img {
  width: 16px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .warn-wrap p,
.brandnaming-wrap .modal-bg .brand-name .company-wrap .warn-wrap p,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .warn-wrap p {
  font-size: 14px;
  line-height: 140%;
  color: #585858;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap,
.brandnaming-wrap .modal-bg .brand-name .social-wrap .domain-check-wrap {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 16px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap h4 {
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap hr {
  background-color: #eaeaea;
  height: 1px;
  border: none;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap {
  margin-top: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap .logo-list {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap .logo-list span {
  border: 1px solid #1150CB;
  color: #1150CB;
  font-family: "PretendardSB", "sans-serif";
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap .logo-list span.red {
  border: 1px solid #E4423C;
  color: #E4423C;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap .logo-list .divider {
  width: 1px;
  height: 28px;
  background-color: #dbdbdb;
  margin: 0 18px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap .logo-list.first {
  margin-bottom: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .domain-check-wrap {
  margin-top: 20px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .domain-check-wrap .check-item {
  display: flex;
  border: 1px solid #eaeaea;
  height: 44px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .domain-check-wrap .check-item p {
  font-size: 14px;
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .domain-check-wrap .check-item span {
  border: 1px solid #E4423C;
  color: #E4423C;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-family: "PretendardSB", "sans-serif";
}

.brandInput-wrap .title-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.brandInput-wrap .title-wrap img {
  margin-left: 6px;
  cursor: pointer;
}

.brandInput-wrap .title-wrap img:hover+article {
  display: block;
}

.brandInput-wrap .ai-wrap .title-wrap article::before,
.brandInput-wrap .strength-wrap .title-wrap article::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
}

/* AI 모델 선택 툴팁 */
.brandInput-wrap .ai-wrap .title-wrap article {
  top: 25px;
  left: -7%;
  /* 왼쪽으로 조정 */
  width: max-content;
  position: absolute;
  background-color: black;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  font-size: 12px;
  color: white;
  padding: 10px 12px;
  display: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  z-index: 10;
  white-space: nowrap;
  border-radius: 4px;
}

.brandInput-wrap .ai-wrap .title-wrap article::before {
  top: -7px;
  left: 50%;
  /* 화살표를 말풍선 가운데로 조정 */
  transform: translateX(-50%);
}

/* 등록가능성 판단 강도 선택 툴팁 */
.brandInput-wrap .strength-wrap .title-wrap article {
  top: 25px;
  left: 4%;
  /* 위치 조정 */
  width: max-content;
  position: absolute;
  background-color: black;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  font-size: 12px;
  color: white;
  padding: 10px 12px;
  display: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
  z-index: 10;
  white-space: nowrap;
  border-radius: 4px;
}

.brandInput-wrap .strength-wrap .title-wrap article::before {
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.brandnaming-wrap .modal-bg .brand-name .social-wrap .domain-check-wrap .check-item span.blue {
  border: 1px solid #1150CB;
  color: #1150CB;
}


.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap {
  margin-top: 20px;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap .title-wrap,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap .title-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap .title-wrap h4,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap .title-wrap h4 {
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap .title-wrap p,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap .title-wrap p {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  color: #585858;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap hr,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap hr {
  border: none;
  background-color: #EEEEEE;
  height: 1px;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 140px;
  overflow-y: auto;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article {
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  width: 100%;
  padding: 15.5px 16px;
  display: flex;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article h5 {
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
  width: 200px;
  border-right: 1px solid #eaeaea;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article h5 strong {
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
  color: #319dff;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article div {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 16px;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article div p {
  white-space: nowrap;
  font-size: 12px;
  line-height: 140%;
  color: #585858;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap .button-wrap,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap .text-wrap    {
  width: 100%;
  text-align: end;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap .button-wrap button,
.brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap .text-wrap p {
  font-size: 12px;
  color: #858585;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  margin-top: 12px;
  width: 100%;

}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section article {
  width: 214px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  height: 188px;
  padding: 8.5px 12px;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section article img {
  width: 190px;
  height: 124px;
  margin-bottom: 8px;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section article h5 {
  font-family: "PretendardM", "sans-serif";
  font-size: 12px;
  line-height: 140%;
  height: 30px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
}

.brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section article h5 strong {
  color: #1150CB;
  font-family: "PretendardM", "sans-serif";
  font-size: 12px;
}

.brandnaming-wrap .modal-bg .brand-name .no-data-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.brandnaming-wrap .modal-bg .brand-name .no-data {
  font-size: 16px;
  color: #585858;
  padding: 59px 0;
}

.brandnaming-wrap .modal-bg .brand-name .no-data-wrap.company {
  height: 300px;
}


.brandnaming-wrap .modal-bg .brand-name .error-wrap {
  height: 100%;
  width: 712px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.brandnaming-wrap .modal-bg .brand-name .error-wrap img {
  width: 24px;
}

.brandnaming-wrap .modal-bg .brand-name .error-wrap div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brandnaming-wrap .modal-bg .brand-name .error-wrap div p {
  font-size: 16px;
  color: #15171a;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .error-wrap div span {
  font-size: 14px;
  color: #666666;
}


.brandnaming-wrap .modal-bg .brand-name .error-wrap button {
  background-color: #319dff;
  border-radius: 15px;
  color: white;
  font-family: "PretendardSB", "sans-serif";
  width: 90px;
  height: 24px;
  font-size: 12px;
}

.brandnaming-wrap .like-button {
  position: absolute;
  left: calc(50vw + 440px);
  top: 90%;
}

.brandnaming-wrap .like-button img {
  width: 60px;
}


/* 좋아요 모달 */
.brandnaming-wrap .modal-bg .brand-name .like-list {
  width: 100%;
  height: 584px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-content: flex-start

}

.brandnaming-wrap .modal-bg .brand-name .like-list article {
  width: 345px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 182px;
  padding: 16px 24px;
  text-align: center;
  background-color: #FFFDF9;
}

.brandnaming-wrap .modal-bg .brand-name .like-list .gpt-card .title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.brandnaming-wrap .modal-bg .brand-name .like-list h5 {
  font-family: "PretendardB", "sans-serif";
  font-size: 22px;
}

.brandnaming-wrap .modal-bg .brand-name .like-list .gpt-card .title-wrap img {
  width: 24px;
}

.brandnaming-wrap .modal-bg .brand-name .like-list article hr {
  border: none;
  background-color: #EEEEEE;
  height: 1px;
}

.brandnaming-wrap .modal-bg .brand-name .like-list .gpt-card .description {
  height: 63px;
  margin: 8px auto;
  text-align: start;
}

.brandnaming-wrap .modal-bg .brand-name .like-list .gpt-card .description p {
  font-size: 14px;
  line-height: 140%;
}

.brandnaming-wrap .modal-bg .brand-name .like-list article button {
  text-align: center;
}


.brandnaming-wrap .modal-bg .brand-name .like-list .card .title-wrap {
  text-align: end;
}

.brandnaming-wrap .modal-bg .brand-name .like-list .card .title-wrap2 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}