.content-customer-wrap .inquiry{
  width: 100vw;
}
.content-customer-wrap .inquiry .service-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: 264px;
  padding: 64px 80px;
  border-radius: 8px;
  background-color: #00274A;
  margin: 0px auto 120px;
  margin-bottom: 40px;
}
.content-customer-wrap .inquiry .service-box .service-text, .service-link {
  width: 50%;
  color: #fff;
}
.content-customer-wrap .inquiry .service-box .service-text h4 {
  font-size: 24px;
  font-family: "PretendardSB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 24px;
}
.content-customer-wrap .inquiry .service-box .service-text span {
  color: #d1d1d1;
  font-size: 16px;
  line-height: 140%;
}
.content-customer-wrap .inquiry .service-box .service-link a {
  display: flex;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
  gap: 17px;
}
.content-customer-wrap .inquiry .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-customer-wrap .inquiry .service-box .faq {
  background-color: #319dff;
}

.content-customer-wrap .inquiry .service-box .inquiry {
  background-color: #666;
}
.content-customer-wrap .inquiry .service-box .button-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content-customer-wrap .inquiry .inquiry-form-bg{
  background: #EAEEF2;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content-customer-wrap .inquiry .inquiry-form-wrap{
  background: #fff;
  padding: 56px 140px;
  margin: 0px auto;
  width: 1280px;
  margin: 80px auto;
  text-align: center;
}

.content-customer-wrap .inquiry .inquiry-form-wrap article {
  width: 1000px;
  background-color: #666666;
  border-radius: 8px;
  padding: 32px 40px;
}

.content-customer-wrap .inquiry .inquiry-form-wrap article div {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.content-customer-wrap .inquiry .inquiry-form-wrap article div h3 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  line-height: 140%;
  color: #fff;
}

.content-customer-wrap .inquiry .inquiry-form-wrap article p {
  font-size: 16px;
  line-height: 140%;
  font-family: "PretendardR", "sans-serif";
  color: #d1d1d1;
  text-align: start;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section {
  height: 507px;
  width: 1000px;
  margin-top: 40px;
  border-top: 2px solid #666;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-first {
  display: flex;
  border-bottom: 1px solid #d1d1d1;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-first input {
  width: 700px;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .label {
  width: 150px;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  padding-left: 20px;
  border-right: 1px solid #d1d1d1;
  display: flex;
  align-items: center;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-first .label {
  height: 60px;
}
.content-customer-wrap .inquiry .inquiry-form-wrap section .table-first .content {
  width: calc( 100% - 150px );
  display: flex;
  padding: 20px 16px;
  justify-content: space-between;
  align-items: flex-end;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-first .content input::placeholder {
  color: #D9D9D9;
}
.content-customer-wrap .inquiry .inquiry-form-wrap section .table-first .content span {
  font-size: 14px;
  line-height: 140%;
  font-family: "PretendardR", "sans-serif";
  color: #666;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-second {
  display: flex;
  height: 312px;
  border-bottom: 1px solid #d1d1d1;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-second .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc( 100% - 150px );
  padding: 16px;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-second .content span {
  font-size: 14px;
  line-height: 140%;
  font-family: "PretendardR", "sans-serif";
  color: #666;
  text-align: end;
  margin-top: 10px;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-second .content textarea{ 
  width: 817px;
  height: 250px;
  border: 1px solid #d1d1d1;
  resize: none;
  outline-color: #d1d1d1;
  padding: 16px;
  font-size: 14px;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .file_name_display {
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #666;
  margin-left: 10px;
}


.content-customer-wrap .inquiry .inquiry-form-wrap .submit-btn {
  background-color: #319DFF;
  width: 180px;
  height: 50px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  line-height: 140%;
  margin-top: 40px;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-second .content textarea::placeholder {
  color: #D9D9D9;
}
.content-customer-wrap .inquiry .inquiry-form-wrap section .table-third {
  display: flex;
  height: 60px;
  border-bottom: 1px solid #d1d1d1;
}

.content-customer-wrap .inquiry .inquiry-form-wrap section .table-third .content {
  padding: 12.5px 20px;
}
.content-customer-wrap .inquiry .inquiry-btn-wrap {
  display: flex;
  align-items: center;
  margin-top: 32px;
  gap: 329px;
}
.content-customer-wrap .inquiry .inquiry-btn-wrap button {
  background-color: #fff;
  border: 1px solid #050F2F;
  border-radius: 2px;
  color: #050F2F;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  padding: 18px auto 18px 42px ;
  height: 60px;
  line-height: 23px;
  width: 225px;
}




/**/
.inquiryList {
  width: 1280px;
}

.inquiryList,
.inquiryDetail {
  margin: 80px 0;
  text-align: center;
  min-height: 60vh;
}

.inquiryDetai {
  width: 1200px;
}

.inquiryList h1,
.inquiryDetail h1 {
  font-family: "PretendardB", "sans-serif";
  font-size: 48px;
  line-height: 67.2px;
  margin-bottom: 16px;
}

.inquiryList h3,
.inquiryDetail h3 {
  font-family: "PretendardR", "sans-serif";
  font-size: 20px;
  line-height: 28px;
  color: #666;
}

.inquiryList section,
.inquiryDetail section {
  margin: 48px auto 0 auto;
}

.inquiryList section .button-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.inquiryList section .button-list button {
  font-size: 16px;
  height: 48px;
}

.inquiryList section .button-list .view-btn {
  border: 1px solid #666;
  width: 155px;
  color: #666;
}

.inquiryList section .button-list .write-btn {
  background-color: #319DFF;
  width: 134px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.inquiryList section .list-wrap {
  width: 100%;
  border-top: 1px solid #050f2f;
  border-bottom: 1px solid #050f2f;
}

.inquiryList section .list-wrap .list-header,
.inquiryList section .list-wrap .list-main {
  display: flex;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  cursor: pointer;
  padding: 16px 0;
}

.inquiryList section .list-wrap .list-header div {
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
}

.inquiryList section .list-wrap .list-header div:first-child,
.inquiryList section .list-wrap .list-main div:first-child {
  margin-left: 32px;
}

.inquiryList section .list-wrap .list-main .list-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 586px;
  font-family: "PretendardR", "sans-serif";
  margin-left: 0 !important;
}

.inquiryList section .list-wrap .list-main .list-title div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.inquiryList section .list-wrap .list-main .list-title .new {
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 2px;
  font-size: 11px;
  font-family: "PretendardM", "sans-serif";
  padding: 2px 3px;
  line-height: 15.4px;
}

.inquiryList section .list-wrap .list-main .number {
  width: 50px;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
}

.inquiryList section .list-wrap .list-main .field {
  width: 120px;
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
}

.inquiryList section .list-wrap .list-main .name {
  width: 120px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.inquiryList section .list-wrap .list-main .name .member {
  color: #585858;
  border: 1px solid #585858;
  font-size: 11px;
  width: 34px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inquiryList section .list-wrap .list-main .name .name-text {
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  color: #666;
}

.inquiryList section .list-wrap .list-main .date {
  width: 160px;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  color: #666;
}

.inquiryList section .list-wrap .list-main .status {
  width: 100px;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
}

.inquiryList section .list-wrap .list-main .answered {
  color: #319DFF;
}

.inquiryList section .list-wrap .list-main .pending {
  color: #666;
}

.inquiryList section .list-wrap .list-main .name .member-user {
  color: #585858;
  background-color: white;
}

.inquiryList section .list-wrap .list-main .name .non-member-user {
  background-color: #d1d1d1;
}

.inquiryList section .pagination-box>ul>li.active {
  color: #319DFF;
  background-color: white;
  border: none;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
}

.inquiryList section .pagination-box>ul>li {
  font-family: "PretendardR", "sans-serif";
  font-size: 18px;
  color: #d1d1d1;
  margin: 0 8px;
}

.inquiryList section .password-dropdown {
  text-align: start;
}

.inquiryList section .password-dropdown input {
  width: 280px;
  height: 38px;
  border: 1px solid #d1d1d1;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  padding: 0 16px;
  margin: 16px auto auto 32px;
}

.inquiryList section .password-dropdown button {
  background-color: #319DFF;
  color: white;
  width: 60px;
  height: 38px;
  font-size: 16px;
  margin-left: 8px;
}

.inquiryDetail section {
  border-top: 1px solid #050f2f;
  border-bottom: 1px solid #d1d1d1;
}

.inquiryDetail section .title-Wrap {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}


.inquiryDetail section .title-Wrap .main-title {
  display: flex;
  align-items: center;
}

.inquiryDetail section .title-Wrap .main-title p {
  font-size: 20px;
  font-family: "PretendardSB", "sans-serif";
}

.inquiryDetail section .title-Wrap .main-title .type {
  min-width: 120px;
}

.inquiryDetail section .title-Wrap .main-title .lock-text {
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 8px;
  width: 818px;
}

.inquiryDetail section .title-Wrap .main-title .lock-text p {
  font-size: 20px;
  font-family: "PretendardSB", "sans-serif";
}

.inquiryDetail section .title-Wrap .sub-title {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.inquiryDetail section .title-Wrap .sub-title .status {
  font-family: "PretendardSB", "sans-serif";
  color: #666;
}

.inquiryDetail section .title-Wrap .sub-title .blue-text {
  color: #319dff;
  font-family: "PretendardSB", "sans-serif";
}

.inquiryDetail section .title-Wrap .sub-title p,
.inquiryDetail section .title-Wrap .sub-title span {
  color: #666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
}

.inquiryDetail .button-list {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}


.inquiryDetail .button-list button {
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
  width: 96px;
  height: 38px;
}

.inquiryDetail .button-list .edit {
  background-color: #319DFF;
  color: white;
}


.inquiryDetail .button-list .delete {
  background-color: #d1d1d1;
  color: #050F2F;
}

.inquiryDetail .button-list .list {
  border: 1px solid #666;
  color: #666;
  font-family: "PretendardR", "sans-serif";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}



.inquiryDetail section .table .table-first,
.inquiryDetail section .table .table-second {
  display: flex;
  height: 40px;
}

.inquiryDetail section .table .label {
  background-color: #f5faff;
  width: 120px;
  border: 1px solid #d1d1d1;
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: none;
}

.inquiryDetail section .table .table-first .content,
.inquiryDetail section .table .table-second .trademark-name  {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #666;
  border: 1px solid #d1d1d1;
  padding: 0 16px;
  border-left: none;
}

.inquiryDetail section .table .table-second .label,
.inquiryDetail section .table .table-second .trademark-name
{
  border-top: none;
}

.inquiryDetail section .table .table-first .content{
  width: 478px;
}

.inquiryDetail section .table .table-first .content.last {
  border-right: none;
}

.inquiryDetail section .table .table-first .content div {
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #666;
}

.inquiryDetail section .table .table-second .trademark-name {
  width: calc( 100% - 120px );
  border-right: none;
}

.inquiryDetail section .table .detail {
  margin: 20px 0;
  min-height: 140px;
  text-align: start;
  color: #585858;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 22.4px;
}

.inquiryDetail section .table .detail textarea {
  resize: none;
  width: 100%;
  min-height: 140px;
  border: 1px solid #d1d1d1;
  color: #585858;
  line-height: 22.4px;
  padding: 16px;
}

.inquiryDetail section .table .detail textarea:focus {
  outline: none;
}
.inquiryDetail .comment-wrap {
  background-color: #F6F6F6;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  color: #585858;
  text-align: start;
  padding: 20px;
  margin-top: 20px;
}