.actingProcedureName-wrap {width: 100%;}
.actingProcedureName-wrap .actingProcedure-all{
  width: 100%;
  /* min-width: 1920px; */
  height: 100vh;
}
.actingProcedureName-wrap .actingProcedure-contentBox01{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 147px;
  padding-bottom: 128px; */
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox{
  width: 100%;
} 
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top{
  width: 100%;  
  /* max-width: 1280px; */
  height: auto;
  margin: 0px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top span{
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: center;
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  /* line-height: 140%; */
  color: #050F2F;
  letter-spacing: -0.2px;
  margin-bottom: 40px;
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top .agency_explain{
/* margin-left: 105px;
margin-right: 161px;
margin-top: 40px; */
max-width: 100%;
}
.actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
/* .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top img{
  display: block;
  margin: 0px auto;
} */
/* 섹션2 */
.actingProcedureName-wrap .actingProcedure-contentBox02{
  width: 100%;
  height: 100vh;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin: 0px auto;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 50px;
  line-height: 140%;
  color: #050F2F;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox span{
  display: block;
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox{
  display: flex; 
  justify-content: center;
  margin: 0 auto;
  align-items: center;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left{
  /* width: 60%; */
  margin-right: 60px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-left img{
  /* margin: 55px auto 0; */
  /* min-height: 520px; */
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right{
  width: 50%;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li{
  display: flex;
  align-items: center;
  /* width: 620px; */
  /* height: 110px; */
  /* width: 100%; */
  width: 620px;
  height: 107px;
  margin-bottom: 15px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px 35px 24px 24px;
  border: 2px solid #F1F1F1;
  transition: border 0.3s;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li:hover{
  border-color: #316BFF;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li img{
  /* display: inline-block;
  margin-right: 20px; */
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} 
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li h3{
  width: 100%;
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  line-height: 150%;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content p{
  width: 100%;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  color: #666;
  margin-top: 8px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content small{
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #bfbfbf;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(1) img{
  margin-right: 32px;
  margin-left: 8px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img{
  margin-right: 28px;
  margin-left: 6px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img{
  margin-right: 39px;
  margin-left: 16px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img{
  margin-right: 31px;
  margin-left: 17px;
}
.actingProcedureName-wrap .actingProcedure-contentBox02 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(5) img{
  margin-right: 31px;
  margin-left: 18px;
}

/* 섹션3 */
.actingProcedureName-wrap .actingProcedure-contentBox03{
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #F6F6F6;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
} 
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top{
  width: 100%;  
  max-width: 1280px;
  height: auto;
  margin: 0px auto;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top span{
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: center;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 110px;
  line-height: 140%;
  color: #050F2F;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-topBox .content-top p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 60px;
  width: 1280px;
  margin: 0px auto;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0px 40px;
  width: 300px;
  height: auto;
  background: #FFFFFF;
  border-radius: 4px;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li img{
  display: block;
  margin: 0px auto;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li b{
  display: block;
  text-align: center;
  color: #3E67E7;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
  margin-bottom: 16px;

}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li h3{
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
  margin-bottom: 12px;
}
.actingProcedureName-wrap .actingProcedure-contentBox03 .content-botBox ul li span{
  display: block;
  color: #666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  line-height: 140%; 
}

@media (max-width: 1680px) {
  .actingProcedureName-wrap .actingProcedure-contentBox01 .content-topBox .content-top .agency_explain {
    width: 98vw;
  }
}