.notice-list .notice-item-wrap {
    height: 118px;
    ;
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
    border-bottom: 1px solid #e1e1e1;
}

.content-customer-wrap .notice {
    min-height: 70vh;
}

.notice-list .notice-item-wrap:hover {
    background-color: #F4F7FA;
}

.notice-list .notice-item-wrap .notice-contents {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.notice-list .notice-item-wrap .notice-contents strong {
    font-size: 20px;
    font-family: "PretendardR", "sans-serif";
    line-height: 28px;
}

.notice-list .notice-item-wrap .notice-contents span {
    font-size: 16px;
    line-height: 22.4px;
    color: #666666;
    font-family: "PretendardR", "sans-serif";
}

.notice-list .notice-item-wrap img {
    width: 12px;
}

.notice-list-wrap {
    border-top: 1px solid #050f2f;
    margin-bottom: 120px;
}

.notice-detail-wrap {
    width: 100%;
    max-width: 1280px;
    padding-top: 100px;
    text-align: center;
}

.notice-detail-wrap h2 {
    color: #050f2f;
    font-size: 48px;
    line-height: 67.2px;
    font-family: "PretendardB", "sans-serif";
    margin-bottom: 20px;
}

.notice-detail-wrap .subTitle {
    color: #666666;
    font-size: 20px;
    line-height: 28px;
    font-family: "PretendardR", "sans-serif";
}

.notice-detail-wrap .content-box {
    margin: 60px auto;
    border-top: 1px solid #050F2F;
    border-bottom: 1px solid #e1e1e1;
}

.notice-detail-wrap .content-box li h3 {
    font-family: "PretendardSB", "sans-serif";
    font-size: 24px;
    line-height: 33.6px;
    margin: 30px auto 20px auto;
}

.notice-detail-wrap .content-box li .notice-date {
    font-family: "PretendardR", "sans-serif";
    font-size: 16px;
    color: #666666;
    line-height: 22.4px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E1E1E1;
    width: 100%;
}

.notice-detail-wrap .content-box .notice-content {
    padding: 40px;
    text-align: start;
    min-height: 497px;
}

.notice-detail-wrap .content-box .notice-content p {
    color: #666666;
    font-size: 20px;
    line-height: 28px;
}

.notice-detail-wrap .content-box .notice-content strong {
    color: #000;
    font-size: 20px;
    line-height: 28px;
    font-family: "PretendardSB", "sans-serif";
}

.notice-detail-wrap .content-box .notice-content h3 {
    color: #000;
    font-size: 28px;
    line-height: 140%;
    font-family: "PretendardSB", "sans-serif";
}

.notice-detail-wrap .content-box .notice-content h4 {
    color: #000;
    font-size: 25px;
    line-height: 140%;
    font-family: "PretendardSB", "sans-serif";
}

.notice-detail-wrap .content-box .notice-content div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notice-detail-wrap .bottom-nav a {
    display: flex;
    align-items: center;
    border-radius: 2px;
    margin-bottom: 120px;
}

.notice-detail-wrap .bottom-nav a p {
    color: white;
    font-size: 16px;
    font-family: "PretendardM", "sans-serif";
    line-height: 19.2px;
}