@media (max-width: 1680px) {
  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    gap: 10px;
  }
}

@media (max-width: 1440px) {
  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 40px 0px 0px 25px;
    min-height: 440px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-right: 48px;
    margin-left: 45px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 35%;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox {
    justify-content: space-between;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 630px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 42%;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .left-box {
    width: 50%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    padding-left: 0px;
    padding-right: 220px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box p {
    font-size: 24px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul {
    padding-left: 90px;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll {
    justify-content: space-around;
    gap: 10px;
    padding: 0 10px;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All p {
    font-size: 22px;
    word-break: keep-all;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box {
    padding-left: 20px;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article02 .right-box01 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box img {
    width: 100%;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .left-box {
    width: 56%;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01 img {
    width: 100%;
  }

  /* 두번째 */
  .sub_wrap_all .agencySection04 .agencycontent-articleAll {
    justify-content: space-around;
    gap: 10px;
    padding: 0 10px;
  }

  .sub_wrap_all .agencySection04 .agencytextbox-All p {
    font-size: 22px;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box {
    padding-left: 20px;
  }

  .sub_wrap_all .agencySection04 .agencycontent-article02 .right-box01 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box img {
    width: 100%;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .left-box {
    width: 56%;
  }

  .sub_wrap_all .agencySection04 .agencycontent-article03 .left-box01 img {
    width: 100%;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul {
    padding-left: 90px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox {
    justify-content: space-between;
    align-items: center;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 630px;
  }

  /* 세번째 */
  .sub_wrap_all .brandSection04 .brandcontent-articleAll {
    justify-content: space-around;
    gap: 10px;
    padding: 0 10px;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All p {
    font-size: 22px;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box {
    padding-left: 20px;
  }

  .sub_wrap_all .brandSection04 .brandcontent-article02 .right-box01 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box img {
    width: 100%;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .left-box {
    width: 56%;
  }

  .sub_wrap_all .brandSection04 .brandcontent-article03 .left-box01 img {
    width: 100%;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul {
    padding-left: 90px;
  }

  /* 네번째 */
  .sub_wrap_all .potentialSection04 .potencontent-articleAll {
    justify-content: space-around;
    gap: 10px;
    padding: 0 10px;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox h2 {
    word-break: keep-all;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All p {
    font-size: 22px;
    word-break: keep-all;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box {
    padding-left: 20px;
  }

  .sub_wrap_all .potentialSection04 .potencontent-article02 .right-box01 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .pontencontent-article02 .right-box01 img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .pontencontent-article03 .left-box01 img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .left-box {
    width: 56%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-article03 .left-box01 img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .left-box .left-box-ul {
    padding-left: 90px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox {
    justify-content: space-between;
    align-items: center;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 630px;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All h3 {
    word-break: keep-all;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 50%;
  }

  /* 다섯번째 */
  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll {
    justify-content: space-around;
    gap: 10px;
    padding: 0 10px;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All p {
    font-size: 22px;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box {
    padding-left: 20px;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-article02 .right-box01 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box img {
    width: 100%;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .left-box {
    width: 56%;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-article03 .left-box01 img {
    width: 100%;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .left-box-ul {
    padding-left: 90px;
  }
}

@media (max-width: 1280px) {

  /* .sub_wrap_all .subsection{height: 100%;} */
  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    width: 500px;
    height: 350px;
  }

  .Newself-wrap .selfTrade-contentBox04 {
    height: 100%;
    padding: 20px 0;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 100%;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    height: 350px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 100%;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: calc(45% - 50px);
    padding: 16px 10px 16px 10px;
    margin-bottom: 0px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    gap: 15px;
    height: auto;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    word-break: keep-all;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    height: auto;
    min-height: 111px;
  }

  /* .price-info-wrap .price-position .table-img{
    width: 60px;
    top:12px;
    right: 10px;
  }
  .price-info-wrap .price-position .table-img02{
    width: 75px;
    top: 12px;
  }
  .price-info-wrap .price-position .table-img03{
    width: 75px;
    top: 10px;
    right: 10px;
  } */
  .sub_wrap_all .price-info-wrap {
    height: 100%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll {
    width: 100%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    width: 57%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 24px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box h3 {
    color: #050f2f;
    font-size: 27px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
    margin: 12px 0 34px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box p {
    font-size: 17px !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:last-child {
    left: 45%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p img {
    width: 115px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:last-child {
    width: 350px;
  }

  .sub_wrap_all .newselfSection02 {
    /* height: 100%; */
    padding: 0;
    margin: 0;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-right: 0px;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .left-box {
    width: 56%;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01 img {
    width: 100%;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    padding-left: 170px;
    padding-left: 0px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box p {
    font-size: 24px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    padding-right: 100px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active {
    width: 350px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 18px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 24px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: center;
    flex-direction: column;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    justify-content: center;
    flex-direction: column;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: center;
  }

  /* --추가끝-- */

  /* 두번째 */
  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active {
    width: 350px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active p {
    font-size: 18px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 24px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 24px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box h3 {
    color: #050f2f;
    font-size: 27px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
    margin: 12px 0 34px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box p {
    font-size: 17px !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box {
    width: 57%;
    padding-left: 0px;
    padding-right: 130px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll {
    width: 100%;
  }

  .sub_wrap_all .agencySection04 .lawyer_bg_side {
    margin: 50px 10px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-bottom: 40px;
    margin-right: 0px;
    margin-left: 0;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 100%;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    width: 500px;
    height: 350px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 100%;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: center;
    flex-direction: column;
    height: auto;
    min-height: 111px;
    gap: 15px;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    justify-content: center;
    flex-direction: column;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: center;
  }

  /* 세번째 */
  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active {
    width: 350px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 18px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 24px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 24px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box h3 {
    color: #050f2f;
    font-size: 27px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
    margin: 12px 0 34px;
    word-break: keep-all;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box p {
    font-size: 17px !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box {
    width: 57%;
    padding-left: 0px;
    padding-right: 130px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll {
    width: 100%;
  }

  .sub_wrap_all .brandSection04 .lawyer_bg_side {
    margin: 50px 10px;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All h3 {
    word-break: keep-all;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All p {
    word-break: keep-all;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-bottom: 40px;
    margin-right: 0px;
    margin-left: 0;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 100%;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    width: 500px;
    height: 350px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 100%;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: center;
    flex-direction: column;
    height: auto;
    min-height: 111px;
    gap: 15px;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    justify-content: center;
    flex-direction: column;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: center;
  }

  /* 네번째 */
  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active {
    width: 350px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active p {
    font-size: 18px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 24px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 24px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box h3 {
    color: #050f2f;
    font-size: 27px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
    margin: 12px 0 34px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box p {
    font-size: 17px !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box {
    width: 57%;
    padding-left: 0px;
    padding-right: 130px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .lawyer_bg_side {
    margin: 50px 10px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-bottom: 40px;
    margin-right: 0px;
    margin-left: 0;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 100%;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    width: 500px;
    height: 350px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 100%;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: center;
    flex-direction: column;
    height: auto;
    min-height: 111px;
    gap: 15px;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    justify-content: center;
    flex-direction: column;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: center;
  }

  .sub_wrap_all .potentialSection04 .pontencontent-article03 .left-box01 div img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box02 img {
    width: 100%;
  }

  /* 다섯번째 */
  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active {
    width: 350px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 18px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 24px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 24px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box h3 {
    color: #050f2f;
    font-size: 27px;
    font-family: "PretendardB", sans-serif;
    line-height: 140%;
    margin: 12px 0 34px;
    word-break: keep-all;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box p {
    font-size: 17px !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box {
    width: 57%;
    padding-left: 0px;
    padding-right: 130px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll {
    width: 100%;
  }

  .sub_wrap_all .monitorSection04 .lawyer_bg_side {
    margin: 50px 10px;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All h3 {
    word-break: keep-all;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All p {
    word-break: keep-all;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-bottom: 40px;
    margin-right: 0px;
    margin-left: 0;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    width: 100%;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    margin: 0px auto;
    min-height: auto;
    width: 500px;
    height: 350px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 100%;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: center;
    flex-direction: column;
    height: auto;
    min-height: 111px;
    gap: 15px;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    justify-content: center;
    flex-direction: column;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: center;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul {
    gap: 33px;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-top p {
    word-break: keep-all;
  }
}

@media (max-width: 1024px) {
  .sub_wrap_all .subsection {
    margin-bottom: 40px;
  }

  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
    margin-bottom: 0px;
  }

  .sub_wrap_all .newselfSection04 .left-box02 {
    text-align: center;
    width: 100%;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    justify-content: center;
    flex-direction: column;
  }

  /* --추가끝-- */

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right {
    width: 100%;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    width: calc(50% - 16px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content {
    width: 100%;
    text-align: center;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small {
    margin: 0px auto;
    word-break: keep-all;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    height: auto;
    min-height: 101px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 1440px;
    height: 609px;
    background: linear-gradient(90deg,
        rgba(255, 232, 177, 0.3) 0%,
        #f8bd34 100%);
    margin: 0px auto;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll {
    width: 100%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    width: 100%;
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box p {
    font-size: 16px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 17px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li {
    width: calc(50% - 20px);
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:last-child {
    width: calc(50% - 20px);
    height: 145px;
    background: #fff;
    border-radius: 8px;
    padding: 31px 0 0 50px;
    transform: none;
    position: unset;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last h3 {
    font-size: 20px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p {
    font-size: 18px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p img {
    position: absolute;
    right: 1px;
    bottom: 35px;
    width: 80px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:not(.li-last) {
    opacity: 1;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box h3 {
    margin: 12px 0;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    padding-right: 0;
    display: flex;
    align-content: center;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide {
    width: 320px;
    height: 110px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 370px;
    height: 140px !important;
    position: relative;
    transform: translate3d(110px, 60px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-80px, -45px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-80px, -45px, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box h3 {
    margin-bottom: 3px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul {
    padding-left: 15px;
  }

  .swiper-wrapper {
    align-items: center;
  }

  /* 셀프 트레이드 마지막 */
  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img {
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    margin-right: 0px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    word-break: keep-all;
    font-size: 14px;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll {
    display: flex;
    flex-wrap: wrap;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .left-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box {
    width: 100%;
    text-align: center;
    padding-left: 0px;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article02 .left-box {
    text-align: center;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article01 .left-box {
    order: 2;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box {
    order: 2;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All h3 {
    margin: 12px 0;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll {
    margin: 0px auto 80px;
  }

  .sub_wrap_all .subsection .pricetable-wrap .title_h2 {
    font-size: 30px;
  }

  .sub-final-wrap .box-wrap .service-box .service-text h4 {
    margin-bottom: 20px;
    font-size: 30px;
  }

  .sub-final-wrap .final-title-wrap .main-final-top {
    padding-bottom: 40px;
  }

  /* .sub-final-wrap .box-wrap .service-box{
    height: 250px;
  } */
  .sub-final-wrap .final-title-wrap .main-final-move {
    margin: 20px auto 0;
  }

  /* 두번째 */
  .sub_wrap_all .agencySection04 .agencycontent-articleAll {
    flex-direction: column;
    margin: 0px auto 40px;
  }

  .sub_wrap_all .agencySection .agencycontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .left-box {
    order: 1;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box {
    text-align: center;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .agencySection04 .left-box02 {
    text-align: center;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box02 {
    width: 100%;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 609px;
    margin: 0px auto;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide {
    width: 320px;
    height: 110px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 370px;
    height: 140px !important;
    position: relative;
    transform: translate3d(110px, 60px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-80px, -45px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-80px, -45px, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box {
    padding-right: 0;
    display: flex;
    align-content: center;
    width: 100%;
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box02 {
    display: flex;
    justify-content: center;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box h3 {
    margin: 12px 0;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul {
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 17px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box p {
    font-size: 16px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  /* 세번째 */
  .sub_wrap_all .brandSection04 .brandcontent-articleAll {
    flex-direction: column;
    margin: 0px auto 40px;
  }

  .sub_wrap_all .brandSection .brandcontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .left-box {
    order: 1;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box {
    text-align: center;
    width: 100%;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .brandSection04 .left-box02 {
    text-align: center;
    width: 100%;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box02 {
    width: 100%;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 609px;
    margin: 0px auto;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide {
    width: 320px;
    height: 110px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 370px;
    height: 140px !important;
    position: relative;
    transform: translate3d(110px, 60px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-80px, -45px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-80px, -45px, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box {
    padding-right: 0;
    display: flex;
    align-content: center;
    width: 100%;
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box02 {
    display: flex;
    justify-content: center;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box h3 {
    margin: 12px 0;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul {
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 17px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box p {
    font-size: 16px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  /* 네번째 */
  .sub_wrap_all .potentialSection04 .potencontent-articleAll {
    flex-direction: column;
    margin: 0px auto 40px;
  }

  .sub_wrap_all .agencySection .potencontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .left-box {
    order: 1;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box {
    text-align: center;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .left-box02 {
    text-align: center;
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box02 {
    width: 100%;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 609px;
    margin: 0px auto;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide {
    width: 320px;
    height: 110px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 370px;
    height: 140px !important;
    position: relative;
    transform: translate3d(110px, 60px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-80px, -45px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-80px, -45px, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box {
    padding-right: 0;
    display: flex;
    align-content: center;
    width: 100%;
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box strong {
    font-size: 24px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box h3 {
    font-size: 27px;
    margin: 12px 0 34px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box p {
    font-size: 17px !important;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box02 {
    display: flex;
    justify-content: center;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box h3 {
    margin: 12px 0;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul {
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 17px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box p {
    font-size: 16px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.002em;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.002em;
  }

  .sub_wrap_all .potentialSection04 .pontencontent-article03 .left-box01 div img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box02 img {
    width: 100%;
  }

  /* 다섯번째 */
  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll {
    flex-direction: column;
    margin: 0px auto 40px;
  }

  .sub_wrap_all .monitorSection .monitorcontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .left-box {
    order: 1;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box {
    text-align: center;
    width: 100%;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .monitorSection04 .left-box02 {
    text-align: center;
    width: 100%;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box02 {
    width: 100%;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 609px;
    margin: 0px auto;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box {
    width: 100%;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide {
    width: 320px;
    height: 110px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 370px;
    height: 140px !important;
    position: relative;
    transform: translate3d(110px, 60px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-80px, -45px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-80px, -45px, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-80px, 75px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-80px, 195px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box {
    padding-right: 0;
    display: flex;
    align-content: center;
    width: 100%;
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box02 {
    display: flex;
    justify-content: center;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box h3 {
    margin: 12px 0;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .left-box-ul {
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 17px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box p {
    font-size: 16px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul {
    flex-direction: column;
  }
}

@media (max-width: 768px) {

  /* 셀프 4번 */
  .Newself-wrap .selfTrade-contentBox04 .content-oneBox h2 {
    font-size: 30px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox span {
    font-size: 16px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    min-height: 140px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img {
    width: auto !important;
    height: auto !important;
    margin-bottom: 10px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    min-height: 155px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li {
    padding: 20px 5px;
    text-align: center;
    height: auto;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li p {
    font-size: 15px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p {
    font-size: 15px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:last-child {
    padding: 20px 5px;
    text-align: center;
    height: auto;
    max-height: 114px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last h3 {
    margin-bottom: 9px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p img {
    position: absolute;
    right: -2px;
    bottom: 46px;
    width: 50px;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All h3 {
    font-size: 30px;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All p {
    font-size: 22px;
  }

  .sub_wrap_all .subsection .pricetable-wrap .title_h2 {
    font-size: 24px;
  }
}

@media (min-width: 360px) and (max-width: 768px) {
  .sub_wrap_all .subsection {
    margin-bottom: 0;
  }

  .pick-home .service-type-wrap {
    background-image: url("image/mo-main-bg.svg");
  }

  .pick-home .application-card-wrap {
    background-image: url("image/mo-main-bg1.svg");
    background-size: 768px auto;
    background-position: 50% 0;
  }

  .pick-home .recommend-wrap {
    background-image: url("image/mo-main-bg2.svg");
  }

  .pick-home .main-news-wrap ul li .newsbox {
    border-radius: 0 8px 8px 0;
  }

  .sub_wrap_all .agencySection04 .right-box02 .agency_right_arrow {
    width: 16px !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide {
    width: 230px;
    height: 94px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active {
    width: 250px;
    height: 117px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
    position: absolute;
    top: -50px;
    right: -50px;
    width: 100px;
    height: 100px;
    z-index: 5;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    flex-wrap: nowrap;
    padding-right: 0;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll {
    height: 609px;
    flex-wrap: nowrap;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box {
    height: 75%;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    padding: 48px 0;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All strong {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All p {
    font-size: 16px;
    font-weight: 400;
  }



  .sub_wrap_all .newselfSection04 {
    margin: 0;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll {
    gap: 48px;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article01 .left-box01 {
    padding: 16px 24px;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .left-box {
    width: 360px;
    height: 228px;
  }

  .sub_wrap_all .newselfSection04 .left-box02 {
    text-align: center;
    width: 100%;
  }

  .pick-home .brand-value-top .brand-left-box .title-point {
    font-size: 30px;
  }

  .pick-home .brand-value-top .brand-left-box .mo-point {
    font-size: 36px;
  }

  .sub-final-wrap .final-title-wrap h5 span {
    line-height: 0;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box02 img {
    width: 360px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox h2 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox span {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-right: 0;
    margin-bottom: 24px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 360px;
    height: 282px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul {
    gap: 12px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
    font-weight: 600;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    text-align: start;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content {
    text-align: start;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content {
    width: 78%;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img {
    margin-left: 0;
    margin-right: 0;
  }



  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small {
    font-size: 10px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) {
    min-height: 102px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) {
    min-height: 96px;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:first-child img {
    width: 40px !important;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img {
    width: 40px !important;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img {
    width: 40px !important;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img {
    width: 40px !important;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(5) img {
    width: 40px !important;
  }

  .pick-home .final-title-wrap h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .sub-final-wrap .final-title-wrap h5 span {
    font-size: 20px;
    font-weight: 700;
  }

  .sub-final-wrap .final-title-wrap .main-final-move {
    font-size: 16px;
    font-weight: 700;
    width: 237px;
    height: 48px;
  }

  .sub-final-wrap .final-title-wrap .main-final-move img {
    width: 9px;
  }

  .sub_wrap_all .Newself-wrap {
    padding: 0;
  }

  .Newself-wrap .selfTrade-contentBox04 {
    padding: 48px 0;
  }

  .sub_wrap_all .subsection .pricetable-wrap .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .subsection .pricetable-wrap h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 8px 0 16px 0;
  }

  .sub_wrap_all .subsection .pricetable-wrap .title_p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  .sub_wrap_all .subsection .subarticle {
    padding: 0;
  }

  .pc-only {
    display: none;
  }

  .mo-only {
    display: block;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01 div {
    width: 360px;
    height: 228px;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01 div img {
    width: 360px;
    height: 228px;
    margin-left: 5px;
  }

  .sub_wrap_all .newselfSection02 {
    background: #fff8e7;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll {
    height: 609px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box {
    width: 100%;
    height: 314px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /*  */

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .li_mt {
    padding: 20px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    right: -45px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box {
    text-align: center;
    padding: 48px 0;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 16px;
    display: block;
    margin-bottom: 12px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box h3 {
    font-size: 28px;
    line-height: 1.3;
    color: #050f2f;
    margin-bottom: 16px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box p {
    font-size: 16px !important;
    letter-spacing: -0.2px;
    line-height: 1.5;
    color: #666;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll {
    padding: 0;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-left: 0;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: #050f2f;
    margin-bottom: 8px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color: #666;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 18px;
    line-height: 25.2px;
    margin-bottom: 12px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 14px;
    line-height: 19.6px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box-ul .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .li_mt {
    padding: 0;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul {
    padding-left: 0;
  }

  .sub_wrap_all .newselfSection04 .selfcontent-articleAll {
    margin: 48px auto;
  }

  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right {
    padding-bottom: 48px;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide {
    width: 230px;
    height: 94px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 250px;
    height: 117px !important;
    position: relative;
    transform: translate3d(15px, 99px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-65px, 0, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-65px, 0, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  /* 두번째 */
  .sub_wrap_all .agencySection04 .agencycontent-articleAll .left-box {
    width: 360px;
    height: 228px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub_wrap_all .agencySection04 .agencycontent-article04 {
    margin: 0;
  }

  .sub_wrap_all .agencySection04 .agencycontent-article01 .left-box01 div {
    width: 328px;
    height: 180px;
  }

  .sub_wrap_all .agencySection04 .agencytextbox-All strong {
    font-size: 12px;
    font-weight: 700;
  }


  .sub_wrap_all .agencySection04 .agencytextbox-All p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box {
    width: 100%;
    padding-left: 0;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box02 {
    width: 360px;
    height: 228px;
  }

  .sub_wrap_all .agencySection04 .right-box02 ul li:first-child {
    border-top: 1px solid #d9d9d9;
  }

  .sub_wrap_all .agencySection04 .agencytextbox-All .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .agencySection04 .agencytextbox-All .sub-title-h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .agencySection04 .agencycontent-article04 .agencytextbox-All .sub-text {
    font-size: 16px;
    font-weight: 500;
  }

  .sub_wrap_all .agencySection04 .agencytextbox-All .point-color {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_wrap_all .agencySection04 .left-box02 {
    padding-top: 0;
    width: 100%;
  }

  .sub_wrap_all .agencySection04 .right-box02 .agency_right_h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .sub_wrap_all .agencySection04 .right-box02 ul li>p {
    margin-bottom: 0;
    padding-left: 18px;
  }

  .sub_wrap_all .agencySection04 .right-box02 ul li>p::after {
    left: 3%;
    width: 2px;
    height: 2px;
  }

  .sub_wrap_all .agencySection04 .right-box02 ul li>.last_p::after {
    left: 3%;
    width: 2px;
    height: 2px;
  }

  .sub_wrap_all .agencySection04 .right-box02 ul li:first-child {
    padding: 25px 0;
  }

  .sub_wrap_all .agencySection04 .right-box02 ul li>p {
    font-size: 12px;
    font-weight: 400;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll {
    gap: 48px;
    padding-bottom: 48px;
    margin: 0;
  }

  .sub_wrap_all .agency-wrap {
    padding: 0;
  }

  .agency-wrap .selfTrade-contentBox04 {
    padding: 48px 0;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox h2 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox span {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: flex-start;
    flex-direction: row;
    min-height: 79px;
    width: 360px;
    padding: 16px;
    flex-wrap: nowrap;
    gap: 12px;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img {
    margin-left: 0;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: start;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 360px;
    height: 282px;
  }

  .sub_wrap_all .newselfSection02 .agencycontent-articleAll {
    height: 609px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box {
    text-align: center;
    padding: 48px 0;
    align-content: center;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 16px;
    display: block;
    margin-bottom: 12px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box h3 {
    font-size: 22px;
    line-height: 1.3;
    color: #050f2f;
    margin-bottom: 16px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box p {
    font-size: 16px !important;
    letter-spacing: -0.2px;
    line-height: 1.5;
    color: #666;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul {
    padding-left: 0;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll {
    flex-wrap: nowrap;
    width: 100%;
    height: 609px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box {
    width: 100%;
    height: 314px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_wrap_all .agencySection02 {
    padding: 0 0 0 0 !important;
    margin: 0 !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    right: -33px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: #050f2f;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color: #666;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .li_mt {
    padding: 0;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box-ul .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
    font-weight: 600;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub_wrap_all .agencySection04 .agencycontent-articleAll {
    padding: 48px 0;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide {
    width: 230px;
    height: 94px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 250px;
    height: 117px !important;
    position: relative;
    transform: translate3d(15px, 99px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 25.2px;
    margin-bottom: 8px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide p {
    font-size: 12px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    margin-bottom: 12px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-65px, 0, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-65px, 0, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  /* 세번째 */
  .sub_wrap_all .brandSection04 .brandcontent-articleAll .left-box {
    width: 360px;
    height: 228px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide {
    width: 230px;
    height: 94px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 250px;
    height: 117px !important;
    position: relative;
    transform: translate3d(15px, 99px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active b {
    font-size: 16px;
    font-weight: 700;
    line-height: 25.2px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-65px, 0, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-65px, 0, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  .sub_wrap_all .brandSection04 .brandcontent-article04 {
    margin: 0;
  }

  .sub_wrap_all .brandSection04 .brandcontent-article01 .left-box01 div {
    width: 328px;
    height: 180px;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All strong {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All h3 {
    margin: 8px 0 16px 0;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box {
    width: 100%;
    padding-left: 0;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box02 {
    width: 360px;
    height: 228px;
  }

  .sub_wrap_all .brandSection04 .right-box02 ul li:first-child {
    border-top: 1px solid #d9d9d9;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All .sub-title-h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .brandSection04 .brandcontent-article04 .brandtextbox-All .sub-text {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_wrap_all .brandSection04 .brandtextbox-All .point-color {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_wrap_all .brandSection04 .left-box02 {
    padding-top: 0;
    width: 100%;
  }

  .sub_wrap_all .brandSection04 .right-box02 .brand_right_h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .sub_wrap_all .brandSection04 .right-box02 ul li>p {
    margin-bottom: 0;
    padding-left: 30px;
  }

  .sub_wrap_all .brandSection04 .right-box02 ul li>p::after {
    left: 5%;
  }

  .sub_wrap_all .brandSection04 .right-box02 ul li:first-child {
    padding: 25px 0;
  }

  .sub_wrap_all .brandSection04 .right-box02 ul li>p {
    font-size: 12px;
    font-weight: 400;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll {
    gap: 40px;
    padding-bottom: 48px;
    margin: 0;
  }

  .sub_wrap_all .brand-wrap {
    padding: 0;
  }

  .brand-wrap .selfTrade-contentBox04 {
    padding: 48px 0;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox h2 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox span {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: flex;
    justify-content: center;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: flex-start;
    flex-direction: row;
    min-height: 79px;
    width: 360px;
    padding: 16px 24px;
    flex-wrap: nowrap;
    gap: 16px;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img {
    margin-left: 0;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: start;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 360px;
    height: 282px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll {
    height: 585px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box {
    text-align: center;
    padding: 48px 0;
    align-content: center;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 16px;
    display: block;
    margin-bottom: 12px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box h3 {
    font-size: 22px;
    line-height: 1.3;
    color: #050f2f;
    margin-bottom: 16px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box p {
    font-size: 16px !important;
    letter-spacing: -0.2px;
    line-height: 1.5;
    color: #666;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul {
    padding-left: 0;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box {
    width: 100%;
    height: 314px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_wrap_all .brandSection02 {
    padding: 0 0 0 0 !important;
    margin: 0 !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -60px;
    right: -50px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: #050f2f;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color: #666;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .li_mt {
    padding: 0;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box-ul .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .brand-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
    font-weight: 600;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub_wrap_all .brandSection04 .brandcontent-articleAll {
    padding: 48px 0;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #666;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide b {
    font-size: 14px;
    font-weight: 700;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active b {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide {
    width: 230px;
    height: 86px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-65px, 102px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 250px;
    height: 100px !important;
    position: relative;
    transform: translate3d(15px, 95px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-65px, 0, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-65px, 0, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-65px, 102px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 204px, 0px) !important;
  }

  .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 204px, 0px) !important;
  }

  /* 네번째 */
  .sub_wrap_all .potentialSection04 .potencontent-articleAll .left-box {
    width: 360px;
    height: 228px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub_wrap_all .potentialSection04 .pontencontent-article01 .left-box01 {
    padding: 16px 24px;
  }

  .sub_wrap_all .potentialSection04 .pontencontent-article03 .left-box01 div img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box02 img {
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .potencontent-article04 {
    margin: 0;
  }

  .sub_wrap_all .potentialSection04 .potencontent-article01 .left-box01 div {
    width: 328px;
    height: 180px;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All strong {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All h3 {
    margin: 8px 0 16px 0;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box {
    width: 100%;
    padding-left: 0;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll .right-box02 {
    width: 360px;
    height: 228px;
  }

  .sub_wrap_all .potentialSection04 .right-box02 ul li:first-child {
    border-top: 1px solid #d9d9d9;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All .sub-title-h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .potentialSection04 .potencontent-article04 .agencytextbox-All .sub-text {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_wrap_all .potentialSection04 .potentextbox-All .point-color {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_wrap_all .potentialSection04 .left-box02 {
    padding-top: 0;
    width: 100%;
  }

  .sub_wrap_all .potentialSection04 .right-box02 .agency_right_h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .sub_wrap_all .potentialSection04 .right-box02 ul li>p {
    margin-bottom: 0;
    padding-left: 30px;
  }

  .sub_wrap_all .potentialSection04 .right-box02 ul li>p::after {
    left: 5%;
  }

  .sub_wrap_all .potentialSection04 .right-box02 ul li:first-child {
    padding: 25px 0;
  }

  .sub_wrap_all .potentialSection04 .right-box02 ul li>p {
    font-size: 12px;
    font-weight: 400;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll {
    gap: 40px;
    padding-bottom: 48px;
    margin: 0;
  }

  .sub_wrap_all .poten-wrap {
    padding: 0;
  }

  .agency-wrap .selfTrade-contentBox04 {
    padding: 48px 0;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox h2 {
    font-size: 22px;
    font-weight: 700;
    margin: 8px 0 16px 0;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox span {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: flex-start;
    flex-direction: row;
    min-height: 79px;
    width: 360px;
    padding: 16px;
    flex-wrap: nowrap;
    gap: 12px;
    margin-bottom: 0;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img {
    margin-left: 0;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: start;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 100%;
    height: 100%;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll {
    height: 609px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box {
    text-align: center;
    padding: 48px 0;
    align-content: center;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 16px;
    display: block;
    margin-bottom: 12px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box h3 {
    font-size: 22px;
    line-height: 1.3;
    color: #050f2f;
    margin-bottom: 16px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box p {
    font-size: 16px !important;
    letter-spacing: -0.2px;
    line-height: 1.5;
    color: #666;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul {
    padding-left: 0;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll {
    flex-wrap: nowrap;
    width: 100%;
    height: 609px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box {
    width: 100%;
    height: 314px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_wrap_all .potentialSection02 {
    padding: 0 0 0 0 !important;
    margin: 0 !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    right: -45px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 18px;
    line-height: 25.2px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active p {
    font-size: 14px;
    line-height: 19.6px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: #050f2f;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color: #666;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .li_mt {
    padding: 0;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box-ul .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active {
    width: 250px !important;
    height: 117px !important;
  }

  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
    font-weight: 600;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub_wrap_all .potentialSection04 .potencontent-articleAll {
    padding: 48px 0;
  }

  .sub-final-wrap .final-title-wrap .potential-final-top .main-final-move {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.8px;
    width: 286px;
    height: 48px;
  }

  .sub-final-wrap .final-title-wrap .main-final-top {
    padding-bottom: 48px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide {
    width: 230px;
    height: 94px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 250px;
    height: 117px !important;
    position: relative;
    transform: translate3d(15px, 99px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 25.2px;
    margin-bottom: 8px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide p {
    font-size: 12px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    margin-bottom: 12px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-65px, 0, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-65px, 0, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  /* 다섯번째 */
  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .left-box {
    width: 360px;
    height: 228px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-article04 {
    margin: 0;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-article01 .left-box01 div {
    width: 328px;
    height: 180px;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All strong {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All h3 {
    margin: 8px 0 16px 0;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box {
    width: 100%;
    padding-left: 0;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box02 {
    width: 360px;
    height: 228px;
  }

  .sub_wrap_all .monitorSection04 .right-box02 ul li:first-child {
    border-top: 1px solid #d9d9d9;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All .sub-title-h3 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-article04 .monitortextbox-All .sub-text {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_wrap_all .monitorSection04 .monitortextbox-All .point-color {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_wrap_all .monitorSection04 .left-box02 {
    padding-top: 0;
    width: 100%;
  }

  .sub_wrap_all .monitorSection04 .right-box02 .brand_right_h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .sub_wrap_all .monitorSection04 .right-box02 ul li>p {
    margin-bottom: 0;
    padding-left: 30px;
  }

  .sub_wrap_all .monitorSection04 .right-box02 ul li>p::after {
    left: 5%;
  }

  .sub_wrap_all .monitorSection04 .right-box02 ul li:first-child {
    padding: 25px 0;
  }

  .sub_wrap_all .monitorSection04 .right-box02 ul li>p {
    font-size: 12px;
    font-weight: 400;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll {
    gap: 40px;
    padding-bottom: 48px;
    margin: 0;
  }

  .sub_wrap_all .monitor-wrap {
    padding: 0;
  }

  .monitor-wrap .selfTrade-contentBox04 {
    padding: 48px 0;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .sub-title-point {
    font-size: 12px;
    font-weight: 700;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox h2 {
    font-size: 22px;
    font-weight: 700;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox span {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    display: flex;
    justify-content: center;
    width: 360px;
    height: 282px;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: flex-start;
    flex-direction: row;
    min-height: 79px;
    width: 360px;
    padding: 16px 24px;
    flex-wrap: nowrap;
    gap: 16px;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img {
    margin-left: 0;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p {
    text-align: start;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
    width: 360px;
    height: 282px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll {
    height: 609px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box {
    text-align: center;
    padding: 48px 0;
    align-content: center;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box strong {
    color: #319dff;
    font-size: 12px;
    font-weight: 700;
    display: block;
    margin-bottom: 12px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box h3 {
    font-size: 22px;
    line-height: 1.3;
    color: #050f2f;
    margin-bottom: 16px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box p {
    font-size: 16px !important;
    letter-spacing: -0.2px;
    line-height: 1.5;
    color: #666;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .left-box-ul {
    padding-left: 0;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll {
    flex-wrap: nowrap;
    width: 100%;
    height: 609px;
    display: flex;
    flex-direction: column;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll div:nth-child(1) {
    order: 2;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box {
    width: 100%;
    height: 314px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_wrap_all .monitorSection02 {
    padding: 0 0 0 0 !important;
    margin: 0 !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    right: -40px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    color: #050f2f;
    margin-bottom: 8px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.2px;
    color: #666;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .li_mt {
    padding: 0;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box-ul .li_mt {
    margin-left: 20px;
  }

  .sub_wrap_all .monitor-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3 {
    font-size: 16px;
    font-weight: 600;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub_wrap_all .monitorSection04 .monitorcontent-articleAll {
    padding: 48px 0;
  }

  .monitorSection06 .monitorcontent-article01 {
    padding: 48px 0;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-top h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    letter-spacing: -0.002em;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-top h2 span {
    font-size: 22px;
    font-weight: 700;
    line-height: 30.8px;
    letter-spacing: -0.002em;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-top p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.002em;
    margin: 16px 0 40px 0;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot {
    width: 100%;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul {
    gap: 16px;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 24px;
    justify-content: space-between;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: -0.002em;
    margin-bottom: 8px;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.002em;
  }

  .monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li span {
    margin: 0 0 4px 0;
    width: 48px;
    height: 20px;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.002em;
  }

  .sub-final-wrap .final-title-wrap .monitor-final-top .main-final-move {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.8px;
    width: 299px;
    height: 48px;
  }

  .selfTradeName-wrap .poten-contentBox04 {
    padding: 48px 0 48px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide {
    width: 230px;
    height: 94px !important;
    border-radius: 10px;
    padding: 0;
    margin: 0;
    opacity: 0.4 !important;
    background-color: #ffffff;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    position: absolute;
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active {
    z-index: 1;
    background-color: #ffffff;
    opacity: 1 !important;
    width: 250px;
    height: 117px !important;
    position: relative;
    transform: translate3d(15px, 99px, 0px) !important;
    transition: opacity 0.5s ease-in-out !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next {
    transform: translate3d(-65px, 0, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-prev {
    transform: translate3d(-65px, 0, 0px) !important;
    transition-duration: 0ms !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next+.swiper-slide {
    transform: translate3d(-65px, 110px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-prev+.swiper-slide+.swiper-slide {
    transform: translate3d(-65px, 220px, 0px) !important;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 25.2px;
    margin-bottom: 8px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide p {
    font-size: 12px;
    font-weight: 400;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    margin-bottom: 12px;
  }

  .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active p {
    font-size: 14px;
    font-weight: 400;
  }

  .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-bottom: 24px;
  }

  .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left {
    margin-bottom: 24px;
  }
}

/* 3/27 반응형 모바일*/
@media (min-width: 360px) and (max-width: 768px) {

  .mobile-header .header-wrap .hamburger,
  .mobile-header .header-wrap .hamburger img {
    width: 40px;
  }

  .brand-value-top.default {
    padding-top: 0;
  }

  .brand-value-top {
    height: 100%;
  }

  .brand-content-wrap.default {
    padding-top: 0;
  }

  .pick-home .brand-value-wrap {
    height: 100%;
    margin-top: 24px;
  }

  .pick-home .brand-value-wrap .brand-content-wrap .brand-value-bottom .brand-value-bottom-right li .main-navbox .newMain01-btn02 p {
    white-space: nowrap;
  }

  /* 로그인 */
  .sign-in-wrap,
  .sing-wrap-all {
    height: 100%;
    padding: 32px 20px;
  }

  .sign-in-wrap .login-img01,
  .sing-wrap-all .sign-upImg {
    width: 144px;
  }

  .sign-in-wrap .gray-span,
  .sing-wrap-all .sign-titleName,
  .sign-up.three span {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .sign-in-wrap .login-formBox {
    margin-top: 20px;
    padding: 20px 16px;
  }

  .sign-in-wrap .form-wrap input[type="text"],
  .sign-in-wrap .form-wrap input[type="password"] {
    border-radius: 0;
    width: 100%;
  }

  .sign-in-wrap .login-formBox strong,
  .find-pw .findPw-wrap strong {
    font-size: 14px;
  }

  .container .common-checkBox span {
    font-size: 14px;
  }

  .sign-in-wrap .form-wrap .find-wrap span {
    font-size: 14px;
  }

  .sign-in-wrap .form-wrap .check-wrap .common-checkBox {
    margin-right: 0;
  }

  .sign-in-wrap .form-wrap .btn-wrap>button {
    border-radius: 4px;
    font-size: 16px;
    font-family: "PretendardSB", "sans-serif";
  }

  .sign-in-wrap .form-wrap .btn-wrap .naver-btn,
  .sign-up.one .authorize .naver-btn {
    gap: 30px;
    justify-content: center;
    padding-left: 0;
  }

  /* 아이디 찾기 */
  .find-id,
  .check-id,
  .find-pw,
  .change-pw,
  .complete-change-pw {
    padding: 0 20px;
  }

  .find-id .findId-wrap,
  .check-id .findId-wrap,
  .find-pw .findPw-wrap,
  .change-pw .changePw-wrap,
  .complete-change-pw .completePw-wrap,
  .sing-wrap-all .sign-up-contwrap,
  .sign-up.three .three-wrap {
    margin: 32px 20px;
    width: 100%;
    padding: 20px 16px;
  }

  .sing-wrap-all.two .sign-up-contwrap {
    margin: 20px;
  }

  .sing-wrap-all.two .enter-hr {
    margin-bottom: 16px;
  }

  .sign-up .enter-info-title {
    margin: 32px 0 0 0;
  }

  .sign-up.two .enter-info-titleBox p {
    display: none;
  }

  .sign-up.two .input-wrap span p::before {
    display: none;
  }

  .find-id .findId-wrap img,
  .check-id .findId-wrap img,
  .complete-change-pw .completePw-wrap img,
  .sign-up.three .three-wrap .checkImg {
    margin: 24px auto;
    width: 80px;
    height: 80px;
  }

  .sign-up .input-id-wrap div input {
    width: 100%;
  }

  .find-id .findId-wrap span,
  .check-id .findId-wrap span,
  .change-pw .changePw-wrap span,
  .sign-up.three h3 {
    font-size: 14px;
    border-bottom: none;
    margin-bottom: 0;
  }

  .sign-up.three h3 {
    margin-bottom: 12px;
  }

  .check-id .findId-wrap span {
    padding-bottom: 0;
  }

  .sign-up.three .desc {
    font-size: 14px;
    margin-top: 12px;
  }

  .find-id .findId-wrap span {
    padding-bottom: 24px;
  }

  .find-id .findId-wrap .btn-wrap {
    flex-direction: column-reverse;
    gap: 12px;
  }

  .check-id .findId-wrap .btn-wrap {
    flex-direction: column;
    gap: 12px;
  }

  .check-id .btn-wrap {
    margin-top: 10px;
  }

  .find-id .findId-wrap .btn-wrap button,
  .find-id .findId-wrap .find-id-prev,
  .check-id .findId-wrap .btn-wrap button {
    width: 100% !important;
    height: 48px;
    font-size: 16px;
    font-family: "PretendardSB", "sans-serif";
    border-radius: 4px;
  }

  .check-id .findId-wrap .btn-wrap .blue-btn,
  .check-id .findId-wrap .btn-wrap .border-btn,
  .container .btn-wrap button.blue-btn,
  .find-pw .findPw-wrap .blue-border-btn,
  .sign-up.two .btn-wrap .gray-btn {
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    font-family: "PretendardSB", "sans-serif";
  }

  .find-id .findId-wrap .find-id-prev,
  .check-id .findId-wrap .btn-wrap .border-btn,
  .find-pw .findPw-wrap .blue-border-btn,
  .sign-up.two .btn-wrap .gray-btn {
    border: 1px solid #d1d1d1 !important;
  }

  .input-email-wrap {
    gap: 4px;
  }

  .input-email-wrapper .input-email-wrap input:nth-of-type(1),
  .input-email-wrapper .input-email-wrap input:nth-of-type(2) {
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sign-up.three .btn-wrap .blue-btn {
    width: 100%;
    margin-top: 24px;
  }

  .sign-up.three hr {
    display: none;
  }

  .find-pw .findPw-wrap .findPw-desc {
    font-size: 14px;
    margin: 10px auto 24px auto;
    padding-top: 0;
  }

  .find-pw .btn-wrap,
  .change-pw .btn-wrap {
    margin-top: 4px;
  }

  .sign-up .input-phone-wrap {
    margin-bottom: 32px;
  }

  .find-pw .findPw-wrap .btn-wrap,
  .change-pw .changePw-wrap .btn-wrap,
  .complete-change-pw .completePw-wrap .btn-wrap {
    border-top: none;
  }

  .change-pw .changePw-wrap .btn-wrap,
  .complete-change-pw .completePw-wrap .btn-wrap {
    padding-top: 0;
  }

  .change-pw .changePw-wrap h3,
  .complete-change-pw .completePw-wrap h3 {
    font-size: 14px;
  }

  .complete-change-pw .completePw-wrap h3 {
    margin-bottom: 0;
  }

  .complete-change-pw .btn-wrap {
    margin-top: 24px;
  }

  .sign-up h4 {
    font-size: 18px;
    width: 100%;
    margin-bottom: 16px;
  }

  .sign-up.one .white-area-wrap {
    padding: 16px 12px;
  }

  .sign-up.one .white-area-wrap .span-wrap {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .sign-up.one .white-area {
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 12px;
  }

  .sign-up.one .white-area:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .sign-up.one .white-area-wrap span {
    font-size: 14px;
  }

  .sign-up.one .authorize button {
    height: 48px;
    font-size: 16px;
    font-family: "PretendardSB", "sans-serif";
    border-radius: 4px;
  }

  .sign-up.one .white-area .view-text {
    font-size: 14px;
    white-space: nowrap;
  }

  .footer {
    padding: 24px 20px;
  }

  .modal-bg .terms-modal {
    width: 340px;
    height: 485px;
    padding: 24px 16px;
  }

  .modal-bg .terms-modal>h1 {
    font-size: 18px;
    margin-bottom: 12px;
    border-bottom: 1px solid #050f2f;
    width: 100%;
    text-align: center;
    padding-bottom: 12px;
  }

  .modal-bg .terms-modal .sign-up-desc {
    width: 100%;
  }

  .sign-up-contwrap .row {
    width: 100%;
  }

  .modal-bg .terms-modal .btn-wrap>button {
    width: 100%;
    height: 38px;
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
  }

  .sign-up.two .btn-wrap {
    flex-direction: column-reverse;
    gap: 12px;
  }

  .sign-up .input-id-wrapper {
    gap: 4px;
  }

  .sign-up.two .btn-wrap .gray-btn {
    margin-top: 0;
  }

  .input-email-wrap {
    width: 100%;
  }

  .sign-up.two .input-wrap .input-email-wrap .selectBox .selectVal {
    width: 90px;
    padding: 15px 8px;
  }

  .sign-up.two .end-hr {
    display: none;
  }

  .sign-up .input-id-wrap div .blue-border-btn {
    margin-left: 0;
    white-space: nowrap;
    font-family: "PretendardSB", "sans-serif";
  }

  .sign-up.two .btn-wrap button {
    margin-top: 0;
    width: 100%;
  }

  /* 셀프 상표 모바일*/
  .temp-modal,
  .template-modal {
    width: 90%;
    padding: 24px;
  }

  .temp-modal .closeBtn {
    display: none;
  }

  .temp-modal>.head p,
  .self-application-wrap .modal-overlay.issue>.issue-modal>.head>p,
  .template-modal>.head p {
    font-family: "PretendardSB", "sans-serif";
  }

  .template-modal .txt-wrap p {
    display: none;
  }

  .template-modal .txt-wrap .template-input {
    width: 100%;
    margin-left: 0;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal>.head>p {
    font-size: 16px;
  }

  .temp-modal .txt-wrap,
  .self-application-wrap .modal-overlay.issue>.issue-modal .content>p {
    font-size: 14px;
    color: #585858;
    border: none;
    padding: 0;
    line-height: 140%;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap>div>a:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal .content .issue-btn-wrap>div>a>button {
    width: 240px;
    display: flex;
    justify-content: space-between;
  }

  .site-title {
    position: static;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap>div {
    line-height: 140%;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap>div br {
    display: none;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap {
    flex-direction: column;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal .issue-guide>.txt-wrap>button {
    width: 100%;
    padding: 5px 0;
    margin-top: 10px;
  }

  .self-appliction-step {
    margin: 20px auto;
  }

  .self-application-wrap .self-appliction-step.one {
    content: url("../../image/progress_bar1.svg");
  }

  .self-application-wrap .self-appliction-step.two {
    content: url("../../image/progress_bar2.svg");
  }

  .self-application-wrap .self-appliction-step.three {
    content: url("../../image/progress_bar3.svg");
  }

  .self-application-wrap .self-appliction-step.four {
    content: url("../../image/progress_bar4.svg");
  }

  .self-application-wrap .self-appliction-step.five {
    content: url("../../image/progress_bar5.svg");
  }

  .agencies .self-appliction-step.one {
    content: url("../../image/progress_agency_1.svg");
  }

  .agencies .self-appliction-step.two {
    content: url("../../image/progress_agency_2.svg");
  }

  .agencies .self-appliction-step.three {
    content: url("../../image/progress_agency_3.svg");
  }

  .agencies .self-appliction-step.four {
    content: url("../../image/progress_agency_4.svg");
  }

  .agencies .self-appliction-step.five {
    content: url("../../image/progress_agency_5.svg");
  }

  .self-application-wrap .self-application .applicant-info-wrap,
  .self-application-wrap .content-box,
  .self-application-wrap .final-info-wrap,
  .agencies .content-box {
    width: 100%;
    padding: 24px 16px;
    border-radius: 8px;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-head {
    flex-direction: column;
    padding-bottom: 0;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 20px;
  }

  .select-mark .content-box .title-line {
    display: none;
  }

  .select-mark .content-box .content p {
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-head>h4,
  .self-application-wrap .content-box h3,
  .agencies .content-box h3 {
    font-size: 18px;
    width: 100%;
    border-bottom: 1px solid #050f2f;
    padding-bottom: 13px;
  }

  .self-application-wrap .content-box h3,
  .agencies .content-box h3 {
    margin-bottom: 0;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-head>div {
    margin: 20px auto;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container,
  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head.direct,
  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head,
  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct,
  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .link-wrap.direct,
  .select-mark .content-box .content>.row .column {
    width: 100%;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>input {
    width: calc(100% - 100px);
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap>input {
    font-size: 14px;
    padding-left: 12px;
    line-height: 140%;
  }

  .select-mark .content-box .content>.row {
    flex-direction: column;
    gap: 8px;
  }

  .select-mark .content-box .content>.row .column {
    margin-right: 0;
    width: 100%;
    height: 48px;
    flex-direction: row;
    padding: 15px 12px;
    gap: 6px;
  }

  .select-mark .content-box .content>.row .column h5 {
    margin-bottom: 0;
  }

  .select-mark .content-box .content>.row .column .mark-type {
    flex-direction: row;
    gap: 2px;
  }

  .select-mark .content-box .content-line {
    margin-top: 20px;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>.share-input-wrap>input,
  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>.share-input-wrap>input::placeholder,
  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap.direct>.share-input-wrap>span {
    font-size: 14px;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container>.head>strong {
    font-size: 14px;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap>input,
  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap>input:last-child,
  .self-application-wrap .final-info-wrap,
  .select-classification .content-box .classification .classification-wrap {
    width: 100%;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .input-wrap {
    width: 100%;
  }

  .self-application-wrap {
    padding: 16px 20px;
  }

  .self-application-wrap .modal-overlay.issue>.issue-modal,
  .select-product .modal-bg .modal,
  .agencies .modal-bg .warrant {
    width: 90%;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container {
    margin-top: 20px;
  }

  .self-application-wrap .self-application .applicant-info-wrap .regi-content>.input-container .line {
    width: 100%;
    margin-bottom: 20px;
  }

  .self-application-wrap .self-application>.issue-btn-container,
  .self-application-wrap .btn-wrap,
  .self-application-wrap .step5-btn-wrap,
  .agencies .btn-wrap {
    width: 100%;
    flex-direction: column;
    margin: 20px auto;
    gap: 8px;
  }

  .self-application-wrap .self-application>.issue-btn-container>button,
  .self-application-wrap .btn-wrap button,
  .self-application-wrap .step5-btn-wrap button,
  .agencies .btn-wrap button {
    margin-right: 0;
    width: 100% !important;
    border-radius: 4px;
    font-size: 16px;
    font-family: "PretendardSB", "sans-serif";
  }

  .select-mark .content-box .content .input-wrap {
    justify-content: flex-start;
  }

  .select-mark .content-box .content .input-wrap .row .common-text-input {
    width: calc(100% - 60px);
    margin: 0px 6px;
  }

  .select-mark .content-box .content .input-wrap .row span {
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
  }

  .select-mark .content-box .content .preview,
  .select-mark .content-box .content .upload,
  .select-mark .content-box .content .input-wrap,
  .select-mark .content-box .content .upload .file-upload-zone,
  .select-mark .content-box .content .upload .compound-file-upload,
  .select-mark .content-box .content .upload .compound-file-upload .file-upload-zone,
  .select-mark .content-box .content .upload .compound-file-upload .preview,
  .select-classification .content-box .direct-input-wrap input,
  .select-classification .content-box .none-list,
  .possibility-wrap main,
  .possibility-wrap main .possibility-step1,
  .possibility-wrap main .possibility-step2 {
    width: 100%;
  }

  .select-mark .content-box .content>.row .column:hover {
    border: 1px solid #319dff;
  }

  .select-classification .content-box .direct-input-wrap {
    margin-top: 8px;
  }

  .select-mark .content-box .content .upload .file-upload-zone {
    height: 150px;
  }

  .select-mark .content-box .content .upload .file-upload-zone .column img {
    width: 80px;
    margin-bottom: 10px;
  }

  .select-mark .content-box .content .preview {
    margin-top: 10px;
  }

  .select-mark .content-box .content .upload .upload-file .upload-file-btn,
  .select-mark .content-box .content .upload .upload-file .upload-file-btn {
    white-space: nowrap;
  }

  .select-mark .content-box .content .upload .upload-file .upload-file-btn span,
  .select-mark .content-box .content .upload .upload-file span {
    font-size: 14px;
  }

  .select-mark .content-box .content .upload .file-upload-zone>img {
    width: 120px;
    height: 120px;
  }

  .select-mark .content-box .content .upload .compound-file-upload .file-upload-zone {
    height: 150px;
  }

  .select-mark .content-box .content .upload .compound-file-upload {
    height: 100%;
  }

  .select-mark .content-box .content .upload .upload-file .file-name,
  .select-mark .content-box .content .upload .upload-file .file-name {
    width: 130px;
  }

  .select-mark .content-box .content .upload .compound-file-upload .file-upload-zone>img {
    width: 140px;
    height: 140px;
  }

  .select-mark .content-box .content>.row .column.active {
    border: 1px solid #319dff;
  }

  /*3단계...*/
  .select-classification .content-box .select-wrap .select-box {
    width: 100%;
  }

  .self-application-wrap .self-application>.self-classification>.content-box .category-line,
  .self-application-wrap .self-application>.select-product>.content-box .category-line,
  .agencies .content-box .category-line {
    display: none;
  }

  .select-classification .content-box .select-wrap {
    margin: 20px auto;
    flex-direction: column;
    gap: 8px;
  }

  .select-classification .content-box .select-wrap .select-box:first-child {
    margin-right: 0;
  }

  .select-classification .content-box .select-wrap .select-box {
    flex-direction: row;
    height: 48px;
    gap: 6px;
    justify-content: flex-start;
    padding-left: 12px;
  }

  .select-classification .content-box .select-wrap .select-box span {
    margin-top: 0;
  }

  .self-classification>.content-box>.content>p {
    margin-top: 20px;
    padding-left: 0;
  }

  .self-application-wrap .final-info-wrap>.head h4 {
    padding-left: 0;
    font-size: 16px;
  }

  .self-application-wrap .final-info-wrap>.head.mt-56>h4 {
    margin-top: 20px;
  }

  .self-application-wrap .final-info-wrap>.head>hr {
    margin: 12px auto;
  }

  .self-application-wrap .final-info-wrap>.head .img-wrap {
    margin-bottom: 0;
  }

  .self-application-wrap .final-info-wrap .final-btn-wrap {
    margin-top: 20px;
  }

  .self-application-wrap .final-info-wrap>.head>.info-wrap p,
  .self-application-wrap .final-info-wrap>.head .img-wrap>div:first-child {
    margin-right: 0;
    border-radius: 0;
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
    width: 100px;
  }

  .self-application-wrap .final-info-wrap>.head>.info-wrap>div,
  .self-application-wrap .final-info-wrap>.head .img-wrap>div:last-child {
    width: calc(100% - 100px);
    border-radius: 0;
    border-left: none;
  }

  .self-application-wrap .final-info-wrap>.head>.info-wrap {
    margin-bottom: 0;
  }

  .self-application-wrap .final-info-wrap>.head .img-wrap>div:first-child {
    min-width: 100px;
  }

  .self-application-wrap .final-info-wrap>.head .img-wrap>div:last-child {
    margin-left: 0;
  }

  .select-classification .content-box select {
    width: 100%;
    border: 1px solid #d1d1d1;
    margin-top: 8px;
  }

  .select-classification .content-box select {
    font-size: 14px;
    padding: 15px;
  }

  .select-classification .content-box .placeholder-option {
    color: #666;
  }

  .select-classification .content-box .classification .classification-wrap>.column span:first-child {
    font-family: "PretendardSB", "sans-serif";
    color: #585858;
  }

  .select-classification .content-box .direct-input-wrap button {
    width: 57px;
    margin-left: 8px;
    padding: 0;
  }

  .select-classification .content-box .classification {
    margin-top: 6px;
  }

  .select-classification .content-box .none-list {
    font-size: 14px;
    height: 52px;
  }

  .select-classification .content-box .direct-input-wrap input {
    font-size: 14px;
  }

  .select-classification .content-box .selectList-wrap,
  .select-classification .content-box .selectList-wrap .btnList,
  .select-classification .content-box .selectList-wrapper,
  .select-classification .content-box .selectList-wrapper h5,
  .select-classification .content-box .selectList-category,
  .select-classification .content-box .selectList-category .optionItem,
  .select-product .content .optionItem-wrap,
  .select-product .content,
  .select-product .content .selectList-wrap,
  .select-product .content .product-wrap,
  .select-product .select-total-product {
    width: 100%;
  }

  .select-product .content .topBar {
    margin: 20px auto;
  }

  .select-product .content {
    padding: 0;
  }

  .select-product .select-product-subTitle {
    font-size: 14px;
  }

  .select-product .topBar p {
    font-size: 12px;
  }

  .select-product .content .selectList-wrap article {
    width: 29px;
  }

  .select-product .content .selectList-wrap .btnList {
    width: calc(100% - 60px);
  }

  .select-product .content .product-wrap .product-list {
    padding: 5px;
  }

  .select-product .content .product-wrap .product {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .select-product .content .product-button-wrap .empty-btn,
  .select-product .content .product-button-wrap {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .select-product .content .product-button-wrap button,
  .select-product .content .product-button-wrap button {
    margin-right: 0;
  }

  .select-product .select-total-product .kind-group .kind-itemList {
    padding: 10px;
  }

  .select-product .modal-bg .modal {
    padding: 24px 16px;
  }

  .select-product .modal-bg .modal .input-section .common-text-input {
    width: 100%;
  }

  .select-product .modal-bg .modal .input-section span {
    display: none;
  }

  .content-wrap .btn-wrap.side {
    flex-direction: row;
  }

  .select-product .modal-bg .modal .input-section.search .common-text-input {
    width: calc(100% - 65px);
  }

  .select-product .modal-bg .modal .input-section.search .search-btn {
    width: 65px;
    font-size: 14px;
  }

  .select-product .modal-bg .input-table-wrap {
    margin-top: 16px;
  }

  .select-product .modal-bg .input-table-wrap,
  .select-classification .content-box .content>p,
  .agencies.input-applicant .form,
  .agencies.input-applicant .form>.row input[type="text"].common-input {
    width: 100%;
  }

  .select-product .modal-bg .input-table-wrap .t-head .content,
  .select-product .modal-bg .input-table-wrap .t-body .content {
    width: calc(100% - 36px);
  }

  .self-application-wrap .final-info-wrap>.head>.info-wrap>div {
    padding: 8px 12px;
    line-height: 140%;
  }

  .self-application-wrap .final-info-wrap .info-table {
    width: 100%;
    border: 1px solid #D1D1D1;
  }

  .self-application-wrap .final-info-wrap .info-table th {
    background-color: #F5FAFF;
    width: 100px;
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
    vertical-align: middle;
    border-bottom: 1px solid #d1d1d1;
  }

  .self-application-wrap .final-info-wrap .info-table td {
    text-align: start;
    font-size: 14px;
  }

  .self-application-wrap .final-info-wrap .info-table td div {
    font-size: 14px;
    line-height: 130%;
  }

  .self-application-wrap .final-info-wrap .info-table td img {
    border: 1px solid #d1d1d1;
    width: 100px;
    height: 100px;
    margin-bottom: 8px;
  }

  .self-application-wrap .final-info-wrap .info-table td button {
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    font-size: 12px;
    height: 25px;
    width: 100px;
    white-space: nowrap;
  }

  .self-application-wrap .final-info-wrap .info-table td .product-wrap {
    display: flex;
    margin-bottom: 5px;
    gap: 5px;
  }


  .self-application-wrap .final-info-wrap .info-table td .product-wrap strong {
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
    white-space: nowrap;
  }

  .self-application-wrap .final-info-wrap .info-table td .product-wrap ul li {
    font-size: 14px;
  }

  .select-mark .content-box .content>.row .column.active h5,
  .select-mark .content-box .content>.row .column span {
    white-space: nowrap;
  }

  /* 등록대행 */
  .container .agency-wrap {
    align-items: flex-start;
    padding: 16px 20px;
  }

  .select-classification .content-box .selectList-wrapper h5 {
    margin-top: 20px;
  }

  .agencies .secondLine {
    margin-bottom: 20px;
  }

  .agency-wrap.select-classification .content-box .content>p {
    text-align: start;
    margin-top: 20px;
    font-size: 14px;
    padding-left: 0;
  }

  .select-product .content .tooltip-text {
    transform: translateX(-20%);
  }

  .select-product .content .tooltip-text::after {
    transform: translateX(-86px);
  }

  .agencies.input-applicant .content-box .topBar {
    flex-direction: column;
    align-items: flex-start;
  }

  .agencies.input-applicant .content-box .topBar .select-target {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 16px;
  }

  .agencies.input-applicant .form {
    margin-top: 20px;
  }

  .agencies.input-applicant .form>.row {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  .agencies.input-applicant .form>.row .essential,
  .agencies.input-applicant .form>.row .optional {
    font-family: "PretendardSB", "sans-serif";
    font-size: 14px;
  }

  .agencies.input-applicant .form>.row input[type="text"].id-input,
  .agencies.input-applicant .form>.row input[type="password"].id-input {
    width: calc((100% - 15px) / 2);
  }

  .agencies.input-applicant .form>.row .hyphen {
    margin: 0 4px;
  }

  .agencies.input-applicant .form>.row input[type="text"].email-input {
    width: calc((100% - 106px) / 2);
  }

  .agencies.input-applicant .form>.row input[type="text"].phone-input {
    width: calc((100% - 30px) / 3);
  }

  .agencies.input-applicant .form>.row .email-input-wrap {
    display: flex;
  }

  .agencies.input-applicant .form>.row .at {
    margin: 0 4px;
  }

  .agencies.input-applicant .form>.row .selectBox .selectVal,
  .agencies.input-applicant .form>.row .selectBox {
    width: 90px;
  }

  .agencies.input-applicant .form>.row .selectBox .selectVal span {
    font-size: 14px;
  }

  .agencies.input-applicant .form>.row .selectBox {
    margin-left: 4px;
  }

  .agencies.input-applicant .form>.row input {
    font-size: 14px;
  }

  .agencies.input-applicant .content-box .topBar .common-checkBox:last-child {
    margin-right: 20px;
  }

  .agencies.input-applicant .form>.row input[type="text"].search-input {
    width: calc(100% - 80px);
  }

  .agencies.input-applicant .form>.row .search-btn {
    width: 73px;
    margin-left: 4px;
    font-size: 14px;
  }

  .agencies.input-applicant .form>.row.address {
    margin-bottom: 4px;
  }

  .agencies.input-applicant .content-box .dashed {
    width: 100%;
    margin: 20px auto;
  }

  .agencies.input-applicant .content-box .dashed.foreign {
    width: 100%;
  }

  .agencies.input-applicant .content-box .agree-wrap {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
  }

  .agencies.input-applicant .content-box .agree-wrap .agree-title {
    font-size: 18px;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid #050f2f;
    font-family: "PretendardSB", "sans-serif";
  }

  .agencies.input-applicant .content-box .agree-wrap>.column {
    width: 100%;
    margin-top: 16px;
  }

  .agencies.input-applicant .content-box .agree-wrap>.column .row .agree-context.bold {
    font-family: "PretendardSB", "sans-serif";
    margin-bottom: 16px;
  }

  .agencies.input-applicant .content-box .agree-wrap>.column .sub-agree .row:first-child {
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 10px;
    width: 100%;
    justify-content: flex-start;
  }

  .agencies.input-applicant .content-box .agree-wrap>.column .sub-agree {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f6f6f6;
    padding: 16px 12px;
    gap: 12px;
  }

  .agencies.input-applicant .content-box .agree-wrap>.column .sub-agree .row {
    margin-bottom: 0;
    font-size: 14px;
  }

  .agencies.input-applicant .guide {
    transform: translate(0%, 20px);
    width: 300px;
  }

  .agencies.input-applicant .guide.privacy {
    width: 300px;
    transform: translate(-50%, -130px);
  }

  .agencies.input-applicant .form>.row .selectBox .selectVal {
    padding: 8px;
  }

  .agencies.input-applicant .content-box .agree-wrap>.column .row {
    margin-bottom: 0;
  }

  .agencies.input-applicant .form>.row,
  .agencies.input-applicant .form>.row .email-input-wrap,
  .select-product .content .product-button-wrap button {
    width: 100%;
  }

  .agencies .content-box .content.mark {
    width: 100%;
  }

  .agencies.input-applicant .form>.row .file-name-input {
    width: calc(100% - 77px);
    font-size: 14px;
  }

  .agencies.input-applicant .form>.row .file-input-wrap {
    width: 100%;
  }

  .agencies.input-applicant .form>.row .file-input-wrap.foreign {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .agencies.input-applicant .form>.row .file-input-wrap.foreign .file-name-input {
    width: calc(100% - 108px);
  }

  .agencies.input-applicant .form>.row .copy-title {
    white-space: nowrap;
    font-size: 14px;
    margin-right: 8px;
  }

  .agencies.input-applicant .form>.row .file-input .select-file-button,
  .agencies.input-applicant .form>.row .copy-btn {
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
    padding: 13px 10px;
    white-space: nowrap;
    width: 73px;
    margin-left: 4px;
  }

  .agencies.input-applicant .form>.row .check-wrap {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding: 10px 0;
  }

  .agencies .modal-bg .warrant {
    padding: 24px 16px;
    height: 634px;
  }

  .agencies .modal-bg .warrant .warrant-title {
    font-size: 18px;
  }

  .agencies .modal-bg .warrant hr {
    border-bottom: 1px solid #050f2f;
    margin-top: 12px;
  }

  .agencies .modal-bg .scroll-wrap {
    height: 460px;
    width: 100%;
  }

  .agencies .modal-bg .warrant .info {
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }

  .agencies .modal-bg .agree-btn {
    width: 100%;
  }

  .agencies .modal-bg .search-country {
    width: 90%;
    padding: 24px 16px;
  }

  .agencies .modal-bg .search-country hr {
    display: none;
  }

  .agencies .modal-bg .search-country h3 {
    margin-bottom: 16px;
  }

  .agencies .modal-bg .search-country>.row .common-text-input {
    width: calc(100% - 65px);
  }

  .agencies .modal-bg .search-country .searchCountryBtn {
    width: 57px;
  }

  .agencies .modal-bg .search-country .btn-wrap {
    text-align: center;
  }

  .agencies.payment-confirm .content-box {
    padding: 20px 16px;
  }

  .agencies.payment-confirm .content-box .payment-line {
    display: none;
  }

  .agencies.payment-confirm .content-box .payment-step {
    margin: 20px auto;
  }

  .agencies.payment-confirm .content-box .order-info h3 {
    border-bottom: none;
    padding-bottom: 8px;
  }

  .agencies.payment-confirm .content-box .box-head {
    width: 64px;
    padding: 10px;
  }

  .agencies.payment-confirm .content-box .order-info .order-content {
    flex-direction: column;
    padding: 13px 8px;
  }

  .agencies.payment-confirm .content-box .box-content {
    width: calc(100% - 64px);
    padding: 12px 8px;
  }

  .agencies.payment-confirm .content-box .order-info .order-content .order-list {
    border-bottom: 1px dashed #d1d1d1;
    padding-bottom: 8px;
    width: 100%;
  }

  .agencies.payment-confirm .content-box .order-info .order-content .application-type .small-box {
    flex-direction: column;
    width: 96px;
    height: 80px;
  }

  .agencies.payment-confirm .content-box .order-info .order-content .application-type .guide-icon {
    position: static;
  }

  .agencies.payment-confirm .content-box .order-info .order-content .application-type {
    flex-direction: row;
    gap: 8px;
    margin-top: 8px;
  }

  .agencies.payment-confirm .content-box .order-info .order-content .application-type .check-wrap {
    flex-direction: column;
    white-space: nowrap;
    align-items: flex-start;
    gap: 20px;
  }

  .agencies.payment-confirm .content-box .order-info .order-content .application-type .guide {
    transform: translate(0px, -80px);
  }

  .agencies.payment-confirm .content-box .view-amount-list {
    flex-direction: column;
    gap: 16px;
  }

  .agencies.payment-confirm .content-box .view-amount-list .amount-group {
    margin-right: 0;
    width: 100%;
  }

  .agencies.payment-confirm .content-box .box-head.total-price {
    width: 100px;
  }

  .agencies.payment-confirm .content-box .final-check .box-content {
    text-align: end;
  }

  .agencies.payment-confirm .content-box .payment-select .agree-wrap {
    width: 100%;
  }

  .agencies.payment-confirm .content-box .payment-select .agree-wrap label {
    align-items: flex-start;
  }

  .payment-confirm .agree-wrap label span {
    width: calc(100% - 28px);
  }

  .agencies.payment-confirm .content-box .payment-select .agree-wrap ul li,
  .agencies.payment-confirm .content-box .payment-select .agree-wrap ul {
    width: 100%;
  }

  .agencies.payment-confirm .content-box .payment-select .agree-wrap ul {
    flex-direction: column;
  }

  .agencies.payment-confirm .content-box .payment-select .agree-wrap ul li {
    margin-right: 0;
  }

  .agencies.payment-confirm .content-box .payment-select .bank-name .box-content {
    flex-direction: column;
    height: auto;
    gap: 5px;
  }

  .agencies.payment-confirm .content-box .payment-select .bank-name .box-head,
  .agencies.payment-confirm .content-box .payment-select .tax-bill-wrap .box-head {
    white-space: wrap;
    /* word-break: break-all; */
    padding: 12px;
  }

  .agencies.payment-confirm .content-box .payment-select .bank-manual,
  .agencies.payment-confirm .content-box .payment-select .red {
    font-size: 12px;
  }

  .agencies.payment-confirm .content-box .payment-select .radio-option .radio-label {
    white-space: nowrap;
  }

  .agencies.payment-confirm .content-box .payment-select .input-wrap {
    align-items: flex-start;
  }

  .agencies.payment-confirm .content-box .payment-select .input-wrap .row.number {
    gap: 4px;
    flex-direction: column;
    align-items: flex-start;
  }

  .agencies.payment-confirm .content-box .payment-select .input-wrap .row input[type="text"].number-input {
    width: calc((100% - 40px) / 3);
  }

  .agencies.payment-confirm .content-box .payment-select .input-wrap .row.number .input-number {
    flex-direction: row;
    padding: 0;
  }

  .agencies.payment-confirm .content-box .payment-select .bank-name {
    height: auto;
  }

  .agencies.payment-confirm .content-box .payment-select .input-wrap .row.name-input {
    flex-direction: column;
    align-items: flex-start;
  }

  .agencies.payment-confirm .content-box .payment-select .input-wrap .row strong {
    width: auto;
  }

  .agencies.payment-confirm .content-box .payment-select .input-wrap .row input[type="text"] {
    width: 100%;
  }

  .agencies.payment-confirm .content-box .payment-select .select-area .box-content {
    line-height: 100%;
  }

  .agencies.payment-confirm .content-box .payment-check .guide-context span {
    font-size: 18px;
    font-family: "PretendardSB", "sans-serif";
  }

  .agencies.payment-confirm .content-box .payment-check .guide-context {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .agencies.payment-confirm .content-box .payment-check .guide-comment {
    font-size: 14px;
  }

  .agencies.payment-confirm .content-box .payment-check h4 {
    font-size: 14px;
  }

  .agencies.payment-confirm .content-box .payment-check .guide-comment span,
  .agencies.payment-confirm .content-box .payment-check .total span,
  .agencies.payment-confirm .content-box .payment-check .price span {
    font-size: 14px;
  }

  .agencies.payment-confirm .content-box .payment-check .price :first-child {
    font-size: 14px;
  }

  .agencies.payment-confirm .content-box .payment-check .box-head {
    font-size: 12px;
  }

  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .box-head:nth-child(1),
  .agencies.payment-confirm .content-box .purchase-wrap .box-content:nth-child(1) {
    width: 25% !important;
  }

  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .purchase-content .box-content:nth-child(1) {
    width: 25% !important;
  }

  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .box-head:nth-child(2),
  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .purchase-content .box-content:nth-child(2) {
    width: 25% !important;
  }

  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .box-head:nth-child(3),
  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .purchase-content .box-content:nth-child(3) {
    width: 10% !important;
  }

  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .box-head:nth-child(4),
  .agencies.payment-confirm .content-box .payment-check .purchase-wrap .purchase-content .box-content:nth-child(4) {
    width: 40% !important;
  }

  .agencies.payment-confirm .content-box .purchase-info.last .box-content:nth-child(1) {
    width: 58% !important;
  }

  .agencies.payment-confirm .content-box .payment-check .purchase-info.last {
    width: 100%;
  }

  /* 브랜드 네이밍*/
  .brandnaming-wrap {
    padding: 16px 9px;
    flex-direction: column;
    width: 100%;
  }

  .brandnaming-wrap main,
  .possibility-wrap main {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .brandnaming-wrap main .brandInput-wrap {
    width: 100%;
  }

  .brandnaming-wrap main .brandInput-wrap .choice-wrap {
    flex-direction: column;
  }

  .brandnaming-wrap main .brandInput-wrap .choice-wrap .divider {
    display: none;
  }

  .brandnaming-wrap .mobile-common-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .brandnaming-wrap .mobile-common-wrap button {
    font-size: 14px;
    color: #319dff;
    width: 101px;
    height: 28px;
    border: 1px solid #319dff;
    border-radius: 4px;
    background-color: #F2F8FD;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  .brandnaming-wrap .modal-bg .brand-name .like-list article {
    padding: 16px 5px;
  }

  .brandnaming-wrap .mobile-common-wrap button img {
    width: 14px;
  }

  .brandnaming-wrap main {
    width: 100%;
  }

  .brandnaming-wrap main .brandInput-wrap .keyword-wrap {
    padding: 24px;
  }

  .brandnaming-wrap main .brandInput-wrap .button-list {
    width: 100%;
  }

  .brandnaming-wrap main .brandInput-wrap .choice-wrap .ai-wrap .button-list button {
    width: calc((100% - 20px) / 2);
  }

  .brandnaming-wrap main .brandInput-wrap .choice-wrap .strength-wrap {
    width: 100%;
  }

  .brandnaming-wrap main .product-wrap .list .list-item span {
    width: calc(100% - 50px);
  }


  .brandnaming-wrap main .product-wrap .list .list-item .list-preview div,
  .possibility-wrap main .possibility-step2 .list .list-item .list-preview div {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
  }

  .brandnaming-wrap main .product-wrap .list .list-item strong {
    white-space: nowrap;
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .input-wrap {
    width: 100%;
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .input-wrap button {
    width: 70px;
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .input-wrap input,
  .possibility-wrap main .product-input {
    width: calc(100% - 75px);
  }

  .brandnaming-wrap main .brandResult-wrap {
    width: 100%;
  }

  .brandnaming-wrap main .brandResult-wrap .keyword-wrap {
    width: 100%;
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap,
  .brandnaming-wrap .modal-bg .brand-name .like-list {
    width: 100%;
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap .common-version,
  .brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version,
  .brandnaming-wrap .modal-bg .brand-name .like-list article {
    width: calc((100% - 8px) / 2);
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap .common-version button,
  .brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version button {
    white-space: nowrap;
    font-size: 14px;
  }

  .brandnaming-wrap .modal-bg .brand-name {
    width: 90%;
    overflow-y: auto;
    gap: 8px;
    height: 80%;
  }

  .brandnaming-wrap .modal-bg .brand-name .keyword-wrap h3 {
    font-size: 18px;
  }

  .brandnaming-wrap .modal-bg .brand-name .like-list h5 {
    font-size: 14px;
  }

  .brandnaming-wrap .modal-bg .brand-name .keyword-wrap {
    padding: 11px;
  }

  .brandnaming-wrap .modal-bg .brand-name .tab-list {
    flex-wrap: wrap;
    gap: 4px;
    height: auto;
  }

  .brandnaming-wrap .modal-bg .brand-name .tab-list button {
    width: calc((100% - 4px) / 2);
    font-size: 10px;
    height: 27px;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section,
  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section {
    width: 100%;
    padding: 15px;
  }

  .brandnaming-wrap .modal-bg .brand-name .keyword-wrap hr {
    margin: 5px auto;
  }

  .brandnaming-wrap .modal-bg .brand-name .keyword-wrap p {
    font-size: 12px;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap {
    flex-direction: column;
    align-items: center;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .first-warn-wrap p,
  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .first-warn-wrap strong {
    font-size: 10px;
    white-space: wrap;
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version,
  .brandnaming-wrap main .brandResult-wrap .result-wrap .common-version {
    padding: 24px 15px;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap p,
  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap .result {
    font-size: 14px;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .left-section .result-wrap {
    gap: 7px;
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version .title-wrap h4 {
    font-size: 14px;
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap .gpt-version .description {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .brandnaming-wrap .modal-bg .brand-name .like-list .gpt-card .description p {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .brandnaming-wrap .modal-bg .brand-name .like-list article button {
    font-size: 14px;
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap .common-version {
    height: auto;
  }

  .brandnaming-wrap main .brandResult-wrap .result-wrap .common-version h4 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    margin: 30px auto 10px auto;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item {
    width: 125px;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list {
    justify-content: center;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item .badge-list span {
    white-space: nowrap;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .card-list .card-item .badge-list {
    padding: 0
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article {
    flex-direction: column;
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article h5 {
    border-right: none;
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article div {
    margin-left: 0;
  }

  .brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap input {
    width: calc(100% - 110px);
  }

  .brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap select {
    width: 80px;
    padding: 0 5px;
  }

  .brandnaming-wrap .modal-bg .brand-name .error-wrap {
    width: 100%;
  }

  .brandnaming-wrap .modal-bg .brand-name .company-wrap .result-wrap article {
    width: 100%;
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .trade-wrap section article div p {
    white-space: wrap;
  }

  .brandnaming-wrap .modal-bg .brand-name .big-tab-content {
    width: 100%;
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section {
    gap: 5px;
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section article {
    width: 120px;
    height: 100px;
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section article h5 {
    font-size: 10px;
  }

  .brandnaming-wrap .modal-bg .brand-name .product-wrap .productItem-wrap section article img {
    width: 80px;
    height: 50px;
  }

  .brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap .logo-list {
    flex-direction: column;
  }

  .brandnaming-wrap .modal-bg .brand-name .social-wrap .idCheck-wrap .logo-wrap .logo-list .divider {
    display: none;
  }

  .brandnaming-wrap .modal-bg .brand-name .social-wrap,
  .brandnaming-wrap .modal-bg .brand-name .company-wrap,
  .brandnaming-wrap .modal-bg .brand-name .product-wrap,
  .brandnaming-wrap .modal-bg .brand-name .social-wrap .input-wrap,
  .brandnaming-wrap .modal-bg .brand-name .company-wrap .input-wrap,
  .brandnaming-wrap .modal-bg .brand-name .product-wrap .input-wrap {
    width: 100%;
  }

  .brandnaming-wrap .modal-bg .brand-name .social-wrap .input-wrap input,
  .brandnaming-wrap .modal-bg .brand-name .product-wrap .input-wrap input {
    width: calc(100% - 57px);
  }

  .brandnaming-wrap .modal-bg .brand-name .button-list button {
    font-size: 12px;
  }

  .notice-detail-wrap {
    padding-top: 0;
  }

  .notice-detail-wrap h2 {
    font-size: 22px;
    line-height: 140%;
    margin-bottom: 8px;
  }

  .notice-detail-wrap .subTitle {
    font-size: 18px;
  }

  .notice-detail-wrap .content-box {
    margin: 40px auto;
    width: calc(100% - 40px);
  }

  .notice-detail-wrap .content-box .notice-content img {
    max-width: 100%;
  }

  .notice-detail-wrap .content-box .notice-content p {
    font-size: 14px;
  }

  .notice-detail-wrap .content-box .notice-content {
    padding: 20px 16px;
    border-bottom: 1px solid #050f2f;
  }

  .notice-detail-wrap .bottom-nav a {
    width: calc(100% - 40px);
    height: 56px;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }

  /*간단 검색*/
  .SelfDiagnosis01-wrap {
    width: 100%;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content {
    width: 100%;
    padding-top: 0;
    padding-bottom: 64px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left {
    width: 100%;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content {
    padding: 40px 20px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul {
    flex-direction: column;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul li .title-p,
  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul li .title-p span,
  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul li .title-p .txt-black {
    font-size: 20px;
    line-height: 150%;
    justify-content: flex-start;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul {
    width: 100%;
    gap: 3px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul li {
    width: 64px;
    height: 64px;
    border-radius: 4px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul li img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .second-ul .second-ul-mb {
    margin-bottom: 0;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul {
    padding: 16px 12px;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 12px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .danger_text {
    display: none;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul p {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .mark_subtitle_mt {
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .last-ul .mark_subtitle {
    font-size: 14px;
    text-align: center;
    white-space: wrap;
    line-height: 140%;
    padding: 12px 0;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-ul .first-li01 {
    justify-content: flex-start;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-li03 {
    display: flex;
    background-color: #666666;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 20px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-left .left-content .first-li03 p {
    color: white;
    font-family: "PretendardSB", "sans-serif";
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right {
    width: 100%;
    padding: 8px 20px;
    text-align: center;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content span {
    font-size: 16px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content h2 {
    font-size: 22px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li {
    border: 1px solid #319dff;
    border-radius: 8px;
    padding: 10px 12px;
    gap: 8px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li img {
    width: 72px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li p,
  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-right .right-content ul li p strong {
    font-size: 16px;
    text-align: start;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li,
  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li:first-child {
    width: calc(100% - 20px);
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .diagnosis-bot ul li a {
    font-size: 16px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box .title-p,
  .SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box span {
    font-size: 16px;
  }

  .SelfDiagnosis01-wrap .SelfDiagnosis-content .second-ul .none_bg_box {
    height: 136px;
    gap: 16px;
  }

  .notice-list-wrap .notice-list .notice-item-wrap {
    padding: 16px;
    height: auto;
    gap: 7px;
    width: 100%;
    min-width: 100%;
  }

  .notice-list-wrap .notice-list {
    width: 100%;
    min-width: 100%;
  }

  .content-customer-wrap .notice-list-wrap {
    width: 100%;
  }

  .content-customer-wrap.notice {
    width: 100%;
  }

  .notice-list .notice-item-wrap .notice-contents {
    width: calc(100% - 22px);
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .select-list,
  .possibility-step2 .select-list {
    flex-direction: column;
    gap: 5px;
  }

  .possibility-step2 .select-list {
    gap: 0;
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item,
  .possibility-step2 .select-list .select-item {
    margin-top: 10px;
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item:first-child {
    margin-top: 16px;
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item {
    margin-top: 0;
  }

  .select-classification .content-box .selectList-category .optionItem .list-header button,
  .select-product .content .header-btn {
    white-space: nowrap;
    width: auto;
    height: 25px;
    font-size: 12px;
    padding: 0 8px;
  }

  .brandnaming-wrap .modal-bg .brand-name .no-data-wrap.company {
    height: auto;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .tab-list {
    width: 100%;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .tab-list button {
    width: calc((100% - 14px) / 3);
  }

  .brandnaming-wrap main .brandInput-wrap .product-wrap .select-list .select-item p {
    max-width: calc(100% - 70px);
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap {
    white-space: nowrap;
    gap: 2px;
  }

  .brandnaming-wrap .modal-bg .brand-name .possible-wrap .right-section .total-wrap .divider {
    margin: 0 2px;
  }

  .personal-wrap {
    width: 100%;
  }

  .personal-wrap .use-btn-wrap {
    margin: 30px auto;
  }

  .personal-wrap .use-btn-wrap>button {
    width: calc(60%);
    height: 50px;
    font-size: 16px;
    white-space: nowrap;
  }


  /* 등록 가능성 */

  .possibility-wrap {
    flex-direction: column;
    padding: 16px 9px;
    justify-content: flex-start;
  }

  .possibility-wrap main .possibility-step1 {
    height: auto;
  }

  .possibility-wrap main .step1-wrap h2 {
    font-size: 20px;
    white-space: nowrap;
  }

  .possibility-wrap .caution p {
    font-size: 11px;
  }

  .possibility-wrap .caution img {
    width: 12px;
  }

  .possibility-wrap main .button-wrap {
    flex-direction: column;
    gap: 10px;
    margin-top: 0;
  }

  .possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap article {
    left: 0;
  }

  .possibility-wrap main .possibility-step1 .button-wrap .strength-btn-wrap .text-wrap article::before {
    left: 31%;
  }

  .possibility-wrap .possibility-tempResult,
  .possibility-wrap .step2-wrap .possibility-edit,
  .possibility-wrap .step2-wrap {
    width: 100%;
  }

  .possibility-wrap .step2-wrap .possibility-tempResult .item-wrap:first-child {
    border-top: none;
  }

  .possibility-wrap .step2-wrap .possibility-tempResult .item-wrap {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid #E0E0E0;
    padding: 12px 0;
  }

  .possibility-wrap .step2-wrap .possibility-tempResult .item-list {
    width: 100%;
    align-items: flex-start
  }

  .possibility-wrap .step2-wrap .possibility-tempResult .item-wrap .item-content.second {
    width: 100%;
  }

  .possibility-wrap .step3-wrap {
    flex-direction: column;
    width: 100%;
  }

  .possibility-wrap .step3-wrap article,
  .possibility-wrap .step2-wrap .possibility-edit .edit-content input {
    width: 100%;
  }

  .possibility-wrap .step2-wrap .possibility-edit .edit-content {
    flex-direction: column;
    align-items: flex-start
  }

  .possibility-wrap .step3-wrap .left-section {
    width: 100%;
  }


  /**/
  .modal-bg .mypage-manage-applicant {
    width: 90% !important;
    padding: 24px 16px !important;
    height: 640px !important;
  }

  .modal-bg .mypage-manage-applicant .body .trademark-table.mobile tbody .table-name {
    width: 100px;
    font-size: 14px;
  }

  .modal-bg .mypage-manage-applicant .body .table-name td,
  .modal-bg .mypage-manage-applicant .body .table-last .item-detail,
  .modal-bg .mypage-manage-applicant .body table td {
    font-size: 14px !important;
  }

  .modal-bg .mypage-manage-applicant .body .table-last .item-detail {
    text-align: center;
  }

  .possibility-wrap .step3-wrap .possibility-tempResult .select-wrap {
    flex-direction: column;
    align-items: flex-start
  }

  .possibility-wrap .step3-wrap .possibility-tempResult .select-wrap .maincode {
    width: 100%;
  }

  .possibility-wrap .step3-wrap .caution p,
  .possibility-wrap .step3-wrap .caution p strong {
    font-size: 12px;
  }

  .possibility-wrap .step3-wrap .chart-wrap .content-center .warn-wrap img {
    width: 24px;
  }

  .possibility-wrap .step3-wrap .chart-wrap .content-center .warn-wrap .description p,
  .possibility-wrap .step3-wrap .chart-wrap .description p strong {
    font-size: 14px;
  }

  .possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap .result-title,
  .possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap .result-content,
  .possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap span {
    font-size: 16px;
  }

  .possibility-wrap .step3-wrap .chart-wrap .possibility-result-wrap span {
    margin: 0 5px;
  }

  .possibility-wrap .step3-wrap .chart-wrap .split-result .result-title,
  .possibility-wrap .step3-wrap .chart-wrap .split-result .result-content {
    font-size: 16px;
  }

  .possibility-wrap .caution {
    align-items: flex-start;
  }


  .possibility-wrap .step3-wrap .chart-wrap .split-result .result-content {
    width: 100%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  .possibility-wrap .step3-wrap .chart-wrap .split-result {
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .possibility-wrap .step3-wrap article,
  .possibility-wrap main .possibility-step1,
  .possibility-wrap .possibility-tempResult,
  .possibility-wrap .step2-wrap .possibility-edit,
  .possibility-wrap main .possibility-step1,
  .possibility-wrap main .possibility-step2 {
    padding: 16px;
  }

  .possibility-wrap .step3-wrap .right-section section li,
  .possibility-wrap .step3-wrap .right-section section .description,
  .possibility-wrap .step3-wrap .right-section section .badgeList .badge-title,
  .possibility-wrap .step3-wrap .right-section section .badgeList strong,
  .possibility-wrap .step3-wrap .right-section section .badgeList .badge,
  .possibility-wrap .step3-wrap .right-section section .badgeList span {
    font-size: 12px;
  }

  .possibility-wrap .step3-wrap .right-section section hr {
    margin: 10px 0;
  }

  .possibility-wrap .step3-wrap .right-section section .badgeList span {
    margin: 0 5px;
  }

  .possibility-wrap .step3-wrap .right-section section .badgeList .badge-title {
    margin-right: 3px;
  }

  .possibility-wrap .step3-wrap .right-section section h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .possibility-wrap .step3-wrap .right-section .tab-list button {
    font-size: 12px;
    height: 30px;
  }

  .possibility-wrap .step3-wrap .right-section .chartBarWrap {
    display: flex;
    justify-content: center
  }

  .possibility-wrap .step3-wrap .right-section section .cardList {
    gap: 5px;
  }

  /* 메인 */

  .brand-content-wrap.hovered-5 {
    padding-top: 0;
    padding-bottom: 48px;
  }

  .pick-home .brand-value-top .brand-left-box .partners-title {
    margin-bottom: 8px;
  }

  .pick-home .brand-value-top .brand-left-box p {
    margin-bottom: 12px;
  }

  .pick-home .service-type-wrap ul {
    gap: 8px;
  }

  /* 1번째 호버 */
  .brand-value-top.hovered-1,
  .brand-content-wrap.hovered-1,
  .pick-home .brand-value-wrap .brand-value-top .brand-left-box,
  .brand-value-top.hovered-2,
  .brand-value-top.hovered-3,
  .brand-content-wrap.hovered-3,
  .brand-value-top.hovered-5,
  .brand-value-top.hovered-4,
  .brand-content-wrap.hovered-4 {
    padding-top: 0;
  }

  .pick-home .brand-value-top.hovered-1 .brand-left-box .partners-title,
  .pick-home .brand-value-top.hovered-2 .brand-left-box .partners-title,
  .pick-home .brand-value-top.hovered-3 .brand-left-box .partners-title .pick-home .brand-value-top.hovered-4 .brand-left-box .partners-title,
  .pick-home .brand-value-top.hovered-5 .brand-left-box .partners-title {
    line-height: 140%;
    margin-bottom: 16px;
  }

  .pick-home .brand-value-top.hovered-1 .brand-left-box p,
  .pick-home .brand-value-top.hovered-2 .brand-left-box p,
  .pick-home .brand-value-top.hovered-3 .brand-left-box p,
  .pick-home .brand-value-top.hovered-4 .brand-left-box p,
  .pick-home .brand-value-top.hovered-5 .brand-left-box p {
    font-size: 15px;
    margin-bottom: 40px;
  }

  .pick-home .brand-value-wrap .brand-content-wrap.hovered-1 .brand-value-bottom .brand-value-bottom-right {
    padding-bottom: 24px;
  }

  .brand-content-wrap.hovered-5 {
    padding-bottom: 0;
  }

  .sub_wrap_all .newselfSection04 .selftextbox-All h3 {
    margin: 8px 0;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
    justify-content: flex-start;
    flex-direction: row;
    min-height: 79px;
    width: 360px;
    padding: 16px;
    flex-wrap: nowrap;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img {
    margin-left: 0;
  }

  .sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small {
    margin: 0;
    color: #aaa;
  }

  /* 두번째 호버 */
  .sub_wrap_all .agencySection02 .agencycontent-articleAll {
    border-radius: 0;
  }

  .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img,
  .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img {
    margin-left: 0;
  }

  .sub_wrap_all .agencySection04 .agencytextbox-All h3 {
    margin: 8px 0 16px 0;
    font-size: 22px;
    font-weight: 700;
  }


  /* 마이페이지 모바일 반응형*/
  .container .mypage-wrap .side-bar {
    /* display: none; */
  }

  .container .mypage-wrap {
    width: 100%;
  }

  .container .mypage-wrap .content-wrap-mypage {
    width: 100%;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    gap: 0;
  }

  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap {
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding: 40px 20px;
  }

  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common {
    width: 100%;
    padding: 20px 16px;
  }

  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .info-title div {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
  }

  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .info-title p {
    font-size: 12px;
  }

  .container .mypage-wrap .content-wrap-mypage .mobile-title {
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
    color: #585858;
  }

  .container .mypage-wrap .content-wrap-mypage .info-content-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mypage-wrap .content-item .content {
    padding-left: 12px;
    margin: 4px 0 0 0;
  }

  .mypage-wrap .content-item .content.gray {
    background-color: #F6F6F6;
  }

  .mypage-wrap .content-item .job-select-wrap {
    display: flex;
    align-items: flex-end;
  }
  .mypage-wrap .content-item .job-select-wrap .job-dropdown {
    margin: 4px 0 0 0 ;
  }
  .mypage-wrap .content-item .job-select-wrap .job-dropdown .select-job {
    padding: 9px;
  }
  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .phone-auth {
    border-right: none;
    width: 100%;
    display: flex;
    gap: 4px;
    margin-top: 4px;
  }

  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .email-wrap {
    margin-top: 4px;
  }

  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .phone-auth button {
    white-space: nowrap;
    width: 81px;
  }

  .container .mypage-wrap .content-wrap-mypage .bank-select-wrap {
    background-color: red;
    margin-top: 4px;
  }

  .container .mypage-wrap .content-wrap-mypage .bank-select-wrap .bank-dropdown {
    margin: 0;
    width: 100%;
  }

  .mobile-member-wrap .save-user-info-button {
    width: 100%;
    min-width: auto;
    font-size: 16px;
    border-radius: 8px;
  }

  .container .mypage-wrap .content-wrap-mypage .account-input,
  .container .mypage-wrap .content-wrap-mypage .bank-account-input {
    width: 100%;
    margin: 0;
  }
  .container .mypage-wrap .content-wrap-mypage .bank-select-wrap .bank-dropdown .select-bank {
    width: 100%;
  }
  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .phone-auth .phone-input,
  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .email-input {
    margin: 0;
  }

  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .member-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .member-button .user-withdrawal,
  .container .mypage-wrap .content-wrap-mypage .info-content-mobile .member-button .pw-edit {
    position: static;
    margin-top: 0;
  }


  .mypage-wrap .mypage-auth-fail-wrap,
  .mypage-wrap .user-pw-info .modal,
  .container .mypage-wrap .modal-overlay.user-pw-change .modal,
  .container .mypage-wrap>.modal-overlay.user-delete .modal {
    width: 90%;
    padding: 24px 16px;
    height: auto;
  }

  .mypage-wrap .mypage-auth-fail-wrap .mypage-auth-fail-header::after,
  .mypage-wrap .mypage-auth-fail-wrap .mypage-auth-fail-content::after {
    display: none;
  }

  .mypage-wrap .modal-overlay.user-pw-info .modal .pw-input-wrap input {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .mypage-wrap>.modal-overlay.user-pw-info .modal .pw-input-wrap {
    flex-direction: column !important;
    align-items: flex-start !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
  }

  .container .mypage-wrap .modal-overlay.user-pw-change .modal {
    min-width: 90%;
    min-height: auto;
  }

  .container .mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .container .mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap>div input {
    width: 100%;
    margin: 0;
  }

  .container .mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>p.mb-18 {
    margin-bottom: 20px;
  }

  .container .mypage-wrap>.modal-overlay.user-delete .modal>.delete-content .dropdown-toggle,
  .container .mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>textarea,
  .container .mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>div {
    width: 100%;
  }

  .container .mypage-wrap>.modal-overlay.user-delete .user-btn-wrap {
    margin-top: 20px;
  }

  .container .mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>textarea {
    margin: 0;
  }
  .container .mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>p {
    font-size: 12px;
  }
  .container .mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap.change-pw {
    width: 100%;
    padding: 0;
  }
  .container .mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap span {
    margin-right: 0;
    margin-bottom: 4px;
    
    min-width: auto;
    margin-bottom: 4px;
    font-family: "PretendardSB", "sans-serif";
    color: #585858;
  }
  .mypage-wrap>.modal-overlay.user-pw-info .modal .user-btn-wrap {
    margin-top: 0 !important;
  }
  .mypage-wrap .mypage-auth-fail-wrap .mypage-auth-fail-content {
    height: auto;
    margin: 16px auto;
  }

  .mypage-wrap .mypage-auth-fail-wrap .mypage-auth-fail-footer {
    height: auto;
    padding: 0;
  }

  .mypage-wrap .mypage-auth-fail-wrap .mypage-auth-fail-footer .mypage-auth-fail-confirm {
    width: 100%;
  }

  .mypage-wrap .mypage-auth-fail-wrap .mypage-auth-fail-header {
    padding: 0;
    height: auto;
    padding-bottom: 16px;
    border-bottom: 1px solid #050f2f;
  }

  .mypage-wrap .mypage-auth-fail-wrap .mypage-auth-fail-content {
    padding: 0;
  }


  /* 마이페이지 등록 대행 */
  .mobile-member-wrap .member-info-common table {
    width: 100%;
  }
  .mobile-member-wrap .member-info-common table thead tr th {
    font-size: 12px;
    font-family: "PretendardSB", "sans-serif";
    height: 40px;
  }

  .mobile-member-wrap .member-info-common table .table-body td {
    font-size: 12px;
    height: 46px;
    padding: 6px;
  }
  .mobile-member-wrap .member-info-common table .table-body td:first-child {
    width: 10%;
  }
  .mobile-member-wrap .member-info-common table .table-body td img {
    margin-right: 0;
  }

  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .pagination-box {
    justify-content: center;
    margin-top: 20px;
  }
  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .pagination-box>ul>li.active {
    background-color: transparent;
    border: none;
    color: #319dff;
    font-size: 14px;
    font-family: "PretendardSB", "sans-serif";
  }
  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .pagination-box>ul>li {
    font-size: 14px;
  }
  .mobile-member-wrap .member-info-common table .table-body td .payment-button {
    width: 100%;
    height: 22px;
    white-space: nowrap;
    font-size: 11px;
  }
  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .info-title.inquiry {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .info-title.inquiry .inquiry-btn {
    font-size: 12px;
    color: #666;
  }
  .container .mypage-wrap .content-wrap-mypage .mobile-member-wrap .member-info-common .info-title.inquiry .inquiry-btn img {
    margin-left: 6px;
  }
  .mypage-menu .mobile-mypage-header{
    background-color: white;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    position: relative;
  }
  .mypage-menu .mobile-mypage-header span{
    font-size: 22px;
    font-family: "PretendardSB", "sans-serif";
  }
  .mypage-menu .mobile-mypage-header img {
    width: 24px;
    position: absolute;
    right: 20px;
  }
  .mypage-menu {
    position: relative;
  }
  .mypage-menu .mobile-mypage-options,
  .mypage-menu .mobile-mypage-options .btn-list
   {
    background-color: white;
    width: 100%;
  } 

  .mypage-menu .mobile-mypage-options {
    position: absolute;
  }
  .mypage-menu .mobile-mypage-header .arrow-icon.rotate{
    transform: rotate(180deg);
  }
}

.monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li .monitor-article-itembox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 나중에 바꾸기 */
.mobile-menu.active {
  right: 0;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li:first-child {
  margin-top: 16px;
}

.mobile-menu li {
  width: 100%;
  margin-bottom: 16px;
}

.mobile-menu li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #000;

}

.mobile-menu li a.active {
  border-left: 4px solid #000;
}

.mobile-menu .sub-nav {
  border-left: none;
  padding-left: 20px;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 998;
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 16px;
}

.mobile-menu-header img {
  width: 80px;
  height: 32px;
}

.mobile-menu .menu .main-nav {
  padding: 15px 0;
  width: 100%;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
}

.mobile-menu .menu .main-nav-with-arrow {
  display: flex;
  width: 100%;
}

.mobile-menu .menu .main-nav-with-arrow img {
  width: 15px;
}

.mobile-menu .menu .main-nav-with-arrow .arrow-icon.open {
  transform: rotate(180deg);
}

.mobile-menu .sub-nav li {
  padding: 12px 0;
  margin-bottom: 0;
}


.mobile-menu .sub-nav {
  padding-left: 0;
}

.mobile-menu .menu .main-nav-with-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #333;
}


.mobile-menu .menu .arrow-icon {
  width: 14px;
  height: 14px;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: brightness(0) invert(0.5);
}

.mobile-menu .menu .arrow-icon.open {
  transform: rotate(180deg);
  filter: brightness(0) saturate(100%) sepia(100%) hue-rotate(180deg) brightness(1.2) contrast(1);
}