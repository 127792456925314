.content-wrap-mypage {
  background-color: #eaeef2;
  display: flex;
  width: 100vw;
  justify-content: center;
  gap: 40px;
  padding-top: 100px;
  padding-bottom: 166px;
}

.member-info-common,
.common-break-down,
.common-agent-info{
  width: 990px;
  padding: 50px 40px;
  background-color: #fff;
  border-radius: 8px;
}

.common-inquiry-container {
  width: 990px;
  padding: 45px 40px;
  background-color: #fff;
  border-radius: 8px;
}

.common-inquiry-container .title-underline {
  margin-top: 6px;
}
.member-info-common strong,
.common-break-down strong,
.common-agent-info strong,
.common-inquiry-container strong {
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
  color: #050f2f;
  line-height: 140%;
}

.info-title {
  margin-bottom: 12px;
}

.user-info.first .info-title {
  display: flex;
  justify-content: space-between;
}

.user-info.first .info-title div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.user-info.first .info-title img {
  width: 20px;
  cursor: pointer;
}
.user-info.first .info-title div p {
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

.title-underline {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #050f2f;
  margin: 12px auto 20px auto;
}

/* 사이드 바 스타일 시작*/
.side-bar {
  background-color: #ffffff;
  border-radius: 8px;
  width: 250px;
  height: 440px;
}

.side-bar .left-info {
  height: 180px;
  background-color: #319dff;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  flex-direction: column;
  gap: 16px;
  /* padding-top: 15px; */
  border-radius: 8px 8px 0 0;
}

.left-info strong {
  font-size: 24px;
  color: white;
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
}

.left-info p {
  font-size: 14px;
  color: #dddddd;
  font-family: "PretendardR", "sans-serif";
}

/* .side-menu {
    margin: 18px auto;
} */

.btn-list {
  cursor: pointer;
  width: 100%;
  display: flex;
  /* height: 49px; */
  height: 64px;
  border-bottom: 1px solid #e1e1e1;
  color: #050f2f;
  font-family: "PretendardM", "sans-serif";
  font-size: 18px;
  line-height: 140%;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 20px;
}

.btn-list.active {
  color: #319dff;
  font-family: "PretendardSB", "sans-serif";
}

.btn-list:last-child {
  border-bottom: none;
}

.btn-list.active img {
  filter: invert(42%) sepia(98%) saturate(1751%) hue-rotate(196deg) brightness(103%) contrast(105%);
}

/* 사이드 바 스타일 끝*/


.user-info-list-wrap {
  margin-bottom: 60px;
}

.info-content {
  margin-bottom: 48px;
}

.user-info {
  position: relative;
  min-width: 910px;
  /* height: 232px; */
}

.user-info-block {
  height: 48px;
}

/* 회원 탈퇴 */
.user-withdrawal {
  position: absolute;
  /* bottom: -33px; */
  margin-top: 30px;
  left: 0;
  color: #666666;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  text-decoration-line: underline;
  text-decoration-color: #666666;
  text-underline-offset: 3px;
}

/* 비밀번호 변경 */
.pw-edit {
  position: absolute;
  /* bottom: -33px; */
  margin-top: 20px;
  padding: 12px 8px 12px 16px;
  right: 0;
  min-width: 126px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #319dff;
  border-radius: 2px;
  background-color: #319dff;
  color: #ffffff;
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  gap: 6px;
}

.user-trademark-detail,
.user-additional-payment {
  padding-bottom: 0;
  margin-top: 48px;
}

.info-content div {
  display: flex;
}


.refund-account .info-content,
.optional-wrap .info-content{
  display: flex;
}

.optional-wrap .info-content{
  display: flex;
}

.optional-wrap .info-content .optional-accout-input input{
  width: 163px;
  padding: 9px 8px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  border: 1px solid #e1e1e1;
  margin: 10px 12px;
}


.phone-auth {
  display: flex;
  align-items: center;
  border-right: 1px solid #e1e1e1;
}

.phone-auth button {
  min-width: 81px;
  height: 35px;
  border: 1px solid #319dff;
  color: #319dff;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  padding: 9px 16px;
  margin-right: 12.6px;
  white-space: nowrap;
}

.select-file-btn {
  min-width: 84px;
  height: 35px;
  border: 1px solid #319dff;
  color: #319dff;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  padding: 9px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row input {
  border: 1px solid #e1e1e1;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #050f2f;
  padding: 9px 20px;
  margin-left: 4px;
}

.info-content {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.info-content>div {
  display: flex;
  width: 100%;
  /* border-right: 1px solid #e1e1e1; */
}

.info-mail {
  border-right: 1px solid #e1e1e1;
  padding-right: 3px;
}

.info-border {
  display: table;
  border-collapse: collapse;
  border-bottom: 1px solid #e1e1e1;
}

.info-content .info-head,
.title,
.require {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  color: #050f2f;
  min-width: 136px;
  /* max-width: 136px; */
  background-color: #f2f8fd;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.refund-account .info-content>div:last-child,
.optional-wrap .info-content>div:last-child {
  border-right: 1px solid #e1e1e1;
}


.container .title {
  margin-bottom: 0;
}

.account-input,
.bank-account-input {
  border: 1px solid #e1e1e1;
  margin: 10px 12px;
  width: 143px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}

.account-input {
  width: 120px;
  padding: 9px 20px;
}

.bank-account-input {
  width: 163px;
  padding: 9px 8px;
}

.phone-input,
.email-input {
  border: 1px solid #e1e1e1;
  margin: 10px 12px;
  height: 35px;
  width: 345px;
  padding: 9px 16px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}

.phone-input {
  /* width: 100%; */
  width: 220px;
  margin-right: 10px;
}

.email-input {
  /* width: 321px; */
  width: 100%;
}

.email-input::placeholder {
  color: #050f2f;
}

.mypage-wrap .content,
.bankName,
.holderName,
.accountNumber {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 10px 12px;
  border: 1px solid #e1e1e1;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #666666;
  min-width: 276px;
}

.info-id .content,
.info-name .content {
  background-color: #f6f6f6;
  padding: 9px 16px;
  max-width: 300px;
}

.column-require-docs-list .row {
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-right: 1px solid #e1e1e1;
}

.column-require-docs-list .row input {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.column-require-docs-list {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-top: 1px solid #e1e1e1;
}

.button-container {
  display: flex;
  justify-content: center;
}

.save-user-info-button {
  color: white;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  padding: 10px 20px;
  min-width: 910px;
  height: 60px;
  background-color: #319dff;
  border-radius: 2px;
  cursor: pointer;
}

.table-wrap {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e1e1e1;
  white-space: nowrap;
  table-layout: fixed;
}

.table-head th {
  background-color: #f2f8fd;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  text-align: center;
  border: 1px solid #e1e1e1;
  height: 56px;
  vertical-align: middle;
}

.table-body:hover {
  background-color: #FAFAFA;
}

.table-body .no-data {
  color: #666666;
  font-size: 14px;
}

.table-body .no-data:hover {
  background-color: #ffffff;
}

.table-body.no-data:hover {
  background-color: #ffffff;
}


.table-body td {
  padding: 8px;
  border: 1px solid #e1e1e1;
  height: 56px;
  color: #050F2F;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  overflow: hidden;
}

.table-body td .payment-button {
  border: 1px solid #319DFF;
  color: #319dff;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
  width: 80px;
  height: 38px;
}

.table-body .status-td div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 0;
}

.user-trademark-detail .tooltip {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
  padding: 8px 10px;
  white-space: nowrap;
  z-index: 10;
}

.user-trademark-detail .tooltip p {
  margin: 0;
  text-align: center;
  color: white;
  font-size: 12px;
  line-height: 140%;
}

.user-trademark-detail .tooltip::after {
  content: "";
  position: absolute;
  top: 100%; /* 툴팁 아래쪽 */
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}


.table-body .agent-td {
  text-decoration: underline;
  line-height: 140%;
  text-underline-offset : 3px;
}

.table-body .status-td p {
  font-size: 14px;
}

.table-body .status-td button {
  border: 1px solid #ff0000;
  background-color: white;
  color: #ff0000;
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
  width: 98px;
  height: 25px;
}

.table-body td img {
  max-height: 40px;
  margin-right: 8px;
}

.table-body td span {
  font-size: inherit;
  color: inherit;
}

.no-data {
  text-align: center;
  color: #999;
}

/* 특허 고객 번호*/


.user-info-enter-common .searchBtn {
  background-color: #319DFF;
  color: white;
  width: 100%;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  height: 56px;
  border-radius: 4px;
  margin-top: 20px;
}



.info-list-container {
  display: flex;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.info-list-container div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info-list-container .head,
.info-list-container li {
  font-size: 16px;
  text-align: center;
  padding: 18px;
  white-space: nowrap;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
}

.info-list-container .head {
  background-color: #f2f8fd;
  font-family: "PretendardSB", "sans-serif";
  border-bottom: 1px solid #e1e1e1;
}

.trademark-btn-wrap {
  display: flex;
  gap: 10px;
}

.trademark-btn-wrap button {
  border: 1px solid #319dff;
  width: 450px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
}

.trademark-btn-wrap button img {
  margin-left: 8px;
}

.trademark-btn-wrap button strong {
  color: #319dff;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 100%;
}

.user-inquiry-info td,
.user-inquiry-info span {
  color: #878787;
}

.user-inquiry-info .inquiry-title,
.user-inquiry-info .inquiry-main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-inquiry-info .inquiry-btn {
  width: 99px;
  height: 36px;
  color: #666666;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  font-size: 14px;
  gap: 6px;
  border: 1px solid #666;
  white-space: nowrap;
}

.user-inquiry-info .inquiry-btn img {
  filter: brightness(0) invert(40%);
}

.user-inquiry-info .inquiry-btn:hover {
  background-color: #319DFF;
  color: white;
  border: 1px solid #319dff;
}

.user-inquiry-info .inquiry-btn:hover img {
  filter: brightness(0) saturate(100%) invert(100%);
}

/* 
.user-inquiry-info .inquiry-main-title {
  margin-top: 48px;
} */


.common-inquiry-container select {
  width: 105px;
  padding: 4px 12px;
  border: 1px solid #666;
  font-size: 12px;
  color: #666;
  margin-bottom: 16px;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='5' viewBox='0 0 9 5' fill='none'%3E%3Cpath d='M1 0.75L4.5 4.25L8 0.75' stroke='%23666666'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 9px 5px;
}



.inquiry-detail h4 {
  color: #050f2f;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
}

.inquiry-detail .detail-header {
  margin-bottom: 20px;
}

.inquiry-detail .detail-header .inquiry-status {
  display: flex;
  align-items: center;
  gap: 20px;
}

.inquiry-detail .detail-header .inquiry-time {
  color: #666666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
}

.inquiry-detail .detail-header .answer-status {
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}

.inquiry-detail .detail-header .answer-status .complete {
  color: #319dff;
}

.inquiry-detail .detail-header .answer-status .no-complete {
  color: #666;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.inquiry-context {
  padding: 20px 0;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  color: #585858;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  min-height: 140px;
}

.inquiry-context textarea {
  padding: 16px;
  resize: none;
  outline-color: #d1d1d1;
  width: 910px;
  height: 180px;
}

.inquiry-answer {
  margin-top: 30px;
  width: 100%;
  padding: 30px;
  background-color: #f6f6f6;
  color: #666666;
}

.user-inquiry-info .inquiry-detail .file-wrap {
  display: flex;
  padding: 20px 0;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #d1d1d1;
}

.user-inquiry-info .inquiry-detail .file-wrap p {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}

.user-inquiry-info .inquiry-detail .file-wrap div {
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  line-height: 140%;
  color: #666;
}

.user-inquiry-info .inquiry-detail .button-list {
  width: 100%;
  margin: 40px auto 0 auto;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.user-inquiry-info .inquiry-detail .button-list button {
  width: 96px;
  height: 38px;
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
}

.user-inquiry-info .inquiry-detail .button-list .gray-btn {
  background-color: #d1d1d1;
  color: #050F2F;
}

.user-inquiry-info .inquiry-detail .button-list .blue-btn {
  background-color: #319dff;
  color: white;
}

.list-btn {
  width: 118px;
  height: 44px;
  color: white;
  background-color: #319dff;
  display: flex;
  align-items: center;
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
  gap: 8px;
  padding-left: 24px;
  margin: 60px auto 0 auto;
}


/* 회원정보 수정, 회원탈퇴 비밀번호 확인 모달 */
.mypage-wrap>.modal-overlay.user-pw-info .modal .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .head p {
  color: #050f2f;
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .txt-wrap {
  line-height: 140%;
  color: #666666;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  margin-bottom: 24px;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .pw-input-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .pw-input-wrap span {
  font-size: 14px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
  color: #15171a;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .pw-input-wrap input {
  width: 360px;
  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
  color: #050f2f;
  border: 1px solid #cdcdcd;
  margin-left: 8px;
  border-radius: 4px;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .pw-input-wrap input::placeholder {
  color: #cdcdcd;
  font-size: 14px;
  line-height: 22px;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .user-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .user-btn-wrap button {
  width: 96px;
  height: 38px;
  /* border-radius: 8px; */
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "PretendardM", "sans-serif";
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .user-btn-wrap button.ok {
  background-color: #319dff;
  margin-right: 16px;
}

.mypage-wrap>.modal-overlay.user-pw-info .modal .user-btn-wrap button.close {
  background-color: #cdcdcd;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal {
  padding: 24px 32px;
  border-radius: 16px;
  min-width: 516px;
  min-height: 402px;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .head>p {
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
  color: #050f2f;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .txt-wrap {
  font-size: 14px;
  line-height: 140%;
  color: #878787;
  margin-bottom: 24px;
  font-family: "PretendardR", "sans-serif";
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .txt-wrap>span {
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .txt-wrap>span.red {
  color: #f74848;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .txt-wrap>span.blue {
  color: #3c8fef;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  /* margin-left: 47px;
    margin-right: 8px; */
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap.change-pw {
  margin-bottom: 12px;
  /* margin-left: 31px; */
  background-color: transparent;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap.change-pw2 {
  margin-bottom: 16px;
  /* margin-left: 2px; */
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap span {
  font-size: 14px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
  color: #050f2f;
  /* margin-right: 16px; */
  min-width: 120px;
  text-align: right;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap>div {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap>div input {
  width: 330px;
  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 22px;
  color: #050f2f;
  border: 1px solid #cdcdcd;
  margin-left: 8px;
  margin-right: 16px;
  border-radius: 4px;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal .pw-input-wrap>div input::placeholder {
  color: #cdcdcd;
}

.mypage-wrap>.modal-overlay.user-pw-change .modal>.line {
  border: none;
  border-bottom: 1px solid #e8e8e8;
  margin: 0;
  margin-bottom: 16px;
}

.mypage-wrap>.modal-overlay.user-pw-change .user-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mypage-wrap>.modal-overlay.user-pw-change .user-btn-wrap button {
  width: 96px;
  height: 38px;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-family: "PretendardM", "sans-serif";
}

.mypage-wrap>.modal-overlay.user-pw-change .user-btn-wrap button.ok {
  background-color: #319dff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08); */
  margin-right: 16px;
}

.mypage-wrap>.modal-overlay.user-pw-change .user-btn-wrap button.close {
  background-color: #cdcdcd;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08); */
}

/**/
.mypage-wrap>.modal-overlay.user-delete .modal {
  padding: 24px 32px;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.head {
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 24px;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.head img {
  margin-right: 16px;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.head h4 {
  font-size: 18px;
  font-family: "PretendardSB", "sans-serif";
  color: #050f2f;
  transform: translateY(2px);
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>strong {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  font-family: "PretendardSB", "sans-serif";
  color: #15171a;
  margin-bottom: 10px;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>p {
  font-size: 14px;
  /* line-height: 20px; */
  color: #878787;
  /* font-weight: 400; */
  font-family: "PretendardR", "sans-serif";
  /* margin-bottom: 6px; */
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>p.mb-18 {
  margin-bottom: 30px;
  line-height: 20px;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>div {
  /* margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; */
  width: 420px;
  margin: 0 auto;
  margin-bottom: 8px;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>div>select {
  width: 420px;
  /* border-radius: 4px; */
  border: 1px solid #cdcdcd;
  color: #cdcdcd;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>textarea {
  margin: 0 34px;
  width: 420px;
  height: 120px;
  padding: 16px 20px;
  resize: none;
  outline: none;
  /* border-radius: 4px; */
  font-size: 14px;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
  color: #050f2f;
  background-color: #f0f0f0;
  border: none;
  overflow-y: auto;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>textarea.active {
  background-color: #fff;
  border: 1px solid #cdcdcd;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.delete-content>textarea:disabled {
  color: #878787;
}

.mypage-wrap>.modal-overlay.user-delete .modal>.line {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid #e8e8e8;
}

.mypage-wrap>.modal-overlay.user-delete .user-delete-text {
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
  color: #050f2f;
  /* font-weight: 400; */
  line-height: 22px;
}

.mypage-wrap>.modal-overlay.user-delete .user-btn-wrap {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mypage-wrap>.modal-overlay.user-delete .user-btn-wrap button {
  width: 96px;
  height: 38px;
  /* border-radius: 8px; */
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-family: "PretendardM", "sans-serif";
}

.mypage-wrap>.modal-overlay.user-delete .user-btn-wrap button.ok {
  background-color: #319dff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08); */
  margin-right: 16px;
}

.mypage-wrap>.modal-overlay.user-delete .user-btn-wrap button.close {
  background-color: #cdcdcd;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08); */
}

/* 회원 정보 - 본인인증 실패시 모달창 */
.mypage-auth-fail-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mypage-auth-fail-wrap {
  width: 482px;
  height: 212px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.mypage-auth-fail-header {
  position: relative;
  height: 69px;
  padding: 24px 32px;
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  color: #050f2f;
}

.mypage-auth-fail-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 32px;
  right: 32px;
  height: 1px;
  background-color: #e8e8e8;
}

.mypage-auth-fail-content {
  position: relative;
  height: 69px;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  color: #050f2f;
}

.mypage-auth-fail-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 32px;
  right: 24px;
  height: 1px;
  background-color: #e8e8e8;
}

.mypage-auth-fail-footer {
  height: 74px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mypage-auth-fail-confirm {
  width: 97px;
  height: 42px;
  padding: 8px 36px;
  background: #319dff;
  border-radius: 0;
  color: #ffffff;
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  cursor: pointer;
  border: none;
}

/* 변경확인 버튼 수정완료 */
/* .edit-user-info-button {
  color: white;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  padding: 10px 20px;
  min-width: 910px;
  height: 60px;
  background-color: #319dff;
  margin-top: 48px;
  border-radius: 2px;
  cursor: pointer;
} */

.phone-input:read-only {
  background-color: #f6f6f6;
  cursor: default;
}

.select-file-btn {
  min-width: 84px;
  height: 35px;
  border: 1px solid #319dff;
  color: #319dff;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  padding: 9px 16px;
  /* cursor: pointer; */
}

.delete-file-btn {
  border: 1px solid #ff0000 !important;
  color: #ff0000 !important;
}

/* 탈퇴사유 드롭다운 */
.dropdown {
  position: relative;
  margin: 0 auto;
  width: 420px;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 16px; */
  padding: 10px 24px;
  width: 420px;
  height: 40px;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  line-height: 22px;
  color: #cdcdcd;
  border: 1px solid #cdcdcd;
  background: #ffffff;
  cursor: pointer;
}

.dropdown-toggle.selected {
  color: #15171a;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #cdcdcd;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dropdown-menu::-webkit-scrollbar {
  display: none;
}

.dropdown-menu li {
  padding: 0 14px;
  height: 40px;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  line-height: 22px;
  padding: 10px 24px;
  color: #15171a;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu li.selected {
  color: #15171a;
}

.dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid #cdcdcd;
  width: 400px;
  padding: 10px 14px;
  margin: 0 10px;
}

.dropdown-menu li:hover {
  color: #319dff;
}

/* 은행 선택 드롭다운 */
.bank-dropdown,
.job-dropdown {
  position: relative;
  width: 140px;
  margin: 10px 12px;
}

.select-bank,
.select-job 
 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 35px;
  padding: 9px 12px 9px 16px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #666666;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  cursor: pointer;
}

.optional-wrap  .job-dropdown {
  width: 276px;
}

.optional-wrap .select-job {
  width: 276px;
}

.bank-select-options,
.job-select-options {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #e1e1e1;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bank-select-options::-webkit-scrollbar,
.job-select-options ::-webkit-scrollbar {
  display: none;
}

.bank-select-options li,
.job-select-options li {
  height: 35px;
  padding: 9px 12px 9px 16px;
  display: flex;
  align-items: center;
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  color: #050f2f;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
}

.bank-select-options li:last-child,
.job-select-options li:last-child {
  border-bottom: none;
}

.bank-select-options li:hover,
.job-select-options li:hover {
  font-family: "PretendardSB", "sans-serif";
  color: #319dff;
}

.select-bank.selected,
.select-job.selected {
  color: #15171a;
}

.modal-bg .mypage-self-popup {
  width: 800px;
  padding: 32px;
  align-items: flex-start;
  height: 850px;
  z-index: 9999;
}

.modal-bg .mypage-self-popup .head,
.modal-bg .mypage-search-applicant .head {
  width: 100%;
  border-bottom: 1px solid #666666;
  padding-bottom: 12px;
}

.modal-bg .mypage-self-popup .head .modal-title,
.modal-bg .mypage-search-applicant .head .modal-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.modal-bg .mypage-self-popup .head .modal-subTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 140%;
}

.modal-bg .mypage-self-popup .head .modal-subTitle .edit-templateName {
  display: flex;
  gap: 6px;
  align-items: center;
}

.modal-bg .mypage-self-popup .head .modal-subTitle .edit-templateName input {
  width: 180px;
  height: 28px;
  border: 1px solid #D1D1D1;
}

.modal-bg .mypage-self-popup .head .modal-subTitle article {
  display: flex;
  align-items: center;
  gap: 12px;
}

.modal-bg .mypage-self-popup .head .modal-subTitle article button {
  border: 1px solid #319DFF;
  color: #319DFF;
  font-size: 16px;
  line-height: 140%;
  padding: 3px 8px;
}

.modal-bg .mypage-self-popup .body {
  height: 630px;
  overflow-y: auto;
  position: relative;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.08);
}

.modal-bg .mypage-self-popup .head h4,
.modal-bg .mypage-search-applicant .head h4 {
  font-size: 20px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
}


.modal-bg .mypage-self-popup .head img {
  cursor: pointer;
  width: 16px;
}

.modal-bg .mypage-self-popup .applicant-info h4,
.modal-bg .mypage-self-popup .mark-info h4,
.modal-bg .mypage-self-popup .category-info h4,
.modal-bg .mypage-self-popup .regi-info h4 {
  font-size: 18px;
  color: #000;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  margin-top: 24px;
}

.modal-bg .mypage-self-popup .info-wrap {
  margin-top: 9px;
}




.modal-bg .mypage-self-popup .info-wrap .info-one .name-kr,
.modal-bg .mypage-self-popup .info-wrap .mark-type,
.modal-bg .mypage-self-popup .info-wrap .mark-preview,
.modal-bg .mypage-self-popup .category-info .mb-4 {
  display: flex;
}

.modal-bg .mypage-self-popup .info-wrap .name-kr .name-title,
.modal-bg .mypage-self-popup .info-wrap .mark-type .name-title,
.modal-bg .mypage-self-popup .info-wrap .mark-preview .name-title,
.modal-bg .mypage-self-popup .category-info .mb-4 .name-title {
  font-size: 16px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  width: 136px;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  background-color: #f2f8fd;
  border: 1px solid #e1e1e1;
  border-bottom: none;
}


.modal-bg .mypage-self-popup .info-wrap .name-kr .name,
.modal-bg .mypage-self-popup .info-wrap .mark-type .content,
.modal-bg .mypage-self-popup .info-wrap .mark-preview .preview-wrap,
.modal-bg .mypage-self-popup .category-info .mb-4 .content {
  font-size: 14px;
  color: #050f2f;
  font-family: "PretendardR", "sans-serif";
  display: flex;
  height: 40px;
  align-items: center;
  width: 597px;
  padding: 0 16px;
  border: 1px solid #e1e1e1;
  border-left: none;
  margin: 0;
  border-bottom: none;
}

.modal-bg .mypage-self-popup .info-wrap .mark-preview .preview-wrap,
.modal-bg .mypage-self-popup .info-wrap .mark-preview .name-title {
  height: 140px;
  border-bottom: 1px solid #e1e1e1;
}

.modal-bg .mypage-self-popup .category-info .mb-4,
.modal-bg .mypage-self-popup .regi-info .info-wrap {
  border-bottom: 1px solid #E1E1E1;
}

.modal-bg .mypage-self-popup .info-wrap .mark-preview .last {
  height: 140px;
  border-bottom: 1px solid #e1e1e1;
}

.modal-bg .mypage-self-popup .info-wrap .info-one .name-kr .last,
.modal-bg .mypage-self-popup .info-wrap .mark-preview .last {
  border-bottom: 1px solid #e1e1e1;
}

.modal-bg .mypage-self-popup .info-wrap .info-one .name-kr .final,
.modal-bg .mypage-self-popup .info-wrap .mark-preview .final {
  border-top: none;
  border-bottom: 1px solid #e1e1e1;
}

.modal-bg .mypage-self-popup .xml-btn-wrap button {
  background-color: #319dff;
  border-radius: 8px;
  color: white;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  width: 736px;
  height: 48px;
  margin-top: 24px;
}

.modal-bg .mypage-self-popup .info-wrap .mark-preview .preview-wrap .img-wrap {
  display: flex;
  flex-direction: row;
}

.modal-bg .mypage-self-popup .info-wrap .mark-preview .preview-wrap .img-wrap .img-preview {
  width: 120px;
  height: 120px;
  object-fit: fill;
}

.modal-bg .mypage-self-popup .info-wrap .mark-preview .preview-wrap .img-wrap .img-preview img {
  width: 120px;
  height: 120px;
  object-fit: fill;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 10px;
}

.user-self-appDetail .pagination-box {
  margin-top: 20px;
  margin-bottom: 48px;
}

.user-info-enter-common .pagination-box,
.user-trademark-agent .pagination-box,
.user-inquiry-info .pagination-box,
.user-trademark-detail .pagination-box,
.user-additional-payment .pagination-box {
  margin-top: 20px;
}


.user-self-appDetail .pagination-box>ul>li.active,
.user-info-enter-common .pagination-box>ul>li.active,
.user-trademark-agent .pagination-box>ul>li.active,
.user-inquiry-info .pagination-box>ul>li.active,
.user-trademark-detail .pagination-box>ul>li.active,
.user-additional-payment .pagination-box>ul>li.active {
  background-color: transparent;
  border: none;
  color: #319DFF;
  font-family: "PretendardSB", "sans-serif";
}

.user-self-appDetail .pagination-box>ul>li,
.user-info-enter-common .pagination-box>ul>li,
.user-trademark-agent .pagination-box>ul>li,
.user-inquiry-info .pagination-box>ul>li,
.user-trademark-detail .pagination-box>ul>li,
.user-additional-payment .pagination-box>ul>li {
  font-size: 18px;
  font-family: "PretendardR", "sans-serif";
  color: #CDCDCD;
}

.user-self-appDetail .pagination-box>ul>li img:first-child,
.user-info-enter-common .pagination-box>ul>li img:first-child,
.user-trademark-agent .pagination-box>ul>li img:first-child,
.user-inquiry-info .pagination-box>ul>li img:first-child,
.user-trademark-detail .pagination-box>ul>li img:first-child,
.user-additional-payment .pagination-box>ul>li img:first-child {
  width: 25px;
  margin-right: 10px;
}

.user-self-appDetail .pagination-box>ul>li img:last-child,
.user-info-enter-common .pagination-box>ul>li img:last-child,
.user-trademark-agent .pagination-box>ul>li img:last-child,
.user-inquiry-info .pagination-box>ul>li img:last-child,
 .user-trademark-detail .pagination-box>ul>li img:last-child,
 .user-additional-payment .pagination-box>ul>li img:last-child {
  width: 25px;
  margin-left: 10px;
}

.user-self-appDetail .table-body td img {
  margin: 0;
  height: 40px;
}

.user-self-appDetail .table-body .product-info p {
  width: 144px;
  overflow: hidden;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  color: #050F2F;
  text-overflow: ellipsis;
  word-break: break-all;
}

.user-self-appDetail .table-head img,
.user-self-appDetail .table-body .self-checkBox {
  width: 20px;
  cursor: pointer;
}

.user-self-appDetail .headWrap,
.user-info-enter-common .headWrap {
  display: flex;
  justify-content: space-between;
}

.user-self-appDetail .template-delete-btn,
.user-info-enter-common .template-delete-btn {
  border: 1px solid #ff0000;
  font-family: "PretendardM", "sans-serif";
  color: #ff0000;
  font-size: 14px;
  padding: 4px 10px;
}

.user-self-appDetail .template-delete-btn:hover,
.user-info-enter-common .template-delete-btn:hover {
  background-color: #FFD9D9;
}

.modal-bg .mypage-self-popup .info-wrap .mark-preview .preview-wrap button {
  border: 1px solid #D1D1D1;
  width: 127px;
  height: 37px;
  font-size: 14px;
  color: #666;
  border-radius: 4px;
  margin-left: 16px;
}

.modal-bg .mypage-self-popup .info-wrap .mark-preview .preview-wrap button img {
  width: 12px;
  margin-right: 6px;
}

.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F8FD;
}


.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-header .name-title {
  font-family: "PretendardSB", "sans-serif";
  color: #050F2F;
  font-size: 16px;
  border: 1px solid #E1E1E1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: none;
  border-bottom: none !important;
}

.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-header .name-title:first-child {
  border-bottom: none;
}

.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-header .name-title:first-child,
.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-body .name-title:first-child {
  border: 1px solid #E1E1E1;
}

.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-body .text-start {
  justify-content: start !important;
  padding: 0 16px;
}

.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-body {
  display: flex;
}

.modal-bg .mypage-self-popup .regi-info .info-wrap .productInfo-body .name-title {
  border: 1px solid #e1e1e1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: none;
  border-bottom: none !important;
  font-size: 14px;
}


/* 출원번호 검색하기 모달 */
.modal-bg .mypage-search-applicant {
  width: 800px;
  height: 850px;
  padding: 0;
  z-index: 999;
}

.modal-bg .mypage-search-applicant .head {
  height: 80px;
  padding: 32px 32px 0 32px;
}

.modal-bg .mypage-search-applicant .body {
  height: 519px;
  width: 100%;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
  padding: 32px;
}

.modal-bg .mypage-search-applicant .body .tabList {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  white-space: nowrap
}

.modal-bg .mypage-search-applicant .body .tabList button {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  color: #050F2F;
  line-height: 140%;
  width: 367px;
  height: 52px;
}

.modal-bg .mypage-search-applicant .body .tabList button:first-child {
  border-radius: 8px 0 0 8px;
}

.modal-bg .mypage-search-applicant .body .tabList button:last-child {
  border-radius: 0 8px 8px 0;
}

.modal-bg .mypage-search-applicant .body .tabList .active {
  color: white;
  line-height: 140%;
  background-color: #319dff;
}

.modal-bg .mypage-search-applicant .body section {
  margin-top: 24px;
}


.modal-bg .mypage-search-applicant .body section .search-section input {
  border: 1px solid #d1d1d1;
  color: #050f2f;
  line-height: 140%;
  width: 240px;
  height: 48px;
  padding-left: 16px;
  border-radius: 4px;
}

.modal-bg .mypage-search-applicant .body section .search-section input::placeholder {
  color: #878787;
}

.modal-bg .mypage-search-applicant .body section .search-section button {
  border: 1px solid #319DFF;
  color: #319DFF;
  width: 72px;
  height: 48px;
  border-radius: 4px;
  margin-left: 12px;
}

.modal-bg .mypage-search-applicant .body section .search-result {
  height: 347px;
  margin-top: 16px;
  overflow-y: auto;
}

.search-result .result-item {
  width: 100%;
  padding: 20px 12px;
  cursor: pointer;
  background-color: white;
}

.search-result .result-wrap:first-child {
  border-top: 1px solid #d1d1d1;
}

.search-result .result-wrap {
  border-bottom: 1px solid #d1d1d1;
}


.modal-bg .mypage-search-applicant .body section .search-result article .result-header {
  display: flex;
}


.modal-bg .mypage-search-applicant .body section .search-result article p {
  font-family: "PretendardSB", "sans-serif";
  color: #050f2f;
  line-height: 140%;
  width: 688px;
}

.modal-bg .mypage-search-applicant .body section .search-result article:hover .result-header p {
  color: #319DFF;
}

.modal-bg .mypage-search-applicant .body section .search-result article .dropdown-content table {
  width: 100%;
  margin-top: 12px;
}


.modal-bg .mypage-search-applicant .body section .search-result article .dropdown-content table thead th {
  font-size: 14px;
  height: 40px;
}

.modal-bg .mypage-search-applicant .body section .search-result article .dropdown-content table tbody td {
  font-size: 14px;
  height: 40px;
}

.modal-bg .mypage-search-applicant .body section .search-result article .dropdown-content table tbody .overflow-td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.modal-bg .mypage-search-applicant .body section .search-result article .dropdown-content table tbody .box-btn {
  height: 40px;
  vertical-align: middle;
}

.modal-bg .mypage-search-applicant .body section .search-result article .dropdown-content table tbody td:last-child {
  color: #319dff;
}

.modal-bg .mypage-search-applicant .body section .search-result article .dropdown-content table tbody .box-btn {
  padding: 0;
}

.modal-bg .mypage-search-applicant .body section .search-result article img {
  width: 20px;
}

.modal-bg .mypage-search-applicant .body section .search-result article img.rotate {
  transform: rotate(180deg);
  filter: invert(49%) sepia(96%) saturate(3537%) hue-rotate(182deg) brightness(98%) contrast(101%);
}

.modal-bg .mypage-search-applicant .foot {
  height: 248px;
  width: 100%;
  padding: 24px 32px 0 32px;
}

.modal-bg .mypage-search-applicant .foot .title-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end
}

.modal-bg .mypage-search-applicant .foot .title-box header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.modal-bg .mypage-search-applicant .foot .title-box header h4 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}

.modal-bg .mypage-search-applicant .foot .title-box header p {
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  font-size: 14px;
  color: #666666;
}

.modal-bg .mypage-search-applicant .foot .title-box header p strong {
  color: #319DFF;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  font-size: 14px;
}

.modal-bg .mypage-search-applicant .foot .title-box button {
  color: #666;
  font-family: "PretendardM", "sans-serif";
  border: 1px solid #666;
  font-size: 14px;
  width: 70px;
  height: 25px;
}

.modal-bg .mypage-search-applicant .foot .title-box button:hover {
  background-color: #6666667a;
  color: white;
}

.modal-bg .mypage-search-applicant .foot .title-box button:active {
  background-color: #666;
  color: white;
}

.modal-bg .mypage-search-applicant .foot .title-box .deleteBtn {
  color: #FF0000;
  font-family: "PretendardM", "sans-serif";
  border: 1px solid #FF0000;
  font-size: 14px;
  width: 91px;
  margin-left: 6px;
  white-space: nowrap;
}

.modal-bg .mypage-search-applicant .foot .title-box .deleteBtn:hover {
  background-color: #ff00002c;
  color: #FF0000;
  border: 1px solid #FF0000;
}

.modal-bg .mypage-search-applicant .foot .title-box .deleteBtn:active {
  background-color: #FF0000;
  color: white;
}

.modal-bg .mypage-search-applicant .foot .button-list {
  height: 90px;
  width: 100%;
  margin: 16px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 11.5px;
}

.modal-bg .mypage-search-applicant .foot .button-list button {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  border: 1px solid #d1d1d1;
  width: 138px;
  height: 40px;
}

.modal-bg .mypage-search-applicant .foot .button-list button:hover {
  background-color: #F2F8FD;
  color: #319dff;
  border: 1px solid #319dff;
}

.modal-bg .mypage-search-applicant .foot .button-list button:active {
  background-color: #319dff;
  color: white;
  border: 1px solid #319dff;
  font-family: "PretendardSB", "sans-serif";
  white-space: nowrap;
}

.modal-bg .mypage-search-applicant .foot .button-list .selected {
  background-color: #F2F8FD;
  color: #319dff;
  border: 1px solid #319dff;
  font-family: "PretendardSB", "sans-serif";
  white-space: nowrap;
}


.modal-bg .mypage-search-applicant .foot .enter-applicant {
  width: 100%;
  background-color: #319DFF;
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  color: white;
  border-radius: 8px;
  height: 48px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header {
  display: flex;
  align-items: center;
}

.modal-bg .mypage-search-applicant .body .applicant-section header button {
  width: 72px;
  height: 52px;
  border-radius: 4px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .reset {
  border: 1px solid #d1d1d1;
  color: #666666;
  margin-right: 8px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .searchBtn {
  border: 1px solid #319dff;
  color: #319dff;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .single-input {
  border: 1px solid #d1d1d1;
  width: 240px;
  height: 48px;
  border-radius: 4px;
  padding-left: 16px;
  margin: auto 154px auto 8px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .label-single {
  margin-right: 8px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .label-range {
  margin-right: 4px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .range-input {
  width: 176px;
  height: 52px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .range-input.first {
  margin: auto 6px auto 8px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .range-input {
  margin: auto 19px auto 6px;
  padding-left: 13px;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .range-input::placeholder {
  color: #D1D1D1;
}

.modal-bg .mypage-search-applicant .body section .search-section input::placeholder {
  color: #d1d1d1;
}

.modal-bg .mypage-search-applicant .body .applicant-section header .single-input::placeholder {
  color: #d1d1d1;
}

.modal-bg .mypage-search-applicant .body .applicant-section .search-result {
  padding-bottom: 20px;
}

.modal-bg .mypage-search-applicant .body .applicant-section .search-result table th {
  height: 40px;
  font-size: 14px;
}

.modal-bg .mypage-search-applicant .body .applicant-section .search-result table .box-btn {
  width: 20px;
  height: 20px;
  padding: 0;
}

.modal-bg .mypage-search-applicant .body .applicant-section .search-result table td {
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
}

.modal-bg .mypage-search-applicant .body .applicant-section .search-result table .overflow-td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-bg .mypage-search-applicant .body .applicant-section .questionIcon {
  position: relative;
  display: block;
}

.modal-bg .mypage-search-applicant .body .applicant-section .tooltip {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px;
  font-size: 12px;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 100;
}

.modal-bg .mypage-search-applicant .body .applicant-section .tooltip p {
  color: white;
  font-size: 12px;
}

.modal-bg .mypage-search-applicant .body .applicant-section .tooltip span {
  color: #d1d1d1;
  font-size: 12px;
}

.modal-bg .mypage-search-applicant .body .applicant-section .tooltip-container {
  position: relative;
  display: inline-block;
}

.modal-bg .mypage-search-applicant .body .applicant-section .question-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: 4px;
}


.modal-bg .mypage-search-applicant .body .applicant-section .tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
}

.skeleton-box {
  background: linear-gradient(90deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite linear;
  border-radius: 4px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}


.modal-bg .mypage-manage-applicant {
  width: 800px;
  padding: 32px;
  align-items: flex-start;
  height: 715px;
  z-index: 999;
}

.modal-bg .mypage-manage-applicant .head {
  width: 100%;
  border-bottom: 1px solid #666666;
  padding-bottom: 12px;
}

.modal-bg .mypage-manage-applicant .head .modal-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.modal-bg .mypage-manage-applicant .head .modal-title h4 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  line-height: 28px;
}

.modal-bg .mypage-manage-applicant .body {
  width: 100%;
  height: 639px;
  overflow: auto;
}


.modal-bg .mypage-manage-applicant .body h5 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  line-height: 25.2px;
  margin-top: 24px;
}

.modal-bg .mypage-manage-applicant .body table td {
  height: 40px;
}

.modal-bg .mypage-manage-applicant .body .trademark-table {
  margin-top: 9px;
}

.modal-bg .mypage-manage-applicant .body .trademark-table tbody .table-name {
  background-color: #F5FAFF;
  width: 136px;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
}

.modal-bg .mypage-manage-applicant .body .trademark-table tbody .table-content {
  width: 230px;
  text-align: start;
  font-size: 16px;
  color: #050f2f;
  font-family: "PretendardR", "sans-serif";
}

.modal-bg .mypage-manage-applicant .body .trademark-table tbody .table-content-img {
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}

.modal-bg .mypage-manage-applicant .body .trademark-table tbody .table-content-img div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-bg .mypage-manage-applicant .body .trademark-table thead {
  background-color: #F5FAFF;
  height: 40px;
}

.modal-bg .mypage-manage-applicant .body .table-last {
  table-layout: fixed;
}

.modal-bg .mypage-manage-applicant .body .table-name {
  background-color: #F5FAFF;
}

.modal-bg .mypage-manage-applicant .body .table-name td {
  font-size: 16px;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
}

.modal-bg .mypage-manage-applicant .body .table-last .item-detail {
  text-align: start;
  line-height: 140%;
}

.modal-bg .mypage-agentStatus-popup {
  width: 800px;
  padding: 32px;
  border-radius: 16px;
}

.modal-bg .mypage-agentStatus-popup .modal-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #050f2f;
  padding-bottom: 18px;
}

.modal-bg .mypage-agentStatus-popup .modal-title h4 {
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
}

.modal-bg .mypage-agentStatus-popup .agent-info {
  width: 100%;
  margin: 24px auto;
  overflow-y: auto;
  max-height: 643px;
}


.modal-bg .mypage-agentStatus-popup .agent-info h5 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}

.modal-bg .mypage-agentStatus-popup .agent-info table {
  table-layout: fixed;
  width: 100%;
  margin-top: 17px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .label {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  background-color: #F5FAFF;
  width: 120px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .label .document-container p {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content {
  text-align: start;
  font-size: 14px;
  padding: 9px 16px;
  width: 613px;
  height: 38px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content div p,
.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content p,
.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content span {
  font-size: 14px;
  line-height: 140%;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content img {
  width: 92px;
  height: 92px;
  margin: 4px;
  border: 1px solid #d1d1d1;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content div span {
  font-size: 12px;
  font-family: "PretendardSB", "sans-serif";
  color: #666666;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content .select-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content .select-box select {
  border: 1px solid #d1d1d1;
  font-size: 14px;
  width: 70px;
  height: 28px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content.caution {
  font-size: 12px;
  color: #666;
  font-family: "PretendardR", "sans-serif";
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content.file div {
  display: flex;
  justify-content: space-between;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content.file div .subtitle {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content.file div .file-name {
  color: #666;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  text-decoration: underline;
  width: 179px;
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content.file div button {
  font-family: "PretendardM", "sans-serif";
  font-size: 14px;
  color: #319dff;
  border: 1px solid #319dff;
  width: 73px;
  height: 25px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content.file .button-disabled {
    background-color: #f6f6f6 !important; 
    color: #d1d1d1 !important; 
    cursor: not-allowed !important; 
    border: 1px solid #d1d1d1 !important; 
    /* opacity: 0.6 !important;  */
}


.modal-bg .mypage-agentStatus-popup .agent-info table .agent-content div .divider {
  color: #d1d1d1;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
}

.modal-bg .mypage-agentStatus-popup .agent-confirm {
  background-color: #319dff;
  width: 100%;
  height: 48px;
  color: white;
  border-radius: 8px;
  font-family: "PretendardSB", "sans-serif";
}

.modal-bg .mypage-agentStatus-popup .agent-info table .label .document-container .required {
  color: #FF0000;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .label .document-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .label div img {
  margin-left: 6px;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .label .document-container .tooltip {
  position: absolute;
  background-color: black;
  color: white;
  width: 266px;
  padding: 8px;
  font-size: 12px;
  line-height: 140%;
  bottom: 25px;
  left: 0%;
  text-align: start;
  z-index: 9999;
}

.modal-bg .mypage-agentStatus-popup .agent-info table .label .document-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 30%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.modal-bg .mypage-additional-popup .head {
  width: 100%;
  padding: 32px 32px 0 32px;
}

.modal-bg .mypage-additional-popup .head .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.modal-bg .mypage-additional-popup .head .modal-title h4 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 20px;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body {
  width: 100%;
  height: 416px;
  padding: 0 32px;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.08);
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-bg .mypage-additional-popup .body h5 {
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
  margin: 24px auto 16px auto
}

.modal-bg .mypage-additional-popup .body table {
  width: 100%;
  table-layout: fixed;
}

.modal-bg .mypage-additional-popup .body table th {
  background-color: #F5FAFF;
  height: 48px;
  vertical-align: middle;
  border: 1px solid #d1d1d1;
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
}

.modal-bg .mypage-additional-popup .body table td {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  padding: 14px 8px;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body hr {
  background-color: #d1d1d1;
  height: 1px;
  border: 0;
}

.modal-bg .mypage-additional-popup .body .reason {
  border-bottom: 1px dotted #d1d1d1;
}

.modal-bg .mypage-additional-popup .body .reason .reason-title {
  display: flex;
  justify-content: space-between;
  margin: 20px 16px 0 16px;
  font-size: 14px;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body .reason .description {
  margin: 8px 32px 0 32px;
}

.modal-bg .mypage-additional-popup .body .reason .description div {
  display: flex;
  gap: 8px;
}

.modal-bg .mypage-additional-popup .body .price {
  display: flex;
  justify-content: space-between;
  margin: 20px 16px;
}

.modal-bg .mypage-additional-popup .body .price.total p {
  font-family: "PretendardSB", "sans-serif";
}

.modal-bg .mypage-additional-popup .body .reason .description div p {
  font-size: 14px;
  line-height: 140%;
  color: #666;
}

.modal-bg .mypage-additional-popup .body .reason .description div:last-child {
  margin-bottom: 20px;
}

.modal-bg .mypage-additional-popup .body .status {
  display: flex;
  justify-content: space-between;
  margin: 17px 16px;
}

.modal-bg .mypage-additional-popup .body .status .normal-text {
  color: #050F2F !important;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif" !important;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body article {
  background-color: #F6F6F6;
  padding: 24px;
}

.modal-bg .mypage-additional-popup .body article h5 {
  margin: 0 auto 16px auto;
}

.modal-bg .mypage-additional-popup .body article .select-area,
.modal-bg .mypage-additional-popup .body article .bank-name,
.modal-bg .mypage-additional-popup .body article .tax-bill-wrap {
  background-color: white;
  border: 1px solid #d1d1d1;
}

.modal-bg .mypage-additional-popup .body article .bank-name,
.modal-bg .mypage-additional-popup .body article .tax-bill-wrap {
  border-top: none;
  display: flex;
}

.modal-bg .mypage-additional-popup .body article .bank-name .box-content {
  display: flex;
}

.modal-bg .mypage-additional-popup .body article .bank-name .bank-namebox {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  width: 130px;
  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body article .select-area .box-head,
.modal-bg .mypage-additional-popup .body article .bank-name .box-head,
.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .box-head {
  background-color: #F5FAFF;
  width: 100px;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  padding: 14px 0;
  text-align: center;
  border-right: 1px solid #d1d1d1;
}

.modal-bg .mypage-additional-popup .body article .select-area .box-content,
.modal-bg .mypage-additional-popup .body article .bank-name .box-content {
  width: calc(100% - 100px);
  display: flex;
  gap: 16px;
  padding: 0 16px;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .box-content {
  width: calc(100% - 100px);
  padding: 24px 16px;
}

.modal-bg .mypage-additional-popup .body article .select-area .box-content .radio-option {
  display: flex;
  align-items: flex-start;
}

.modal-bg .mypage-additional-popup .body article .select-area .box-content .radio-option label {
  margin-left: 8px;
  line-height: 140%;
  font-size: 14px;
}

.modal-bg .mypage-additional-popup .body article .bank-name .box-content {
  display: flex;
  height: 72px;
  align-items: center;
}

.modal-bg .mypage-additional-popup .body article .bank-name .box-head,
.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .box-head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-bg .mypage-additional-popup .body article .bank-name .box-content .bank-manual {
  font-size: 14px;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body .status p:last-child {
  color: #585858;
  font-family: "PretendardSB", "sans-serif";
}

.modal-bg .mypage-additional-popup .foot {
  width: 100%;
  padding: 24px 32px 32px 32px;
}

.modal-bg .mypage-additional-popup .foot button {
  width: 100%;
  background-color: #319dff;
  height: 48px;
  border-radius: 8px;
  font-family: "PretendardSB", "sans-serif";
  color: white;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .radio-area {
  display: flex;
  gap: 16px;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .radio-area .radio-option {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .radio-area .radio-option label {
  line-height: 140%;
  font-size: 14px;
}


.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .input-wrap {
  width: 359px;
  gap: 12px;
  align-items: flex-end;
  margin: 20px 0;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .input-wrap.private {
  width: 338px;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap p {
  font-size: 14px;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .input-wrap strong {
  font-size: 14px;
  line-height: 140%;
  font-family: "PretendardM", "sans-serif";
  margin-right: 8px;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .input-wrap input {
  height: 40px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.modal-bg .mypage-additional-popup .body article .agree-wrap label {
  /* background-color: red; */
  display: flex;
  align-items: center;
  margin-top: 16px;
}


.modal-bg .mypage-additional-popup .body article .agree-wrap .checkBox {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #d1d1d1;
}


.modal-bg .mypage-additional-popup .body article .agree-wrap .checkBox img {
  width: 20px;
  height: 20px;
}

.modal-bg .mypage-additional-popup .body article .agree-wrap span {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
}

.agree-wrap.active ul li img {
  filter: invert(31%) sepia(89%) saturate(348%) hue-rotate(183deg) brightness(95%) contrast(95%);
}


.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .input-wrap span {
  margin-left: 6px;
}

.modal-bg .mypage-additional-popup .body article .tax-bill-wrap .input-wrap input::placeholder {
  color: #d1d1d1;
}

.modal-bg .mypage-additional-popup .body article ul li {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-bg .mypage-additional-popup .body article ul li p {
  font-size: 14px;
  line-height: 140%;
  color: #666;
}

.modal-bg .mypage-additional-popup .body .agree-wrap ul li img {
  filter: grayscale(100%); /* 기본 회색 */
  transition: filter 0.3s ease;
}

.modal-bg .mypage-additional-popup .body .agree-wrap.active ul li img {
  filter: invert(31%) sepia(89%) saturate(348%) hue-rotate(183deg) brightness(95%) contrast(95%);
}

.modal-bg .mypage-additional-popup .body article ul li button {
  font-size: 14px;
  color: #666;
  text-decoration: underline;
  line-height: 140%;
}

.modal-bg .mypage-additional-popup .body article ul {
  margin-top: 16px;
}

.modal-bg .mypage-additional-popup .body .total-price {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.modal-bg .mypage-additional-popup .body .total-price p {
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}