.doughtWrapBox {
  width: 310px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.doughtWrapBox .doughnutContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
  position: relative;
}

.doughtWrapBox .doughnutContainer .doughnut {
  width: 170px;
  height: 170px;
}

.doughtWrapBox .doughnutContainer .stateGraph {
  position: absolute;
  top: 3px;
  left: 109px;
  text-align: center;
  color: #1150CB;
  width: 116.043px;
  height: 115.043px;
  margin-top: 40px;
  flex-shrink: 0;
  border-radius: 1303.043px;
  background-color: #E7EDFA;
}

.doughtWrapBox .doughnutContainer .stateGraph div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: 5px #1150CB solid;
  border-radius: 9999px;
}

.doughtWrapBox .doughnutContainer .stateGraph div span:nth-child(1) {
  text-align: center;
  color: #1150CB;
  font-size: 18px;
  font-weight: 600;
  word-wrap: break-word;
  margin: 8px 0;
}

.doughtWrapBox .doughnutContainer .stateGraph div span:nth-child(1):lang(en) {
  font-size: 14px;
}

.doughtWrapBox .doughnutContainer .stateGraph div span:nth-child(2) {
  text-align: center;
  color: #1150CB;
  font-size: 30px;
  font-weight: 700;
  word-wrap: break-word;
}

.doughtWrapBox .doughnutContainer .containerWithLabelDots {
  height: 20px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
}

.doughtWrapBox .doughnutContainer .containerWithLabelDots.small-container {
  margin-top: 0;
}

.doughtWrapBox .doughnutContainer .containerWithLabelDots:lang(en) {
  width: 230px;
}

.doughtWrapBox .doughnutContainer .labelWithColorDot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.doughtWrapBox .doughnutContainer .labelWithColorDot p {
  font-size: 22px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
}

.doughtWrapBox .doughnutContainer .labelWithColorDot.small-label p{
  font-size: 14px;
}
.doughtWrapBox .doughnutContainer .circularColorIndicator.small-dot {
  width: 12px;
  height: 12px;
  border-radius: 9999px;
}

.doughtWrapBox .doughnutContainer .circularColorIndicator {
  width: 15px;
  height: 15px;
  border-radius: 9999px;
}


.doughtWrapBox .doughnutContainer .verticalDivider {
  width: 1px;
  height: 16px;
  background: #BEBEBE;
}

.doughtWrapBox .doughnutContainer .doughnutContainer2 {
  width: 100%;
  height: 200px;
  position: relative;
}

.doughtWrapBox .doughnutContainer .halfDoughnut {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  left: 35%;
  transform: translateX(-40%);
}

.doughtWrapBox .doughnutContainer .halfDoughnut2 {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 35%;
  transform: rotate(180deg) scaleY(-1);
}

.doughtWrapBox .doughnutContainer .textBox {
  width: 65px;
  height: 31px;
  left: 12%;
  padding: 4px 12px;
  background: #2F11CB;
  border-radius: 20px;
  border: 2px white solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(0%, 30%);
}

.doughtWrapBox .doughnutContainer .textBox2 {
  width: 65px;
  height: 31px;
  left: 85%;
  padding: 4px 12px;
  background: #11ADCB;
  border-radius: 20px;
  border: 2px white solid;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(-50%, 20%);
}
.doughtWrapBox .doughnutContainer .number-box {
  font-size: 18px;
  color: white;
  line-height: 140%;
  font-family: "PretendardSB", "sans-serif";
}


