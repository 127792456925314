.selfTradeName-wrap {width: 100%;}
.selfTradeName-wrap .selfTrade-all{
  width: 100%;
  /* height: 100vh; */
}
.selfTradeName-wrap .selfTrade-contentBox01{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-topBox{
  width: 100%;
} 
.selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top{
  width: 100%;  
  max-width: 1280px;
  height: auto;
  margin: 0px auto;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top span{
  display: block;
  color: #319DFF;
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 20px;
  line-height: 140%;
  text-align: center;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top h2{
  font-size: 48px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-topBox .content-top p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 1280px;
  margin: 0px auto;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  height: 280px;
  /* padding: 0px 63px; */
  background: #F6F6F6;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li img{
  display: block;
  margin: 0px auto;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li h3{
  font-size: 20px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
  margin-bottom: 12px;
}
.selfTradeName-wrap .selfTrade-contentBox01 .content-botBox ul li span{
  display: block;
  color: #666;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  line-height: 140%; 
}
/* 섹션2 */
.selfTradeName-wrap .selfTrade-contentBox02{
  width: 100%;
  min-width: 1920px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(248, 189, 52, 0.1);
}
.selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox{
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;
  box-sizing: border-box;
}
.selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.selfTradeName-wrap .selfTrade-contentBox02 .content-oneBox .selfTrade-youtube{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width: 100%;
  max-width: 1048px;
  height: 550px;
  background: #383838;
  color: #fff;
}
/* 섹션3 */
.selfTradeName-wrap .selfTrade-contentBox03{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox{
  width: 100%;
  height: 100vh;
  height: auto;
  margin: 0px auto;
  box-sizing: border-box;
}
.selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.selfTradeName-wrap .selfTrade-contentBox03 .content-oneBox img{
  display: block;
  margin: 0px auto;
}
/* 섹션3-1 */
.selfTradeName-wrap .selfTrade-contentBox03-01{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.selfTradeName-wrap .selfTrade-contentBox03-01 .content-oneBox{
  width: 100%;
  height: 100vh;
  height: auto;
  margin: 0px auto;
  box-sizing: border-box;
}
.selfTradeName-wrap .selfTrade-contentBox03-01 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.selfTradeName-wrap .selfTrade-contentBox03-01 .content-oneBox img{
  display: block;
  margin: 0px auto;
}
/* 섹션4 */
.selfTradeName-wrap .selfTrade-contentBox04{
  width: 100%;
  /* height: 100vh; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox{
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox span{
  display: block;
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 10px;
  text-align: center;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox{
  display: flex;
  justify-content: center;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
  margin-right: 48px;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img{
  margin: 35px auto 0;
  min-height: 520px;
}
/* .selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
  width: 25%;
} */

.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right > ul{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
}
.selfTradeName-wrap .poten-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right > ul{
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
}

.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li{
  display: flex;
  /* align-items: center; */
  /* width: 620px; */
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 20px 35px 20px 24px;
  border: 2px solid transparent; 
  transition: border 0.3s; 
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
.hover-y li:hover{
  outline: 3px solid #F8BD34;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
.hover-b li:hover{
  outline: 3px solid #316bff;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
.hover-g li:hover{
  outline: 3px solid #1CC4CB;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul .hover_defaulty{
  outline: 3px solid #F8BD34;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul .hover_defaultb{
  outline: 3px solid #316bff;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul .hover_defaultg{
  outline: 3px solid #1CC4CB;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li img{
  display: inline-block;
  margin-right: 20px;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} 
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li h3{
  width: 100%;
  font-size: 18px;
  font-family: "PretendardB", "sans-serif";
  line-height: 150%;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content p{
  width: 100%;
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  color: #666;
}
.selfTradeName-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right
ul li .hover-content small{
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: #bfbfbf;
}
/* 섹션5 */
.selfTradeName-wrap .selfTrade-contentBox05{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox{
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;
  box-sizing: border-box;
}
.selfTradeName-wrap .selfTrade-contentBox05 h2{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  margin-bottom: 20px;
  line-height: 140%;
  color: #050F2F;
}
.selfTradeName-wrap .selfTrade-contentBox05  h2 span{
  color: #319DFF;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox p{
  color: #666666;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 60px;
  text-align: center;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox{
  position: relative;
  margin: 0px auto;
  min-width: 1280px;
  height: 350px;
  background-image: url('image/subBg6_1.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content{ 
  width: 502px;
  height: 150px;
  position: absolute;
  right: 265px;
  bottom: 99px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content p{
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .content-oneBox .guide-content .guidebox-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  font-family: "PretendardR", "sans-serif";
  padding: 8px 16px;
  border: 1px solid #050F2F;
  cursor: pointer;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  min-height: 254px;
  background: #F8BD34;
  border-radius: 8px;
  margin: 20px auto 0px;
  padding: 60px 180px 60px 120px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text{
  width: 70%;;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text h4{
  color: #fff;
  font-family: "PretendardB", "sans-serif";
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 40px;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-text span{
  color: #fff;
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  background-color: #fff;
  color: #050F2F;
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-link .main-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
}
.selfTradeName-wrap .selfTrade-contentBox05 .guidebox-bot .service-link .main-nav img{
  transform: rotate(90deg);
}
/* 
풀페이지 스크롤
.selfTrade-allwrap .Section{
  max-width: 1280px;
  height: 100vh !important;
  border: 1px solid #000;
}
.selfTrade-section01{background-color: #009fff;}
.selfTrade-section05 {padding: 140px 320px !important;}
.selfTrade-section05 .final-title-wrap h6 ,
.selfTrade-section05 .final-title-wrap h5,
.selfTrade-section05 .final-title-wrap h5 span{
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}
.selfTrade-section05 .final-title-wrap {
  margin-bottom: 60px;

}
.selfTrade-section05 .final-title-wrap h6 {
  line-height: 46px;
  color: #050F2F;
  margin-bottom: 4px;
}
.selfTrade-section05 .final-title-wrap h5 {
  line-height: 70px;
}
.selfTrade-section05 .final-title-wrap h5 span {
  line-height: 70px;
}
.selfTrade-section05 .title-point {
  color: #319DFF;
}
.selfTrade-section05 .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  min-width: 1280px;
  height: 350px;
  background-image: url(http://localhost:3000/static/media/guideBg02.bac9e6a….svg);
  background-repeat: no-repeat;
  background-position: center;
}
.selfTrade-section05 .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.selfTrade-section05 .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  padding: 8px 16px;
  border: 1px solid #000;
  cursor: pointer;
}
.selfTrade-section05 .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  color: #333333;
}
.selfTrade-section05 .box-wrap .service-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: 350px;
  padding: 83px 216px 80px 104px;
  border-radius: 8px;
  background-color: #00274A;
}
.selfTrade-section05 .box-wrap .service-box .service-text, .service-link {
  width: 50%;
  color: #fff;
}
.selfTrade-section05 .box-wrap .service-box .service-text h4 {
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 40px;
}
.selfTrade-section05.box-wrap .service-box .service-text span {
  font-size: 20px;
  color: #DDDDDD;
}
.selfTrade-section05 .box-wrap .service-box .service-link {
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: #319DFF;
}
.selfTrade-section05 .box-wrap .service-box .service-link a {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
} */