:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

.marquee {
  width: 1920px;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: 40px;

}

.marquee__group {
  margin-right: 80px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  width: 100%;
  -webkit-animation: scroll-x var(--duration) linear infinite;
          animation: scroll-x var(--duration) linear infinite;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
}

@-webkit-keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@-webkit-keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}



/* Parent wrapper */
.progress-content {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

@media (max-width: 1024px) {
  .marquee__group{
    width: 70% !important;
  }

}
@media (max-width: 580px) {
  .marquee__group{
    width: 62% !important;
  }
}