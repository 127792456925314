@font-face {
  font-family: 'NotoSansBlack';
  src: url('../fonts/NotoSansKR-Black.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSansB';
  src: url('../fonts/NotoSansKR-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSansM';
  src: url('../fonts/NotoSansKR-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSansR';
  src: url('../fonts/NotoSansKR-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSansL';
  src: url('../fonts/NotoSansKR-Light.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSansT';
  src: url('../fonts/NotoSansKR-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'PretendardBlack';
  src: url('../fonts/Pretendard-Black.otf') format('opentype');
}
@font-face {
  font-family: 'PretendardB';
  src: url('../fonts/Pretendard-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'PretendardSB';
  src: url('../fonts/Pretendard-SemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'PretendardM';
  src: url('../fonts/Pretendard-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'PretendardR';
  src: url('../fonts/Pretendard-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'PretendardL';
  src: url('../fonts/Pretendard-Light.otf') format('opentype');
}
@font-face {
  font-family: 'PretendardT';
  src: url('../fonts/Pretendard-Thin.otf') format('opentype');
}