@charset "UTF-8";

.App * {
  font-family: "PretendardR", "sans-serif";
  font-size: 16px;
  color: #15171a;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.display-none {
  display: none;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.container {
  overflow: hidden;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container.gray-bg {
  background-color: #f6f6f6;
}

.container .header {

  background-color: none;

  width: 100vw;
  height: 100px;
  padding: 0 240px;
  justify-content: space-between;
  position: relative;
  z-index: 50;
}

.white-header {
  background-color: #ffffff;

}



.container .header .header-wrap .logo {
  width: 120px;
  height: 48px;
  cursor: pointer;
}

.container .header .userMenu .snsBox {
  display: flex;
  margin-right: 40px;
}

.container .header .userMenu .snsBox li {
  display: flex;
  margin-right: 16px;
}

.container .header .userMenu .snsBox img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.container .header .userMenu>li:nth-child(2)::after {
  content: "|";
  position: relative;
  top: 0px;
  margin: 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.2);
}

.container .header .menu li {
  /* margin-right: 40px; */
  cursor: pointer;
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
}

.container .header .userMenu li {
  margin-right: 0px;
  cursor: pointer;
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
  color: #666;
}

.container .header .userMenu>li:last-child {
  margin-left: 40px;
}

.container .header .menu li a,
.container .header .userMenu li a {
  font-family: "PretendardM", "sans-serif";
  font-size: 20px;
}

.container .header .menu li:last-child,
.container .header .userMenu li:last-child {
  margin-right: 0;
}

.container .header .sub-nav {
  display: none;
  position: absolute;
  top: 100px;
  z-index: 5;
  height: 80px;
  font-family: "PretendardR", "sans-serif";
  font-size: 18px;
}

.container .header .sub-nav>li {
  display: inline-block;
  line-height: 80px;
  color: #15171a;
  margin-right: 0;
}

.container .header .sub-nav>li a:hover {
  font-family: "PretendardSB", "sans-serif";
  color: #319dff;
  background-color: transparent !important;
}

.container .header .sub-nav>li::after {
  content: "|";
  position: relative;
  top: -3px;
  margin: 0 30px;
  font-size: 12px;
}

.container .header .sub-nav>li:last-child::after {
  content: "";
}

.container .header .sub-nav>li a {
  color: #15171a;
  font-family: "PretendardR", "sans-serif";
}

.container .header .sub-nav-bg {
  display: none;
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 3;
  width: 100%;
  height: 80px;
  background-color: white;
  opacity: 80%;
  box-shadow: 0 4px 18px 0 #0000000a;
}

.container .header>.header-wrap {
  gap: 70px;
  height: 100%;
}

.container .header>.header-wrap>.menu {
  gap: 70px;
  height: 100%;
}

.container .header>.header-wrap>.menu>li {
  height: 100%;
}

.container .header>.header-wrap>.menu>li>a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  font-family: "PretendardR", "sans-serif";
}

.container .header>.header-wrap>.menu>li>a:hover {
  font-family: "PretendardSB", "sans-serif";
  color: #319dff;
}




/* .container .header>.header-wrap>.menu>li:hover a {
  color: #319DFF;
  font-family: "PretendardSB", "sans-serif";
} */
.container .title {
  margin-bottom: 80px;
  width: 100%;
}

.container .title>.img-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container .title>.img-wrap>img {
  display: inline-block;
  margin: 0px 16px;
}

.container .title>.img-wrap>span {
  font-size: 18px;
  font-family: "PretendardM", "sans-serif";
  line-height: 36px;
}

.container .content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.content-wrap.agencies {
  position: relative;
}

.container .btn-wrap button {
  border-radius: 8px;
  width: 180px;
  height: 50px;
  text-align: center;
  font-size: 20px;
}

.container .btn-wrap button.common-btn {
  background-color: #ffc200;
  color: white;
}

.container .btn-wrap button.gradient-btn {
  background-color: #319dff;
  color: white;
}

.container .btn-wrap button.border-btn {
  border: 2px solid #319dff;
  color: #319dff;
}

.container .btn-wrap button.gray-btn {
  background-color: #d9d9d9;
  color: #241e20;
}

.container .btn-wrap button.blue-btn {
  background-color: #3c8fef;
  color: white;
}

.container .btn-wrap button.blue-border-btn {
  border: 1px solid #3c8fef;
  color: #3c8fef;
}

.container .common-text-input {
  padding: 16px;
  width: 400px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.container .common-text-input::placeholder {
  color: #cdcdcd;
}

.container .selectBox .selectVal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 10px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  cursor: pointer;
}

.container .selectBox .selectOptGroup {
  position: absolute;
  z-index: 5;
  transform: translateY(9px);
  border-radius: 4px;
  background-color: white;
  border: 1px solid #d9d9d9;
}

.container .selectBox .selectOptGroup .selectOpt {
  cursor: pointer;
  background-color: white;
  padding: 10px;
  line-height: 23px;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}

.container .selectBox .selectOptGroup .selectOpt:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.container .selectBox .selectOptGroup .selectOpt:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.container .checkBox {
  width: 20px;
  height: 20px;
  border: 1px solid #858585;
  border-radius: 2px;
  margin-right: 10px;
}

.container .checkBox.active {
  border: 0;
  background-color: #3c8fef;
}

.container .checkBox>img {
  width: 13px;
  height: 10px;
}

.container .common-checkBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container .common-checkBox span {
  user-select: none;
  color: #858585;
}

/* 체크 박스 - 추가 */
.common-checkBox .checkBox {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #e1e1e1 !important;
  background-color: #ffffff !important;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.common-checkBox .checkBox.active {
  border: none !important;
  background-color: #319dff !important;
}

.common-checkBox .checkBox img {
  width: 14px !important;
  height: 14px !important;
}

.common-checkBox span {
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  color: #050f2f !important;
}

/* 체크 박스 - 추가 여기까지*/

.personal-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 768px;
}

.personal-wrap h4 {
  display: flex;
  margin: 100px 0 32px 0;
  color: #050f2f;
  font-family: "PretendardSB", "sans-serif";
  font-size: 28px;
  font-weight: 600;
  background-color: white;
  border-radius: 8px;
  width: 350px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border: 4px solid #319dff;
}

.personal-wrap .personal {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 100px;
}

.personal-wrap .personal p {
  font-family: "PretendardR", "sans-serif";
  font-size: 12px;
  line-height: 130%;
}

.personal-wrap .personal p.ml-20 {
  margin-left: 20px;
}

.personal-wrap .personal p.bold-head {
  font-family: "PretendardM", "sans-serif";
  font-size: 14px;
}

.personal-wrap .personal p.bold-head-16 {
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
}

.personal-wrap .use-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 100px 0 32px 0;
}

.personal-wrap .use-btn-wrap>button {
  width: 350px;
  height: 80px;
  border-radius: 8px;
  background-color: #fff;
  color: #666666;
  margin-right: 24px;
  font-size: 28px;
  font-family: "PretendardSB", "sans-serif";
}

.personal-wrap .use-btn-wrap>button.active {
  background-color: #fff;
  border: 4px solid #319dff;
  color: #050f2f;
}

.personal-wrap .use-btn-wrap>button:last-child {
  margin-right: 0;
}

.footer {
  width: 100%;
  height: 226px;
  /* margin: 100px auto 0; */
  margin: 0 auto 0;
  background-color: #222222;
  /* border-top: 1px solid #ddd; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 32px 0;
}

.footer * {
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
  color: #888888;
}

.footer .content-container {
  width: 1280px;
}

.footer .top>ul {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  border-bottom: 1px solid #323232;
}

.footer .top>ul>li .highlight {
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
  border-right: 1px solid #323232;
  padding-right: 5px;
  margin-right: 5px;
}

.footer .top>ul>li:first-child>ul {
  display: none;
  width: 150px;
  height: 140px;
  border-radius: 10px;
  margin: 5px auto;
}

.footer .top>ul>li:first-child>ul>li {
  line-height: 35px;
}

.footer .top>ul>li:first-child>ul>li a {
  display: block;
  width: 100%;
  padding-left: 15px;
  color: #15171a;
}

.footer .top>ul>li:first-child>ul>li a.first:hover {
  border-radius: 10px 10px 0px 0px;
}

.footer .top>ul>li:first-child>ul>li a.last:hover {
  border-radius: 0px 0px 10px 10px;
}

.footer .top button {
  display: flex;
  width: 160px;
  height: 40px;
  line-height: 40px;
  border-radius: 30px;
}

.footer .bottom-flex {
  display: flex;
  justify-content: space-between;
}

.footer .bottom div p:nth-child(1),
.footer .bottom div p:nth-child(2),
.footer .bottom div p:nth-child(3) {
  margin: 20px 80px 20px 0;
}

.footer .bottom div p:nth-child(1) .copyright {
  color: #878787;
}

.footer .bottom div p:last-child {
  font-size: 16px;
  font-family: "PretendardR", "sans-serif";
}

.footer .bottom div p:last-child span {
  display: inline-block;
  min-width: 90px;
  font-size: 16px;
  font-family: "PretendardB", "sans-serif";
}

.footer .bottom div #familySite {
  min-width: 200px;
  margin-top: 22px;
  padding: 12px 24px;
  border: 1px solid #dddddd;
  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; */
}

.footer .foot_innerbox {
  width: 1180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 72px;
}

.footer .foot-leftbox {
  display: flex;
  flex-wrap: wrap;
  width: 76%;
}

.footer .foot-rigthbox {
  width: 20%;
}

.footer .foot-leftbox .leftbox-top,
.leftbox-mid,
.leftbox-bot {
  width: 100%;
}

.footer .foot-leftbox .leftbox-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #323223;
  padding-bottom: 16px;
}

.footer .foot-leftbox .leftbox-top>ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .foot-leftbox .leftbox-top>.rule_ul li:first-child {
  padding-right: 16px;
  border-right: 1px solid #323232;
}

.footer .foot-leftbox .leftbox-top>.rule_ul li:first-child a {
  color: #888888;
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
}

.footer .foot-leftbox .leftbox-top>.rule_ul li:last-child {
  padding-left: 16px;
}

.footer .foot-leftbox .leftbox-top>.rule_ul li:last-child a {
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
}

.footer .foot-leftbox .leftbox-top>.sns_ul {
  gap: 12px;
}

.footer .foot-leftbox .leftbox-mid>ul {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0 4px;
  border-bottom: 1px solid #323223;
  margin-bottom: 16px;
}

.footer .foot-leftbox .leftbox-mid>ul>li {
  padding-right: 16px;
  border-right: 1px solid #323232;
  margin-bottom: 12px;
  font-size: 14px;
}


.footer .foot-leftbox .leftbox-mid>ul>li:nth-child(3),
.footer .foot-leftbox .leftbox-mid>ul>li:nth-child(5) {
  border: none;
}

.footer .foot-leftbox .leftbox-fot>p {
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
}

.foot-rightbox {
  width: 17%;
}

.foot-rightbox .foot-three {
  padding: 14px;
  border: 1px solid #555555;
  margin-bottom: 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  background: url('/src/image/selectbtn.svg') no-repeat right 10px center;
}

.foot-rightbox .foot-three select {
  width: 100%;
  -webkit-appearance: none;
}

.foot-rightbox .foot_right_ul {
  width: 100%;
}

.foot-rightbox .foot_right_ul>li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.foot-rightbox .foot_right_ul>li>p {
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
}

.foot-rightbox .foot_right_ul>li>span {
  display: inline-block;
  width: 114px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  text-align: left;
}

.pagination-box {
  margin-top: 50px;
}

.pagination-box>ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagination-box>ul>li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.pagination-box>ul>li.active {
  background-color: #319dff;
  border: 1px solid #319dff;
  color: #fff;
}

.pagination-box>ul>li.disabled>i {
  color: #cdcdcd;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: #fff;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18), 2px 0px 8px rgba(0, 0, 0, 0.18);
}

.select-mark .content-box .dashed {
  border: 1px dashed #d9d9d9;
  background-color: transparent;
  margin-top: 40px;
  width: 850px;
}

.select-mark .content-box .content-line {
  margin: 0;
  width: 100%;
  height: 1px;
  border: 0;
  margin-top: 40px;
  background-color: #666;
}

.select-mark .content-box .content p {
  margin-top: 40px;
  font-family: "PretendardSB", "sans-serif";
}

.select-mark .content-box .content>.row {
  margin-top: 20px;
}

.select-mark .content-box .content>.row .column {
  cursor: pointer;
  justify-content: flex-start;
  padding: 16px 0;
  width: 132px;
  height: 96px;
  border-radius: 4px;
  margin-right: 12px;
  transition: all 0.1s;
  border: 1px solid #cdcdcd;
}

.select-mark .content-box .content>.row .column h5 {
  color: #000000;
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  margin-bottom: 12px;
}

.select-mark .content-box .content>.row .column span {
  color: #666;
  text-align: center;
  font-size: 12px;
  line-height: 150%;
}

.select-mark .content-box .content>.row .column.active h5 {
  color: #319dff;
}

.select-mark .content-box .content>.row .column .mark-type {
  display: flex;
  flex-direction: column;
}

.select-mark .content-box .content>.row .column.active {
  background-color: #F5FAFF;
  border: 2px solid #319dff;
}

.select-mark .content-box .content>.row .column:last-child {
  margin-right: 0;
}

.select-mark .content-box .content>.row .column:hover {
  background-color: #F5FAFF;
  border: 2px solid #319dff;
  transition: all 0.1s;
}

.select-mark .content-box .content .input-wrap {
  margin-top: 15px;
  width: 420px;
}

.select-mark .content-box .content .input-wrap .row {
  margin-bottom: 10px;
}

.select-mark .content-box .content .input-wrap .row span {
  font-family: "PretendardM", "sans-serif";
  font-size: 16px;
}

.select-mark .content-box .content .input-wrap .row .common-text-input {
  width: 352px;
  height: 45px;
  margin: 0px 10px;
  padding: 9px 16px;
  font-size: 16px;
  color: #050F2F;
  text-decoration: none;
}

.select-mark .content-box .content .input-wrap .row .common-text-input::placeholder {
  color: #878787;
}

.select-mark .content-box .content .input-wrap .row>.empty {
  width: 24px;
}

.select-mark .content-box .content .input-wrap .row>img {
  cursor: pointer;
  width: 24px;
}

.select-mark .content-box .content .input-wrap .row>img.disabled {
  opacity: 0;
  cursor: default;
}

.select-mark .content-box .content .input-wrap .row:last-child {
  margin-bottom: 0;
}

.select-mark .content-box .content .input-wrap .transform-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #319dff;
  width: 400px;
  height: 54px;
  border-radius: 4px;
  display: none;
}

.select-mark .content-box .content .input-wrap .transform-btn span {
  color: white;
  margin-right: 8px;
  font-size: 18px;
  line-height: 140%;
}

.select-mark .content-box .content .preview {
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid #D1D1D1;
  min-height: 150px;
  width: 420px;
  padding: 24px 0;
}

.select-mark .content-box .content .preview .placeholder-text {
  color: #878787;
  font-size: 24px !important;
  line-height: 140% !important;
  font-family: "PretendardSB", "sans-serif" !important;
}

.select-mark .content-box .content .preview .filled-text {
  color: #000;
  font-size: 24px !important;
  line-height: 140% !important;
  font-family: "PretendardSB", "sans-serif" !important;
  white-space: nowrap;
}

.select-mark .content-box .content .preview>span {
  font-family: "PretendardM", "sans-serif";
  width: 100%;
  line-height: 24px;
}

.select-mark .content-box .content .preview .column {
  min-width: 100px;
}

.select-mark .content-box .content .preview .column span {
  font-size: 18px;
  line-height: 26px;
  font-family: "PretendardM", "sans-serif";
  text-align: center;
}

.select-mark .content-box .content .upload {
  width: 420px;
}

.select-mark .content-box .content .upload .upload-file {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
}

.select-mark .content-box .content .upload .upload-file .upload-file-btn {
  background-color: #F5FAFF;
  border: 1px solid #319dff;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  padding: 6px 16px;
}

.select-mark .content-box .content .upload .upload-file .upload-file-btn span {
  font-size: 16px;
  line-height: 140%;
  color: #319dff;
  font-family: "PretendardM", "sans-serif";
}

.select-mark .content-box .content .upload .upload-file .upload-file-btn img {
  margin-right: 8px;
}

.select-mark .content-box .content .upload .upload-file .delete-btn {
  font-size: 16px;
  font-family: "PretendardM", "sans-serif";
  color: #787878;
  line-height: 100%;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  padding: 6px 16px;
  height: 34px;
}



.select-mark .content-box .content .upload .upload-file .file-name {
  color: #858585;
  text-decoration: underline;
  font-size: 16px;
  line-height: 23px;
  width: 248px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select-mark .content-box .content .upload .file-upload-zone {
  margin-top: 15px;
  padding: 20px 24px;
  width: 420px;
  height: 240px;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  margin-bottom: 8px;
}

.select-mark .content-box .content .upload .compound-file-upload {
  height: 420px;
  width: 420px;
  margin-top: 15px;
  padding: 20px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

.select-mark .content-box .content .upload .compound-file-upload .file-upload-zone {
  padding: 0;
  margin: 0;
  width: 380px;
  border: none;
  height: 270px;
}


.select-mark .content-box .content .upload .compound-file-upload .file-upload-zone>img {
  width: 270px;
  height: 270px;
}

.select-mark .content-box .content .upload .compound-file-upload .preview {
  width: 380px;
  height: 80px;
  min-height: 80px;
  border: none;
  padding: 0;
}


.select-mark .content-box .content .upload .file-upload-zone .column {
  cursor: pointer;
}

.select-mark .content-box .content .upload .file-upload-zone .column img {
  margin-bottom: 32px;

}

.select-mark .content-box .content .upload .file-upload-zone .column span {
  font-size: 14px;
  line-height: 22px;
  color: #858585;
}

.select-mark .content-box .content .upload .file-upload-zone>img {
  width: 200px;
  height: 200px;
}

.select-product .content {
  padding: 0 48px;
}

.select-product .content .topBar {
  margin: 32px 0 16px;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
}

.select-product .select-product-subTitle {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
  line-height: 140%;
}

.select-product .topBar p {
  color: #666666;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 140%;
}

.select-product .content .selectList-wrap {
  border: 1px solid #d1d1d1;
  height: 48px;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 620px;
}

.select-product .content .selectList-wrap .btnList {
  display: flex;
  gap: 8px;
  width: 516px;
  overflow: hidden;
}


.select-product .content .topBar .selectBox.on .selectVal {
  border: 1px solid #319dff;
}

.select-product .content .topBar .selectBox.on .selectVal>img {
  transform: rotate(180deg);
}

.select-product .content .topBar .selectBox .selectVal {
  width: 120px;
  height: 40px;
  padding: 0;
  padding-left: 16px;
}

.select-product .content .topBar .selectBox .selectVal span {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
}

.select-product .content .topBar .selectBox .selectVal img {
  width: 16px;
  margin-right: 12px;
}

.select-product .content .topBar .selectBox .selectOptGroup {
  width: 120px;
  border: 1px solid #cdcdcd;
}

.select-product .content .topBar .selectBox .selectOptGroup .selectOpt {
  padding-left: 16px;
  border-bottom: 0;
  font-size: 14px;
}

.select-product .content .topBar .selectBox .selectOptGroup .selectOpt:hover {
  background-color: #e3f0ff;
}

.select-product .content .topBar>.row {
  border: 1px solid #cdcdcd;
  border-radius: 4px;
}


.select-product .content .product-wrap,
.select-product .content .select-product-wrap {
  width: 620px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 10px;
}

.select-product .content .product-wrap .product.highlight {
  color: #319DFF;
  background-color: #F5FAFF;
}

.select-product .content .product-wrap .product,
.select-product .content .product-wrap .select-product,
.select-product .content .select-product-wrap .product,
.select-product .content .select-product-wrap .select-product {
  cursor: pointer;
  border: 1px solid #d1d1d1;
  font-size: 13px;
  padding: 0 8px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.select-product .content .product-wrap .product:hover,
.select-product .content .product-wrap .select-product:hover,
.select-product .content .select-product-wrap .product:hover,
.select-product .content .select-product-wrap .select-product:hover {
  background-color: #319dff;
  color: white;
  border: 1px solid #319DFF;
  transition: all 0.3s;
}


.select-product .content .select-product-container {
  margin-bottom: 16px;
}

.select-product .content .select-product-container .class-name {
  font-family: "PretendardSB", "sans-serif";
  font-size: 16px;
}

.select-product .content .select-product-container:last-child {
  margin-bottom: 8px;
}

.select-product .content .product-button-wrap {
  width: 100%;
  margin: 24px 0 16px 0;
}

.select-product .content .product-button-wrap button {
  width: 305px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  gap: 8px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
}

.select-product .content .product-button-wrap button:hover {
  background-color: #F5FAFF;
  border: 2px solid #319dff;
  transition: all 0.1s;
  color: #319DFF;
}



.select-product .content .product-button-wrap button:last-child {
  margin-right: 0;
}

.select-product .modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  background-color: rgba(21, 23, 26, 0.3);
}

.select-product .modal-bg .modal {
  width: 472px;
}

.select-product .modal-bg .modal>.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.select-product .modal-bg .modal>.head h3 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 18px;
  color: #050F2F;
}

.select-product .modal-bg .modal>.head .close-btn {
  cursor: pointer;
}

.select-product .modal-bg .modal hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #cdcdcd;
  margin-bottom: 24px;
  margin-top: 20px;
}

.select-product .modal-bg .modal .input-section span {
  margin-right: 32px;
  font-size: 14px;
  font-family: "PretendardM", "sans-serif";
}

.select-product .modal-bg .modal .input-section .common-text-input {
  width: 310px;
  padding: 9px 16px;
  font-size: 14px;
  height: 40px;
}

.select-product .modal-bg .modal .input-section .common-text-input .placeholder-text {
  color: #CDCDCD;
}

.select-product .modal-bg .modal .input-section.search span {
  margin-right: 8px;
  font-family: "PretendardM", "sans-serif";
}

.select-product .modal-bg .modal .input-section.search .common-text-input {
  width: 263px;
  height: 40px;
  color: #050f2f;
}

.select-product .modal-bg .modal .input-section.search .search-btn {
  width: 80px;
  height: 40px;
  color: #319dff;
  border: 1px solid #319dff;
  border-radius: 4px;
  background: #ffffff;
  margin-left: 8px;
}

.select-product .modal-bg .modal .btn-wrap {
  margin-top: 20px;
  margin-bottom: 0;
}

.select-product .modal-bg .modal .btn-wrap>button {
  width: 100px;
  height: 40px;
  padding: 10px 37px;
  font-size: 14px;
  margin-right: 16px !important;
  border-radius: 0 !important;
  font-family: "PretendardSB", "sans-serif";
}


.select-product .modal-bg .modal .btn-wrap>button:last-child {
  margin-right: 0;
  color: white;
  background-color: #858585;
}

.select-product .modal-bg .input-table-wrap {
  margin: 24px auto 4px auto;
}

.select-product .modal-bg .input-table-wrap .content {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  line-height: 22px;
  color: #15171a;
}

.select-product .modal-bg .input-table-wrap .t-head {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-product .modal-bg .input-table-wrap .t-head .content {
  font-family: "PretendardM", "sans-serif";
  font-size: 14px;
}

.select-product .modal-bg .input-table-wrap .t-head .input-wrap {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  background-color: #F5FAFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.select-product .modal-bg .input-table-wrap .t-head .input-wrap>label {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.select-product .modal-bg .input-table-wrap .t-head .input-wrap>label .checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.select-product .modal-bg .input-table-wrap .t-head .input-wrap>label .checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  /* 체크박스 이미지의 가로 크기 */
  height: 20px;
  /* 체크박스 이미지의 세로 크기 */
  background-image: url("image/Modal/modal-border.svg");
  /* 체크박스 이미지 경로 */
  background-size: cover;
}

.select-product .modal-bg .input-table-wrap .t-head .input-wrap>label>input:checked+.checkbox-custom {
  background-image: url("image/btn_check_box.svg");
  /* 체크된 이미지 경로 */
}

.select-product .modal-bg .input-table-wrap .t-head .content {
  width: 372px;
  height: 32px;
  background-color: #F5FAFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.select-product .modal-bg .input-table-wrap .t-body {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-product .modal-bg .input-table-wrap .t-body .input-wrap {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cdcdcd;
}

.select-product .modal-bg .input-table-wrap .t-body .input-wrap>label {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.select-product .modal-bg .input-table-wrap .t-body .input-wrap>label .checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.select-product .modal-bg .input-table-wrap .t-body .input-wrap>label .checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  /* 체크박스 이미지의 가로 크기 */
  height: 20px;
  /* 체크박스 이미지의 세로 크기 */
  background-image: url("image/Modal/modal-border.svg");
  /* 체크박스 이미지 경로 */
  background-size: cover;
}

.select-product .modal-bg .input-table-wrap .t-body .input-wrap>label>input:checked+.checkbox-custom {
  background-image: url("image/btn_check_box.svg");
  /* 체크된 이미지 경로 */
}

.select-product .modal-bg .input-table-wrap .t-body .content {
  width: 372px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cdcdcd;
}

.select-product .modal-bg .no-result-message{
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  line-height: 22px;
  color: #15171a;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding: 7px 0;
  border: 1px solid #cdcdcd;
}
.temp-modal {
  width: 652px;
}

/* .temp-modal.import-modal {
  height: 530px;
} */

.temp-modal.import-modal>.pagination-box {
  margin-top: 16px;
  margin-bottom: 16px;
}

.temp-modal>.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.temp-modal>.head p {
  font-size: 18px;
  color: #050f2f;
  font-family: "PretendardM", "sans-serif";
}

.temp-modal .txt-wrap {
  padding: 24px 0;
  border-top: 1px solid #CDCDCD;
  border-bottom: 1px solid #CDCDCD;
  font-size: 16px;
  line-height: 32px;
  color: #050F2F;
  font-weight: 400;
}

.temp-modal .temp-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
}

.temp-modal .temp-btn-wrap.import-wrap {
  margin-top: 0;
}

.temp-modal .temp-btn-wrap button {
  width: 97px;
  height: 42px;
  color: #fff;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  line-height: 20px;
}

.temp-modal .temp-btn-wrap button.check-btn {
  background-color: #319dff;
  margin-right: 12px;
}

.temp-modal .temp-btn-wrap button.cancel-btn {
  background: #cdcdcd;
}

.temp-modal .temp-btn-wrap button.select-btn {
  background-color: #319dff;
  border-radius: 0;
}

.temp-modal>hr {
  border-top: 1px solid #e8e8e8;
  margin: 0 0 16px 0;
}


.temp-modal>.temp-list li {
  padding: 13px 0;
  text-align: center;
  margin-bottom: 4px;
  background-color: #f5faff;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 22px;
  cursor: pointer;
  color: #050f2f;
}

.temp-modal>.temp-list li.active {
  color: #fff;
  background-color: #319dff;
  font-family: "PretendardSB", "sans-serif" !important;
}

.temp-modal>.temp-list li.temp-list-title {
  background-color: #d6ebff;
  color: #15171a;
  font-family: "PretendardM", "sans-serif";
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  padding: 8px 0;
}

.template-modal {
  width: 472px;
}

.template-modal>.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
}

.template-modal>.head p {
  font-size: 18px;
  color: #050F2F;
  font-family: "PretendardSB", "sans-serif";
}
.template-modal .txt-wrap {
  display: flex;
  border-top: 1px solid #e8e8e8;
  padding: 20px 0;
  align-items: center;
}
.template-modal .txt-wrap p{
  font-size: 14px;
  color: #050F2F;
  font-family: "PretendardM", "sans-serif";
}

.template-modal .txt-wrap .template-input {
  border: 1px solid #cdcdcd;
  width: 310px;
  height: 40px;
  margin-left: 32px;
  border-radius: 4px;
  padding: 11.5px 16px;
}

.template-modal .txt-wrap .template-input::placeholder {
  font-size: 14px;
  color: #878787;
  line-height: 22px;
  font-family: "PretendardR", "sans-serif";
}

.template-modal .temp-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.template-modal .temp-btn-wrap.import-wrap {
  margin-top: 0;
}

.template-modal .temp-btn-wrap button {
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
}

.template-modal .temp-btn-wrap button.check-btn {
  background-color: #319dff;
  margin-right: 16px;
}

.template-modal .temp-btn-wrap button.cancel-btn {
  background: #858585;
}

.template-modal .temp-btn-wrap button.select-btn {
  background-color: #319dff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.template-modal>hr {
  border-top: 1px solid #e8e8e8;
  margin: 0 0 16px 0;
}

.template-modal>.temp-list {
  height: 300px;
  overflow-y: auto;
}

.template-modal>.temp-list li {
  padding: 13px 0;
  text-align: center;
  margin-bottom: 4px;
  background-color: #fff9e3;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 22px;
  cursor: pointer;
}

.template-modal>.temp-list li.temp-list-title {
  background-color: #fbe395;
  color: #15171a;
  font-family: "PretendardM", "sans-serif";
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  padding: 8px 0;
}

.select-classification .content-box .content {
  width: 100%;
}

.select-classification .content-box .content>p {
  padding-left: 90px;
  font-size: 16px;
  line-height: 24px;
  font-family: "PretendardSB", "sans-serif";
  margin-top: 40px;
}



.select-classification .content-box .select-wrap {
  margin: 37px 0;
}

.select-classification .content-box .select-wrap .select-box {
  cursor: pointer;
  user-select: none;
  width: 302px;
  height: 80px;
  position: relative;
  padding: 19px 0;
  border-radius: 4px;
  border: 1px solid #D1D1D1;
}

.select-classification .content-box .select-wrap .select-box h5 {
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  line-height: 140%;
}

.select-classification .content-box .select-wrap .select-box.active h5 {
  color: #319dff;
}


.select-classification .content-box .select-wrap .select-box span {
  font-size: 12px;
  margin-top: 8px;
  color: #666666;
  font-family: "PretendardR", "sans-serif";
  line-height: 130%;
  text-align: center;
}

.select-classification .content-box .select-wrap .select-box.active {
  border: 2px solid #319dff;
  background-color: #F5FAFF;
}

.select-classification .content-box .select-wrap .select-box.active>img {
  width: 20px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.select-classification .content-box .select-wrap .select-box:hover {
  background-color: #F5FAFF;
  border: 2px solid #319dff;
  transition: all 0.1s;
}

.select-classification .content-box .select-wrap .select-box:first-child {
  margin-right: 16px;
}

.select-classification .content-box .direct-input-wrap {
  margin: 24px 0;
  width: 100%;
  margin: 24px auto 0 auto;
}

.select-classification .content-box .direct-input-wrap input {
  padding: 11.5px 21px;
  width: 530px;
  font-size: 16px;
  font-family: "PretendardM", "sans-serif";
  height: 45px;
  color: #050F2F;
  text-decoration: none;
}

.select-classification .content-box .direct-input-wrap ::placeholder {
  font-family: "PretendardR", "sans-serif";
  color: #878787;
}

.select-classification .content-box .direct-input-wrap button {
  padding: 14px 27.5px;
  border-radius: 4px;
  color: #319dff;
  font-size: 14px;
  margin-left: 10px;
  font-family: "PretendardM", "sans-serif";
  border: 1px solid #319dff;
  height: 45px;
}

.select-classification .content-box .none-list {
  width: 620px;
  height: 66px;
  padding: 23px 0;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  color: #878787;
  font-size: 16px;
  line-height: 140%;
}


.select-classification .content-box .classification {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.select-classification .content-box .classification .classification-wrap .imgBox {
  width: 80px;
  background-color: cadetblue;
  height: 50px;
  margin-right: 10px;
  border-radius: 4px;
}

.select-classification .content-box .classification .classification-wrap {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  margin-bottom: 8px;
  transition: all 0.2s;
  cursor: pointer;
  width: 620px;
}

.select-classification .content-box .classification .classification-wrap.on .recommend {
  font-family: "PretendardSB", "sans-serif";
  background-color: #319DFF;
  color: white;
  font-size: 12px;
  white-space: nowrap;
  padding: 4px 10px;
  border-radius: 40px;
  line-height: 140%;
}

.select-classification .content-box .classification .classification-wrap>img {
  width: 80px;
  height: 50px;
  margin-right: 8px;
  border-radius: 4px;
}

.select-classification .content-box .classification .classification-wrap>.column {
  width: 500px;
  align-items: flex-start;
  flex-wrap: wrap;
}

.select-classification .content-box .classification .classification-wrap>.column span {
  transition: all 0.2s;
  font-size: 13px;
  line-height: 22px;
  font-family: "PretendardM", "sans-serif";
  color: #050F2F;
}

.select-classification .content-box .classification .classification-wrap>.column span:first-child {
  color: #666666;
  font-family: "PretendardR", "sans-serif";
  font-size: 12px;
}

.select-classification .content-box .classification .classification-wrap label {
  transition: all 0.2s;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.select-classification .content-box .classification .classification-wrap label .column {
  width: 24px;
  height: 24px;
}

.select-classification .content-box .classification .classification-wrap label .column img {
  display: none;
}

.select-classification .content-box .classification .classification-wrap:hover {
  transition: all 0.2s;
  border-color: #319dff;
}

.select-classification .content-box .classification .classification-wrap:hover>.column span {
  color: #319dff;
  transition: all 0.2s;
}

.select-classification .content-box .classification .classification-wrap:hover label {
  transition: all 0.2s;
  border-color: #319dff;
}

.select-classification .content-box .classification .classification-wrap.active {
  background-color: #F5FAFF;
  border: 2px solid #319dff;
}

.select-classification .content-box .classification .classification-wrap.active>.column span {
  color: #319dff;
}


.select-classification .content-box .classification .classification-wrap.active>.column span:first-child {
  color: #666;
}

.select-classification .content-box .classification .classification-wrap.active label {
  background-color: #319dff;
  border-color: #319dff;
}

.select-classification .content-box .classification .classification-wrap.active label img {
  display: block;
}

.select-classification .content-box .box-wrap {
  flex-wrap: wrap;
  margin: 32px auto 40px;
}

.select-classification .content-box .box-wrap .box {
  cursor: pointer;
  font-size: 14px;
  width: 201px;
  height: 40px;
  margin: 0 8px 8px 0;
  border-radius: 4px;
  border: 1px solid #D1D1D1;
  color: #050f2f;
}

.select-classification .content-box .box-wrap .box:nth-child(3n) {
  margin-right: 0;
}

.select-classification .content-box .box-wrap .box.active {
  background-color: #F5FAFF;
  border: 2px solid #319dff;
  color: #319dff;
}


.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select .select-value {
  width: 106px;
  height: 30px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ffc200;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.custom-select .select-value>img {
  margin-left: 9px;
}

.custom-select .select-value:hover {
  background-color: #fff9e3;
  color: #ff8e00;
}

.custom-select .select-value.on {
  background-color: #fff9e3;
}

.custom-select .select-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  transform: translateY(1px);
  z-index: 10;
  display: none;
  /* 초기에는 보이지 않도록 함 */
}

.custom-select .select-list.on {
  display: block;
}

.custom-select .select-list li {
  height: 34px;
  cursor: pointer;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  line-height: 22px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.custom-select .select-list li:first-child {
  position: relative;
}

.custom-select .select-list li:first-child::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 6px;
  width: 94px;
  height: 1px;
  background-color: #e8e8e8;
}

.custom-select .select-list li:hover {
  background-color: #fff9e3;
}

/* .loader {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  background: url("image/Mypage/pick_loading.gif") no-repeat;
  background-size: contain;
} */

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 15;
  transform: translate(-50%, -60%);
  width: 130px;
  height: 130px;
  background: linear-gradient(90deg,
      #3dcfdd 0%,
      #a8c0ef 99.96%,
      #5a88e5 99.97%);
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  display: none;
}

.loader>div {
  width: 58px;
  height: 58px;
  margin: 36px;
  background: url("../../image/Mypage/serviceloadingImg.png") no-repeat center/cover;
  animation: 3s distress infinite alternate;
}

@keyframes distress {
  0% {
    background-position: 0% center;
  }

  100% {
    background-position: 100% center;
  }
}

.loader-wrap {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -65%);
  width: 230px;
  height: 230px;
  padding: 24px 0 16px 0;
}

.loader-wrap.application {
  transform: translate(-50%, 5%);
}

.loader-wrap p {
  font-size: 14px;
  font-family: "NotoSansR";
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.site-title {
  position: absolute;
  top: 40px;
  left: 200px;
}

.site-title .self-img-wrap {
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 16px;
}

.site-title .self-img-wrap .arrowImg {
  width: 8px;
}

.site-title .self-img-wrap span {
  color: #666666;
  white-space: nowrap;
}


.self-appliction-step {
  margin: 124px auto 80px auto;
}

.self-application-wrap {
  position: relative;
  width: 100vw;
  background-color: #f6f6f6;
}




.select-classification .content-box .selectList-wrapper {
  margin: 0 auto;
}


.select-classification .content-box .selectList-wrapper h5 {
  color: #050F2F;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
  margin-bottom: 10px;
  line-height: 140%;
  width: 620px;
  margin: 32px auto 24px auto;
}

.select-classification .content-box .selectList-wrapper span {
  font-family: "PretendardSB", "sans-serif";
  color: #666;
  margin-left: 8px;
  font-size: 12px;
}

.select-classification .content-box .selectList-category {
  margin: 0 auto;
  width: 620px;
  align-items: center;
}

.select-classification .content-box .selectList-category p {
  color: #666666;
  font-family: "PretendardSB", "sans-serif";
  font-size: 12px;
  margin-right: 12px;
  white-space: nowrap;
}


.select-classification .content-box .selectList-category .subTitle {
  margin-top: 12px !important;
}


.select-classification .content-box .selectList-wrap {
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 8px;
  cursor: pointer;
}

.select-classification .content-box .selectList-wrap .btnList {
  white-space: nowrap;
  overflow: hidden;
  width: 516px;
  text-overflow: ellipsis;
  display: flex;
  gap: 8px;
}


.select-classification .content-box .selectList-wrap .btnList button,
.select-product .content .selectList-wrap .btn-item {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  color: #585858;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  background-color: #D9D9D9;
  line-height: 140%;
}

.select-product .content .selectList-wrap .btn-item.selected {
  background-color: #F5FAFF;
  color: #319dff;
  border: 1px solid #d1d1d1;
  cursor: default;
}

.select-product .content .selectList-wrap .btn-item {
  cursor: default;
}

.select-product .content .selectList-wrap .btnList {
  cursor: default;
}

.select-product .content .selectList-wrap .btn-item.selected strong {
  color: #319dff;
}

.select-classification .content-box .selectList-wrap .btnList button strong,
.select-product .content .selectList-wrap .btn-item strong {
  font-size: 14px !important;
  font-family: "PretendardSB", "sans-serif";
  margin-right: 4px;
  color: #585858;
}

.select-classification .content-box .selectList-wrap .ellipsis,
.select-product .content .selectList-wrap .ellipsis {
  color: white;
  margin: 4px 8px;
}

.select-classification .content-box .selectList-wrap .ellipsis.show,
.select-product .content .selectList-wrap .ellipsis.show {
  color: #050F2F;
}

.select-classification .content-box .selectList-wrap article,
.select-product .content .selectList-wrap article {
  background-color: #319DFF;
  border-radius: 4px;
  width: 29px;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
  color: white;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.select-classification .content-box .selectList-wrap .arrow,
.select-product .content .selectList-wrap img {
  width: 16px;
  margin-left: 10px;
}

.select-classification .content-box .selectList-category .optionItem,
.select-product .content .optionItem-wrap {
  padding-bottom: 12px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #d1d1d1;
  border-top: none;
  transition: all 0.3s ease;
  width: 620px;
}

.select-classification .content-box .selectList-category .optionItem .list-header p {
  margin-top: 0 !important;
}


.select-product .content .optionItem-wrap {
  padding-top: 12px;
}

.select-classification .content-box .selectList-category .optionItem li,
.select-product .content .optionItem li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

.select-classification .content-box .selectList-category .optionItem li img {
  width: 16px;
}

.select-classification .content-box .selectList-wrap .arrow.rotate .select-product .content .selectList-wrap .arrow.rotate,
.select-product .content .selectList-wrap .arrow.rotate,
.select-product .select-total-product .kind-group .kind-title .arrow.rotate,
.select-classification .content-box .selectList-wrap .arrow.rotate {
  transform: rotate(180deg);
}

.select-classification .content-box .selectList-category .optionItem ul,
.select-product .content .optionItem {
  max-height: 180px;
  overflow: auto;
}



.select-classification .content-box .selectList-category .optionItem li:hover,
.select-product .content .optionItem li:hover {
  background-color: #F5FAFF;
}

.select-product .content .optionItem li:hover p,
.select-product .content .optionItem li:hover strong {
  color: #319dff;
}


.select-classification .content-box .selectList-category .optionItem li:hover img {
  filter: invert(47%) sepia(96%) saturate(1595%) hue-rotate(191deg) brightness(96%) contrast(101%);
}


.select-classification .content-box .selectList-category .optionItem li p strong,
.select-product .content .optionItem li p strong {
  font-size: 14px;
  color: #585858;
  font-family: "PretendardSB", "sans-serif";
  margin-right: 4px;
}

.select-classification .content-box .selectList-category .optionItem li p,
.select-product .content .optionItem li p {
  font-size: 14px;
  color: #585858;
  padding: 8px 0;
  line-height: 140%;
  font-family: "PretendardR", "sans-serif";
  margin: 0;
  width: 576px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-product .content .optionItem li span {
  white-space: nowrap;
  font-family: "PretendardSB", "sans-serif";
  font-size: 14px;
  display: none;
}

.select-product .content .optionItem li:hover span {
  display: block;
  color: #319DFF;
}

.select-classification .content-box .selectList-category .optionItem .list-header {
  display: flex;
  justify-content: space-between;
  padding: 14px 12px;
  align-items: center;
}

.select-classification .content-box .selectList-category .optionItem .list-header p {
  color: #050F2F;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  margin: 0;
}

.select-classification .content-box .selectList-category .optionItem .list-header span {
  color: #319DFF;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  margin-left: 4px;
}

.select-classification .content-box .selectList-category .optionItem .list-header button {
  font-family: "PretendardR", "sans-serif";
  font-size: 14px;
  border: 1px solid #050f2f;
  width: 69px;
  height: 28px;
  line-height: 140%;
}

.select-classification .content-box .selectList-category .optionItem .list-header button:hover {
  background-color: #319DFF;
  border: 1px solid #319DFF;
  color: #fff;
}

.select-product .content .select-product-header {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 4px;
}

.select-product .content .product-wrap .header-title {
  background-color: #f5faff;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  line-height: 140%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-product .content .product-wrap .header-title div p {
  color: #319DFF;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}

.select-product .content .product-wrap .header-title strong {
  color: #319DFF;
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
  margin-right: 4px;
}

.select-product .content .product-wrap .product-list {
  height: 160px;
  width: 100%;
  border-radius: 0 0 0 4px;
  overflow: auto;
  padding: 16px;
}

.select-product .content .product-wrap .product-list-empty {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.select-product .content .product-wrap .product-list-empty p {
  font-family: "PretendardR", "sans-serif";
  color: #666666;
  font-size: 14px;
}

.select-product .content .product-button-wrap .empty-btn {
  display: flex;
}

.select-product .content .product-button-wrap .empty-btn button {
  color: #D1D1D1;
}

.select-product .content .product-button-wrap .empty-btn button img {
  filter: brightness(0) saturate(100%) invert(91%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(88%) contrast(90%);
}

.select-product .select-total-product {
  width: 620px;
}

.select-product .select-total-product .topBar {
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 16px;
}

.select-product .select-total-product .kind-group {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.select-product .select-total-product .kind-group .kind-title {
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  border-radius: 4px;
  align-items: center;
}

.select-product .select-total-product .kind-group .kind-title p {
  font-size: 14px;
  font-family: "PretendardSB", "sans-serif";
}


.select-product .select-total-product .kind-group .kind-title.open {
  border-radius: 4px 4px 0 0;
  background-color: #F5FAFF;
}

.select-product .select-total-product .kind-group .kind-title.open p {
  color: #319dff;
}

.select-product .select-total-product .kind-group .kind-title img {
  width: 16px;
}

.select-product .select-total-product .kind-group .kind-itemList {
  display: flex;
  padding: 16px;
}

.select-product .select-total-product .kind-group .kind-itemList p {
  font-size: 13px;
  color: #000;
  line-height: 140%;
}

.select-product .select-total-product .total-wrap {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.select-product .select-total-product .topBar p {
  font-size: 14px;
}

.select-product .select-total-product .topBar p span {
  font-size: 14px;
  color: #319DFF;
  font-family: "PretendardSB", "sans-serif";
}

.select-product .content .header-btn {
  background-color: #fff;
  color: #666;
  border: 1px solid #666;
  font-family: "PretendardM", "sans-serif";
  font-size: 14px;
  width: 66px;
  height: 25px;
}


.select-product .content .question-icon {
  cursor: pointer;
}

.select-product .content .tooltip-container {
  position: relative;
  display: inline-block;
}

.select-product .content .tooltip-text {
  visibility: hidden;
  background-color: #000000;
  color: #fff;
  padding: 4px 8px;
  position: absolute;
  z-index: 1;
  top: 125%;
  /* 툴팁이 아이콘 위에 위치 */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  /* 툴팁 글자 크기 */
  white-space: nowrap;
  line-height: 140%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28);
}

/* 화살표 추가 */
.select-product .content .tooltip-text::after {
  content: '';
  position: absolute;
  top: -12px;
  /* 툴팁 상단에 화살표 위치 */
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px;
  /* 삼각형 크기 */
  border-style: solid;
  border-color: transparent transparent #000000 transparent;
  /* 화살표 색상 */
}


.select-product .content .tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}


.select-product .content .product-button-wrap .empty-btn button:hover {
  background-color: #fff;
  color: #D1D1D1;
  border: 1px solid #d1d1d1;
  cursor: not-allowed;
}

.border-white-box {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 16px;
}

.border-white-box-title {
  text-align: start;
  color: #000;
  font-size: 16px;
  font-family: "PretendardSB", "sans-serif";
  word-wrap: break-word;
}

.double-border-white-box {
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 24px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  justify-items: start;
}

.double-border-white-box .box-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.service-direct-type-container {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  overflow-y: auto;
}

.service-direct-type-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.service-direct-list-item {
  width: 100%;
  height: 40px;

  border: 1px solid #e4e4e4;
  border-radius: 4px;

  text-align: start;
  align-content: center;

  color: #15171a;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  margin-bottom: 8px;
}

.service-direct-list-item .direct-rye-title {
  color: #15171a;
  font-size: 14px;
  font-weight: 800;
  margin-right: 10px;
}

.service-direct-list-item .direct-rye-content {
  color: #15171a;
  font-size: 14px;
  font-weight: 400;
}

.activable-btn-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.activable-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;

  padding: 12px 16px;
}

.activable-btn .activable-btn-title {
  color: #15171a;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}

.professional-data-container {
  margin-top: 8px;
}

.professional-data-item {
  display: flex;
  margin-bottom: 8px;
  margin-left: 16px;
}

.professional-data-item-text {
  font-size: 14px;
  align-content: center;
  margin-left: 4px;
}

.errorPage {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #C8E3FF, #F3F6F9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  padding: 1.25rem;
  box-sizing: border-box;
  text-align: center;
}

.errorPage img {
  width: 12.5rem;
  height: 5rem;
}

.errorPage h1 {
  font-family: "PretendardB", sans-serif;
  color: #319dff;
  font-size: 10rem;
  line-height: 1.5;
}

.errorPage h1 strong {
  color: #050f2f;
  font-size: 10rem;
  font-family: "PretendardSB", sans-serif;
}

.errorPage p {
  color: #333;
  font-size: 2.25rem;
  font-family: "PretendardR", sans-serif;
  line-height: 1.5;
}

.errorPage button {
  color: #333;
  border: 0.25rem solid #333;
  font-family: "PretendardM", sans-serif;
  font-size: 2rem;
  width: 16.875rem;
  height: 6.25rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.errorPage button:hover {
  background-color: #333;
  color: #fff;
}

@media (max-width: 48rem) {
  .errorPage {
    gap: 2.5rem;
  }

  .errorPage img {
    width: 10rem;
    height: 4rem;
  }

  .errorPage h1 {
    font-size: 7.5rem;
  }

  .errorPage h1 strong {
    font-size: 7.5rem;

    .errorPage p {
      font-size: 1.75rem;
    }

    .errorPage button {
      font-size: 1.75rem;
      width: 13.75rem;
      height: 5rem;
    }
  }

  @media (max-width: 30rem) {
    .errorPage {
      gap: 1.875rem;
      padding: 0.625rem;
    }

    .errorPage img {
      width: 7.5rem;
      height: 3rem;
    }

    .errorPage h1 {
      font-size: 5rem;
    }

    .errorPage h1 strong {
      font-size: 5rem;
    }

    .errorPage p {
      font-size: 1.5rem;
    }

    .errorPage button {
      font-size: 1.5rem;
      width: 11.25rem;
      height: 3.75rem;
    }
  }
}


/* 팝업 */


.modal-bg .event-modal {
  background-color: #EAEAEA;
  max-width: 100vw;
}

.modal-bg .event-modal .button-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-bg .event-modal div img {
  width: 20px;
}

.modal-bg .event-modal div button {
  font-size: 14px;
}