:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}


.marquee02 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: var(--gap);
}

.marquee__group02 {
  /* margin-bottom: 16px; */
  /* margin-bottom: 7px; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* gap: var(--gap); */
  gap: 27px;
  /* height: 100%; */
  -webkit-animation: scroll-y02 var(--duration) linear infinite;
          animation: scroll-y02 var(--duration) linear infinite;
}

.marquee--reverse02 .marquee__group02 {
  animation-direction: reverse;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
}

@-webkit-keyframes scroll-y02 {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

@keyframes scroll-y02 {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

/* Parent wrapper */
.progress-content02 {
  display: flex;
  /* flex-direction: column; */
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

@media (max-width: 1024px) {
  .marquee__group02 {
    /* height: 100% !important; */
    /* gap: 7px; */
  }
}

/* @media (max-width: 580px) {
  .marquee__group02 {
    height: 62% !important;
  }
} */
