.SelfProcedureAll-wrap{
  width: 100%;
  background: #F6F6F6;
}
.SelfProcedureAll-wrap .SelfProcedureAll-contentwrap{
  width: 1320px;
  margin: 0px auto;
}
.SelfProcedureAll-wrap .SelfProcedureAll-contentwrap .selfAll-contentBox{
  width: 950px;
}
.SelfProcedureAll-wrap .SelfProcedureAll-contentwrap .selfAll-botBox{
  background: #fff;
  border-radius: 16px;
  padding: 43px 75px;
}