/* 공통 */
.sub_wrap_all .subsection{
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sub_wrap_all .subsection .subarticle{
  width: 1440px;
  margin: 0px auto;
}

/* 서브 테이블 */
/* .sub_wrap_all .subsection .pricetable-wrap{

} */
.sub_wrap_all .subsection .pricetable-wrap .title_h2{
  color: #050f2f;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
}
.sub_wrap_all .subsection .pricetable-wrap .title_p{
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  margin: 20px 0 50px;
  line-height: 140%;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title >th{
  padding: 32px 16px 24px;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title th:nth-child(2) h3{
  color: #050E2F;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title th:nth-child(3) h3{
  color: #FF7900;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title th:nth-child(4) h3{
  color: #0ECDC1;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title h3:last-child{
  color: #00BF7A;
}
.sub_wrap_all .subsection .price-info-title > th >p:not(.price-font){
  padding: 16px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #E1E1E1;
  line-height: 140%;
  color: #858585;
}
.sub_wrap_all .subsection  .price-info-title > th > .price-info-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.sub_wrap_all .subsection .price-font{
  border-top: none;
  padding-top: 0;
  color: #555555;
}
.sub_wrap_all .subsection .price-day{
  color: #686868;
}
.sub_wrap_all .subsection .price-radio-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub_wrap_all .subsection .price-radio-wrap div{
  display: flex;
  gap: 10px;
}
.sub_wrap_all .subsection .price-radio-wrap input[type="radio"]{
  margin: 0px;
}
.sub_wrap_all .subsection .price-radio-wrap label{
  color: #878787;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}
/* 라디오버튼 커스텀 */
.sub_wrap_all .subsection .price-radio-wrap .P_radio01{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc; 
  border-radius: 50%;
  outline: none; 
  cursor: pointer;
}
.sub_wrap_all .subsection .price-radio-wrap .P_radio01:checked {
  background-color: #0ECDC1; 
  border: 3px solid white; 
  box-shadow: 0 0 0 1.6px #E1E1E1; 
}
.sub_wrap_all .subsection .price-radio-wrap .P_radio02{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc; 
  border-radius: 50%;
  outline: none; 
  cursor: pointer;
}
.sub_wrap_all .subsection .price-radio-wrap .P_radio02:checked {
  background-color: #00BF7A; 
  border: 3px solid white; 
  box-shadow: 0 0 0 1.6px #E1E1E1; 
}
.sub_wrap_all .subsection .none-height{
  height: 15px;
}
.subsection .subprice_table tr > td:first-child{
  padding: 12px 10px;
}
/* 서브 2번섹션 */
.sub_wrap_all .newselfSection02{
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 120px 0 120px 0;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll{
  display: flex;
  align-items: center;
  width: 1440px;
  height: 508px;
  background: linear-gradient(90deg, rgba(255, 232, 177, 0.3) 0%, #F8BD34 100%);
  margin: 0px auto;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  padding-right: 125px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 150%;
}
/* swiper custome*/
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper {
  width: 100%;
  height: 468px;
  margin: 0;
  overflow: visible;
  position: relative;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
  letter-spacing: -0.002em;
  font-weight: 700;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #666666;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .li-mt{
  margin-left: 40px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide {
  width: 370px;
  height: 140px !important;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  opacity: 0.4 !important; 
  background-color: #FFFFFF;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  position: absolute;
  transform: translate3d(0px, 183px, 0px) !important;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide img {
  display: none;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active img {
  display: block;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active h3 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.002em;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active p {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active .li_mt {
  margin-left: 50px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box-ul .li_mt {
  margin-left: 40px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
  position: absolute;
  top: -70px;
  right: -40px;
  width: 140px;
  height: 140px;
  z-index: 5;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active {
  z-index: 1;
  background-color: #FFFFFF;
  opacity: 1 !important;
  width: 420px;
  height: 170px !important; 
  position: relative;
  transform: translate3d(110px, 169px, 0px) !important;
  transition: opacity 0.5s ease-in-out !important;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-active > .li-mt {
  margin-left: 50px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next {
  transform: translate3d(0px, 20px, 0px) !important;
  
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-prev {
  transform: translate3d(0px, 20px, 0px) !important;
  transition-duration: 0ms !important; 
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next + .swiper-slide  {
  transform: translate3d(0px, 183px, 0px) !important; 
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-next + .swiper-slide + .swiper-slide {
  transform: translate3d(0px, 348px, 0px) !important; 
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .swiper-slide-prev + .swiper-slide  + .swiper-slide {
  transform: translate3d(0px, 348px, 0px)!important; 
}
/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .newselfSection02{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
/* .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 17px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li{
  width: 370px;
  height: 140px;
  background: #fff;
  border-radius: 8px;
  padding: 22px 0 0 40px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:not(.li-last){
  opacity: 0.4;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #050f2f;
  line-height: 140%;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:last-child{
  width: 400px;
  height: 170px;
  position: absolute;
  top: 50%;
  left: 40%;
  background: #fff !important;
  transform: translate(0, -50%);
  padding: 30px 0 0 50px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last h3{
  font-size: 24px;
  margin-bottom: 9px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p{
  position: relative;
  font-size: 20px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p img{
  position: absolute;
  right: -22px;
  bottom: 60px;
} */


/* 서브 4번 컨텐츠 3개짜리 */
.sub_wrap_all .newselfSection04{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0 80px 0;
}

/* .sub_wrap_all .newselfSection04 .selfcontent-articleAll{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto 160px;
  gap: 0px;
} */
.sub_wrap_all .selfcontent-articleAll .selfcontent-article03{
  margin-bottom: 80px;
}
.sub_wrap_all .newselfSection04 .selfcontent-articleAll .left-box{
  /* width: 50%; */
  width: 760px;
}
.sub_wrap_all .newselfSection04 .selfcontent-article01 .left-box01{
  background: #FFF3D7;
  border-radius: 8px;
  height: 480px;
  padding: 75px 27px;
  display: flex;
  align-items: center;
}
.sub_wrap_all .newselfSection04 .left-box02{
  width: 50%;
}

.sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box{
  width: 41%;
}
.sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box02{
  width: 55%;
}
.sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box02 img{
  width: 100%;
}
.sub_wrap_all .newselfSection04 .selfcontent-article02 .right-box01{
  /* background: #efc35b; */
  border-radius: 8px;
  /* height: 500px; */
}
.sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01{
  background: #f4e8c8;
  border-radius: 8px;
  /* height: 500px; */
}
.sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01 div{
  height: 100%;
  color: #fff;
}






.sub_wrap_all .newselfSection04 .selftextbox-All strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .newselfSection04 .selftextbox-All h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
  word-break: keep-all;
}
.sub_wrap_all .newselfSection04 .selftextbox-All p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 140%;
}





/* 탭목록 */
.sub_wrap_all .Newself-wrap{
  background: #FFF7E4;
  padding: 120px 0;
}
.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox span{
  margin-bottom: 70px;
  word-break: keep-all;
}
.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
  margin: 0px;
}
.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 620px;
  height: 107px;
  border-radius: 8px;
  margin-bottom: 13px;
}
.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
  margin: 0px;
  margin-top: 0px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:first-child img{
  width: 55px;
  height: 42px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img{
  width: 60px;
  height: 43px;
  margin-right: -3px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img{
  width: 47px;
  height: 49px;
  margin-left: 10px;
  margin-right: 1px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img{
  width: 54px;
  height: 42px;
  margin-left: 3px;
  margin-right: 2px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img{
  width: 47px;
  height: 52px;
  margin-left: 11px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .sub-title-point{
  color: #319dff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox h2{
  color: #050F2F;
  font-size: 48px;
  font-weight: bold;
  line-height: 140%;
  margin: 12px 0px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox span{
  font-size: 26px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
  display: flex;
  align-items: center;
  gap: 90px;
  line-height: 140%;
  margin-bottom: 8px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small{
  color: #858585;
  font-size: 11px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
  line-height: 140%;
}

/* 서브테이블 */
.sub_wrap_all .subsection .pricetable-wrap .sub-title-point{
  color: #319dff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .subsection .pricetable-wrap h2{
  color: #050F2F;
  font-size: 42px;
  font-weight: bold;
  line-height: 140%;
  margin: 12px 0px;
}
.sub_wrap_all .subsection .pricetable-wrap span{
  font-size: 14px;
}
.sub_wrap_all .selfTrade-contentBox05{
  padding: 120px 0;
}
.sub_wrap_all .subsection .pricetable-wrap .table-text-last{
  color: #050F2F;
}



/* 서브 마지막 */
.sub-final-wrap {
  width: 100%;
  /* background: linear-gradient(180deg, #FFFFFF 50%, #FCEBC3 100%); */
}
.sub-final-wrap .final-title-wrap {
  margin-bottom: 0px;
}
.sub-final-wrap .final-title-wrap,
.sub-final-wrap .final-title-wrap h6,
.sub-final-wrap .final-title-wrap h5,
.sub-final-wrap .final-title-wrap h5 span {
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}
.sub-final-wrap .final-title-wrap h6 {
  line-height: 46px;
  color: #050F2F;
  margin-bottom: 4px;
}
.sub-final-wrap .final-title-wrap h5 {
  margin-bottom: 40px;
  text-align: center;
  line-height: 150%;
  font-size: 52px;
}
.sub-final-wrap .final-title-wrap h5 span {
  line-height: 70px;
}
.sub-final-wrap .final-title-wrap .main-final-move{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 365px;
  height: 74px;
  border-radius: 100px;
  margin: 0px auto;
  background: #319dff;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 8px 18px rgba(49, 157, 255, 0.18);
  border-radius: 90px;
  gap: 18px;
}
.sub-final-wrap .final-title-wrap .box-wrap{
  width: 2500px;
}
.sub-final-wrap .box-wrap > .row > .column * {
  color: white;
}
.sub-final-wrap .box-wrap > .row > .column h4 {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  font-size: 26px;
  line-height: 24px;
  margin-bottom: 29px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.sub-final-wrap .box-wrap > .row > .column > span {
  line-height: 24px;
  margin-bottom: 16px;
}
.sub-final-wrap .box-wrap > .row > .column .direct-btn {
  cursor: pointer;
}
.sub-final-wrap .box-wrap > .row > .column .direct-btn span {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  margin-right: 12px;
}
.sub-final-wrap .box-wrap > .row > .column .direct-btn img {
  width: 8px;
  height: 12px;
}
.sub-final-wrap .box-wrap > .row > .column.self-box {
  background-color: rgba(255, 194, 0, 0.75);
  margin-right: 20px;
}
.sub-final-wrap .box-wrap > .row > .column.agencies-box {
  background-color: rgba(255, 142, 0, 0.75);
}
.sub-final-wrap .box-wrap > .row > .column > img {
  position: absolute;
  right: 50px;
  bottom: 26px;
  z-index: 1;
  width: 80px;
  height: 124px;
}
.sub-final-wrap .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  width: 1280px;
  height: 350px;
  background-image: url("image/guideBg02.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius:8px;
}
.sub-final-wrap .box-wrap .guide-box .guide-content{
  width:502px;
  height: 150px;
  position: absolute;
  right: 265px;
  bottom: 99px;
}
.sub-final-wrap .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.sub-final-wrap .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 46px;
  padding: 12px 24px;
  border: 1px solid #050F2F;
  border-radius: 2px;
  cursor: pointer;
}
.sub-final-wrap .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  color: #333333;
}
.sub-final-wrap .box-wrap .service-box{
  display: flex;
  flex-direction:row;
  justify-content: center;
  width: 100%;
  height: 190px;
  padding: 83px 216px 80px 104px;
  border-radius: 0px;
  background-color: #00274A;
}
@media (max-width: 1920px) {
  .sub-final-wrap .box-wrap .service-box {
    width: 2500px;
    background-color: #00274A;
  }
}
.sub-final-wrap .box-wrap .service-box .service-text,
.service-link {
  width: 50%;
  color: #fff;
}
.sub-final-wrap .box-wrap .service-box .service-text h4{
  font-size: 34px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 16px;
}
.sub-final-wrap .box-wrap .service-box .service-text span{
  font-size: 20px;
  color: #DDDDDD;
  line-height: 25.2px;
}
.sub-final-wrap .box-wrap .service-box .service-link{
  width: 200px;
  height: 60px;
  padding: 18px 24px 18px 42px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #FFFFFF;
  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 52px;
}
.sub-final-wrap .box-wrap .service-box .service-link a{
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
}
.sub-final-wrap .final-title-wrap .main-final-top{
/* background: linear-gradient(180deg, #FFFFFF 50%, #FCEBC3 100%); */
background: linear-gradient(180deg, rgba(243, 246, 249, 0) 0%, #FCEBC3 100%);
padding-bottom: 150px;
}
.sub-final-wrap .main-final-content{
  margin-top: 160px;
}
.pick-home .sub-final-wrap .box-wrap{
  padding: 0px !important;
}

/* 마크에이전시 */
/* 공통 */
.sub_wrap_all .subsection{
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sub_wrap_all .subsection .subarticle{
  width: 1440px;
  margin: 0px auto;
}

.sub_wrap_all .subsection .pricetable-wrap .title_h2{
  color: #050f2f;
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  text-align: center;
  line-height: 140%;
}
.sub_wrap_all .subsection .pricetable-wrap .title_p{
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", "sans-serif";
  text-align: center;
  margin: 20px 0 50px;
  line-height: 140%;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title >th{
  padding: 32px 16px 24px;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title th:nth-child(2) h3{
  color: #050E2F;
  font-size: 24px;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title th:nth-child(3) h3{
  color: #FF7900;
  font-size: 24px;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title th:nth-child(4) h3{
  color: #0ECDC1;
  font-size: 24px;
}
.sub_wrap_all .subsection .pricetable-wrap .subprice_table .price-info-title h3:last-child{
  color: #00BF7A;
  font-size: 24px;
}
.sub_wrap_all .subsection .price-info-title > th >p:not(.price-font){
  padding: 16px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #E1E1E1;
  line-height: 140%;
  color: #858585;
}
.sub_wrap_all .subsection  .price-info-title > th > .price-info-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.sub_wrap_all .subsection .price-font{
  border-top: none;
  padding-top: 0;
  color: #555555;
}
.sub_wrap_all .subsection .price-day{
  color: #686868;
}
.sub_wrap_all .subsection .price-radio-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub_wrap_all .subsection .price-radio-wrap div{
  display: flex;
  gap: 10px;
}
.sub_wrap_all .subsection .price-radio-wrap input[type="radio"]{
  margin: 0px;
}
.sub_wrap_all .subsection .price-radio-wrap label{
  color: #878787;
  font-size: 14px;
  font-family: "PretendardR", "sans-serif";
}
/* 라디오버튼 커스텀 */
.sub_wrap_all .subsection .price-radio-wrap .P_radio01{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc; 
  border-radius: 50%;
  outline: none; 
  cursor: pointer;
}
.sub_wrap_all .subsection .price-radio-wrap .P_radio01:checked {
  background-color: #0ECDC1; 
  border: 3px solid white; 
  box-shadow: 0 0 0 1.6px #E1E1E1; 
}
.sub_wrap_all .subsection .price-radio-wrap .P_radio02{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc; 
  border-radius: 50%;
  outline: none; 
  cursor: pointer;
}
.sub_wrap_all .subsection .price-radio-wrap .P_radio02:checked {
  background-color: #00BF7A; 
  border: 3px solid white; 
  box-shadow: 0 0 0 1.6px #E1E1E1; 
}
.sub_wrap_all .subsection .none-height{
  height: 15px;
}
.subsection .subprice_table tr > td:first-child{
  padding: 12px 10px;
}
/* 셀프 상표 서브 2번섹션 */
.sub_wrap_all .newselfSection02{
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 120px 0 120px 0;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll{
  display: flex;
  align-items: center;
  width: 1440px;
  height: 508px;
  background: linear-gradient(90deg, rgba(255, 232, 177, 0.3) 0%, #F8BD34 100%);
  margin: 0px auto;
  border-radius: 8px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box{
  /* width: calc(50% - 90px); */
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .right-box p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 150%;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li{
  width: 370px;
  height: 140px;
  background: #fff;
  border-radius: 8px;
  padding: 22px 0 0 40px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:not(.li-last){
  opacity: 0.4;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #050f2f;
  line-height: 140%;
}
.sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul li:last-child{
  width: 420px;
  height: 170px;
  position: absolute;
  top: 50%;
  left: 40%;
  background: #fff !important;
  transform: translate(0, -50%);
  padding: 30px 50px;
  box-shadow: 0px 4px 18px rgba(248, 189, 52, 0.4);
}
/* .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last h3{
  font-size: 24px;
  margin-bottom: 9px;
} */
/* .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p{
  position: relative;
  font-size: 22px;
  color: #666666;
} */
/* .sub_wrap_all .newselfSection02 .selfcontent-articleAll .left-box .left-box-ul .li-last .li-last-p img{
  position: absolute;
  right: -95px;
  bottom: 60px;
} */
/* 서브 4번 컨텐츠 3개짜리 */
.sub_wrap_all .newselfSection04{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0 80px 0;
}

.sub_wrap_all .newselfSection04 .selfcontent-articleAll{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px auto 160px; */
  gap: 0px;
}
/* .sub_wrap_all .newselfSection04 .selfcontent-article03{
  margin-bottom: 80px;
} */
.sub_wrap_all .newselfSection04 .selfcontent-article01{
  margin-bottom: 80px;
}
.sub_wrap_all .newselfSection04 .selfcontent-article02{
  margin: 80px 0 80px 0;
}
.sub_wrap_all .newselfSection04 .selfcontent-article03{
  margin-top: 80px;
}
.sub_wrap_all .newselfSection04 .selfcontent-articleAll .left-box{
  /* width: 50%; */
  width: 760px;
}
.sub_wrap_all .newselfSection04 .selfcontent-article01 .left-box01{
  background: #FFF3D7;
  border-radius: 8px;
  height: 480px;
  padding: 75px 27px;
}
.sub_wrap_all .newselfSection04 .left-box02{
  width: 50%;
}
.sub_wrap_all .newselfSection04 .selfcontent-article01 .left-box01 img{
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sub_wrap_all .newselfSection04 .selfcontent-article01 .left-box01 div{
  /* background: #383838; */
  width: 100%;
  height: 100%;
}
.sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box{
  width: 41%;
}
.sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box02{
  width: 55%;
}
.sub_wrap_all .newselfSection04 .selfcontent-articleAll .right-box02 img{
  width: 100%;
}
.sub_wrap_all .newselfSection04 .selfcontent-article02 .right-box01{
  /* background: #efc35b; */
  border-radius: 8px;
  /* height: 500px; */
}
.sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01{
  background: #f4e8c8;
  border-radius: 8px;
  /* height: 500px; */
}
.sub_wrap_all .newselfSection04 .selfcontent-article03 .left-box01 div{
  height: 100%;
  color: #fff;
}

.sub_wrap_all .newselfSection04 .selftextbox-All strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .newselfSection04 .selftextbox-All h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .newselfSection04 .selftextbox-All p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 140%;
}





/* 탭목록 */
.sub_wrap_all .Newself-wrap{
  background: #FFF7E4;
  padding: 120px 0;
}
.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox span{
  margin-bottom: 70px;
  word-break: keep-all;
}
.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
  margin: 0px;
}

.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 620px;
  height: 108px;
  border-radius: 8px;
  margin-bottom: 0px;
}

.sub_wrap_all .Newself-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
  margin: 0px;
  margin-top: 0px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:first-child img{
  width: 55px;
  height: 42px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img{
  width: 60px;
  height: 43px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img{
  width: 47px;
  height: 49px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img{
  width: 54px;
  height: 42px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img{
  width: 47px;
  height: 52px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .sub-title-point{
  color: #319dff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox h2{
  color: #050F2F;
  font-size: 48px;
  font-weight: bold;
  line-height: 140%;
  margin: 12px 0px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox span{
  font-size: 26px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
  display: flex;
  align-items: center;
  gap: 90px;
  line-height: 140%;
  margin-bottom: 8px;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small{
  color: #858585;
  font-size: 11px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .Newself-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
  line-height: 140%;
}

/* 서브테이블 */
.sub_wrap_all .subsection .pricetable-wrap .sub-title-point{
  color: #319dff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .subsection .pricetable-wrap h2{
  color: #050F2F;
  font-size: 42px;
  font-weight: bold;
  line-height: 140%;
  margin: 12px 0px;
}
.sub_wrap_all .subsection .pricetable-wrap span{
  font-size: 14px;
}
.sub_wrap_all .selfTrade-contentBox05{
  padding: 120px 0;
}
.sub_wrap_all .subsection .pricetable-wrap .table-text-last{
  color: #050F2F;
}

/* 서브 마지막 */
.sub-final-wrap {
  width: 100%;
}
.sub-final-wrap .final-title-wrap {
  margin-bottom: 0px;
}
.sub-final-wrap .final-title-wrap,
.sub-final-wrap .final-title-wrap h6,
.sub-final-wrap .final-title-wrap h5,
.sub-final-wrap .final-title-wrap h5 span {
  font-family: "PretendardB", sans-serif;
  font-size: 48px;
}
.sub-final-wrap .final-title-wrap h6 {
  line-height: 46px;
  color: #050F2F;
  margin-bottom: 4px;
}
.sub-final-wrap .final-title-wrap h5 {
  margin-bottom: 40px;
  text-align: center;
  line-height: 150%;
  font-size: 52px;
}
.sub-final-wrap .final-title-wrap h5 span {
  line-height: 70px;
}
.sub-final-wrap .final-title-wrap .main-final-move{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 365px;
  height: 74px;
  border-radius: 100px;
  margin: 0px auto;
  background: #319dff;
  font-size: 24px;
  font-family: "PretendardSB", "sans-serif";
  color: #fff;
  box-shadow: 0px 8px 18px rgba(49, 157, 255, 0.18);
  border-radius: 90px;
  gap: 18px;
}
/* .sub-final-wrap .final-title-wrap .box-wrap{
  width: 1920px;
} */
.sub-final-wrap .box-wrap > .row > .column * {
  color: white;
}
.sub-final-wrap .box-wrap > .row > .column h4 {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  font-size: 26px;
  line-height: 24px;
  margin-bottom: 29px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.sub-final-wrap .box-wrap > .row > .column > span {
  line-height: 24px;
  margin-bottom: 16px;
}
.sub-final-wrap .box-wrap > .row > .column .direct-btn {
  cursor: pointer;
}
.sub-final-wrap .box-wrap > .row > .column .direct-btn span {
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  margin-right: 12px;
}
.sub-final-wrap .box-wrap > .row > .column .direct-btn img {
  width: 8px;
  height: 12px;
}
.sub-final-wrap .box-wrap > .row > .column.self-box {
  background-color: rgba(255, 194, 0, 0.75);
  margin-right: 20px;
}
.sub-final-wrap .box-wrap > .row > .column.agencies-box {
  background-color: rgba(255, 142, 0, 0.75);
}
.sub-final-wrap .box-wrap > .row > .column > img {
  position: absolute;
  right: 50px;
  bottom: 26px;
  z-index: 1;
  width: 80px;
  height: 124px;
}
.sub-final-wrap .box-wrap .guide-box {
  position: relative;
  margin: 20px 0 20px;
  width: 1280px;
  height: 350px;
  background-image: url("image/guideBg02.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius:8px;
}
.sub-final-wrap .box-wrap .guide-box .guide-content{
  width:502px;
  height: 150px;
  position: absolute;
  right: 265px;
  bottom: 99px;
}
.sub-final-wrap .box-wrap .guide-box p {
  font-size: 20px;
  line-height: 32px;
  color: #000;
  text-align: left;
  margin-bottom: 40px;
}
.sub-final-wrap .box-wrap .guide-box .go-guide {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 46px;
  padding: 12px 24px;
  border: 1px solid #050F2F;
  border-radius: 2px;
  cursor: pointer;
}
.sub-final-wrap .box-wrap .guide-box .go-guide span {
  margin-right: 12px;
  font-family: "NotoSansM", "맑은 고딕", "sans-serif";
  line-height: 32px;
  color: #333333;
}
.sub-final-wrap .box-wrap .service-box{
  display: flex;
  flex-direction:row;
  justify-content: center;
  width: 100%;
  height: 190px;
  /* padding: 83px 216px 80px 104px; */
  padding: 0;
  background-color: #00274A;
}
@media (min-width: 2500px) {
  .sub-final-wrap .box-wrap .service-box {
    width: 2500px;
    background-color: #00274A;
  }
  .sub-final-wrap .final-title-wrap .box-wrap{
    width: 2500px;
  }
}

.sub-final-wrap .box-wrap .service-box .service-text,
.service-link {
  width: 50%;
  color: #fff;
}
.sub-final-wrap .box-wrap .service-box .service-text h4{
  font-size: 36px;
  font-family: "PretendardB", "sans-serif";
  color: #fff;
  line-height: 140%;
  margin-bottom: 16px;
}
.sub-final-wrap .box-wrap .service-box .service-text span{
  font-size: 18px;
  color: #DDDDDD;
}
.sub-final-wrap .box-wrap .service-box .service-link{
  width: 200px;
  height: 56px;
  padding: 18px 24px 18px 42px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #FFFFFF;
  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 52px;
}
.sub-final-wrap .box-wrap .service-box .service-link a{
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  font-family: "PretendardM", "sans-serif";
  color: #fff;
}
.sub-final-wrap .final-title-wrap .main-final-top{
/* background: linear-gradient(180deg, #FFFFFF 50%, #FCEBC3 100%); */
padding-bottom: 150px;
}
.sub-final-wrap .main-final-content{
  margin-top: 160px;
}
.pick-home .sub-final-wrap .box-wrap{
  padding: 0px !important;
}


/* 에이전시 페이지 */
/* 셀프 상표 서브 2번섹션 */
.sub_wrap_all .agencySection02{
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 106px 0;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll{
  display: flex;
  align-items: center;
  width: 1440px;
  height: 508px;
  background: linear-gradient(283.67deg, #E0ECFF -6.97%, #A3BFFF 68.13%, #316BFF 107.68%);
  margin: 0px auto;
  border-radius: 8px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  padding-right: 185px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .right-box p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 150%;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
/* swiper custome*/
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper {
  width: 100%;
  height: 468px;
  margin: 0;
  overflow: visible;
  position: relative;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
  letter-spacing: -0.002em;
  font-weight: 700;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #666666;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box-ul .li_mt{
  margin-left: 40px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide {
  width: 370px;
  height: 140px !important;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  opacity: 0.4 !important; 
  background-color: #FFFFFF;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  position: absolute;
  transform: translate3d(0px, 183px, 0px) !important;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide img {
  display: none;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active img {
  display: block;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active h3 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.002em;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active p {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
  position: absolute;
  top: -70px;
  right: -40px;
  width: 140px;
  height: 140px;
  z-index: 5;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active {
  z-index: 1;
  background-color: #FFFFFF;
  opacity: 1 !important;
  width: 420px;
  height: 170px !important; 
  position: relative;
  transform: translate3d(110px, 169px, 0px) !important;
  transition: opacity 0.5s ease-in-out !important;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-active .li_mt{
  margin-left: 50px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next {
  transform: translate3d(0px, 20px, 0px) !important;
  
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-prev {
  transform: translate3d(0px, 20px, 0px) !important;
  
  transition-duration: 0ms !important; 
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next + .swiper-slide  {
  transform: translate3d(0px, 183px, 0px) !important; 
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-next + .swiper-slide + .swiper-slide {
  transform: translate3d(0px, 348px, 0px) !important; 
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .swiper-slide-prev + .swiper-slide  + .swiper-slide {
  transform: translate3d(0px, 348px, 0px)!important; 
}
/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .agencySection02{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
/* .sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 17px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul li{
  width: 370px;
  height: 140px;
  background: #fff;
  border-radius: 8px;
  padding: 22px 0 0 40px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul li:not(.li-last){
  opacity: 0.4;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul li h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul li p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #050f2f;
  line-height: 140%;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul li:last-child{
  width: 420px;
  height: 170px;
  position: absolute;
  top: 50%;
  left: 40%;
  background: #fff !important;
  transform: translate(0, -50%);
  padding: 30px 50px;
  box-shadow: 0px 4px 18px rgba(49, 107, 255, 0.4);
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul .li-last h3{
  font-size: 24px;
  margin-bottom: 9px;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul .li-last .li-last-p{
  position: relative;
  font-size: 22px;
  color: #666666;
}
.sub_wrap_all .agencySection02 .agencycontent-articleAll .left-box .left-box-ul .li-last .li-last-p img{
  position: absolute;
  right: -95px;
  bottom: 60px;
} */

/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .agencySection04{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sub_wrap_all .agencySection04 .agencycontent-articleAll{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px auto 150px; */
  margin: 80px auto 80px;
  gap: 0px;
}

  .sub_wrap_all .agencySection04 .agencycontent-article04{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 130px  150px;
  gap: 0px;
}
.sub_wrap_all .agencySection04 .lawyer_bg_side{
  margin: 130px  240px;
}
@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1920px 0;
  }
}
.sub_wrap_all .agencySection04 .background_wrapper {
  background-image: url('../../image/lawyer_bg.svg');
  background-repeat: repeat-x;
  animation: moveBackground 30s linear infinite; 
  background-size: 3050px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes moveBackground {
  from {
    background-position: 1000 0;
  }
  to {
    background-position: -3050px 0;
  }
}
.sub_wrap_all .agencySection04 .agencycontent-articleAll .left-box{
  width: 760px;
}
.sub_wrap_all .agencySection04 .agencycontent-article01 .left-box01{
  background: #83A6FF;
  border-radius: 8px;
  height: 480px;
  padding: 75px 27px;
  display: flex;
  align-items: center;
}
.sub_wrap_all .agencySection04 .left-box02{
  width: 50%;
}
.sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box{
  width: 41%;
}
.sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box02{
  width: 55%;
}
.sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box02 img{
  width: 100%;
}
.sub_wrap_all .agencySection04 .agencycontent-article02 .right-box01{
  border-radius: 8px;
}
.sub_wrap_all .agencySection04 .agencycontent-article03 .left-box01{
  border-radius: 8px;
}
.sub_wrap_all .agencySection04 .agencycontent-article03 .left-box01 div{
  height: 100%;
  color: #fff;
}
.sub_wrap_all .agencySection04 .agencycontent-article04 .agencytextbox-All .sub-text{
  font-size:32px;
}
.sub_wrap_all .agencySection04 .agencytextbox-All strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .agencySection04 .agencytextbox-All h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .agencySection04 .agencytextbox-All p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 140%;
  white-space: nowrap;
}
.sub_wrap_all .agencySection04 .agencytextbox-All .sub-title-point{
  color: #319dff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .agencySection04 .agencytextbox-All .sub-title-h3{
    color: #050F2F;
    font-size: 56px;
    font-weight: bold;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .agencySection04 .agencytextbox-All .point-color{
  color: #050F2F;
  font-size: 32px;
  font-weight: bold;
  line-height: 150%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .agencySection04 .right-box02 .agency_right_h3{
  display: flex;
  align-items: center;
  gap: 4px;
  color: #050F2F;
  font-size: 26px;
  font-weight: bold;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 8px;
}
.sub_wrap_all .agencySection04 .right-box02 .agency_right_arrow{
  width: 32px !important;
}
.sub_wrap_all .agencySection04 .right-box02 ul li:first-child{
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.sub_wrap_all .agencySection04 .right-box02 ul li > p{
  position: relative;
  color: #666666;
  padding-left: 73px;
  margin-bottom: 8px;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .agencySection04 .agencycontent-articleAll .right-box-text01{
  width:41%;
}
.sub_wrap_all .agencySection04 .right-box02 ul li > p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 42%;
  left: 10%;
}
.sub_wrap_all .agencySection04 .right-box02 ul li > .last_p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 13%;
  left: 10%;
}

/* 에이전시 텝목록 */
.sub_wrap_all .agency-wrap{
  background: #F1F6FF;
  padding: 120px 0;
}
.sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox span{
  margin-bottom: 80px;
  word-break: keep-all;
  line-height: 150%;
  letter-spacing: -0.002em;
}
.sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
  margin: 0px;
}
.sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 620px;
  height: 108px;
  border-radius: 8px;
  margin-bottom: 0px;
}
/* .sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:hover {
  border: 3px solid #316bff;
  transition:none;
} */
.sub_wrap_all .agency-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
  margin: 0px;
  margin-top: 0px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:first-child img{
  width: 55px;
  height: 42px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img{
  width: 60px;
  height: 43px;
  margin-right: -3px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img{
  width: 47px;
  height: 49px;
  margin-left: 11px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img{
  width: 54px;
  height: 42px;
  margin-left: 4px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img{
  width: 47px;
  height: 52px;
  margin-left: 12px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .sub-title-point{
  color: #319dff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox h2{
  color: #050F2F;
  font-size: 48px;
  font-weight: bold;
  line-height: 140%;
  margin: 12px 0px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox span{
  font-size: 26px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
  display: flex;
  align-items: center;
  gap: 90px;
  line-height: 140%;
  margin-bottom: 8px;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small{
  color: #858585;
  font-size: 11px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .agency-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
  line-height: 140%;
}
/* 마지막섹션 백그라운드 */
.sub-final-wrap .final-title-wrap .agency-final-top{
  background: linear-gradient(180deg, rgba(243, 246, 249, 0) 0%, #B7D4FF 100%);
}
/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */

/* 브렌드네이밍 */

.sub_wrap_all .brandSection02{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 106px;
  padding-bottom: 186px;
}

.sub_wrap_all .brandSection02 .brandcontent-articleAll{
  display: flex;
  align-items: center;
  width: 1440px;
  height: 508px;
  background: linear-gradient(283.67deg, #FFA37C -6.97%, #FFEDE5 107.68%);
  margin: 0px auto;
  border-radius: 8px;
}


.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  padding-right: 141px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  letter-spacing: -0.002em;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
  letter-spacing: -0.002em;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .right-box p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 150%;
  letter-spacing: -0.002em;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
/* swiper custome*/
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper {
  width: 100%;
  height: 468px;
  margin: 0;
  overflow: visible;
  position: relative;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
  letter-spacing: -0.002em;
  font-weight: 700;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #050f2f;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box-ul .li_mt{
  margin-left: 40px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide {
  width: 370px;
  height: 140px !important;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  opacity: 0.4 !important; 
  background-color: #FFFFFF;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  position: absolute;
  transform: translate3d(0px, 183px, 0px) !important;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide img {
  display: none;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active img {
  display: block;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active h3 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.002em;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active p {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
  position: absolute;
  top: -70px;
  right: -40px;
  width: 140px;
  height: 140px;
  z-index: 5;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active {
  z-index: 1;
  background-color: #FFFFFF;
  opacity: 1 !important;
  width: 420px;
  height: 170px !important; 
  position: relative;
  transform: translate3d(110px, 169px, 0px) !important;
  transition: opacity 0.5s ease-in-out !important;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active .li_mt{
  justify-content: center;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next {
  transform: translate3d(0px, 20px, 0px) !important;
  
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev {
  transform: translate3d(0px, 20px, 0px) !important;
  
  transition-duration: 0ms !important; 
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next + .swiper-slide  {
  transform: translate3d(0px, 183px, 0px) !important; 
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-next + .swiper-slide + .swiper-slide {
  transform: translate3d(0px, 348px, 0px) !important; 
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-prev + .swiper-slide  + .swiper-slide {
  transform: translate3d(0px, 348px, 0px)!important; 
}
/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .monitorSection04{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide p{
  font-family: "PretendardR", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide b{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active p{
  font-family: "PretendardR", sans-serif;
  font-size: 26px;
  color: #050f2f;
  line-height: 140%;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .swiper-slide-active b{
  font-family: "PretendardB", sans-serif;
  font-size: 26px;
  color: #050F2F;
}
/* .sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul li{
  width: 370px;
  height: 140px;
  background: #fff;
  border-radius: 8px;
  padding: 22px 0 0 40px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul li:not(.li-last){
  opacity: 0.4;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul li h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul li p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #050f2f;
  line-height: 140%;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul li:last-child{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 170px;
  position: absolute;
  top: 50%;
  left: 40%;
  background: #fff !important;
  transform: translate(0, -50%);
  padding: 0;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul .li-last h3{
  font-size: 24px;
  margin-bottom: 9px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul .li-last .li-last-p{
  position: relative;
  font-size: 26px;
  font-family: "PretendardR", sans-serif;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul .li-last .li-last-p b{
  font-family: "PretendardB", sans-serif;
  font-size: 26px;
}
.sub_wrap_all .brandSection02 .brandcontent-articleAll .left-box .left-box-ul .li-last .li-last-p img{
  position: absolute;
  right: -101px;
  bottom: 66px;
} */

/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .brandSection04{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sub_wrap_all .brandSection04 .brandcontent-articleAll{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto 150px;
  gap: 0px;
}
.sub_wrap_all .brandSection04 .brandcontent-articleAll .left-box{
  width: 760px;
}
.sub_wrap_all .brandSection04 .brandcontent-article01 .left-box01{
  background: #FFEDE5;
  border-radius: 8px;
  height: 480px;
  padding: 75px 27px;
  display: flex;
  align-items: center;
}
.sub_wrap_all .brandSection04 .left-box02{
  width: 50%;
}

.sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box{
  width: 42%;
}
.sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box02{
  width: 55%;
}
.sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box02 img{
  width: 100%;
}
.sub_wrap_all .brandSection04 .brandcontent-article02 .right-box01{
  border-radius: 8px;
}
.sub_wrap_all .brandSection04 .brandcontent-article03 .left-box01{
  border-radius: 8px;
}
.sub_wrap_all .brandSection04 .brandcontent-article03 .left-box01 div{
  height: 100%;
  color: #fff;
}

.sub_wrap_all .brandSection04 .brandtextbox-All strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .brandSection04 .brandtextbox-All h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .brandSection04 .brandtextbox-All p{
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .brandSection04 .brandtextbox-All .sub-title-point{
  color: #319dff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .brandSection04 .brandtextbox-All .sub-title-h3{
    color: #050F2F;
    font-size: 56px;
    font-weight: bold;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .brandSection04 .brandtextbox-All .point-color{
  color: #050F2F;
  font-size: 32px;
  font-weight: bold;
  line-height: 150%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .brandSection04 .right-box02 .brand_right_h3{
  display: flex;
  align-items: center;
  gap: 4px;
  color: #050F2F;
  font-size: 26px;
  font-weight: bold;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 8px;
}
.sub_wrap_all .brandSection04 .right-box02 .brand_right_arrow{
  width: 32px !important;
}
.sub_wrap_all .brandSection04 .right-box02 ul li:first-child{
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.sub_wrap_all .brandSection04 .right-box02 ul li > p{
  position: relative;
  color: #666666;
  padding-left: 73px;
  margin-bottom: 8px;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .brandSection04 .brandcontent-articleAll .right-box-text01{
  width:41%;
}
.sub_wrap_all .brandSection04 .right-box02 ul li > p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 42%;
  left: 10%;
}
.sub_wrap_all .brandSection04 .right-box02 ul li > .last_p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 13%;
  left: 10%;
}

/* 마지막섹션 백그라운드 */
.sub-final-wrap .final-title-wrap .brand-final-top{
  background: linear-gradient(180deg, rgba(243, 246, 249, 0) 0%, #FED8C8 100%);
}

.sub-final-wrap .final-title-wrap .main-final-top{
  padding-bottom: 160px;
}

/* 포텐셜 */
.sub-final-wrap .final-title-wrap .potential-final-top{
  background: linear-gradient(180deg, rgba(243, 246, 249, 0) 0%, #B9E7E9 100%);
}

.sub_wrap_all .potentialSection02{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll{
  display: flex;
  align-items: center;
  width: 1440px;
  height: 508px;
  background: linear-gradient(283.67deg, #D2F7DF -6.97%, #BBEEF0 107.68%);
  margin: 0px auto;
  margin-bottom: 186px;
  border-radius: 8px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  padding-right: 149px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .right-box p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 150%;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
/* swiper custome*/
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper {
  width: 100%;
  height: 468px;
  margin: 0;
  overflow: visible;
  position: relative;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
  letter-spacing: -0.002em;
  font-weight: 700;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #666666;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box-ul .li_mt{
  margin-left: 40px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide {
  width: 370px;
  height: 140px !important;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  opacity: 0.4 !important; 
  background-color: #FFFFFF;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  position: absolute;
  transform: translate3d(0px, 183px, 0px) !important;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide img {
  display: none;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active img {
  display: block;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active h3 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.002em;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active p {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
  position: absolute;
  top: -70px;
  right: -40px;
  width: 140px;
  height: 140px;
  z-index: 5;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active {
  z-index: 1;
  background-color: #FFFFFF;
  opacity: 1 !important;
  width: 420px;
  height: 170px !important; 
  position: relative;
  transform: translate3d(110px, 169px, 0px) !important;
  transition: opacity 0.5s ease-in-out !important;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-active .li_mt{
  margin-left: 50px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next {
  transform: translate3d(0px, 20px, 0px) !important;
  
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-prev {
  transform: translate3d(0px, 20px, 0px) !important;
  
  transition-duration: 0ms !important; 
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next + .swiper-slide  {
  transform: translate3d(0px, 183px, 0px) !important; 
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-next + .swiper-slide + .swiper-slide {
  transform: translate3d(0px, 348px, 0px) !important; 
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .swiper-slide-prev + .swiper-slide  + .swiper-slide {
  transform: translate3d(0px, 348px, 0px)!important; 
}
/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .potentialSection02{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 106px;
}
/* .sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul li{
  width: 370px;
  height: 140px;
  background: #fff;
  border-radius: 8px;
  padding: 22px 0 0 40px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul li:not(.li-last){
  opacity: 0.4;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul li h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul li p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #050f2f;
  line-height: 140%;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul li:last-child{
  width: 420px;
  height: 170px;
  position: absolute;
  top: 50%;
  left: 40%;
  background: #fff !important;
  transform: translate(0, -50%);
  padding: 30px 0 0 50px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul .li-last h3{
  font-size: 24px;
  margin-bottom: 9px;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul .li-last .li-last-p{
  position: relative;
  font-size: 22px;
  color: #666666;
}
.sub_wrap_all .potentialSection02 .potencontent-articleAll .left-box .left-box-ul .li-last .li-last-p img{
  position: absolute;
  right: -40px;
  bottom: 60px;
} */

/* 포텐셜4번 */

/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .potentialSection04{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sub_wrap_all .potentialSection04 .potencontent-articleAll{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto 150px;
  gap: 80px;
}
.sub_wrap_all .potentialSection04 .pontencontent-article01 .left-box{
  width: 760px;
}
.sub_wrap_all .potentialSection04 .pontencontent-article01 .left-box01{
  background: #BBEEF0;
  border-radius: 8px;
  height: 480px;
  padding: 75px 27px;
  display: flex;
  align-items: center;
}
.sub_wrap_all .potentialSection04 .left-box02{
  width: 50%;
}

.sub_wrap_all .potentialSection04 .pontencontent-article01 .right-box{
  width: 41%;
}
.sub_wrap_all .potentialSection04 .pontencontent-article01 .right-box02{
  width: 55%;
}
.sub_wrap_all .potentialSection04 .pontencontent-article01 .right-box02 img{
  width: 100%;
}
.sub_wrap_all .potentialSection04 .pontencontent-article02 .right-box01{
  border-radius: 8px;
}
.sub_wrap_all .potentialSection04 .pontencontent-article03 .left-box01{
  border-radius: 8px;
}
.sub_wrap_all .potentialSection04 .pontencontent-article03 .left-box01 div{
  height: 100%;
  color: #fff;
}

.sub_wrap_all .potentialSection04 .potentextbox-All strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .potentialSection04 .potentextbox-All h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .potentialSection04 .potentextbox-All p{
  color: #666666;
  font-size: 26px;
  font-family: "PretendardR", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .potentialSection04 .potentextbox-All .sub-title-point{
  color: #319dff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .potentialSection04 .potentextbox-All .sub-title-h3{
    color: #050F2F;
    font-size: 56px;
    font-weight: bold;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .potentialSection04 .potentextbox-All .point-color{
  color: #050F2F;
  font-size: 32px;
  font-weight: bold;
  line-height: 150%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .potentialSection04 .right-box02 .agency_right_h3{
  display: flex;
  align-items: center;
  gap: 4px;
  color: #050F2F;
  font-size: 26px;
  font-weight: bold;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 8px;
}
.sub_wrap_all .potentialSection04 .right-box02 .agency_right_arrow{
  width: 32px !important;
}
.sub_wrap_all .potentialSection04 .right-box02 ul li:first-child{
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.sub_wrap_all .potentialSection04 .right-box02 ul li > p{
  position: relative;
  color: #666666;
  padding-left: 73px;
  margin-bottom: 8px;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .potentialSection04 .pontencontent-article01 .right-box-text01{
  width:41%;
}
.sub_wrap_all .potentialSection04 .right-box02 ul li > p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 42%;
  left: 10%;
}
.sub_wrap_all .potentialSection04 .right-box02 ul li > .last_p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 13%;
  left: 10%;
}

/* 포텐 탭메뉴 */
.sub_wrap_all .poten-wrap{
  background: #F1FDF5;
  padding: 120px 0;
}
@media (min-width: 2500px) {
  .sub_wrap_all .poten-wrap {
    width: 2500px;
    background: #F1FDF5;
  }
}
.sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox span{
  margin-bottom: 70px;
  word-break: keep-all;
}
.sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left img {
  margin: 0px;
  min-height: 468px;
}
.sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 620px;
  height: 107px;
  border-radius: 8px;
  margin-bottom: 13px;
}
/* .sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:hover {
  border: 3px solid #1CC4CB;
  transition:none;
} */
.sub_wrap_all .poten-wrap .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li img{
  margin: 0px;
  margin-top: 0px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:first-child img{
  width: 60px;
  height: 48px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(2) img{
  width: 60px;
  height: 43px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(3) img{
  width: 47px;
  height: 49px;
  margin-left: 10px;
  margin-right: 3px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:nth-child(4) img{
  width: 54px;
  height: 42px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li:last-child img{
  width: 47px;
  height: 52px;
  margin-left: 12px;
  margin-right: 2px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .sub-title-point{
  color: #319dff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox h2{
  color: #050F2F;
  font-size: 48px;
  font-weight: bold;
  line-height: 140%;
  margin: 12px 0px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox span{
  font-size: 26px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li h3{
  display: flex;
  align-items: center;
  gap: 90px;
  line-height: 140%;
  margin-bottom: 8px;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content small{
  color: #858585;
  font-size: 11px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .poten-wrap  .selfTrade-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right ul li .hover-content p{
  line-height: 140%;
}
.selfTradeName-wrap .poten-contentBox04 .content-oneBox .subpage-hoverBox{
  width: 100%;
  display: flex;
  align-items: center;
}
/* .selfTradeName-wrap .poten-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-left{
  width: 42%;
} */
/* .selfTradeName-wrap .poten-contentBox04 .content-oneBox .subpage-hoverBox .hoverimg-right{
  width: 25%;
} */
/* 마지막 */
.sub-final-wrap .final-title-wrap .main-final-top{
  padding-bottom: 160px;
}
.sub-final-wrap .final-title-wrap .potential-final-top .main-final-move{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 445px;
  height: 74px;
  border-radius: 100px;
  margin: 0px auto;
  background: #319dff;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 8px 18px rgba(49, 157, 255, 0.18);
  border-radius: 90px;
  gap: 18px;
}

/* 모니터링 */
.sub_wrap_all .monitorSection02{
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 106px;
  padding-top: 106px;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll{
  display: flex;
  align-items: center;
  width: 1440px;
  height: 508px;
  background: linear-gradient(283.67deg, #BBEEF0 -6.97%, #EAE6FD 107.68%);
  margin: 0px auto;
  border-radius: 8px;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box{
  /* width: calc(50% - 114px); */
  width: 100%;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box{
  /* width: 50%;
  padding-left: 150px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  padding-right: 211px;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .right-box p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 150%;
}
/* .sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .left-box-ul{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 17px;
} */

/* swiper custome*/
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper {
  width: 100%;
  height: 468px;
  margin: 0;
  overflow: visible;
  position: relative;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box  {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box h3{
  font-family: "PretendardB", sans-serif;
  font-size: 20px;
  color: #050f2f;
  line-height: 140%;
  margin-bottom: 12px;
  letter-spacing: -0.002em;
  font-weight: 700;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box p{
  font-family: "PretendardR", sans-serif;
  font-size: 18px;
  color: #666666;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box-ul{
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box-ul .li_mt{
  margin-left: 40px;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide {
  width: 370px;
  height: 140px !important;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  opacity: 0.4 !important; 
  background-color: #FFFFFF;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  position: absolute;
  transform: translate3d(0px, 183px, 0px) !important;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide img {
  display: none;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active img {
  display: block;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active h3 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.002em;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active p {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.002em;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide .left-boximg-overlay {
  position: absolute;
  top: -70px;
  right: -40px;
  width: 140px;
  height: 140px;
  z-index: 5;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active {
  z-index: 1;
  background-color: #FFFFFF;
  opacity: 1 !important;
  width: 420px;
  height: 170px !important; 
  position: relative;
  transform: translate3d(110px, 169px, 0px) !important;
  transition: opacity 0.5s ease-in-out !important;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-active .li_mt{
  margin-left: 50px;
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next {
  transform: translate3d(0px, 20px, 0px) !important;
  
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-prev {
  transform: translate3d(0px, 20px, 0px) !important;
  
  transition-duration: 0ms !important; 
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next + .swiper-slide  {
  transform: translate3d(0px, 183px, 0px) !important; 
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-next + .swiper-slide + .swiper-slide {
  transform: translate3d(0px, 348px, 0px) !important; 
}
.sub_wrap_all .monitorSection02 .monitorcontent-articleAll .left-box .swiper-slide-prev + .swiper-slide  + .swiper-slide {
  transform: translate3d(0px, 348px, 0px)!important; 
}
/* 서브 4번 컨텐츠 4개짜리 */
.sub_wrap_all .monitorSection04{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sub_wrap_all .monitorSection04 .monitorcontent-articleAll{
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 0;
  gap: 0px;
}
.sub_wrap_all .monitorSection04 .monitorcontent-articleAll .left-box{
  width: 760px;
}
.sub_wrap_all .monitorSection04 .monitorcontent-article01 .left-box01{
  background: #EAE6FD;
  border-radius: 8px;
  height: 480px;
  padding: 75px 27px;
  display: flex;
  align-items: center;
}
.sub_wrap_all .monitorSection04 .left-box02{
  width: 50%;
}

.sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box{
  width: 41%;
}
.sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box02{
  width: 55%;
}
.sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box02 img{
  width: 100%;
}
.sub_wrap_all .monitorSection04 .monitorcontent-article02 .right-box01{
  border-radius: 8px;
}
.sub_wrap_all .monitorSection04 .monitorcontent-article03 .left-box01{
  border-radius: 8px;
}
.sub_wrap_all .monitorSection04 .monitorcontent-article03 .left-box01 div{
  height: 100%;
  color: #fff;
}

.sub_wrap_all .monitorSection04 .monitortextbox-All strong{
  color: #319DFF;
  font-size: 28px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .monitorSection04 .monitortextbox-All h3{
  color: #050F2F;
  font-size: 42px;
  font-family: "PretendardB", sans-serif;
  line-height: 140%;
  margin: 8px 0 24px;
}
.sub_wrap_all .monitorSection04 .monitortextbox-All p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", sans-serif;
  line-height: 140%;
}
.sub_wrap_all .monitorSection04 .monitortextbox-All .sub-title-point{
  color: #319dff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .monitorSection04 .monitortextbox-All .sub-title-h3{
    color: #050F2F;
    font-size: 56px;
    font-weight: bold;
    line-height: 140%;
    font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .monitorSection04 .monitortextbox-All .point-color{
  color: #050F2F;
  font-size: 32px;
  font-weight: bold;
  line-height: 150%;
  font-family: "PretendardB", "sans-serif";
}
.sub_wrap_all .monitorSection04 .right-box02 .agency_right_h3{
  display: flex;
  align-items: center;
  gap: 4px;
  color: #050F2F;
  font-size: 26px;
  font-weight: bold;
  line-height: 140%;
  font-family: "PretendardB", "sans-serif";
  margin-bottom: 8px;
}
.sub_wrap_all .monitorSection04 .right-box02 .agency_right_arrow{
  width: 32px !important;
}
.sub_wrap_all .monitorSection04 .right-box02 ul li:first-child{
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.sub_wrap_all .monitorSection04 .right-box02 ul li > p{
  position: relative;
  color: #666666;
  padding-left: 73px;
  margin-bottom: 8px;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
}
.sub_wrap_all .monitorSection04 .monitorcontent-articleAll .right-box-text01{
  width:41%;
}
.sub_wrap_all .monitorSection04 .right-box02 ul li > p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 42%;
  left: 10%;
}
.sub_wrap_all .monitorSection04 .right-box02 ul li > .last_p::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666666;
  position: absolute;
  top: 13%;
  left: 10%;
}
.sub-final-wrap .final-title-wrap .monitor-final-top{
  background: linear-gradient(180deg, rgba(243, 246, 249, 0) 0%, #EAE6FD 100%);
}

/* 모니터섹션6 */
.monitorSection06{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.monitorSection06 .monitorcontent-article01{
  width: 100%;
  background: #F2FCFC;
  padding: 120px 0;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-top h2{
  font-size: 42px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
  color: #050f2f;
  text-align: center;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-top h2 span{
  color: #7460D4;
  font-size: 42px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-top p{
  color: #666666;
  font-size: 28px;
  font-family: "PretendardR", "sans-serif";
  line-height: 140%;
  margin: 24px 0 56px;
  text-align: center;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-bot{
  width: 1080px;
  margin: 0px auto;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul{
  display: flex;
  align-items: center;
  gap: 60px;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 320px;
  padding: 40px 24px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li .monitor-article06-ul-li-div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li span{
  width: 69px;
  height: 26px;
  background: #7460D4;
  border-radius: 30px;
  color: #fff;
  font-size: 14px;
  font-family: "PretendardB", "sans-serif";
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 8px;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li h3{
  color: #050F2F;
  font-size: 28px;
  font-family: "PretendardB", "sans-serif";
  line-height: 140%;
  margin-bottom: 16px;
}
.monitorSection06 .monitorcontent-article01 .monitor-article06-bot .monitor-article06-ul li p{
  color: #666666;
  font-size: 20px;
  font-family: "PretendardR", "sans-serif";
  line-height: 150%;
  text-align: center;
}






/* 마지막 */
.sub-final-wrap .final-title-wrap .main-final-top{
  padding-bottom: 160px;
}
.sub-final-wrap .final-title-wrap .monitor-final-top .main-final-move{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 445px;
  height: 74px;
  border-radius: 100px;
  margin: 0px auto;
  background: #319dff;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 8px 18px rgba(49, 157, 255, 0.18);
  border-radius: 90px;
  gap: 18px;
}
